import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrmService} from './crm.service';

@Injectable()
export class AgendaService {

  public usuarioSeleccionadoAgenda: string;

  constructor(public http: HttpClient,
              public crmservice: CrmService) {

    console.log('AgendaService');
    this.usuarioSeleccionadoAgenda = this.crmservice.userLogin._id;
  }

  public dameAccionesCalendario() {
    const accionesCalendario = [];

    for (const accion of this.crmservice.acciones) {
      if (accion.de.indexOf(this.usuarioSeleccionadoAgenda) > -1 || accion.para.indexOf(this.usuarioSeleccionadoAgenda) > -1) {
        switch (accion.hito) {
          case 'accionTienda':
            accionesCalendario.push({
              title: 'Tienda - ' + this.dameObjetivoTienda(accion.objetivo),
              start: accion.fechaInicio,
              end: accion.fechaFin,
              color: '#384950',
              textColor: 'white',
              id: accion._id,
              estado: accion.estado
            });
            break;
          case 'accionPedido':
            accionesCalendario.push({
              title: accion.objetivo === '200' ? 'Pedido' : 'Presupuesto',
              start: accion.fechaInicio,
              end: accion.fechaFin,
              color: '#384950',
              textColor: 'white',
              id: accion._id,
              estado: accion.estado
            });
            break;
          case 'accionDV':
            accionesCalendario.push({
              title: 'DV - ' + accion.solicitudDV + ' - ' + this.dameObjetivoDV(accion.objetivo),
              start: accion.fechaInicio,
              end: accion.fechaFin,
              color: '#cf3476',
              textColor: 'white',
              id: accion._id,
              estado: accion.estado
            });
            break;
          default:
            accionesCalendario.push({
              title: this.dameHito(accion.hito) + ' - ' + this.dameNombreComercial(accion.direccion),
              start: accion.estado === '0' ? accion.fechaInicio : accion.fechaPrevista,
              end: accion.estado === '0' ? accion.fechaFin : null,
              color: this.dameColor(accion.estado, accion.de),
              textColor: this.dameTextColor(accion.estado, accion.de),
              id: accion._id,
              estado: accion.estado,
              hito: accion.hito
            });
            break;
        }
      }
    }

    return accionesCalendario;
  }
  public dameNombreComercial(direccion: string) {
    const pos = this.crmservice.direcciones.findIndex(e => e._id === direccion);
    if (pos !== -1) {
      return this.crmservice.direcciones[pos].nombreComercial;
    }
  }
  public dameHito(hito: string): string {
    const pos = this.crmservice.hitos.findIndex(e => e.id === hito);
    if (pos !== -1) {
      return this.crmservice.hitos[pos].nombre;
    }
  }
  public dameColor(estado, de) {
    let color = '';
    switch (estado) {
      case '0':
        color = '#ff1500'; // Finalizada
        break;
      case '1': // Sin asignar
        color = '#00ff7b'; // Enviada pero no asignada
        break;
      case '2':
        if (de.indexOf(this.usuarioSeleccionadoAgenda) > -1) { // Asignada
          color = '#b2b8ff'; // Es mía/Del usuario que se está consultando
        } else {
          color = '#ff9000'; // Está asignada por otro usuario distinto del que se está consultando
        }

        break;
    }

    return color;
  }
  public dameTextColor(estado: string, de) {
    let color = '';
    switch (estado) {
      case '0':
        color = 'white';
        break;
      case '1':
        color = 'black';
        break;
      case '2':
        if (de.indexOf(this.usuarioSeleccionadoAgenda) > -1) {
          color = 'black';
        } else {
          color = 'white';
        }
    }

    return color;
  }
  public dameObjetivoTienda(objetivo: string) {
    const pos = this.crmservice.objetivosTienda.findIndex(e => e.id === objetivo);
    if (pos !== -1) {
      return this.crmservice.objetivosTienda[pos].nombre;
    }
    return 'Desconocido';
  }
  public dameObjetivoDV(objetivo: string) {
    const pos = this.crmservice.objetivosDV.findIndex(e => e.id === objetivo);
    if (pos !== -1) {
      return this.crmservice.objetivosDV[pos].nombre;
    }
    return 'Desconocido';
  }
}
