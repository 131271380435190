import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UsuariosEquipoComponent} from './usuarios-equipo/usuarios-equipo.component';

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css']
})
export class EquipoComponent implements OnInit {

  @Input() public estado: string;
  @Input() public equipo: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public get img(): string {
    return 'assets/img/markers/' + this.equipo + '/' + this.estado + '.png';
  }

  public usuariosEquipo(): void {
    this.dialog.open(UsuariosEquipoComponent, {
      height: 'auto',
      width: '800px',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {equipo: this.equipo}
    });
  }
}
