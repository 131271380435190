import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {Actualizacion} from '../../modelos/Actualizacion';
import {CrmService} from '../../servicios/crm.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-nueva-actualizacion',
  templateUrl: './nueva-actualizacion.component.html',
  styleUrls: ['./nueva-actualizacion.component.css']
})
export class NuevaActualizacionComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargandoDatos: boolean;

  public mensajeError: string;

  public version: string;
  public fecha: string;
  public titulo: string;

  public secciones: {
    id: string
    titulo: string,
    cambios: {
      id: string,
      texto: string
    }[]
  }[];

  constructor(public crmservice: CrmService,
              public dialogRef: MatDialogRef<NuevaActualizacionComponent>,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargandoDatos = false;
    this.mensajeError = '';

    this.secciones = [];
    this.secciones.push({
      id: Math.random().toString(36).substr(2),
      titulo: '',
      cambios: [{
        texto: '',
        id: Math.random().toString(36).substr(2)
      }]
    });

  }

  ngOnInit() {
  }

  public guardar(): void {
    if (this.comprobarFormulario()) {

      this.cargandoDatos = true;

      const actualizacion = new Actualizacion();
      actualizacion.version = this.version;
      actualizacion.usuarios = [];
      actualizacion.titulo = this.titulo;
      actualizacion.fecha = this.fachaToStr(new Date(this.fecha));
      actualizacion.autor = this.crmservice.userLogin._id;
      actualizacion.secciones = [];

      for (const seccion of this.secciones) {
        const nSeccion = {
          titulo: seccion.titulo,
          cambios: []
        };
        for (const cambio of seccion.cambios) {
          nSeccion.cambios.push(cambio.texto);
        }
        actualizacion.secciones.push(nSeccion);
      }

      this.crmservice.nuevaActualizacion(actualizacion).subscribe(
        () => {
          this.cargandoDatos = false;
          this.dialogRef.close();
        }, error => {
          console.error(error);
          this.cargandoDatos = false;
        });
    }
  }
  public comprobarFormulario(): boolean {

    if (this.version == null || !this.version.trim().length) {
      this.mensajeError = 'Introduce la versión.';
      return false;
    }

    if (this.fecha == null || !this.fecha.trim().length) {
      this.mensajeError = 'Introduce la fecha.';
      return false;
    }

    if (this.titulo == null || !this.titulo.trim().length) {
      this.mensajeError = 'Introduce el título de la versión.';
      return false;
    }

    for (const seccion of this.secciones) {
      if (seccion.titulo == null || !seccion.titulo.trim().length) {
        this.mensajeError = 'Hay una sección sin título.';
        return false;
      }
      for (const cambio of seccion.cambios) {
        if (cambio.texto == null || !cambio.texto.trim().length) {
          this.mensajeError = 'Hay un cambio sin texto';
          return false;
        }
      }
    }

    this.mensajeError = '';
    return true;
  }

  public addSeccion(): void {
    this.secciones.push({
      id: Math.random().toString(36).substr(2),
      titulo: '',
      cambios: [{
        texto: '',
        id: Math.random().toString(36).substr(2)
      }]
    });
  }
  public removeSeccion(id): void {
    const pos = this.secciones.findIndex(e => e.id === id);
    console.log(pos);
    if (pos !== -1) {
      this.secciones.splice(pos, 1);
    }
  }

  public addCambio(seccion: any): void {
    seccion.cambios.push({
      texto: '',
      id: Math.random().toString(36).substr(2)
    });
  }
  public removeCambio(seccion: any, idCambio: string): void {
    const posCambio = seccion.cambios.findIndex(e => e.id === idCambio);
    seccion.cambios.splice(posCambio, 1);
  }

  public fachaToStr(fecha: Date): string {
    let dia: string;
    let mes: string;
    let year: string;

    if (fecha.getDate() <= 9) {
      dia = '0' + fecha.getDate();
    } else {
      dia = fecha.getDate().toString(10);
    }
    if ((fecha.getMonth() + 1) <= 9) {
      mes = '0' + (fecha.getMonth() + 1);
    } else {
      mes = (fecha.getMonth() + 1).toString(10);
    }

    year = fecha.getFullYear().toString(10);

    return dia + '-' + mes + '-' + year;
  }
}
