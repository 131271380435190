import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import {Calendar} from 'fullcalendar';
import {AgendaService} from '../../servicios/agenda.service';
import {PosponerAccionFormularioComponent} from '../ficha-cliente/formularios/posponer-accion-formulario/posponer-accion-formulario.component';
import {Accion} from '../../modelos/Accion';
import {MediaMatcher} from '@angular/cdk/layout';
import {HistoricoAccionesComponent} from '../ficha-cliente/formularios/historico-acciones/historico-acciones.component';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit, AfterViewInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public calendar: Calendar;
  public tareasCargadas: boolean;

  public calendarioDOM: any;

  displayedColumns: string[] = ['fecha', 'medio', 'objetivo', 'hito', 'cliente'];
  public dataSource: MatTableDataSource<Accion> = new MatTableDataSource();

  constructor(public crmservice: CrmService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private router: Router,
              public agendaservice: AgendaService,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    console.log('Agenda');

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.tareasCargadas = false;
  }

  ngAfterViewInit(): void {
    this._inicializarCalendario();
  }

  ngOnInit() {
  }

  public cambioUsuario(): void {
    this.tareasCargadas = false;
    this.calendar.removeAllEvents();
    this._cargarAccionesUsuario()
      .then(() => {
        this._cargarAccionesPendientes()
          .then(() => this.tareasCargadas = true)
          .catch(() => this.tareasCargadas = true);
      })
      .catch(() => this.tareasCargadas = true);
  }

  public getMedio(medio: string): string {
    const medioFind = this.crmservice.medios.find(e => e.id.toString() === medio);
    return medioFind ? medioFind.nombre : 'Sin Medio';
  }
  public getObjetivo(objetivo: string): string {
    const objFind = this.crmservice.objetivos.find(e => e.id.toString() === objetivo);
    return objFind ? objFind.nombre : 'Sin Objetivo';
  }
  public getHito(hito: string): string {
    const hitoFind = this.crmservice.hitos.find(e => e.id.toString() === hito);
    return hitoFind ? hitoFind.nombre : 'Sin Hito';
  }
  public getDireccion(direccion: string): string {
    const dirFind = this.crmservice.direcciones.find(e => e._id === direccion);
    return dirFind ? dirFind.nombreComercial : 'Sin Direccion';
  }
  public irAccion(idAccion: string): void {
    this.crmservice.direccionSeleccionada[0] = false;
    this.router.navigate(['accion', idAccion]).then();
  }

  private _inicializarCalendario(): void {
    if (this.crmservice == null || !this.crmservice.datosCargados) {
      window.setTimeout(this._inicializarCalendario, 100);
    } else {
      this._iniAccionesUsuario()
        .then(() => {
          this._cargarAccionesPendientes()
            .then(() => {
              this._iniCalendar();
              this.tareasCargadas = true;
            })
            .catch(() => this.tareasCargadas = true);
        })
        .catch(() => this.tareasCargadas = true);
    }
  }
  private _iniAccionesUsuario(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.crmservice.acciones == null || !this.crmservice.acciones.length) {
        this.agendaservice.usuarioSeleccionadoAgenda = this.crmservice.userLogin._id;
        this.crmservice.getAccionesUser(this.agendaservice.usuarioSeleccionadoAgenda).subscribe(
          valueAcciones => {
            this.crmservice.acciones = valueAcciones;
            resolve();
          }, error => reject(error));
      } else {
        resolve();
      }
    });
  }
  private _cargarAccionesUsuario(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crmservice.getAccionesUser(this.agendaservice.usuarioSeleccionadoAgenda).subscribe(
        value => {
          this.crmservice.acciones = value;
          this.calendar.addEventSource({events: this.agendaservice.dameAccionesCalendario()});
          resolve();
        }, error => reject(error));
    });
  }
  private _cargarAccionesPendientes(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crmservice.getAccionesPendientes(this.agendaservice.usuarioSeleccionadoAgenda).subscribe(
        value => {
          this.dataSource.data = value;
          resolve();
        }, error => reject(error));
    });
  }
  private _iniCalendar(): void {
    this.calendarioDOM = document.getElementById('calendario');
    this.calendar = new Calendar(this.calendarioDOM, {
      locale: 'es',
      firstDay: 1,
      eventLimitText: 'más',
      buttonText: {
        today: 'Hoy'
      },
      height: 'auto',
      views: {
        month: {
          eventLimit: 8
        }
      },
      editable: true,
      selectable: true,
      eventSources: [{
        events: this.agendaservice.dameAccionesCalendario()
      }],
      eventClick: arg => { this._eventClickCalendar(arg); },
      eventDrop: arg => { this._eventDragCalendar(arg); }
    });
    this.calendar.render();
  }
  private _eventClickCalendar(arg): void {
    if (arg.event.backgroundColor !== '#ff1500' && arg.event.backgroundColor !== '#384950') {
      this.crmservice.direccionSeleccionada[0] = false;
      this.router.navigate(['accion', arg.event.id]).then();
    } else if (arg.event.backgroundColor === '#ff1500') {
      // ABRIR EL HISTORICO
      this.tareasCargadas = false;
      this.crmservice.getClienteCompletoAccionN(arg.event.id).subscribe(
        response => {
          this.tareasCargadas = true;

          const accion = this.crmservice.acciones.find(e => e._id === arg.event.id);
          const direcciones = response[1];
          const acciones = response[2];
          const personas = response[3];

          this.dialog.open(HistoricoAccionesComponent, {
            height: '600px',
            width: '700px',
            panelClass: 'custom-dialog-container',
            autoFocus: false,
            data: {
              direcciones: direcciones,
              acciones: acciones,
              direccion: accion.direccion,
              hito: accion.hito,
              personas: personas
            }
          });
        }, error => {
          console.error(error);
          this.tareasCargadas = true;
        });
    }
  }
  private _eventDragCalendar(arg): void {
    if (arg.event.backgroundColor === '#b2b8ff') {
      const pos = this.crmservice.acciones.findIndex(e => e._id === arg.event.id);
      if (pos !== -1) {
        const dialogRef = this.dialog.open(PosponerAccionFormularioComponent, {
          height: '600px',
          width: '700px',
          data: {
            accion: this.crmservice.acciones[pos],
            agenda: true,
            fechaPrevista: arg.event.start
          }
        });

        dialogRef.afterClosed().subscribe(
          value => {
            if (value != null) {
              arg.event.setStart(value.fechaPrevista);
            } else {
              arg.revert();
            }
          }
        );
      } else {
        arg.revert();
      }
    } else {
      arg.revert();
    }
  }
}

