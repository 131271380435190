import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MediaMatcher} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Cliente} from '../../../../modelos/Cliente';

@Component({
  selector: 'app-historial-config-licencias-cli',
  templateUrl: './historial-config-licencias-cli.component.html',
  styleUrls: ['./historial-config-licencias-cli.component.css']
})
export class HistorialConfigLicenciasCliComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cliente: Cliente;

  constructor(private crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<HistorialConfigLicenciasCliComponent>) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cliente = data.cliente;

  }

  ngOnInit() {
  }

  public dameNombreUsuario(id: string): string {
    const pos = this.crmservice.usuarios.findIndex(e => e._id === id);
    if (pos !== -1) {
      return this.crmservice.usuarios[pos].nombre + ' ' + this.crmservice.usuarios[pos].apellidos.split(' ')[0];
    } else {
      return 'Desconocido';
    }
  }

}
