<div id="container">
  <div *ngIf="mensaje_login != null" class="position-absolute text-center alert alert-warning w-100">
    <mat-icon class="float-end" role="button" (click)="mensaje_login = null">close</mat-icon>
    <strong>{{mensaje_login}}</strong>
  </div>
  <mat-card class="card bg-light">
    <mat-card-header>
      <mat-card-title>
        <img id="img-card" src="../../../assets/img/DormaCRM_invertido.png" alt="">
        <span id="version"><strong>{{crmservice.version}}</strong></span>
      </mat-card-title>
    </mat-card-header>
    <form action="">
      <mat-card-content>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Usuario</mat-label>
          <label class="w-100 m-0">
            <input name="nombreUsuario" [(ngModel)]="login.nombreUsuario" matInput placeholder="Usuario" value="">
          </label>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Contraseña</mat-label>
          <label class="w-100 m-0">
            <input name="password" [(ngModel)]="login.password" matInput type="password" placeholder="Contraseña" value="">
          </label>
        </mat-form-field>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="recuerdame" name="Recuérdame" [(ngModel)]="recuerdame">
          <label class="form-check-label" for="recuerdame">Mantenerme logueado</label>
        </div>

      </mat-card-content>
      <mat-card-actions>

        <button type="submit" color="primary" (click)="iniciarSesion()" class="full-width" mat-raised-button [disabled]="cargando">
          <span *ngIf="cargando" class="spinner-border spinner-border-sm text-light"></span>
          INICIAR SESIÓN
        </button>

      </mat-card-actions>
    </form>
  </mat-card>
</div>
