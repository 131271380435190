<div *ngIf="!cargandoDatos && !mobileQuery.matches" class="m-lg-5 m-2">
  <h2>Clasificación de Clientes</h2>
  <mat-tab-group dynamicHeight class="mat-tab-group">
    <!-- CLASIFICACION -->
    <mat-tab label="Clasificacion">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">font_download</mat-icon>
        Clasificacion
      </ng-template>

      <div class="tab-content">
        <div class="alert alert-info text-center m-0-auto">
          <h6>
            Clasificación desde <strong>{{ configuracion.fDesde }}</strong> hasta <strong>{{ configuracion.fHasta }}</strong> ({{
              dataSourceClasificacion.data.length
            }}
            clientes/potenciales)
          </h6>
        </div>

        <div class="mt-3">
          <div class="form-group float-end mb-3">
            <label>
              Buscar...
              <input class="form-control" (keyup)="applyFilter($event.target.value, dataSourceClasificacion)" />
            </label>
          </div>
          <div class="float-start">
            <label>
              Mostrar
              <select class="form-select mb-3" [(ngModel)]="crmservice.rankingMostrar" (ngModelChange)="recargar()">
                <option *ngFor="let opcion of crmservice.opcionesRankingMostrar" [ngValue]="opcion.id">
                  {{ opcion.opcion }}
                </option>
              </select>
            </label>
          </div>
        </div>

        <table
          mat-table
          [(dataSource)]="dataSourceClasificacion"
          matSortActive="totalFacturado"
          matSortDirection="desc"
          matSort
          class="mb-3 w-100 shadow-sm border border-light"
        >
          <ng-container matColumnDef="ranking">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Ranking</th>
            <td mat-cell *matCellDef="let cliente">
              <strong>{{ cliente.ranking }}</strong>
            </td>
          </ng-container>

          <ng-container matColumnDef="clasificacion">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Clas.</th>
            <td mat-cell *matCellDef="let cliente" class="class-char" [ngStyle]="{ color: dameColorClasificacion(cliente.clasificacion) }">
              <span (click)="abrirRankingDetalle(cliente)" class="letra-clasificacion">
                {{ dameClasificacion(cliente.clasificacion) }}<span *ngIf="!cliente.automatico">*</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="cif">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>CIF</th>
            <td mat-cell *matCellDef="let cliente" (click)="navegarANuevaPest(cliente.primeraDir)">{{ cliente.cif }}</td>
          </ng-container>

          <ng-container matColumnDef="razonSocial">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Razon Social</th>
            <td mat-cell *matCellDef="let cliente" title="{{ cliente.razonSocial }}" (click)="navegarANuevaPest(cliente.primeraDir)">
              <span>{{ truncarString(cliente.razonSocial, 20) }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="provincia">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Provincia</th>
            <td mat-cell *matCellDef="let cliente" (click)="navegarANuevaPest(cliente.primeraDir)">{{ cliente.provincia }}</td>
          </ng-container>

          <ng-container matColumnDef="totalFacturado">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Facturado</th>
            <td mat-cell *matCellDef="let cliente" (click)="navegarANuevaPest(cliente.primeraDir)">{{ formatNumber(cliente.totalFacturado) }} €</td>
          </ng-container>

          <ng-container matColumnDef="totalFacturadoAnt">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Facturado (año ant.)</th>
            <td mat-cell *matCellDef="let cliente" (click)="navegarANuevaPest(cliente.primeraDir)">
              {{ formatNumber(cliente.totalFacturadoAnt) }} €
            </td>
          </ng-container>

          <ng-container matColumnDef="diferencia">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Diferencia</th>
            <td
              mat-cell
              *matCellDef="let cliente"
              [ngStyle]="{ color: cliente.diferencia >= 0 ? 'green' : 'red' }"
              (click)="navegarANuevaPest(cliente.primeraDir)"
            >
              <strong>{{ formatNumber(cliente.diferencia) }} €</strong>
            </td>
          </ng-container>

          <ng-container matColumnDef="crecimiento">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Crecimiento</th>
            <td
              mat-cell
              *matCellDef="let cliente"
              [ngStyle]="{ color: cliente.crecimiento >= 0 ? 'green' : 'red' }"
              (click)="navegarANuevaPest(cliente.primeraDir)"
            >
              <strong>{{ formatNumber(cliente.crecimiento) }} %</strong>
              <span *ngIf="cliente.crecimiento >= 0">↑</span>
              <span *ngIf="cliente.crecimiento < 0">↓</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="CMI">
            <th class="text-center" mat-header-cell *matHeaderCellDef>CMI</th>
            <td mat-cell *matCellDef="let cliente">
              <button mat-icon-button [mat-menu-trigger-for]="menuInformesMatTable">
                <mat-icon>chevron_right</mat-icon>
              </button>
              <mat-menu #menuInformesMatTable="matMenu">
                <button (click)="irInforme(4, null, cliente.cliente)" mat-menu-item>
                  <mat-icon>show_chart</mat-icon>
                  <span>Evolución de consumo</span>
                </button>
                <button (click)="irInforme(5, null, cliente.cliente)" mat-menu-item>
                  <mat-icon>bar_chart</mat-icon>
                  <span>Comparativa de consumo</span>
                </button>
                <button (click)="irInforme(6, null, cliente.cliente)" mat-menu-item>
                  <mat-icon>list_alt</mat-icon>
                  <span>Cartera</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsClasificacion"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsClasificacion" class="row-tabla"></tr>
        </table>

        <button (click)="generarExcel()" class="btn btn-sm btn-success float-start">Generar EXCEL</button>

        <div class="text-start cuadro-resumen">
          <table class="w-100 text-center">
            <tr>
              <td>
                <span><strong>Total Facturado: </strong></span>
              </td>
              <td>
                <span
                  ><strong>{{ formatNumber(totalFacturado) }} €</strong></span
                >
              </td>
            </tr>
          </table>
        </div>

        <mat-paginator
          #paginator
          [pageSize]="20"
          [pageSizeOptions]="[20, 50, 100, 150, 200, 500, 1000, 2000]"
          [showFirstLastButtons]="true"
          class="mt-3"
        >
        </mat-paginator>
      </div>
    </mat-tab>

    <!-- 80-20 -->
    <mat-tab label="80-20">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">timeline</mat-icon>
        80-20 Dashboard
      </ng-template>

      <div class="tab-content">
        <div class="text-center">
          <label class="w-50">
            Facturado:
            <span
              ><strong>{{ porcentajeTotal }} %</strong></span
            >
            <input type="range" min="1" max="100" step="1" class="custom-range p-0" [(ngModel)]="porcentajeTotal" (ngModelChange)="drawPareto()" />
          </label>
        </div>

        <table class="tabla-grafica table table-bordered mt-5 shadow-sm">
          <tbody>
            <tr>
              <td class="position-relative">
                <strong>Facturado: {{ porcentajeTotal }} % ({{ formatNumber((totalFacturado * porcentajeTotal) / 100) }} €)</strong>
                <div class="grafico-80-20" [ngStyle]="{ width: porcentajeTotal + '%' }"></div>
              </td>
            </tr>
            <tr>
              <td class="position-relative">
                <strong
                  >Clientes: {{ res.length }} / {{ getClientesFacturacion() }}({{
                    formatNumber((res.length / getClientesFacturacion()) * 100)
                  }}
                  %)</strong
                >
                <div class="grafico-80-20" [ngStyle]="{ width: (res.length / getClientesFacturacion()) * 100 + '%' }"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div echarts [options]="chartOption" class="h-700px w-100"></div>

        <div class="text-end mt-4 mb-4 me-10">
          <button (click)="generarExcelPareto()" class="btn btn-success">Exportar EXCEL</button>
        </div>

        <table class="tabla-grafica table table-bordered">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>Clas.</th>
              <th>CIF</th>
              <th>Razón Social</th>
              <th>Provincia</th>
              <th>Total Facturado</th>
              <th>Facturado Ant.</th>
              <th>Diferencia</th>
              <th>Crecimiento</th>
              <th class="text-center">CMI</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cliente of clientesGrafica">
              <td class="text-center">
                <strong>{{ cliente.ranking }}</strong>
              </td>
              <td class="class-char" [ngStyle]="{ color: dameColorClasificacion(cliente.clasificacion) }">
                <span (click)="abrirRankingDetalle(cliente)" class="letra-clasificacion">
                  {{ dameClasificacion(cliente.clasificacion) }}<span *ngIf="!cliente.automatico">*</span>
                </span>
              </td>
              <td (click)="navegarA(cliente.primeraDir)">{{ cliente.cif }}</td>
              <td (click)="navegarA(cliente.primeraDir)">{{ cliente.provincia }}</td>
              <td title="{{ cliente.razonSocial }}" (click)="navegarA(cliente.primeraDir)">{{ truncarString(cliente.razonSocial, 20) }}</td>
              <td (click)="navegarA(cliente.primeraDir)">{{ formatNumber(cliente.totalFacturado) }} €</td>
              <td (click)="navegarA(cliente.primeraDir)">{{ formatNumber(cliente.totalFacturadoAnt) }} €</td>
              <td [ngStyle]="{ color: cliente.diferencia >= 0 ? 'green' : 'red' }" (click)="navegarA(cliente.primeraDir)">
                <strong>{{ formatNumber(cliente.diferencia) }} €</strong>
              </td>
              <td [ngStyle]="{ color: cliente.crecimiento >= 0 ? 'green' : 'red' }" (click)="navegarA(cliente.primeraDir)">
                <strong>{{ formatNumber(cliente.crecimiento) }} %</strong>
                <span *ngIf="cliente.crecimiento >= 0">↑</span>
                <span *ngIf="cliente.crecimiento < 0">↓</span>
              </td>
              <td class="text-center">
                <button mat-icon-button [matMenuTriggerFor]="menuInformes">
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <mat-menu #menuInformes="matMenu">
                  <button (click)="irInforme(4, null, cliente.cliente)" mat-menu-item>
                    <mat-icon>show_chart</mat-icon>
                    <span>Evolución de consumo</span>
                  </button>
                  <button (click)="irInforme(5, null, cliente.cliente)" mat-menu-item>
                    <mat-icon>bar_chart</mat-icon>
                    <span>Comparativa de consumo</span>
                  </button>
                  <button (click)="irInforme(6, null, cliente.cliente)" mat-menu-item>
                    <mat-icon>list_alt</mat-icon>
                    <span>Cartera</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <!-- CONFIGURACIÓN -->
    <mat-tab label="Configuracion">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">settings</mat-icon>
        Configuracion
      </ng-template>

      <div class="tab-content">
        <div class="row mt-5">
          <div class="col-12 mb-4">
            <h5>Configuración</h5>
          </div>

          <div class="col-6 form-group">
            <label class="w-75">
              Minimo Facturado (últimos 12 meses)
              <input class="form-control" type="number" [(ngModel)]="minimoFacturado" />
            </label>
          </div>

          <div class="col-6">
            <label class="w-75">
              F.Desde
              <input type="date" class="form-control" [(ngModel)]="fDesde" [disabled]="true" />
            </label>
          </div>

          <div class="col-6 form-group">
            <label class="w-75">
              Minimo
              <span class="form-control bg-light text-secondary">Mínimo: {{ formatNumber(minimoFacturado) }} €</span>
            </label>
          </div>

          <div class="col-6">
            <label class="w-75">
              F.Hasta
              <input type="date" class="form-control" [(ngModel)]="fHasta" [disabled]="true" />
            </label>
          </div>

          <div class="col-12">
            <hr />
          </div>

          <div class="col-12 mb-4">
            <h5>Hitos Necesarios</h5>
          </div>

          <div class="col-12 text-center">
            <div class="d-flex justify-content-around p-4 bg-white border border-light rounded shadow-sm w-80 m-0-auto">
              <button class="buttonContrato" (click)="hitoTCLA = !hitoTCLA" mat-button>
                <img *ngIf="hitoTCLA" src="../../../../assets/img/hitos/TCLA.png" class="img-hito" alt="" />
                <img *ngIf="!hitoTCLA" src="../../../../assets/img/hitos/TCLA_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoWV = !hitoWV" mat-button>
                <img *ngIf="hitoWV" src="../../../../assets/img/hitos/wallviewer.png" class="img-hito" alt="" />
                <img *ngIf="!hitoWV" src="../../../../assets/img/hitos/wallviewer_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hito3D = !hito3D" mat-button>
                <img *ngIf="hito3D" src="../../../../assets/img/hitos/3d.png" class="img-hito" alt="" />
                <img *ngIf="!hito3D" src="../../../../assets/img/hitos/3d_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoRevistas = !hitoRevistas" mat-button>
                <img *ngIf="hitoRevistas" src="../../../../assets/img/hitos/revistas.png" class="img-hito" alt="" />
                <img *ngIf="!hitoRevistas" src="../../../../assets/img/hitos/revistas_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoMuestras = !hitoMuestras" mat-button>
                <img *ngIf="hitoMuestras" src="../../../../assets/img/hitos/muestras.png" class="img-hito" alt="" />
                <img *ngIf="!hitoMuestras" src="../../../../assets/img/hitos/muestras_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoIdehabita = !hitoIdehabita" mat-button>
                <img *ngIf="hitoIdehabita" src="../../../../assets/img/hitos/ctm.png" class="img-hito" alt="" />
                <img *ngIf="!hitoIdehabita" src="../../../../assets/img/hitos/ctm_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoCLASS = !hitoCLASS" mat-button>
                <img *ngIf="hitoCLASS" src="../../../../assets/img/hitos/class.png" class="img-hito" alt="" />
                <img *ngIf="!hitoCLASS" src="../../../../assets/img/hitos/class_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoDVIRTUAL = !hitoDVIRTUAL" mat-button>
                <img *ngIf="hitoDVIRTUAL" src="../../../../assets/img/hitos/Idehabita.png" class="img-hito" alt="" />
                <img *ngIf="!hitoDVIRTUAL" src="../../../../assets/img/hitos/Idehabita_off.png" class="img-hito" alt="" />
              </button>

              <button class="buttonContrato" (click)="hitoBannerCreamostumueble = !hitoBannerCreamostumueble" mat-button>
                <img *ngIf="hitoBannerCreamostumueble" src="../../../../assets/img/hitos/banner_ctm.png" class="img-hito" alt="" />
                <img *ngIf="!hitoBannerCreamostumueble" src="../../../../assets/img/hitos/banner_ctm_off.png" class="img-hito" alt="" />
              </button>
            </div>
          </div>

          <div class="col-12 text-end">
            <span *ngIf="mensajeError != ''" class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>

          <div class="col-12 text-end mt-3">
            <button class="btn btn-success" (click)="actualizar()">Actualizar (puede tardar unos segundos)</button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="!cargandoDatos && mobileQuery.matches">
  <mat-card>
    <mat-card-content>
      <h4>Clasificación de Clientes</h4>
      <mat-tab-group dynamicHeight class="mat-tab-group custom-mat-tab-group">
        <mat-tab label="Clasificacion">
          <ng-template mat-tab-label> Clasificacion </ng-template>

          <div class="tab-content">
            <div class="col-12 text-end form-group p-0 mb-3">
              <label>
                Buscar...
                <input class="form-control-sm" (keyup)="applyFilter($event.target.value, dataSourceClasificacion)" />
              </label>
            </div>

            <div class="text-center mb-3">
              <span
                >Desde <strong>{{ configuracion.fDesde }}</strong> hasta <strong>{{ configuracion.fHasta }}</strong> ({{
                  dataSourceClasificacion.data.length
                }}
                clientes)</span
              >
            </div>

            <table
              mat-table
              [(dataSource)]="dataSourceClasificacion"
              matSortActive="totalFacturado"
              matSortDirection="desc"
              matSort
              class="w-100 mt-2 mb-2 shadow-sm border border-light"
            >
              <ng-container matColumnDef="ranking">
                <th class="text-center p-0 fs-x-small" mat-header-cell *matHeaderCellDef mat-sort-header>Pos.</th>
                <td mat-cell *matCellDef="let cliente" class="text-center p-0 fs-x-small">
                  <strong>{{ cliente.ranking }}.-</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="clasificacion">
                <th class="text-center fs-x-small" mat-header-cell *matHeaderCellDef mat-sort-header>Clas.</th>
                <td mat-cell *matCellDef="let cliente" class="fs-x-small" [ngStyle]="{ color: dameColorClasificacion(cliente.clasificacion) }">
                  <span (click)="abrirRankingDetalle(cliente)" class="letra-clasificacion">
                    <strong>{{ dameClasificacion(cliente.clasificacion) }}<span *ngIf="!cliente.automatico">*</span></strong>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="razonSocial">
                <th class="text-center fs-x-small" mat-header-cell *matHeaderCellDef mat-sort-header>R.S.</th>
                <td mat-cell *matCellDef="let cliente" title="{{ cliente.razonSocial }}" (click)="navegarA(cliente.primeraDir)" class="fs-x-small">
                  <span>{{ truncarString(cliente.razonSocial, 20) }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="totalFacturado">
                <th class="text-center fs-x-small" mat-header-cell *matHeaderCellDef mat-sort-header>Fact.</th>
                <td mat-cell *matCellDef="let cliente" (click)="navegarA(cliente.primeraDir)" class="fs-x-small">
                  {{ formatNumber(cliente.totalFacturado, false) }} € (<span [ngStyle]="{ color: cliente.crecimiento >= 0 ? 'green' : 'red' }"
                    >{{ formatNumber(cliente.crecimiento, false) }} %</span
                  >)
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsClasificacion"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsClasificacion" class="row-tabla"></tr>
            </table>

            <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 150, 200, 500, 1000, 2000]" [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </mat-tab>

        <mat-tab label="80-20">
          <ng-template mat-tab-label> 80-20 Dashboard </ng-template>

          <div class="tab-content">
            <div class="text-center">
              <label class="w-100">
                Facturado:
                <span
                  ><strong>{{ porcentajeTotal }} %</strong></span
                >
                <input
                  type="range"
                  min="1"
                  max="100"
                  step="1"
                  class="custom-range p-0"
                  [(ngModel)]="porcentajeTotal"
                  (ngModelChange)="drawPareto()"
                />
              </label>
            </div>

            <table class="tabla-grafica table table-bordered bt-4 shadow-sm">
              <tbody>
                <tr>
                  <td class="position-relative">
                    <strong>Facturado: {{ porcentajeTotal }} % ({{ formatNumber((totalFacturado * porcentajeTotal) / 100) }} €)</strong>
                    <div class="grafico-80-20" [ngStyle]="{ width: porcentajeTotal + '%' }"></div>
                  </td>
                </tr>
                <tr>
                  <td class="position-relative">
                    <strong
                      >Clientes: {{ res.length }} / {{ dataSourceClasificacion.data.length }} ({{
                        formatNumber((res.length / dataSourceClasificacion.data.length) * 100)
                      }}
                      %)</strong
                    >
                    <div class="grafico-80-20" [ngStyle]="{ width: (res.length / dataSourceClasificacion.data.length) * 100 + '%' }"></div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div echarts [options]="chartOption" class="h-400px w-100"></div>

            <table class="tabla-grafica-movil table table-bordered fs-10px">
              <thead>
                <tr>
                  <th>Pos</th>
                  <th>Clas.</th>
                  <th>R.S.</th>
                  <th>Fact.</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cliente of clientesGrafica">
                  <td class="text-center">
                    <strong>{{ cliente.ranking }}</strong>
                  </td>
                  <td [ngStyle]="{ color: dameColorClasificacion(cliente.clasificacion) }">
                    <span (click)="abrirRankingDetalle(cliente)" class="letra-clasificacion">
                      {{ dameClasificacion(cliente.clasificacion) }}<span *ngIf="!cliente.automatico">*</span>
                    </span>
                  </td>
                  <td title="{{ cliente.razonSocial }}" (click)="navegarA(cliente.primeraDir)">{{ truncarString(cliente.razonSocial, 20) }}</td>
                  <td (click)="navegarA(cliente.primeraDir)">
                    {{ formatNumber(cliente.totalFacturado, false) }} € (<span [ngStyle]="{ color: cliente.crecimiento >= 0 ? 'green' : 'red' }">{{
                      formatNumber(cliente.crecimiento, false)
                    }}</span
                    >)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <mat-tab label="Configuracion">
          <ng-template mat-tab-label> Configuracion </ng-template>

          <div class="tab-content">
            <div class="row mt-5">
              <div class="col-12 mb-4">
                <h5>Configuración</h5>
              </div>

              <div class="col-12 form-group">
                <label class="w-100">
                  Minimo Facturado (últimos 12 meses)
                  <input class="form-control" type="number" [(ngModel)]="minimoFacturado" />
                </label>
              </div>

              <div class="col-12 form-group">
                <label class="w-100">
                  Minimo
                  <span class="form-control bg-light text-secondary">Mínimo: {{ formatNumber(minimoFacturado) }}€</span>
                </label>
              </div>

              <div class="col-12">
                <label class="w-100">
                  F.Desde
                  <input type="date" class="form-control" [(ngModel)]="fDesde" [disabled]="true" />
                </label>
              </div>

              <div class="col-12">
                <label class="w-100">
                  F.Hasta
                  <input type="date" class="form-control" [(ngModel)]="fHasta" [disabled]="true" />
                </label>
              </div>

              <div class="col-12">
                <hr />
              </div>

              <div class="col-12 mb-4">
                <h5>Hitos Necesarios</h5>
              </div>

              <div class="col-3 p-0 mb-3">
                <button class="buttonContrato" (click)="hitoTCLA = !hitoTCLA" mat-button>
                  <img *ngIf="hitoTCLA" src="../../../../assets/img/hitos/TCLA.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoTCLA" src="../../../../assets/img/hitos/TCLA_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoWV = !hitoWV" mat-button>
                  <img *ngIf="hitoWV" src="../../../../assets/img/hitos/wallviewer.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoWV" src="../../../../assets/img/hitos/wallviewer_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hito3D = !hito3D" mat-button>
                  <img *ngIf="hito3D" src="../../../../assets/img/hitos/3d.png" class="img-hito" alt="" />
                  <img *ngIf="!hito3D" src="../../../../assets/img/hitos/3d_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoRevistas = !hitoRevistas" mat-button>
                  <img *ngIf="hitoRevistas" src="../../../../assets/img/hitos/revistas.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoRevistas" src="../../../../assets/img/hitos/revistas_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoMuestras = !hitoMuestras" mat-button>
                  <img *ngIf="hitoMuestras" src="../../../../assets/img/hitos/muestras.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoMuestras" src="../../../../assets/img/hitos/muestras_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoIdehabita = !hitoIdehabita" mat-button>
                  <img *ngIf="hitoIdehabita" src="../../../../assets/img/hitos/ctm.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoIdehabita" src="../../../../assets/img/hitos/ctm_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoCLASS = !hitoCLASS" mat-button>
                  <img *ngIf="hitoCLASS" src="../../../../assets/img/hitos/class.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoCLASS" src="../../../../assets/img/hitos/class_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoDVIRTUAL = !hitoDVIRTUAL" mat-button>
                  <img *ngIf="hitoDVIRTUAL" src="../../../../assets/img/hitos/Idehabita.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoDVIRTUAL" src="../../../../assets/img/hitos/Idehabita_off.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-3 p-0">
                <button class="buttonContrato" (click)="hitoBannerCreamostumueble = !hitoBannerCreamostumueble" mat-button>
                  <img *ngIf="hitoBannerCreamostumueble" src="../../../../assets/img/hitos/banner_ctm.png" class="img-hito" alt="" />
                  <img *ngIf="!hitoBannerCreamostumueble" src="../../../../assets/img/hitos/banner_ctm_off.png.png" class="img-hito" alt="" />
                </button>
              </div>

              <div class="col-12 text-center">
                <span *ngIf="mensajeError != ''" class="text-danger">
                  <strong>{{ mensajeError }}</strong>
                </span>
              </div>

              <div class="col-12 text-center mt-4">
                <button class="btn btn-success w-100" (click)="actualizar()">Actualizar (puede tardar unos segundos)</button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="cargandoDatos" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>
