<div class="cabecera-modal">
  <mat-icon class="icon-modal">help</mat-icon>
  <strong>PREGUNTA DE CLIENTE</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">

  <div *ngIf="!cargando" class="ps-md-5 pe-md-5 pt-md-4 p-2 m-0-auto d-flex flex-column"
       style="height: calc(100% - 55px); max-height: calc(100% - 55px); overflow-y: auto">

    <div class="mb-4 text-end text-secondary border-bottom">
      Desde {{pregunta.provinciaCliente}} ({{pregunta.cpCliente}})
    </div>

    <div *ngFor="let intervencion of pregunta.intervenciones" class="d-flex flex-row mb-5">
      <div class="d-inline-block me-2 txt-secondary" style="width: 100px; font-size: 14px">{{intervencion.fecha | date: 'dd/MM/yyyy HH:mm'}}</div>
      <div class="d-inline-block position-relative" style="width: calc(100% - 100px)">
        <div class="mb-2" [class.text-end]="intervencion.origen == 'tienda'">
          <b>{{intervencion.origen == 'cliente' ? pregunta.emailCliente : pregunta.respondida}}</b> {{intervencion.origen == 'cliente' ? 'pregunta' : 'responde'}}:
        </div>
        <div [ngClass]="intervencion.origen == 'cliente' ? 'intervencion-cliente' : 'intervencion-asesor'"></div>
        <div class="intervencion">
          <i>"{{intervencion.pregunta}}"</i>
        </div>
      </div>
    </div>

    <div class="mt-auto mb-2">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Escribe aquí tu respuesta...</mat-label>
        <textarea matInput [(ngModel)]="respuesta" rows="5"></textarea>
      </mat-form-field>
    </div>

  </div>

  <div *ngIf="!cargando" class="d-flex p-2 mt-auto border-top bg-light">
    <div class="btn btn-primary-crm ms-auto d-flex" [class.w-100]="mobileQuery.matches" (click)="enviar()">
      <mat-icon class="me-2">send</mat-icon>
      <span>Enviar Respuesta a Cliente</span>
    </div>
  </div>

  <div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
