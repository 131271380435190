<div class="m-xl-3 m-lg-3 m-md-2 m-sm-2 m-1" [hidden]="cargando">
    <div class="m-auto" style="width:98%">
    <h2>Supervisión Influencers</h2>
    </div>
    <div id="cabecera" class="d-flex">        
      <div class="d-inline-block ms-auto">
        <mat-form-field id="buscador" class="w-auto">
          <input type="text" autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="textoBusqueda" placeholder="Filtro">
        </mat-form-field>
      </div>
    </div>
  
    <div class="overflow-auto">
      <table mat-table [dataSource]="dataSource" matSortActive="razonSocial" matSortDirection="desc" id="tabla-ipdorma" matSort>
  
        <ng-container matColumnDef="razonSocial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Razón social</th>
          <td mat-cell *matCellDef="let direccion" class="p-1" >{{direccion.cliente.razonSocial}}</td>
        </ng-container>
  
        <ng-container matColumnDef="equipo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Equipo</th>
          <td mat-cell *matCellDef="let direccion" class="p-1">{{direccion.equipo}}</td>
        </ng-container>
  
        <ng-container matColumnDef="cliente.cif">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center p-1">CIF</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.cliente.cif}}</td>
        </ng-container>
  
        <ng-container matColumnDef="cliente.poblacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Población</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.poblacion}}</td>
        </ng-container>
  
        <ng-container matColumnDef="cliente.provincia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Provincia</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.provincia}}</td>
        </ng-container>
  
        <ng-container matColumnDef="cliente.fechaCreacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Fecha de Creación</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.fechaCreacion | date: "dd-MM-yyyy"}}</td>
        </ng-container>
  
        <ng-container matColumnDef="fechaRegistroEquipo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 text-center">Registro equipo</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.fechaRegistroEquipo | date: "dd-MM-yyyy"}}</td>
        </ng-container>
  
        <ng-container matColumnDef="ultimoHito">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 text-center">Último Hito</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.hitosObtenidos[0] !== undefined ? direccion.hitosObtenidos[direccion.hitosObtenidos.length - 1].hito : "-"}}</td>
        </ng-container>
  
        <ng-container matColumnDef="fechaUltimoHito">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">F. último hito</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle">{{direccion.hitosObtenidos[0] !== undefined ? (direccion.hitosObtenidos[direccion.hitosObtenidos.length - 1].fecha | date: "dd-MM-yyy") : "-"}}</td>
        </ng-container>
      
        <ng-container matColumnDef="fechaUltimaAccion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">F. última acción</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle text-center">{{direccion.ultimaAccion !== undefined ? (direccion.ultimaAccion | date: "dd-MM-yyy") : "-"}}</td>
        </ng-container>
        
        <ng-container matColumnDef="rolUltimaAccion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Rol última acción</th>
          <td mat-cell *matCellDef="let direccion" class="p-1 align-middle text-center">{{direccion.acciones[0] !== undefined ? direccion.acciones[0].de[0].rol : "-"}}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: auto;"></tr>
      </table>
    </div>
  
    <div id="paginacion-encargos">
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true" (page)="getPageEvent($event)"></mat-paginator>
    </div>
  
    <div id="texto-info" class="d-flex justify-content-between">
      <span class="text-secondary">
        *En este listado se muestran las direcciones tipo comercial o tienda que tienen asignado influencer, que lleven más de un día registrados sin acciones o que llevando 3 días desde el registro que no tengan ninguna acción creada por un supervisor, admin, pedidos o asesor. <br>
      </span>
    </div>
  </div>
  
  