<div class="cabecera-modal">
  <mat-icon class="icon-modal">warning</mat-icon>
  ALERTAS DE CLIENTE
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargandoDatos" class="p-5 pt-3">

    <div *ngIf="alertasCliente != null && alertasCliente.length" class="mt-4 mb-4 text-end">
      <button mat-button (click)="revisar()">
        <mat-icon class="me-1">search</mat-icon>
        COMPROBAR
      </button>
    </div>
    <table *ngIf="alertasCliente != null && alertasCliente.length" class="table table-striped">
      <thead class="text-center cabecera-tabla">
      <tr>
        <td>Fecha</td>
        <td>Gestionado</td>
        <td>Alerta</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let alerta of alertasCliente">
        <td class="align-middle">
          {{alerta.fecha | date: 'dd/MM/yyyy'}}
        </td>
        <td class="align-middle">
          <span *ngIf="alerta.gestionado != null">{{getUsuario(alerta.gestionado)}}</span>
        </td>
        <td class="align-middle">
          {{alerta.msg}}
        </td>
        <td class="align-middle">
          <button *ngIf="alerta.gestionado == null || crmservice.rol('admin')
          || (alerta.gestionado != null && alerta.gestionado == crmservice.userLogin._id)"
                  mat-icon-button [matMenuTriggerFor]="menuAlerta"
                  [matMenuTriggerData]="{alerta: alerta}">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="alertasCliente == null || !alertasCliente.length" class="alert alert-info mt-4 text-center">
      <span><strong>Todas las Alertas han sido resueltas.</strong></span>
    </div>

  </div>

  <div *ngIf="cargandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>

<mat-menu #menuAlerta="matMenu">
  <ng-template matMenuContent let-alerta="alerta">
    <button *ngIf="alerta.gestionado == null" (click)="adquirirAlerta(alerta)" mat-menu-item>
      <mat-icon>support_agent</mat-icon>
      <span>GESTIONAR ALERTA</span>
    </button>
    <button *ngIf="alerta.gestionado != null && (alerta.gestionado === crmservice.userLogin._id || crmservice.rol('admin'))"
            (click)="rechazarAlerta(alerta)" mat-menu-item>
      <mat-icon>highlight_off</mat-icon>
      <span>RECHAZAR ALERTA</span>
    </button>
  </ng-template>
</mat-menu>
