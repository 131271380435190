import { Component, OnInit } from "@angular/core";
import { CrmService } from "../../../servicios/crm.service";
import { Usuario } from "../../../modelos/Usuario";
import EChartOption = echarts.EChartOption;

import * as echarts from "echarts";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfigDNIeTienda } from "../../../modelos/ConfigDNIeTienda";

@Component({
  selector: "app-informes",
  templateUrl: "./informes.component.html",
  styleUrls: ["./informes.component.css"],
})
export class InformesComponent implements OnInit {
  public cargandoDatos = false;

  accionUsuarioParaMaximos: string = "";

  accionesAlfonso = [];

  chartOption: EChartOption;

  public xAxisData = [];
  public yAxisData = [];

  // --- INFORMACION DEL USUARIO --- //
  public usuarioSel: Usuario;
  public tiemposUsuario = [];
  public accionesUsuario = [];
  public jornadasUsuario = [];
  public noJustificado = [];

  public totalNoJustificado: string;

  // --- PARÁMETROS DEL FORMULARIO --- //
  public opcionesFormGroup: FormGroup;

  public fDesde: Date;
  public fHasta: Date;
  public tiempoExtra = false;
  public tiempoNoJustificado = true;
  public tiempoJornadas = true;

  public busquedaSimple = true;

  public mensajeInfo = "";

  // --- PARÁMETROS DE LA CONFIGURACIÓN --- //
  public configDNIeTiendas: ConfigDNIeTienda[];
  public addConfig: boolean;
  public nombreTienda: string;
  public errAddConfig: string;

  constructor(public crmservice: CrmService, public router: Router) {
    /*if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol != 'admin' && this.crmservice.userLogin.rol != 'supervisor') {
      this.router.navigate(['agenda']);
    }*/

    this.usuarioSel = this.crmservice.userLogin;
    this.fDesde = new Date();
    this.fDesde.setHours(0, 0, 0);
    this.fHasta = new Date();
    this.fHasta.setHours(23, 59, 0);

    this.totalNoJustificado = "";

    this.opcionesFormGroup = new FormGroup({
      fDesde: new FormControl("", []),
      fHasta: new FormControl("", []),
      tiempoAsignando: new FormControl("", []),
      tiempoJornadas: new FormControl("", []),
      tiempoNoJustificado: new FormControl("", []),
      busquedaSimple: new FormControl("", []),
    });

    this.cambioUsuarioNuevo();

    if (this.crmservice.userLogin.rol === "admin") {
      this.addConfig = false;
      this.nombreTienda = "";
      this.errAddConfig = "";
      this.crmservice.getAllConfigDNIeTienda().subscribe(
        (valueConfig) => {
          console.log(valueConfig);
          this.configDNIeTiendas = valueConfig;
        },
        (errorConfig) => {
          console.error(errorConfig);
        }
      );
    }
  }

  ngOnInit() {}

  public descargarGestorTiempos(): void {
    window.open(
      this.crmservice.urlCRM + "/gestor-tiempos/Instalador.exe",
      "_blank"
    );
  }

  cambioUsuarioNuevo() {
    this.cargandoDatos = true;

    this.tiemposUsuario = [];
    this.accionesUsuario = [];
    this.jornadasUsuario = [];
    this.noJustificado = [];

    this.totalNoJustificado = "00:00:00";

    this.crmservice.getRegistrosTiempoUsuario(this.usuarioSel._id).subscribe(
      (value1) => {
        this.tiemposUsuario = value1;

        if (!this.tiemposUsuario.length) {
          this.cargandoDatos = false;
          return;
        }

        this.tiemposUsuario.sort(function (registroA, registroB) {
          if (
            new Date(registroA.tiempos[0].fechaInicio) <
            new Date(registroB.tiempos[0].fechaInicio)
          ) {
            return -1;
          } else if (
            new Date(registroA.tiempos[0].fechaInicio) >
            new Date(registroB.tiempos[0].fechaInicio)
          ) {
            return 1;
          }
          return 0;
        });

        this.crmservice.getAccionesUser(this.usuarioSel._id).subscribe(
          (value2) => {
            this.accionesUsuario = value2;

            this.crmservice
              .getJornadasLaboralesUsuario(this.usuarioSel._id)
              .subscribe(
                (value3) => {
                  this.jornadasUsuario = value3;
                  this.calcularTiempoNoJustificado();
                  this.actualizarDiagrama();
                  this.cargandoDatos = false;
                },
                (error3) => {
                  console.log(error3);
                  this.cargandoDatos = false;
                }
              );
          },
          (error2) => {
            console.log(error2);
            this.cargandoDatos = false;
          }
        );
      },
      (error1) => {
        console.log(error1);
        this.cargandoDatos = false;
      }
    );
  }

  actualizarFechas() {
    if (this.busquedaSimple) {
      this.fDesde = new Date(this.fDesde);
      this.fDesde.setHours(0, 0, 0);

      this.fHasta = new Date(this.fDesde);
      this.fHasta.setHours(23, 59, 0);
    } else {
      this.fDesde = new Date(this.fDesde);
      this.fDesde.setHours(0, 0, 0);

      this.fHasta = new Date(this.fHasta);
      this.fHasta.setHours(23, 59, 0);

      if (this.fDesde > this.fHasta) {
        this.mensajeInfo = "fecha";
        return;
      }
    }

    this.actualizarDiagrama();
  }

  async actualizarDiagrama() {
    this.mensajeInfo = "";

    this.limpiarDiagrama();

    if (this.tiempoJornadas) {
      this.addTiemposJornadas();
    }

    if (this.tiempoNoJustificado) {
      this.addTiemposNoJustificados();
    }

    await this.addTiemposAcciones();

    if (this.tiempoExtra) {
      this.addTiemposExtra();
    }

    if (!this.xAxisData.length) {
      this.mensajeInfo = "noDatos";
      return;
    }

    this.draw();
  }
  limpiarDiagrama() {
    this.xAxisData = [];
    this.yAxisData = [];
  }

  calcularTiempoNoJustificado() {
    for (const jornada of this.jornadasUsuario) {
      for (const tiempo of jornada.tiempos) {
        // Calcular cuales son los tiempos justificados dentro del intervalo horario
        const tiempoJustificado = [];

        for (const registro of this.tiemposUsuario) {
          if (registro.accion != null) {
            for (const tiempoRegistro of registro.tiempos) {
              if (this.tiempoEnIntervalo(tiempoRegistro, tiempo)) {
                tiempoJustificado.push(tiempoRegistro);
              }
            }

            for (const tiempoExtra of registro.tiempoExtra) {
              if (this.tiempoEnIntervalo(tiempoExtra, tiempo)) {
                tiempoJustificado.push(tiempoExtra);
              }
            }
          }
        }

        tiempoJustificado.sort(function (tiempoA, tiempoB) {
          if (new Date(tiempoA.fechaInicio) < new Date(tiempoB.fechaInicio)) {
            return -1;
          } else if (
            new Date(tiempoA.fechaInicio) > new Date(tiempoB.fechaInicio)
          ) {
            return 1;
          }
          return 0;
        });

        // Calcular cuales son los tiempos no fustificados dentro del intervalo horario
        if (tiempoJustificado.length) {
          let i = 0;

          if (
            new Date(tiempoJustificado[i].fechaInicio) >
            new Date(tiempo.fechaEntrada)
          ) {
            this.noJustificado.push({
              fechaInicio: tiempo.fechaEntrada,
              fechaFin: tiempoJustificado[i].fechaInicio,
            });
            // i++;
          }

          for (i; i < tiempoJustificado.length - 1; i++) {
            this.noJustificado.push({
              fechaInicio: tiempoJustificado[i].fechaFin,
              fechaFin: tiempoJustificado[i + 1].fechaInicio,
            });
          }

          if (
            new Date(tiempoJustificado[i].fechaFin) <
            new Date(tiempo.fechaSalida)
          ) {
            this.noJustificado.push({
              fechaInicio: tiempoJustificado[i].fechaFin,
              fechaFin: tiempo.fechaSalida,
            });
          }
        } else {
          this.noJustificado.push({
            fechaInicio: tiempo.fechaEntrada,
            fechaFin: tiempo.fechaSalida,
          });
        }
      }
    }
  }

  addTiemposAcciones() {
    return new Promise<void>((resolve, reject) => {
      for (const registroTiempo of this.tiemposUsuario) {
        if (registroTiempo.accion != null) {
          for (const tiempo of registroTiempo.tiempos) {
            if (
              new Date(tiempo.fechaInicio) >= this.fDesde &&
              new Date(tiempo.fechaInicio) <= this.fHasta
            ) {
              // Nombre de la acción
              let nombreAccion = "";

              const pos = this.accionesUsuario.findIndex(
                (e) => e._id === registroTiempo.accion
              );
              if (pos !== -1) {
                switch (this.accionesUsuario[pos].hito) {
                  case "accionTienda":
                    nombreAccion +=
                      "Tienda " +
                      this.dameObjetivoTienda(
                        this.accionesUsuario[pos].objetivo
                      );
                    break;
                  case "accionPedido":
                    nombreAccion +=
                      this.accionesUsuario[pos].objetivo === "200"
                        ? "Pedido"
                        : "Presupuesto";
                    break;
                  case "accionDV":
                    nombreAccion +=
                      "Diseñador Virtual " +
                      this.accionesUsuario[pos].solicitudDV +
                      " " +
                      this.dameObjetivoDV(this.accionesUsuario[pos].objetivo);
                    break;
                  default:
                    nombreAccion +=
                      this.dameHito(this.accionesUsuario[pos].hito) + " ";
                    nombreAccion +=
                      this.dameObjetivoAsesor(
                        this.accionesUsuario[pos].objetivo
                      ) + " ";

                    const dirPos = this.crmservice.direcciones.findIndex(
                      (e) => e._id === this.accionesUsuario[pos].direccion
                    );
                    if (dirPos !== -1) {
                      nombreAccion +=
                        this.crmservice.direcciones[dirPos].nombreComercial;
                    } else {
                      nombreAccion += "Desconocido";
                    }
                    break;
                }
              }

              let accionUsuarioModificada;

              this.accionUsuarioParaMaximos = this.accionesUsuario[pos].hito;

              switch (this.accionesUsuario[pos].hito) {
                case "accionTienda":
                  accionUsuarioModificada = "T";
                  break;
                case "accionPedido":
                  accionUsuarioModificada = "D";
                  break;
                case "accionDV":
                  accionUsuarioModificada = "DV";
                  break;
              }

              const accionCompleta =
                accionUsuarioModificada + ":" + nombreAccion;

              const tiempoAccion =
                this.calcularTiempo(tiempo.fechaInicio, tiempo.fechaFin) +
                " " +
                accionCompleta;

              this.xAxisData.unshift([
                registroTiempo.accion,
                tiempo.fechaInicio,
                tiempo.fechaFin,
                accionCompleta,
                tiempoAccion,
                "#66ffb3",
              ]);
            }
          }
        }
      }
      for (const reg of this.xAxisData) {
        const pos = this.yAxisData.findIndex((e) => e[0] === reg[0]);
        if (pos === -1) {
          let maximoTiempoPorAccion = "";
          switch (this.accionUsuarioParaMaximos) {
            case "accionDV":
              maximoTiempoPorAccion = "01:00:00";
              if (reg[4] > maximoTiempoPorAccion) {
                reg[5] = "#C461F9";
              }
              break;
          }

          this.yAxisData.unshift([reg[0], reg[3]]);
          this.accionesAlfonso.unshift([reg[0], reg[3]]);
        }
      }
      return resolve();
    });
  }
  addTiemposExtra() {
    for (const registroTiempo of this.tiemposUsuario) {
      if (registroTiempo.accion != null) {
        for (const tiempo of registroTiempo.tiempoExtra) {
          if (
            new Date(tiempo.fechaInicio) >= this.fDesde &&
            new Date(tiempo.fechaInicio) <= this.fHasta
          ) {
            const nombreAccion = "Justificando";
            const tiempoAccion = this.calcularTiempo(
              tiempo.fechaInicio,
              tiempo.fechaFin
            );

            this.xAxisData.push([
              registroTiempo.accion,
              tiempo.fechaInicio,
              tiempo.fechaFin,
              nombreAccion,
              tiempoAccion,
              "gold",
            ]);
          }
        }
      }
    }
    for (const reg of this.xAxisData) {
      const pos = this.yAxisData.findIndex((e) => e[0] === reg[0]);
      if (pos === -1) {
        this.yAxisData.push([reg[0], reg[3]]);
        this.accionesAlfonso.push([reg[0], reg[3]]);
      }
    }
  }
  addTiemposJornadas() {
    let insertados = false;

    for (const jornadaLaboral of this.jornadasUsuario) {
      for (const tiempo of jornadaLaboral.tiempos) {
        if (
          new Date(tiempo.fechaEntrada) >= this.fDesde &&
          new Date(tiempo.fechaEntrada) <= this.fHasta
        ) {
          const nombreAccion = "jornada";
          const tiempoAccion = this.calcularTiempo(
            tiempo.fechaEntrada,
            tiempo.fechaSalida
          );

          this.xAxisData.unshift([
            nombreAccion,
            new Date(tiempo.fechaEntrada),
            new Date(tiempo.fechaSalida),
            nombreAccion,
            tiempoAccion,
            "#a65959",
          ]);
          insertados = true;
        }
      }
    }

    if (insertados) {
      this.yAxisData.unshift(["jornada", "Jornada Laboral"]);
      this.accionesAlfonso.unshift(["jornada", "Jornada Laboral"]);
    }
  }
  addTiemposNoJustificados() {
    let insertados = false;

    for (const tiempo of this.noJustificado) {
      if (
        new Date(tiempo.fechaInicio) >= this.fDesde &&
        new Date(tiempo.fechaFin) <= this.fHasta
      ) {
        const nombreAccion = "nojustificado";
        const tiempoAccion = this.calcularTiempo(
          tiempo.fechaInicio,
          tiempo.fechaFin
        );

        this.xAxisData.unshift([
          nombreAccion,
          new Date(tiempo.fechaInicio),
          new Date(tiempo.fechaFin),
          nombreAccion,
          tiempoAccion,
          "#6c9389",
        ]);
        insertados = true;
      }
    }
    if (insertados) {
      this.yAxisData.unshift(["nojustificado", "Tiempo no Justificado"]);
      this.accionesAlfonso.unshift(["nojustificado", "Tiempo no Justificado"]);
    }
    this.calcularTotalNoJustificado();
  }

  draw() {
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    this.chartOption = {
      tooltip: {
        axisPointer: {
          type: "cross",
          animation: true,
          label: {
            backgroundColor: "#505765",
          },
        },
      },
      title: {
        text: "Informe de Tiempos",
        left: "center",
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "weakFilter",
          bottom: 0,
          start: 0,
          end: 100,
          handleIcon:
            "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          // @ts-ignore
          handleSize: "80%",
          showDetail: true,
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "weakFilter",
          start: 0,
          end: 100,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
        },
        {
          type: "slider",
          yAxisIndex: 0,
          filterMode: "weakFilter",
          bottom: 0,
          start: 0,
          end: 100,
          handleIcon:
            "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          // @ts-ignore
          handleSize: "80%",
          showDetail: true,
        },
      ],
      grid: {
        show: true,
        top: 70,
        bottom: 40,
        left: 70,
        right: 40,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: "black",
      },
      xAxis: {
        type: "time",
        position: "top",
        splitLine: {
          lineStyle: {
            color: ["#E9EDFF"],
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          lineStyle: {
            color: "#929ABA",
          },
        },
        axisLabel: {
          color: "#929ABA",
          inside: false,
          align: "center",
        },
      },
      yAxis: {
        type: "category",
        axisTick: { show: true, alignWithLabel: true },
        splitLine: { show: true },
        axisLine: { show: true },
        axisLabel: { show: false },
        min: 0,
        max: this.yAxisData.length - 1,
      },
      series: [
        {
          id: "tiemposData",
          type: "custom",
          // @ts-ignore
          renderItem: this.renderGanttItem,
          // dimensions: this.DIMENSIONES,
          dimensions: ["id", "Inicio", "Fin", "Nombre", "Duracion"],
          encode: {
            // x: [this.DIM_F_INICIO, this.DIM_F_FIN],
            // y: this.DIM_ACCION_INDEX,
            // tooltip: [this.DIM_ACCION_INDEX, this.DIM_F_INICIO, this.DIM_F_FIN, this.DIM_NOMBRE_ACCION, this.DIM_DURACION]
            x: ["Inicio", "Fin"],
            y: "id",
            tooltip: ["Inicio", "Fin", "Nombre", "Duracion"],
          },
          data: this.xAxisData,
        },
        {
          type: "custom",
          // @ts-ignore
          renderItem: this.renderAxisLabelItem,
          dimensions: ["id", "Accion"],
          encode: {
            x: -1,
            y: ["id", "Accion"],
            tooltip: ["id", "Accion"],
            // y: [this.DIM_ACCION_INDEX, this.DIM_NOMBRE_ACCION]
          },
          data: this.yAxisData,
        },
      ],
    };
  }

  public renderGanttItem(params, api) {
    let rectText: echarts.ERectangle;
    const HEIGHT_RATIO = 1;

    const DIM_ACCION_INDEX = 0;
    const DIM_F_INICIO = 1;
    const DIM_F_FIN = 2;

    const categoryIndex = api.value(DIM_ACCION_INDEX);
    const timeArrival = api.coord([api.value(DIM_F_INICIO), categoryIndex]);
    const timeDeparture = api.coord([api.value(DIM_F_FIN), categoryIndex]);

    const coordSys = params.coordSys;
    const _cartesianXBounds = [];
    const _cartesianYBounds = [];

    _cartesianXBounds.push(coordSys.x);
    _cartesianXBounds.push(coordSys.x + coordSys.width);
    _cartesianYBounds.push(coordSys.y);
    _cartesianYBounds.push(coordSys.y + coordSys.height);

    const barLength = timeDeparture[0] - timeArrival[0];
    const barHeight = api.size([0, 1])[1] * HEIGHT_RATIO;
    // const barHeight = api.size([0, 1])[1];
    const x = timeArrival[0];
    const y = timeArrival[1] - barHeight;

    const formatUtil: any = (<any>echarts).format;

    const flightNumber = api.value(4);
    const flightNumberWidth = formatUtil.getTextRect(flightNumber).width;
    const text =
      barLength > flightNumberWidth + 10 && x + barLength >= 180
        ? flightNumber
        : "";

    const OFFSET = barHeight * 0.5;

    const rectNormal = echarts.graphic.clipRectByRect(
      { x: x, y: y + OFFSET, width: barLength, height: barHeight },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    rectText = echarts.graphic.clipRectByRect(
      { x: x, y: y + OFFSET, width: barLength, height: barHeight },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return {
      type: "group",
      children: [
        {
          type: "rect",
          shape: rectNormal,
          style: {
            stroke: "gray",
            fill: api.value(5),
          },
        },
        {
          type: "rect",
          shape: rectText,
          style: api.style({
            fill: "transparent",
            stroke: "transparent",
            text: text,
            textFill: "#000",
          }),
        },
      ],
    };
  }
  public renderAxisLabelItem(params, api) {
    const y = api.coord([0, api.value("id")])[1];
    if (y < params.coordSys.y + 5) {
      return;
    }

    return {
      position: [10, y],
      type: "text",
      style: {
        x: -10,
        y: 0,
        text: "Accion " + (api.value("id") + 1),
        // x: 24,
        // y: -3
      },
    };
  }

  calcularTiempo(fechaInicio: Date, fechaFin: Date) {
    let horas = 0;
    let minutos = 0;
    let segundos: number;

    let horasString = "00";
    let minutosString = "00";
    let segundosString = "00";

    segundos = Math.ceil(
      Math.abs(new Date(fechaFin).getTime() - new Date(fechaInicio).getTime()) /
        1000
    );

    while (segundos >= 60) {
      segundos = segundos - 60;
      minutos++;
    }

    while (minutos >= 60) {
      minutos = minutos - 60;
      horas++;
    }

    if (horas) {
      if (horas > 9) {
        horasString = String(horas);
      } else {
        horasString = "0" + horas;
      }
    }

    if (minutos) {
      if (minutos > 9) {
        minutosString = String(minutos);
      } else {
        minutosString = "0" + minutos;
      }
    }

    if (segundos) {
      if (segundos > 9) {
        segundosString = String(segundos);
      } else {
        segundosString = "0" + segundos;
      }
    }

    return horasString + ":" + minutosString + ":" + segundosString;
  }
  calcularTotalNoJustificado() {
    this.totalNoJustificado = "00:00:00";
    let nanosegundos = 0;

    for (const tiempo of this.noJustificado) {
      if (
        new Date(tiempo.fechaInicio) >= this.fDesde &&
        new Date(tiempo.fechaFin) <= this.fHasta
      ) {
        nanosegundos += Math.abs(
          new Date(tiempo.fechaFin).getTime() -
            new Date(tiempo.fechaInicio).getTime()
        );
      }
    }

    let segundos = Math.ceil(nanosegundos / 1000);
    let minutos = 0;
    let horas = 0;

    while (segundos >= 60) {
      segundos = segundos - 60;
      minutos++;
    }
    while (minutos >= 60) {
      minutos = minutos - 60;
      horas++;
    }

    let horasString = "00";
    let minutosString = "00";
    let segundosString = "00";

    if (horas) {
      if (horas > 9) {
        horasString = String(horas);
      } else {
        horasString = "0" + horas;
      }
    }
    if (minutos) {
      if (minutos > 9) {
        minutosString = String(minutos);
      } else {
        minutosString = "0" + minutos;
      }
    }
    if (segundos) {
      if (segundos > 9) {
        segundosString = String(segundos);
      } else {
        segundosString = "0" + segundos;
      }
    }

    this.totalNoJustificado =
      horasString + ":" + minutosString + ":" + segundosString;
  }

  tiempoEnIntervalo(tiempo, intervalo): boolean {
    if (
      new Date(tiempo.fechaInicio) < new Date(intervalo.fechaEntrada) &&
      new Date(tiempo.fechaFin) > new Date(intervalo.fechaEntrada)
    ) {
      return true;
    }

    return (
      new Date(tiempo.fechaInicio) >= new Date(intervalo.fechaEntrada) &&
      new Date(tiempo.fechaInicio) <= new Date(intervalo.fechaSalida)
    );
  }

  dameHito(hito: string) {
    const pos = this.crmservice.hitos.findIndex(
      (e) => e.id.toString() === hito
    );
    if (pos !== -1) {
      return this.crmservice.hitos[pos].nombre;
    }

    return "Desconocido";
  }

  dameObjetivoAsesor(objetivo: string) {
    const pos = this.crmservice.objetivos.findIndex(
      (e) => e.id.toString() === objetivo
    );
    if (pos !== -1) {
      return this.crmservice.objetivos[pos].nombre;
    }
    return "Desconocido";
  }

  dameObjetivoTienda(objetivo: string) {
    const pos = this.crmservice.objetivosTienda.findIndex(
      (e) => e.id === objetivo
    );
    if (pos !== -1) {
      return this.crmservice.objetivosTienda[pos].nombre;
    }

    return "Desconocido";
  }

  dameObjetivoDV(objetivo: string): string {
    const pos = this.crmservice.objetivosDV.findIndex((e) => e.id === objetivo);
    if (pos !== -1) {
      return this.crmservice.objetivosDV[pos].nombre;
    }
    return "Desconocido";
  }

  cambiarModoPruebas() {
    this.crmservice.cambiarModoPruebas().subscribe(
      (value) => {
        this.crmservice.modoPruebas = value;
      },
      (error1) => {
        console.log(error1);
        this.crmservice.modoPruebas = false;
      }
    );
  }

  // *--- CONFIGURACIÓN DNIe TIENDAS ---* //
  public nuevaConfig(): void {
    if (this.comprobarFormularioNuevaConfig()) {
      this.cargandoDatos = true;
      this.crmservice
        .nuevaConfigDNIeTienda({
          nombreTienda: this.nombreTienda,
          activo: false,
        })
        .subscribe(
          (valueConfig) => {
            this.configDNIeTiendas.push(valueConfig);
            this.nombreTienda = "";
            this.addConfig = false;
            this.cargandoDatos = false;
          },
          (errorConfig) => {
            console.error(errorConfig);
            this.cargandoDatos = false;
          }
        );
    }
  }
  public comprobarFormularioNuevaConfig(): boolean {
    if (this.nombreTienda == null || !this.nombreTienda.trim().length) {
      this.errAddConfig = "Indica el nombre de la Tienda";
      return false;
    }

    const pos = this.configDNIeTiendas.findIndex(
      (e) => e.nombreTienda === this.nombreTienda
    );
    if (pos !== -1) {
      this.errAddConfig = "Ya hay una configuración para " + this.nombreTienda;
      return false;
    }

    this.errAddConfig = "";
    return true;
  }
  public cambiarConfig(config: ConfigDNIeTienda): void {
    config.activo = !config.activo;
    this.cargandoDatos = true;
    this.crmservice.updateConfigDNIeTienda(config).subscribe(
      () => {
        this.cargandoDatos = false;
      },
      (error) => {
        console.error(error);
        this.cargandoDatos = false;
      }
    );
  }
}
