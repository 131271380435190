<div class="cabecera-modal">
  <mat-icon class="icon-modal">priority_high</mat-icon>
  <strong>NUEVA VERSIÓN</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [hidden]="cargandoDatos" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div class="row">

      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
        <label class="w-100">
          Versión:
          <input type="text" class="form-control" [(ngModel)]="version">
        </label>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
        <label class="w-100">
          Fecha:
          <input type="date" class="form-control" [(ngModel)]="fecha">
        </label>
      </div>

      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-4">
        <label class="w-100">
          Nombre:
          <input type="text" class="form-control" [(ngModel)]="titulo">
        </label>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4 p-4">
        <table class="table table-sm table-bordered" style="background-color: #fafafa">
          <tbody>
          <tr style="background-color: #c8c8ff">
            <td></td>
            <td colspan="2" class="td-btn" (click)="addSeccion()">
              <mat-icon>playlist_add</mat-icon>
            </td>
          </tr>
          <ng-container *ngFor="let seccion of secciones">
            <tr style="vertical-align: middle; background-color: #ebebff">
              <td style="width: 90%">
                <label class="w-100 m-0">
                  <input class="form-control" [(ngModel)]="seccion.titulo">
                </label>
              </td>
              <td style="width: 5%" class="td-btn" (click)="addCambio(seccion)">
                <mat-icon>playlist_add</mat-icon>
              </td>
              <td style="width: 5%" class="td-btn" (click)="removeSeccion(seccion.id)">
                <mat-icon>close</mat-icon>
              </td>
            </tr>
            <tr *ngFor="let cambio of seccion.cambios" style="vertical-align: middle; background-color: #fafaff">
              <td>
                <label class="w-100 m-0">
                  <textarea [(ngModel)]="cambio.texto" class="form-control"></textarea>
                </label>
              </td>
              <td class="td-btn" (click)="removeCambio(seccion, cambio.id)">
                <mat-icon>close</mat-icon>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="mensajeError != ''" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4 text-center">
        <div class="alert alert-danger">
          {{mensajeError}}
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right">
        <button class="btn btn-sm btn-success" (click)="guardar()">Guardar</button>
      </div>

    </div>
  </div>

  <div *ngIf="cargandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
