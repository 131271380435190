<div *ngIf="!cargandoDatos" class="m-lg-5 m-sm-2 m-2">
  <h2>Administrador de Muestras</h2>

  <div class="row">
    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-11 mb-4">
      <label>
        Tipo de Muestra
        <select class="form-select" [(ngModel)]="tipoSeleccionado">
          <option *ngFor="let tipoMuestra of crmservice.tiposMuestras"
                  [value]="tipoMuestra.id">{{tipoMuestra.nombre}}</option>
        </select>
      </label>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-1 text-end">
      <button mat-button (click)="nuevaMuestra()">
        <mat-icon class="me-1">add_to_photos</mat-icon>
        <span>Nueva Muestra</span>
      </button>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-light">
      <div class="row mt-2">
        <ng-container *ngFor="let muestra of muestras">
          <div *ngIf="muestra.tipo == tipoSeleccionado" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
               [matMenuTriggerFor]="menuMuestra" [matMenuTriggerData]="{muestra: muestra}">
            <mat-card class="muestraContainer">
              <mat-card-header class="fs-5">
                <mat-card-title class="fs-6">{{muestra.nombre}} - <span class="text-secondary">{{dameNombreTipo()}}</span></mat-card-title>
                <div mat-card-avatar class="shadow-sm"
                     [ngStyle]="{'background-image':'url(' + crmservice.urlCRM + '/img/muestras/' + muestra.imagen + ')', 'background-size':'cover'}"></div>
              </mat-card-header>
              <mat-card-content *ngIf="muestra.descontinuada">
                <mat-card-subtitle class="float-end text-danger">
                  <strong>DESCONTINUADA</strong>
                </mat-card-subtitle>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>

<div *ngIf="cargandoDatos" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<mat-menu #menuMuestra='matMenu' xPosition="before" yPosition="below">
  <ng-template matMenuContent let-muestra="muestra">
    <button mat-menu-item
            (click)="setDescontinuada(muestra)">{{!muestra.descontinuada ? 'DESCONTINUAR MUESTRA' : 'RECUPERAR MUESTRA'}}</button>
  </ng-template>
</mat-menu>
