<div class="cabecera-modal">
  <img alt="" id="img-3d" class="icon-modal" src="../../../../../assets/img/otros/3d.png">
  <strong>HISTORIAL</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div class="row" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">

    <div *ngFor="let cambio of cliente.licenciasDisponibles.cambiosConfig" class="col-12">
      <div class="mb-4 border border-secondary rounded bg-white shadow p-0 ps-4 pe-4 pt-4">
        <div class="mb-3 border-bottom border-secondary">
          <span>{{dameNombreUsuario(cambio.usuario)}}</span>
          <span class="float-end">{{cambio.fecha | date : 'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class="mb-3">
          Cambió la configuración a: <strong>{{cambio.fijo ? 'MANUAL' : 'AUTOMÁTICA'}}</strong>
        </div>
        <div class="mb-3">
          Motivo: <i>{{cambio.motivo}}</i>
        </div>
      </div>
    </div>

  </div>
</div>
