<div class="m-xl-3 m-lg-3 m-md-2 m-sm-2 m-1" [hidden]="cargando">
  <div class="m-auto" style="width: 98%">
    <h2>Encargos</h2>
  </div>
  <div id="cabecera" class="d-flex">
    <div class="d-inline-block">
      <div>Mostrar:</div>
      <button
        class="btnFiltroEncargos"
        (click)="cambiarEstadoEtiquetaTodas()"
        [ngClass]="{ btnFiltroEncargosSeleccionado: etiquetaTodas === true }"
        value="preButton"
      >
        Todas
      </button>
      <button
        class="btnFiltroEncargos"
        (click)="cambiarEstadoEtiquetaPresupuestos()"
        [ngClass]="{ btnFiltroEncargosSeleccionado: etiquetaPresupuestos === true }"
        value="preButton"
      >
        Presupuestos
      </button>
      <button
        class="btnFiltroEncargos"
        (click)="cambiarEstadoEtiquetaDv()"
        [ngClass]="{ btnFiltroEncargosSeleccionado: etiquetaDv === true }"
        value="dvButton"
      >
        Diseñador Virtual
      </button>
      <button
        class="btnFiltroEncargos"
        (click)="cambiarEstadoEtiquetaNini()"
        [ngClass]="{ btnFiltroEncargosSeleccionado: etiquetaNini === true }"
        value="dvButton"
      >
        Nini
      </button>
    </div>

    <div class="d-inline-block ms-auto">
      <mat-form-field id="buscador" class="w-auto">
        <input type="text" autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="textoBusqueda" placeholder="Filtro" />
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex" id="cabecera" style="margin-bottom: 3px">
    <button
      class="btnFiltroEncargosEstado"
      (click)="cambiarEstadoEtiquetaPendtComisionar()"
      [ngClass]="{ btnFiltroEncargosEstadoSeleccionado: etiquetaPendtComisionar === true }"
      value="pendComiButton"
    >
      Pendientes de comisionar
    </button>
    <button
      class="btnFiltroEncargosEstado"
      (click)="cambiarEstadoEtiquetaComisionados()"
      [ngClass]="{ btnFiltroEncargosEstadoSeleccionado: etiquetaComisionados === true }"
      value="comiButton"
    >
      Comisionados
    </button>
  </div>

  <div class="overflow-auto">
    <table mat-table [dataSource]="dataSource" matSortActive="ultimaActividad" matSortDirection="desc" id="tabla-ipdorma" matSort>
      <ng-container matColumnDef="tipoEncargo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Tipo encargo</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1">
          {{
            arrEncargoss.solicitudDV
              ? "Diseñador Virtual"
              : arrEncargoss.presupuestoRealizacionAceptada
              ? "Presupuestos"
              : arrEncargoss.nini
              ? "Nini"
              : "error"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cliente.codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Código</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1">{{ arrEncargoss.cliente.codigo }}</td>
      </ng-container>

      <ng-container matColumnDef="cliente.cif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center p-1">CIF</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.cliente.cif }}</td>
      </ng-container>

      <ng-container matColumnDef="cliente.razonSocial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Razón Social</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.cliente.razonSocial }}</td>
      </ng-container>

      <ng-container matColumnDef="cliente.nombreComercial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Nombre Comercial</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.cliente.nombreComercial }}</td>
      </ng-container>

      <ng-container matColumnDef="cliente.provincia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Provincia</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.cliente.provincia }}</td>
      </ng-container>

      <ng-container matColumnDef="cliente.referencia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 text-center">Referencia</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.referencia }}</td>
      </ng-container>

      <ng-container matColumnDef="BNA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 text-center">Número pedido</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">
          {{ arrEncargoss.BNA }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fechaCreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Fecha Encargo</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle">{{ arrEncargoss.fechaCreacion | date : "dd/MM/yyyy" }}</td>
      </ng-container>

      <ng-container matColumnDef="tieneComisionFacturada">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">¿Comision facturada?</th>
        <td mat-cell *matCellDef="let arrEncargoss" class="p-1 align-middle text-center">
          <button *ngIf="!arrEncargoss.tieneComisionFacturada" (click)="comisionar(arrEncargoss)" class="btn btn-success" style="float: left">
            Realizar
          </button>
          <p *ngIf="arrEncargoss.tieneComisionFacturada" style="float: left">Comisión facturada</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div id="paginacion-encargos">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true" (page)="getPageEvent($event)"></mat-paginator>
  </div>

  <div id="texto-info" class="d-flex justify-content-between">
    <span class="text-secondary">
      *En este listado se muestran el listado de los encargos realizados por los clientes. <br />
      *Todos los importes que se muestran no incluyen impuestos, es decir, muestra base imponible.
    </span>
  </div>
</div>
