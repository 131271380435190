<div class="cabecera-modal">
  <mat-icon class="icon-modal">business</mat-icon>
  <strong>Nueva dirección</strong>
  <mat-icon class="cerrar-modal" (click)="cancelar()">close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargando" class="row bg-white ps-md-5 pe-md-5 pt-md-4 p-2 rounded m-0-auto"
       style="min-height: calc(100% - 55px); max-height: calc(100% - 55px); overflow-y: auto">

    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label class="w-100">Nombre Comercial</mat-label>
        <input [(ngModel)]="direccion.nombreComercial" matInput placeholder="Nombre Comercial"  required>
      </mat-form-field>
    </div>
    <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'" class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Equipo</mat-label>
        <mat-select [(ngModel)]="direccion.equipo" placeholder="Equipo" required>
          <mat-option *ngFor="let item of crmservice.equipos" [value]="item.id">{{item.id}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'" class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Estado</mat-label>
        <mat-select [(ngModel)]="direccion.estado" placeholder="Estado" required>
          <mat-option *ngFor="let item of crmservice.estados" [value]="item.id">{{item.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'" class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>TECLA</mat-label>
        <input [(ngModel)]="direccion.perfilTCLA" matInput placeholder="TECLA" required="{{['potencial', 'activo', 'activoI'].includes(direccion.estado)}}">
      </mat-form-field>
    </div>
    <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'" class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Ver en Mapa</mat-label>
        <mat-select [(ngModel)]="direccion.verMapa" placeholder="Ver en mapa" required>
          <mat-option [value]="true">Si</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Tipo de direccion</mat-label>
        <mat-select [(ngModel)]="direccion.tipoDir" (ngModelChange)="tipoDirCambiado(direccion)" placeholder="Tipo de direccion" required>
          <mat-option *ngFor="let item of crmservice.tiposDir" [value]="item.id">{{item.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Código Postal</mat-label>
        <input [(ngModel)]="direccion.codigoPostal" (ngModelChange)="cambiaCP()" matInput placeholder="Código Postal" required>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Provincia</mat-label>
        <mat-select [(ngModel)]="direccion.provincia" placeholder="Provincia" required>
          <mat-option *ngFor="let item of crmservice.provincias" [value]="item.nombre">{{item.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Poblacion</mat-label>
        <input [(ngModel)]="direccion.poblacion" matInput placeholder="Poblacion" required>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Vía</mat-label>
        <mat-select [(ngModel)]="direccion.tipoVia" placeholder="Vía" required>
          <mat-option *ngFor="let item of crmservice.vias" value="{{item}}">
            {{item[0].toUpperCase() + item.substr(1).toLowerCase()}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Dirección</mat-label>
        <input [(ngModel)]="direccion.direccion" matInput placeholder="Dirección" required>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Detalles Dirección (opcional)</mat-label>
        <input [(ngModel)]="direccion.direccionDetalle" matInput placeholder="Detalles Dirección">
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Email (opcional)</mat-label>
        <input [(ngModel)]="direccion.email" matInput placeholder="Email">
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Web (opcional)</mat-label>
        <input [(ngModel)]="direccion.webEmpresa" matInput placeholder="Web">
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field class="w-100">
        <mat-label>Teléfono (opcional)</mat-label>
        <input [(ngModel)]="direccion.telefono" matInput placeholder="Teléfono">
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Observaciones (opcional)</mat-label>
        <textarea [(ngModel)]="direccion.observaciones" matInput matTextareaAutosize matAutosizeMinRows="2" placeholder="Observaciones"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!cargando" class="d-flex p-2 mt-auto border-top bg-light">
    <div class="btn btn-primary-crm ms-auto" [class.w-100]="mobileQuery.matches" (click)="guardar()">CREAR</div>
  </div>

  <div *ngIf="cargando" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
