import {Usuario} from './usuario';
import {Direccion} from './direccion';
import {Muestra} from './muestra';

export class CajaMuestras {
  _id: string;
  nombre: string;
  tipo: string;
  direccion: Direccion;
  muestras: {
    muestra: Muestra,
    entregado: boolean,
    movimientos: {
      tipo: number,
      fechaMov: Date,
      userMov: Usuario,
    }[]
  }[];
}
