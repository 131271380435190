<div class="cabecera-modal">
  <img alt="3D" id="icono-3d" class="icon-modal" src="../../../../../assets/img/otros/3d.png">
  <strong>CONFIGURACIÓN</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [hidden]="cargandoDatos">
    <div class="row" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
      <div class="col-12">
        <div class="alert alert-info">
          <p><strong>CONFIGURACIÓN AUTOMÁTICA</strong>: el número máximo de licencias del cliente se calcula en base a la facturación y tiempo de uso de 3D.</p>
          <p><strong>CONFIGURACIÓN MANUAL</strong>: el número máximo de licencias se fijará a una determinada cantidad, sin importar la facturación o experiencia usando el 3D.</p>
          <p *ngIf="infoLicencias.inputAdicionales">
            <strong>LICENCIAS ADICIONALES</strong>: se añaden al número máximo de licencias. Sólo están disponibles durante los primeros 90 días de uso del 3D.
          </p>
        </div>
      </div>

      <div class="col-md-10 col-12 mb-3">
        <label class="w-100">
          Configuración:
          <select class="form-select" [(ngModel)]="fijo" (ngModelChange)="cambioTipo()">
            <option [ngValue]="false">AUTOMÁTICA</option>
            <option [ngValue]="true">MANUAL</option>
          </select>
        </label>
      </div>

      <div class="col-md-2 col-12 mb-3">
        <label class="w-100">
          Licencias:
          <input type="number" class="form-control" [(ngModel)]="cantidad" [readOnly]="fijo == false">
        </label>
      </div>

      <div [hidden]="cliente.licenciasDisponibles.fijo == fijo" class="col-12 mb-3">
        <label class="w-100">
          Motivo de Cambio:
          <textarea class="form-control" [(ngModel)]="motivoCambio"></textarea>
        </label>
      </div>

      <div *ngIf="infoLicencias.inputAdicionales" class="col-12 mb-3">
        <label class="w-100">
          Licencias Adicionales:
          <input type="number" class="form-control" [(ngModel)]="adicionales">
        </label>
      </div>

      <div *ngIf="mensajeError != ''"
           class="col-12 mb-3 text-center">
        <div class="alert alert-danger"><strong>{{mensajeError}}</strong></div>
      </div>

      <div class="col-12 mb-3 text-end">
        <button class="btn btn-sm btn-success" (click)="cambiar()">Cambiar</button>
      </div>

    </div>
  </div>

  <div *ngIf="cargandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
