import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import {} from '@types/googlemaps';
import {CrmService} from '../../servicios/crm.service';
import {MapaserviceService} from '../../servicios/mapaservice.service';
import {Router} from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import {CodigoPostal} from '../../modelos/CodigoPostal';

@Component({
  selector: 'app-mapa-chinchetas',
  templateUrl: './mapa-chinchetas.component.html',
  styleUrls: ['./mapa-chinchetas.component.css']
})
export class MapaChinchetasComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargandoDatos = false;

  public cpBusqueda = '';
  public cpMostrar: CodigoPostal;

  @ViewChild('googleMap', { static: true }) gmapElement: any;

  constructor(public crmservice: CrmService,
              public mapaservicio: MapaserviceService,
              private router: Router,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    console.log('MapaChinchetas');

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.mapaservicio.seleccionarEquipos();

    this.mapaservicio.espacioFisicoCargado = false;
    this.mapaservicio.espacioVirtualCargado = false;
    this.mapaservicio.espacioPuntoCompraCargado = false;

    this.mapaservicio.verEspacioFisico = false;
    this.mapaservicio.verEspacioVirtual = false;
    this.mapaservicio.verEspacioPuntoCompra = false;

  }

  ngOnInit() {

    setTimeout(() => {

      if (this.mapaservicio.mapProp == null) {
        this.mapaservicio.mapProp = {
          center: new google.maps.LatLng(40.0, -3.7492200000000366),
          zoom: 6,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          fullscreenControl: true
          // mapTypeId: google.maps.MapTypeId.HYBRID
          // mapTypeId: google.maps.MapTypeId.SATELLITE
          // mapTypeId: google.maps.MapTypeId.TERRAIN
        };
      }

      if (this.mapaservicio.map !== undefined) {
        this.mapaservicio.mapProp.zoom = this.mapaservicio.map.getZoom();
        this.mapaservicio.mapProp.center = this.mapaservicio.map.getCenter();
      }

      this.mapaservicio.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapaservicio.mapProp);

      // Superpone los contornos de las regiones de los Códigos Postales
      let WMS_UnidadesAdministrativas: any;
      WMS_UnidadesAdministrativas = new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const proj = this.mapaservicio.map.getProjection();
          const zfactor = Math.pow(2, zoom);

          const top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor,
            coord.y * 256 / zfactor));
          const bot = proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * 256 /
            zfactor, (coord.y + 1) * 256 / zfactor));

          const bbox = (top.lng()) + ',' +
            (bot.lat()) + ',' +
            (bot.lng()) + ',' +
            (top.lat());

          // let url = 'https://www.ign.es/wms-inspire/ign-base?';
          let url = 'https://www.cartociudad.es/wms-inspire/direcciones-ccpp?';
          url += '&REQUEST=GetMap';
          url += '&SERVICE=WMS';
          url += '&VERSION=1.1.1';
          url += '&LAYERS=' + 'codigo-postal';
          url += '&FORMAT=image/png';
          url += '&BGCOLOR=0xFFFFFF';
          url += '&TRANSPARENT=TRUE';
          url += '&SRS=EPSG:4326';
          url += '&BBOX=' + bbox;
          url += '&WIDTH=256';
          url += '&HEIGHT=256';
          return url;

        },
        tileSize: new google.maps.Size(256, 256)
      });
      // console.log(WMS_UnidadesAdministrativas);
      this.mapaservicio.map.overlayMapTypes.push(WMS_UnidadesAdministrativas);

      // if (!this.mapaservicio.chinchetas.length) {
        this.mapaservicio.generarMarkers();
      // }

      this.ponerMarkers();
    }, 1000);

  }

  public ponerMarkers(): void {
    this.mapaservicio.limpiarMarkers();
    this.mapaservicio.dibujarMarkers();
  }
  public linkDireccion(): void {
    this.crmservice.direccionSeleccionada[0] = false;
    this.router.navigate(['direccion', sessionStorage.getItem('idDireccion')]).then();
  }

  public quitarFiltro(number: number): void {
    switch (number) {
      case 0:
        this.mapaservicio.estadosSeleccionados = [];
        break;
      case 1:
        this.mapaservicio.equiposSeleccionados = [];
        break;
    }

    this.ponerMarkers();
  }
  public seleccionarTodos(number: number): void {
    switch (number) {
      case 0:
        this.mapaservicio.estadosSeleccionados = ['activo', 'noactivo', 'candidato', 'potencial', 'proscrito', 'activoI', 'representante', 'potencialnoactivo'];
        break;
      case 1:
        this.mapaservicio.equiposSeleccionados = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
          'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
          'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        break;
    }
    this.ponerMarkers();
  }
  public buscarCP(): void {

    if (this.crmservice.codigosPostales == null || !this.crmservice.codigosPostales.length) {
      this.cargandoDatos = true;
      this.crmservice.getAllCP().subscribe(
        valueCP => {
          this.crmservice.codigosPostales = valueCP;
          this.cargandoDatos = false;

          try {
            const cpNumber = parseInt(this.cpBusqueda, 10);
            const pos = this.crmservice.codigosPostales.findIndex(e => e.codigoPostal === cpNumber);
            if (pos !== -1) {
              this.cpMostrar = this.crmservice.codigosPostales[pos];
            } else {
              this.cpMostrar = null;
            }
          } catch (e) {
            console.error(e);
            this.cpMostrar = null;
          }

        }, error1 => {
          console.error(error1);
          this.cargandoDatos = false;
        });
    } else {

      try {
        const cpNumber = parseInt(this.cpBusqueda, 10);
        const pos = this.crmservice.codigosPostales.findIndex(e => e.codigoPostal === cpNumber);
        if (pos !== -1) {
          this.cpMostrar = this.crmservice.codigosPostales[pos];
        } else {
          this.cpMostrar = null;
        }
      } catch (e) {
        console.error(e);
        this.cpMostrar = null;
      }

    }
  }
}
