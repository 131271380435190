import {Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Direccion} from '../../../../modelos/Direccion';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-ubicacion-direccion',
  templateUrl: './ubicacion-direccion.component.html',
  styleUrls: ['./ubicacion-direccion.component.css']
})
export class UbicacionDireccionComponent implements OnInit {

  // public urlMaps = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC8nvyAvcOrvFOfGem10HLaMlNN9WEfq1c&libraries=visualization&language=es';
  // public urlMaps = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDSTJ7ljYEfR6seOrE6x8f4WOb-QWPp9RA&q=';
  public urlMaps = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyC8nvyAvcOrvFOfGem10HLaMlNN9WEfq1c&q=';
  public direccion: Direccion;
  public guardandoDatos = false;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public sanitizer: DomSanitizer) {

    this.direccion = this.data.direccion;

  }

  ngOnInit() {
  }

  public urlMapa(): SafeResourceUrl {
    let url: string;

    if (this.direccion.lat != null && this.direccion.lng != null) {
      url = this.urlMaps + this.direccion.lat + '+' + this.direccion.lng + '&language=es';

      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      url = this.urlMaps +
        this.direccion.direccion.replace(/\s/g, '+') +
        '+' +
        this.direccion.poblacion.replace(/\s/g, '+') +
        '&language=es';

      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  public guardarDireccion(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {
        this.guardandoDatos = false;
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al actualizar la dirección', 'OK', {duration: 2000});
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open('Dirección guardada correctamente', 'OK', {duration: 2000});
      }
    );
  }
}
