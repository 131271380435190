<div class="cabecera-modal">
  <img alt="" class="icon-modal" id="img-3d" src="../../../../../../assets/img/otros/3d.png">
  <strong>EDITAR LICENCIA</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div class="row form-group">
      <div class="col-12">
        <label class="w-100">
          Cliente
          <input class="form-control" [(ngModel)]="razonSocialCIF" readonly>
        </label>
      </div>
      <div class="col-12">
        <label class="w-100">
          Direccion
          <input class="form-control" [(ngModel)]="nombreComercial" readonly>
        </label>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <label class="w-100">
          Alias
          <input class="form-control" [(ngModel)]="alias" readonly>
        </label>
      </div>
      <div class="col-lg-6 col-12">
        <label class="w-100">
          Llave de Red
          <input class="form-control" [(ngModel)]="llaveRed">
        </label>
      </div>
      <div class="col-lg-6 col-12">
        <label class="w-100">
          Adaptador de Red
          <input class="form-control" [(ngModel)]="adaptRed">
        </label>
      </div>
      <div class="col-lg-6 col-12">
        <label class="w-100">
          Activación
          <input type="date" class="form-control" [(ngModel)]="fActivacion">
        </label>
      </div>
      <div class="col-lg-6 col-12">
        <label class="w-100">
          Vencimiento
          <input type="date" class="form-control" [(ngModel)]="fVencimiento">
        </label>
      </div>

      <div class="col-12 text-center">
        <span *ngIf="mensajeError != ''" class="text-danger">
          <strong>{{mensajeError}}</strong>
        </span>
      </div>

      <div class="col-12">
        <hr>
      </div>

      <div class="col-12 d-flex justify-content-around">
        <button class="btn btn-secondary" [mat-dialog-close]>
          <span>Cancelar</span>
        </button>
        <button class="btn btn-success" (click)="crearLicencia()">
          <span>Aceptar</span>
        </button>
      </div>

    </div>
  </div>
</div>
