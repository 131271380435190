import { Component, OnInit } from '@angular/core';
import {Download, DownloadService} from '../../servicios/DownloadService';
import {CrmService} from '../../servicios/crm.service';

@Component({
  selector: 'app-gestor-descargas',
  templateUrl: './gestor-descargas.component.html',
  styleUrls: ['./gestor-descargas.component.css']
})
export class GestorDescargasComponent implements OnInit {

  public downloadOver: string | null;

  constructor(public crmservice: CrmService,
              public ds: DownloadService) {
    this.downloadOver = null;
  }

  ngOnInit(): void {
  }

  public abrirDescargas(): void {
    this.ds.abrirGestorDescargas = true;
  }
  public cerrarDescargas(): void {
    this.ds.abrirGestorDescargas = false;
  }

  public setDownloadOver(value: string | null): void {
    this.downloadOver = value;
  }

  public mostrarProgressBar(download: Download): boolean {
    return download.total > 0 && download.estado !== 'TERMINADA';
  }
  public getValueProgressBar(download: Download): number {
    return download.loaded / download.total;
  }
  public getColorProgressBar(download: Download): string {
    if (download.estado === 'TERMINADA') {
      return 'success';
    }
    if (download.estado === 'INTERRUMPIDA') {
      return 'danger';
    }
    return 'primary';
  }

  public getIconoDownload(download: Download): string {
    if (this.downloadOver !== download.id) {
      if (download.estado === 'PENDIENTE' || download.estado === 'DESCARGANDO') {
        return 'description';
      }
      if (download.estado === 'TERMINADA') {
        return 'check_circle';
      }
      if (download.estado === 'INTERRUMPIDA') {
        return 'remove_circle';
      }
    } else {
      if (download.estado === 'PENDIENTE' || download.estado === 'DESCARGANDO') {
        return 'cancel';
      }
      if (download.estado === 'TERMINADA' || download.estado === 'INTERRUMPIDA') {
        return 'delete';
      }
    }
  }
  public getColorIconoDownload(download: Download): string {
    if (this.downloadOver == null || this.downloadOver !== download.id) {
      return 'primary';
    } else {
      return 'dark';
    }
  }

  public btnIcon(download: Download): void {
    if (download.estado === 'PENDIENTE' || download.estado === 'DESCARGANDO') {
      download.stopDownload();
    } else if (download.estado === 'INTERRUMPIDA' || download.estado === 'TERMINADA') {
      download.removeDownload();
    }
  }


}
