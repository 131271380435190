import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CrmService } from "./crm.service";
import { Direccion } from "../modelos/Direccion";

@Injectable()
export class MapaserviceService {
  public map: google.maps.Map;
  public mapProp: any;

  public chinchetas = [];
  public chinchetasPuntoCompra = [];
  public equiposSeleccionados = [];
  public estadosSeleccionados = ["activo", "potencial", "activoI"];

  public verEspacioPuntoCompra = false;
  public verEspacioFisico = false;
  public verEspacioVirtual = false;

  public cargandoEspacioPuntoCompra = false;
  public cargandoEspacioFisico = false;
  public cargandoEspacioVirtual = false;

  public espacioPuntoCompraCargado = false;
  public espacioFisicoCargado = false;
  public espacioVirtualCargado = false;

  public calidad = "media"; // alta, media, baja.

  public infoWindowActual: google.maps.InfoWindow;

  constructor(public http: HttpClient, public crmservice: CrmService) {
    console.log("MapasService");
  }

  public seleccionarEquipos(): void {
    // Se inicializan los equiposSeleccionados con aquellos que tengan al menos una dirección
    // potencial, activa o idehábita.
    for (const direccion of this.crmservice.direcciones) {
      if (!direccion.desactivado && this.equiposSeleccionados.indexOf(direccion.equipo) === -1) {
        if (direccion.estado === "potencial" || direccion.estado === "activo" || direccion.estado === "activoI") {
          this.equiposSeleccionados.push(direccion.equipo);
        }
      }
    }
  }
  public generarMarkers(): void {
    // Se van a generar las chinchetas (únicamente una vez).
    this.chinchetas = [];
    for (const direccion of this.crmservice.direcciones) {
      if (this.generarMarker(direccion)) {
        const chincheta = {
          estado: direccion.estado,
          equipo: direccion.equipo,
          marker: null,
          markerDVirtual: null,
          markerPre_DVirtual: null,
        };

        chincheta.marker = new google.maps.Marker({
          position: {
            lat: direccion.lat,
            lng: direccion.lng,
          },
          icon: "../assets/img/markers/" + direccion.equipo + "/" + direccion.estado + ".png",
        });
        const infoWindow = this.crearInfoWindow(direccion);
        chincheta.marker.addListener("click", () => {
          // No debe haber más de una ventana abierta al mismo tiempo.
          if (this.infoWindowActual != null) {
            this.infoWindowActual.close();
          }

          this.infoWindowActual = infoWindow;
          infoWindow.open(this.map, chincheta.marker);
        });

        if (this.hitoObtenido(direccion, "DVIRTUAL", true)) {
          chincheta.markerDVirtual = new google.maps.Marker({
            position: {
              lat: direccion.lat,
              lng: direccion.lng,
            },
            icon: "../assets/img/markers/marcaLogoIdehabita.png",
          });
          chincheta.markerDVirtual.addListener("click", () => {
            // No debe haber más de una ventana abierta al mismo tiempo.
            if (this.infoWindowActual != null) {
              this.infoWindowActual.close();
            }

            this.infoWindowActual = infoWindow;
            infoWindow.open(this.map, chincheta.markerDVirtual);
          });
        }

        if (this.hitoObtenido(direccion, "PRE_DVIRTUAL", true)) {
          chincheta.markerPre_DVirtual = new google.maps.Marker({
            position: {
              lat: direccion.lat,
              lng: direccion.lng,
            },
            icon: "../assets/img/markers/pre_dvirtual.png",
          });
          chincheta.markerPre_DVirtual.addListener("click", () => {
            if (this.infoWindowActual != null) {
              this.infoWindowActual.close();
            }

            this.infoWindowActual = infoWindow;
            infoWindow.open(this.map, chincheta.markerPre_DVirtual);
          });
        }

        this.chinchetas.push(chincheta);
      } else if (direccion.lat == null) {
        this.generarCoordenadas(direccion).then().catch();
      }
    }
  }
  public generarMarker(direccion: Direccion): boolean {
    // Ya tiene coordenadas, no está desactivada y tiene la propiedad verMapa.
    return direccion.lat != null && !direccion.desactivado && direccion.verMapa;
  }
  public dibujarMarkers(): void {
    const pre_dvirtualMarcado = this.estadosSeleccionados.indexOf("pre_dvirtual") !== -1;
    const dvirtualMarcado = this.estadosSeleccionados.indexOf("amueblando") !== -1;

    const chinchetasDibujar = this.chinchetas.filter((chincheta) => {
      // Si el equipo está seleccionado.
      if (this.equiposSeleccionados.indexOf(chincheta.equipo) !== -1) {
        // Las chinchetas de Diseñador Virtual se muestran siempre si se ha marcado la opcíón.
        if (dvirtualMarcado && chincheta.markerDVirtual != null) {
          return true;
        } else if (pre_dvirtualMarcado && chincheta.markerPre_DVirtual != null) {
          return true;
        } else if (this.estadosSeleccionados.indexOf(chincheta.estado) !== -1) {
          return true;
        }
        return false;
      }
    });

    for (const chincheta of chinchetasDibujar) {
      // No marcados pre y virtual. Dibujar markers normalmente.
      if (!pre_dvirtualMarcado && !dvirtualMarcado) {
        chincheta.marker.setMap(this.map);
      }

      // Solo marcado pre, dibujar pre si existe.
      if (pre_dvirtualMarcado && !dvirtualMarcado) {
        if (chincheta.markerPre_DVirtual != null) {
          chincheta.markerPre_DVirtual.setMap(this.map);
        } else {
          chincheta.marker.setMap(this.map);
        }
      }

      // Solo marcado virtual, dibujar virtual si existe.
      if (!pre_dvirtualMarcado && dvirtualMarcado) {
        if (chincheta.markerDVirtual != null) {
          chincheta.markerDVirtual.setMap(this.map);
        } else {
          chincheta.marker.setMap(this.map);
        }
      }

      // Ambos marcados, virtual debe prevalecer sobre pre.
      if (pre_dvirtualMarcado && dvirtualMarcado) {
        if (chincheta.markerDVirtual != null) {
          chincheta.markerDVirtual.setMap(this.map);
        } else if (chincheta.markerPre_DVirtual != null) {
          chincheta.markerPre_DVirtual.setMap(this.map);
        } else {
          chincheta.marker.setMap(this.map);
        }
      }
    }
  }
  public limpiarMarkers(): void {
    for (const chincheta of this.chinchetas) {
      if (chincheta.marker != null) {
        chincheta.marker.setMap(null);
      }

      if (chincheta.markerDVirtual != null) {
        chincheta.markerDVirtual.setMap(null);
      }

      if (chincheta.markerPre_DVirtual != null) {
        chincheta.markerPre_DVirtual.setMap(null);
      }
    }
  }
  public crearInfoWindow(direccion: Direccion): any {
    const telefono = direccion.telefono != null ? direccion.telefono : "Desconocido";
    const email = direccion.email != null ? direccion.email : "Desconocido";

    const infoWindow = new google.maps.InfoWindow({
      content:
        "<button onclick=\"sessionStorage.setItem('idDireccion','" +
        direccion._id +
        "'); document.getElementById('linkDireccion').click()\" " +
        'class="btn btn-link btn-block mb-2" style="cursor: pointer">' +
        direccion.nombreComercial +
        "</button>" +
        "<p>Clasificacion: " +
        "<span>" +
        this.dameClasificacion(direccion.clasificacion) +
        "</span></p>" +
        "<p>" +
        direccion.direccion +
        " (" +
        direccion.codigoPostal +
        ")</p>" +
        "<p>Población: " +
        direccion.poblacion +
        "</p>" +
        '<p>Teléfono : <a href="tel:+34' +
        direccion.telefono +
        '">' +
        telefono +
        "</a></p>" +
        '<p>Email : <a href="mailto:' +
        direccion.email +
        '">' +
        email +
        "</a></p><br>",
    });

    if (this.hitoObtenido(direccion, "TCLA", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/TCLA.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "TCLA", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/TCLA_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "CLASS", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/class.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "CLASS", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/class_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "3D", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/3d.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "3D", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/3d_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "MUESTRAS", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/muestras.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "MUESTRAS", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/muestras_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "REVISTAS", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/revistas.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "REVISTAS", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/revistas_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "WV", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/wallviewer.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "WV", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/wallviewer_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "IDEHABITA", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/ctm.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "IDEHABITA", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/ctm_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "PRE_DVIRTUAL", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/pre_dvirtual.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "DVIRTUAL", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/Idehabita.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "DVIRTUAL", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/Idehabita_borrado.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "BannerCreamostumueble", true)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/banner_ctm.png" style="height: 50px" alt="">');
    }
    if (this.hitoObtenido(direccion, "BannerCreamostumueble", false)) {
      infoWindow.setContent(infoWindow.getContent() + '<img src="../../assets/img/hitos/banner_ctm_borrado.png" style="height: 50px" alt="">');
    }

    return infoWindow;
  }
  public generarCoordenadas(direccion: Direccion): Promise<void> {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      const address = { address: direccion.tipoVia + " " + direccion.direccion + " " + direccion.poblacion };
      geocoder.geocode(address, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          direccion.lat = results[0].geometry.location.lat();
          direccion.lng = results[0].geometry.location.lng();

          this.crmservice.updateDireccion(direccion).subscribe(
            () => {
              console.log("Nueva chincheta aparecerá la proxima vez");
            },
            (error) => console.error(error),
            () => resolve()
          );
        } else {
          resolve();
        }
      });
    });
  }

  public visualizarEspacioFisico(): void {
    this.verEspacioFisico = !this.verEspacioFisico;
    if (this.verEspacioFisico && !this.espacioFisicoCargado) {
      this.espacioFisicoCargado = true;
      this.cargandoEspacioFisico = true;

      let opcionesVisualizacion;
      switch (this.calidad) {
        case "alta":
          opcionesVisualizacion = null;
          break;
        case "media":
          opcionesVisualizacion = {
            tolerance: 0.001,
            highQuality: true,
            mutate: false,
          };
          break;
        case "baja":
          opcionesVisualizacion = {
            tolerance: 0.003,
            highQuality: false,
            mutate: true,
          };
          break;
      }

      this.getGeoIDE(opcionesVisualizacion).subscribe((value) => {
        const geosIDE: any = value;
        for (const geoIDE of geosIDE) {
          const geoJSON = new google.maps.Data(geoIDE);
          this.map.data.addGeoJson(geoJSON);
        }
        this.cargandoEspacioFisico = false;
      });
    }
    this.dibujarPoligonos();
  }
  public visualizarEspacioVirutal(): void {
    this.verEspacioVirtual = !this.verEspacioVirtual;
    if (this.verEspacioVirtual && !this.espacioVirtualCargado) {
      this.espacioVirtualCargado = true;
      this.cargandoEspacioVirtual = true;

      let opcionesVisualizacion;
      switch (this.calidad) {
        case "alta":
          opcionesVisualizacion = null;
          break;
        case "media":
          opcionesVisualizacion = {
            tolerance: 0.001,
            highQuality: true,
            mutate: false,
          };
          break;
        case "baja":
          opcionesVisualizacion = {
            tolerance: 0.003,
            highQuality: false,
            mutate: true,
          };
          break;
      }

      this.getGeoDV(opcionesVisualizacion).subscribe((value) => {
        const geosDV: any = value;
        for (const geoDV of geosDV) {
          const geoJSON = new google.maps.Data(geoDV);
          this.map.data.addGeoJson(geoJSON);
        }
        this.cargandoEspacioVirtual = false;
      });
    }
    this.dibujarPoligonos();
  }
  public visualizarEspacioPuntoCompra(): void {
    this.verEspacioPuntoCompra = !this.verEspacioPuntoCompra;

    if (this.verEspacioPuntoCompra) {
      if (!this.espacioPuntoCompraCargado) {
        this.espacioPuntoCompraCargado = true;
        this.cargandoEspacioPuntoCompra = true;

        this.getGeoPuntoCompra().subscribe((value: any) => {
          const geosPuntoCompra: any = value.geoJSON;
          for (const geoPuntoCompra of geosPuntoCompra) {
            const geoJSON = new google.maps.Data(geoPuntoCompra);
            this.map.data.addGeoJson(geoJSON);
          }
          this.cargandoEspacioPuntoCompra = false;

          for (const punto of value.points) {
            this.chinchetasPuntoCompra.push(
              new google.maps.Marker({
                position: { lat: punto.p[1], lng: punto.p[0] },
                label: {
                  text: punto.id.toString(),
                  fontWeight: "bold",
                },
                map: this.map,
                icon: "../assets/img/markers/puntoCompraMarco.png",
              })
            );
          }
        });
      }
      this.chinchetasPuntoCompra.forEach((marker) => marker.setVisible(true));
    } else {
      this.chinchetasPuntoCompra.forEach((marker) => marker.setVisible(false));
    }
    this.dibujarPoligonos();
  }

  public eliminarPoligonos(): void {
    this.map.data.forEach((feature) => {
      this.map.data.remove(feature);
    });
    this.verEspacioVirtual = false;
    this.verEspacioFisico = false;
    this.verEspacioPuntoCompra = false;
    this.espacioVirtualCargado = false;
    this.espacioFisicoCargado = false;
    this.espacioPuntoCompraCargado = false;
    this.chinchetasPuntoCompra.forEach((marker) => marker.setMap(null));
    this.chinchetasPuntoCompra = [];
  }

  private getGeoIDE(opcionesVisualizacion: any = null) {
    return this.http.post(this.crmservice.urlCRM + "/cpPoligonos/getGeoIDE", { opcionesVisualizacion: opcionesVisualizacion });
  }
  private getGeoDV(opcionesVisualizacion: any = null) {
    let equipo = null;
    if (this.crmservice.userLogin.rol === "comercial" || this.crmservice.userLogin.rol === "influencer") {
      equipo = this.crmservice.userLogin.team;
    }

    return this.http.post(this.crmservice.urlCRM + "/cpPoligonos/getGeoDV", { opcionesVisualizacion: opcionesVisualizacion, equipo: equipo });
  }
  private getGeoPuntoCompra() {
    return this.http.get(this.crmservice.urlCRM + "/cpPoligonos/getGeoPuntoCompra");
  }
  private dibujarPoligonos(): void {
    this.map.data.setStyle((feature) => {
      let color = "rgba(0, 0, 0, 0)";
      if (feature.getProperty("tipoEspacio") === "fisico" && this.verEspacioFisico) {
        color = feature.getProperty("color");
      }
      if (feature.getProperty("tipoEspacio") === "virtual" && this.verEspacioVirtual) {
        color = feature.getProperty("color");
      }
      if (feature.getProperty("tipoEspacio") === "puntoCompra" && this.verEspacioPuntoCompra) {
        color = feature.getProperty("color");
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 2,
      };
    });
  }

  public dameClasificacion(clasificacion: string): string {
    if (clasificacion != null) {
      return clasificacion === "B1" || clasificacion === "B2" ? "B" : clasificacion;
    } else {
      return "Desconocida";
    }
  }
  public hitoObtenido(direccion: Direccion, hito: string, obtenido: boolean): boolean {
    const hitos = direccion.hitosObtenidos.filter((e) => e.hito === hito);
    if (!hitos.length) {
      return false;
    } else {
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);
        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        } else if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
      const masReciente = hitos[hitos.length - 1];
      return masReciente.obtenido === obtenido;
    }
  }
  public btnEspacioDisabled(): boolean {
    return this.cargandoEspacioFisico || this.cargandoEspacioVirtual || this.cargandoEspacioPuntoCompra;
  }
}
