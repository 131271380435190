<div class="cabecera-modal">
  <mat-icon class="icon-modal">priority_high</mat-icon>
  <strong>ACTUALIZACIONES</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargando" style="padding: 20px; min-height: 75px"
       [ngClass]="!mobileQuery.matches ? 'mat-elevation-z8' : ''"
       [ngStyle]="{'margin' : mobileQuery.matches ? '0' : '15px'}">

    <div class="float-end">
      <label for="versionSelect">
        <select class="form-select form-select-sm" id="versionSelect" [(ngModel)]="actualizacionSel"
                (ngModelChange)="cambiarVersion()">
          <option *ngFor="let actualizacion of this.actualizaciones" [ngValue]="actualizacion"
                  [ngStyle]="{'color' : !userHasChecked(actualizacion) ? 'red' : '', 'font-weight' : !userHasChecked(actualizacion) ? 'bold' : ''}">
            {{actualizacion.version}}
          </option>
        </select>
      </label>
    </div>

    <div *ngIf="actualizacionSel != null" class="p-2">
      <div class="text-center mb-4">
        <h5>{{actualizacionSel.titulo}} ({{actualizacionSel.fecha}})</h5>
      </div>
      <div *ngFor="let seccion of actualizacionSel.secciones">
        <p><strong>{{seccion.titulo}}</strong></p>
        <p *ngFor="let cambio of seccion.cambios">- {{cambio}}</p>
      </div>
    </div>

  </div>

  <div *ngIf="cargando" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
