import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UsuarioBalder} from '../../../../modelos/UsuarioBalder';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-editar-usuario-balder',
  templateUrl: './editar-usuario-balder.component.html',
  styleUrls: ['./editar-usuario-balder.component.css']
})
export class EditarUsuarioBalderComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public usuario: UsuarioBalder;

  public nuevo: boolean;

  public nombreUsuario: string;
  public password: string;
  public nombre: string;
  public apellidos: string;
  public desactivado: boolean;
  public rol: string;
  public fechaCreacion: Date;

  public roles: {rol: string, desc: string}[] = [
    {rol: 'admin', desc: 'Administrador'},
    {rol: 'chofer', desc: 'Chófer'},
    {rol: 'agencia', desc: 'Agencia'}
  ];

  public cambiarPass = false;
  public mensajeError = '';

  constructor(public crmservice: CrmService,
              public dialogRef: MatDialogRef<EditarUsuarioBalderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.nuevo = data.nuevo;

    if (this.nuevo) {
      this.desactivado = false;
    } else {
      this.usuario = data.usuario;

      this.nombreUsuario = this.usuario.nombreUsuario;
      this.nombre = this.usuario.nombre;
      this.apellidos = this.usuario.apellidos;
      this.desactivado = this.usuario.desactivado;
      this.rol = this.usuario.rol;
      this.fechaCreacion = this.usuario.fechaCreacion;
    }
  }

  ngOnInit() {
  }

  public guardar(): void {
    if (this.comprobarFormularios()) {
      if (this.nuevo) {

        const usuario = {
          nombreUsuario: this.nombreUsuario,
          password: this.password,
          nombre: this.nombre,
          apellidos: this.apellidos,
          desactivado: this.desactivado,
          rol: this.rol
        };

        this.crmservice.balder_nuevoUsuario(usuario).subscribe(
          valueUser => {
            if (valueUser !== 'repetido') {
              this.dialogRef.close(valueUser);
            } else {
              this.mensajeError = 'Ya existe el usuario ' + this.nombreUsuario;
            }
          }, error1 => {
            console.error(error1);
          });
      } else {

        const usuario = {
          _id: this.usuario._id,
          nombreUsuario: this.nombreUsuario,
          password: this.password,
          nombre: this.nombre,
          apellidos: this.apellidos,
          desactivado: this.desactivado,
          rol: this.rol
        };

        this.usuario.desactivado = this.desactivado;

        this.crmservice.balder_updateUsuario(usuario, this.cambiarPass).subscribe(
          valueUser => {
            if (valueUser !== 'repetido') {
              this.dialogRef.close(valueUser);
            } else {
              this.mensajeError = 'Ya existe el usuario ' + this.nombreUsuario;
            }
          }, error1 => {
            console.error(error1);
          });
      }
    }
  }

  public comprobarFormularios(): boolean {

    if (this.nombre == null || !this.nombre.trim().length) {
      this.mensajeError = 'Introduce el nombre.';
      return false;
    }

    if (this.apellidos == null || !this.apellidos.trim().length) {
      this.mensajeError = 'Introduce los apellidos.';
      return false;
    }

    if (this.nombreUsuario == null || !this.nombreUsuario.trim().length) {
      this.mensajeError = 'Introduce nombre de usuario.';
      return false;
    }

    /*if (this.nombreUsuario.length < 6) {
      this.mensajeError = 'El nombre de usuario debe tener 6 caracteres como mínimo.';
      return false;
    }*/

    if ((this.nuevo || this.cambiarPass) && (this.password == null || !this.password.trim().length)) {
      this.mensajeError = 'Introduce una contraseña.';
      return false;
    }

    if ((this.nuevo || this.cambiarPass) && this.password.length < 4) {
      this.mensajeError = 'La contraseña debe tener 4 caracteres como mínimo.';
      return false;
    }

    if (this.rol == null) {
      this.mensajeError = 'Introduce el rol del usuario.';
      return false;
    }

    this.mensajeError = '';
    return true;
  }
}
