export class Actualizacion {
  public version: string;
  public numero: number;
  public usuarios: string[];
  public titulo: string;
  public fecha: string;
  public secciones: {
    titulo: string,
    cambios: string[]
  }[];
  autor: string;
  editado: {
    usuario: string,
    fecha: Date
  }[];
}
