import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Direccion} from '../../../../../modelos/Direccion';
import {CrmService} from '../../../../../servicios/crm.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Cliente} from '../../../../../modelos/Cliente';

interface UsuarioTECLAMIN {
  readonly _id: string;
  readonly usuario: string;
  readonly fechacreacion: Date;
  readonly activo: boolean;
  readonly email: string;
  readonly provincia: string;
  readonly nombreFiscal: string;
  readonly nombreComercial: string;
}
interface UsuarioTECLA {
  readonly _id: string;
  readonly usuario: string;
  readonly cif_dni: string;
  readonly nombreFiscal: string;
  readonly nombreComercial: string;
  readonly provincia: string;
  readonly poblacion: string;
  readonly cp: string;
  readonly telefono: string;
  readonly email: string;
  readonly activo: boolean;
  readonly puntoCompra: number;
}
interface DatosCopiar {
  cif: string;
  razonSocial: string;

  nombreComercial: string;
  provincia: string;
  poblacion: string;
  codigoPostal: string;
  telefono: string;
  email: string;

  _id: string;
  _idCli: string;
  estado: string;
  perfilTCLA: string;
  perfilesSecundarios: string[];
  hitosObtenidos: {
    hito: string,
    obtenido: boolean,
    fecha: Date,
    usuario: String
  }[];
}

@Component({
  selector: 'app-asignar-perfil-tecla',
  templateUrl: './asignar-perfil-tecla.component.html',
  styleUrls: ['./asignar-perfil-tecla.component.css']
})
export class AsignarPerfilTeclaComponent implements OnInit, AfterViewInit {

  public tabIndex: 0 | 1 | 2 | 3;

  public direccion: Direccion;
  public cliente: Cliente;
  public perfiles: UsuarioTECLAMIN[];
  public perfilDetalle: UsuarioTECLA;
  public datosCopiar: DatosCopiar;
  public puntoCompra: number;
  public dirTECLARepetido: string[];

  public readonly displayedColumns = ['usuario', 'email', 'nombreFiscal', 'provincia', 'fechacreacion', 'activo'];
  public dataSourcePerfiles: MatTableDataSource<UsuarioTECLAMIN> = new MatTableDataSource();
  public textoBusqueda: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public provincia: string;
  public cargando: boolean;

  ngAfterViewInit() {
    this.dataSourcePerfiles.paginator = this.paginator;
    this.dataSourcePerfiles.sort = this.sort;
    this.dataSourcePerfiles.data = [];
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AsignarPerfilTeclaComponent>,
              private snackbar: MatSnackBar,
              public crmservice: CrmService) {

    this.cargando = true;
    this.tabIndex = 0;
    this.direccion = this.data.direccion;
    this.cliente = this.data.cliente;
    this.textoBusqueda = this.data.perfil || '';
    this.provincia = this.direccion.provincia;
    this.perfiles = [];
    this.puntoCompra = 0;
    this.dirTECLARepetido = [];

    this.getPerfiles().then(() => this.applyFilter());
  }

  ngOnInit(): void {
  }

  public get provincias(): string[] {
    if (this.crmservice.userLogin.rol === 'comercial' || this.crmservice.userLogin.rol === 'influencer') {
      return this.crmservice.provinciasEquipos.find(e => this.crmservice.userLogin.team === e.equipo).provincias;
    } else {
      return this.crmservice.provincias.map(e => e.nombre);
    }
  }

  public getPerfiles(): Promise<void> {
    return new Promise(resolve => {
      this.cargando = true;
      this.perfiles = [];
      this.dataSourcePerfiles.data = [];
      //this.crmservice.direccionSeleccionada[3] contiene el cif
      
      if (this.crmservice.userLogin.rol === 'influencer' || this.crmservice.userLogin.rol === 'comercial') {

        this.crmservice.getUsuarioTeclaParaInfluencersComercialesCRM(this.crmservice.direccionSeleccionada[3]).subscribe(
          response => {
            this.perfiles = response.map(e => this._newTECLAMIN(e));
            this.dataSourcePerfiles.data = this.perfiles;
            this.cargando = false;
            resolve();
          }, error => {
            this.cargando = false;
            console.error(error);
            resolve();
          });

      } else {

        this.crmservice.getUsuariosDistribuidores(this.provincia !== 'todas' ? this.provincia : null).subscribe(
          response => {
            this.perfiles = response.map(e => this._newTECLAMIN(e));
            this.dataSourcePerfiles.data = this.perfiles;
            this.cargando = false;
            resolve();
          }, error => {
            this.cargando = false;
            console.error(error);
            resolve();
          });

      }
    });
  }
  public applyFilter(): void {
    this.dataSourcePerfiles.filter = this.textoBusqueda;
  }

  public seleccionarPerfil(perfil: UsuarioTECLAMIN): void {
    this._getPerfilDetalle(perfil)
      .then(() => {
        this.crmservice.getDireccionesPerfilTCLA(this.perfilDetalle.usuario).subscribe(
          response => {
            this.dirTECLARepetido = response.filter(e => e._id !== this.direccion._id && e.cliente !== this.cliente._id).map(e => e.nombreComercial);
            this.siguiente();
          }, error => {
            console.error(error);
            this.snackbar.open('Se ha producido un error al solicitar el perfil ' + perfil.usuario, 'OK', {duration: 3000});
          });
      })
      .catch(error => {
        console.error(error);
        this.snackbar.open('Se ha producido un error al solicitar el perfil ' + perfil.usuario, 'OK', {duration: 3000});
      });
  }
  public activarAsignar(enviarMail: boolean = false): void {
    const puntoFormateado = this.puntoCompra.toString(10).replace(',', '.');

    this.cargando = true;
    this.crmservice.activarUsuarioTECLA(this.perfilDetalle.usuario, parseFloat(puntoFormateado)).subscribe(
      () => {
        this._updateClienteDireccion()
          .then(() => {
            if (enviarMail) {
              this.crmservice.enviarMailActivacionTECLA(this.perfilDetalle.usuario, this.perfilDetalle.email, parseFloat(puntoFormateado)).subscribe(
                response => {
                  if (response === 'Error al enviar correo de activacion al usuario') {
                    this.snackbar.open('Usuario Activado. No se ha podido enviar el correo de confirmación', 'OK', {duration: 3000});
                  }
                  this.cargando = false;
                  this.dialogRef.close();
                }, error => {
                  console.error(error);
                  this.snackbar.open('Usuario Activado. No se ha podido enviar el correo de confirmación', 'OK', {duration: 3000});
                  this.cargando = false;
                  this.dialogRef.close();
                });
            } else {
              this.cargando = false;
              this.dialogRef.close();
            }
          })
          .catch(error => {
            console.error(error);
            this.snackbar.open('Ocurrió un error al actualizar la dirección en el CRM', 'OK', {duration: 3000});
            this.cargando = false;
          });
      }, error => {
        console.error(error);
        this.snackbar.open('Ocurrió un error al activar la cuenta de usuario de ' + this.perfilDetalle.usuario, 'OK', {duration: 3000});
        this.cargando = false;
      });
  }
  public asignar(): void {
    this.cargando = true;
    this._updateClienteDireccion()
      .then(() => {
        this.cargando = false;
        this.dialogRef.close();
      })
      .catch(error => {
        this.cargando = false;
        console.error(error);
        this.snackbar.open('Ocurrió un error al actualizar la dirección en el CRM', 'OK', {duration: 3000});
      });
  }

  public volver(): void {
    switch (this.tabIndex) {
      case 0:
        this.dialogRef.close();
        return;
      case 1:
        this.tabIndex = 0;
        return;
      case 2:
        this.tabIndex = 1;
        return;
      case 3:
        this.tabIndex = 2;
        return;
      default:
        return;
    }
  }
  public siguiente(): void {
    switch (this.tabIndex) {
      case 0:
        // Selección de perfil
        this._iniciarDatosCopiar();
        this.tabIndex = 1;
        return;
      case 1:
        // Comprobar datos
        this.puntoCompra = 0;
        this.tabIndex = 2;
        return;
      case 2:
        // Punto de compra
        if (this.puntoCompra <= 0 || this.puntoCompra > 1) {
          this.snackbar.open('El Punto de Compra tiene que ser un numero entre 0 y 1', 'OK', {duration: 3000});
          return;
        }
        this.tabIndex = 3;
        return;
      case 3:
        // Correo de confirmación, activar perfil y asociar a dirección.
        this.activarAsignar(false);
        return;
      default:
        return;
    }
  }

  private _updateClienteDireccion(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.datosCopiar.estado === 'candidato' || this.datosCopiar.estado === 'noactivo') {
        this.datosCopiar.estado = 'potencial';
      }

      // Si no lo tiene, o el más reciente no está marcado como obtenido, se añade.
      if (this.datosCopiar.hitosObtenidos == null) {
        this.datosCopiar.hitosObtenidos = [];
      }
      const hitos = this.datosCopiar.hitosObtenidos.filter(e => e.hito === 'TCLA');
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);
        return (aDate > bDate) ? -1 : ((aDate < bDate) ? 1 : 0);
      });
      if (!hitos.length || !hitos[0].obtenido) {
        this.datosCopiar.hitosObtenidos.push({
          hito: 'TCLA',
          usuario: this.crmservice.userLogin._id,
          fecha: new Date(),
          obtenido: true
        });
      }

      this.cliente.cif = this.datosCopiar.cif;
      this.cliente.razonSocial = this.datosCopiar.razonSocial;

      this.direccion.nombreComercial = this.datosCopiar.nombreComercial;
      this.direccion.provincia = this.datosCopiar.provincia;
      this.direccion.poblacion = this.datosCopiar.poblacion;
      this.direccion.codigoPostal = this.datosCopiar.codigoPostal;
      this.direccion.telefono = this.datosCopiar.telefono;
      this.direccion.email = this.datosCopiar.email;
      this.direccion.estado = this.datosCopiar.estado;
      this.direccion.perfilTCLA = this.datosCopiar.perfilTCLA;
      this.direccion.hitosObtenidos = this.datosCopiar.hitosObtenidos;

      this.crmservice.actualizarPerfilTecla(this.datosCopiar).subscribe(
        () => {
          resolve();
        }, error => {
          reject();
        });
    });
  }
  private _iniciarDatosCopiar(): void {
    this.datosCopiar = {
      cif: this.cliente.cif || null,
      razonSocial: this.cliente.razonSocial || null,

      nombreComercial: this.direccion.nombreComercial || null,
      email: this.direccion.email || null,
      codigoPostal: this.direccion.codigoPostal || null,
      poblacion: this.direccion.poblacion || null,
      provincia: this.direccion.provincia || null,
      telefono: this.direccion.telefono || null,

      _id: this.direccion._id,
      _idCli: this.cliente._id,
      estado: this.direccion.estado,
      perfilTCLA: this.perfilDetalle.usuario,
      perfilesSecundarios: [],
      hitosObtenidos: this.direccion.hitosObtenidos || []
    };
  }
  private _newTECLAMIN(value: any): UsuarioTECLAMIN {
    return {
      _id: value._id || '',
      usuario: value.usuario || '',
      fechacreacion: value.fechacreacion ? new Date(value.fechacreacion) : new Date(),
      activo: value.activo || false,
      email: value.email || '',
      provincia: value.provincia || '',
      nombreFiscal: value.nombreFiscal || '',
      nombreComercial: value.nombreComercial || ''
    };
  }
  private _getPerfilDetalle(perfil: UsuarioTECLAMIN): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cargando = true;
      this.crmservice.getFactorConversion(perfil.usuario).subscribe(
        response => {
          this.perfilDetalle = response[0];
          this.cargando = false;
          resolve();
        }, error => {
          this.perfilDetalle = null;
          this.cargando = false;
          reject(error);
        });
    });
  }
}
