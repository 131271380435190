import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Direccion} from '../../../../modelos/Direccion';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-contrato-idehabita-formulario',
  templateUrl: './contrato-idehabita-formulario.component.html',
  styleUrls: ['./contrato-idehabita-formulario.component.css']
})
export class ContratoIdehabitaFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public guardandoDatos = false;
  public direccion: Direccion;

  public provincia: string;
  public filtro: string;

  public CPDataSource: MatTableDataSource<any> = new MatTableDataSource();

  public docToUpload: File = null;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direccion = this.data.direccion;

    this.CPDataSource.data = [];
    for (const cp of this.crmservice.codigosPostales) {
      let pos = this.CPDataSource.data.findIndex(e => e.codigo === cp.codigoPostal);
      if (pos === -1) {
        this.CPDataSource.data.push({
          codigo: cp.codigoPostal,
          poblaciones: '',
          cps: []
        });
        pos = this.CPDataSource.data.findIndex(e => e.codigo === cp.codigoPostal);
      }
      this.CPDataSource.data[pos].poblaciones += cp.poblacion + ' ';
      this.CPDataSource.data[pos].cps.push(cp);
    }

    this.CPDataSource.data.sort((a, b) => {
      if (a.codigo > b.codigo) {
        return 1;
      } else if (a.codigo < b.codigo) {
        return -1;
      }
      return 0;
    });

  }

  ngOnInit() {
  }
  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.CPDataSource.filter = filterValue;
  }


  /** RUTAS DE CODIGO POSTAL**/
  public getProvincia(cp: string): string {
    const pos = this.crmservice.codigosPostales.findIndex(e => e.codigoPostal.toString(10) === cp);
    if (pos !== -1) {
      return this.crmservice.codigosPostales[pos].provincia;
    } else {
      return 'Desconocido';
    }
  }
  public mostrarCP(codigoPostal: any): boolean {
    return codigoPostal.cps.some(e => e.provincia === this.provincia);
  }
  public mostrarProvincia(provincia: string): boolean {
    if (this.crmservice.userLogin.rol !== 'comercial' && this.crmservice.userLogin.rol !== 'influencer') {
      return true;
    } else {
      const team = this.crmservice.userLogin.team;
      const posTeam = this.crmservice.provinciasEquipos.findIndex(e => e.equipo === team);
      if (posTeam !== -1) {
        return this.crmservice.provinciasEquipos[posTeam].provincias.findIndex(e => e === provincia) !== -1;
      } else {
        return false;
      }
    }
  }

  public getDisponibilidadFisica(codigoPostal: any): string {
    const asignadoIDE = codigoPostal.cps[0].asignadoIDE;
    return asignadoIDE != null ? asignadoIDE : 'Disponible';
  }
  public getDisponibilidadVirtual(codigoPostal: any): string {
    const asignadoVirtual = codigoPostal.cps[0].asignadoVirtual;
    return asignadoVirtual != null ? asignadoVirtual : 'Disponible';
  }

  public irACP(codigoPostal: string): void {
    const pos = this.CPDataSource.data.findIndex(e => e.codigo.toString(10) === codigoPostal);
    if (pos !== -1) {
      this.provincia = this.CPDataSource.data[pos].cps[0].provincia;
      this.filtro = this.CPDataSource.data[pos].cps[0].codigoPostal.toString(10);
      this.applyFilter(this.filtro);
    }
  }

  public ponerCP(codigoPostal: any): void {

    this.guardandoDatos = true;

    if (!this.direccion.contratoIDE.codigosPostalesAsociados.includes(codigoPostal.codigo)) {
      this.direccion.contratoIDE.codigosPostalesAsociados.push(codigoPostal.codigo.toString());
    }

    if (!this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.includes(codigoPostal.codigo)) {
      this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.push(codigoPostal.codigo.toString());
    }

    for (const cp of codigoPostal.cps) {
      cp.asignadoIDE = this.direccion.perfilTCLA;
      cp.asignadoVirtual = this.direccion.perfilTCLA;
    }

    const idsCP = [];
    for (const cp of codigoPostal.cps) {
      idsCP.push(cp.idTCLA);
    }

    this.crmservice.asignarCPTCLA(idsCP, this.direccion.perfilTCLA).subscribe(
      () => {

        this.crmservice.asignarCPFisico(codigoPostal.cps, this.direccion._id, this.direccion.perfilTCLA).subscribe(
          () => {
            this.guardandoDatos = false;
          }, error1 => {
            console.error(error1);
            this.guardandoDatos = false;
          });
      }, error1 => {
        console.error(error1);
        this.guardandoDatos = false;
      });
  }
  public quitarCP(codigo: string): void {

    this.guardandoDatos = true;

    let pos = this.direccion.contratoIDE.codigosPostalesAsociados.findIndex(e => e === codigo);
    if (pos !== -1) {
      this.direccion.contratoIDE.codigosPostalesAsociados.splice(pos, 1);
    }

    pos = this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.findIndex(e => e === codigo);
    if (pos !== -1) {
      this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.splice(pos, 1);
    }

    pos = this.CPDataSource.data.findIndex(e => e.codigo.toString(10) === codigo);

    if (pos !== -1) {
      for (const cp of this.CPDataSource.data[pos].cps) {
        cp.asignadoIDE = null;
        cp.asignadoVirtual = null;
      }
    }

    const idsCP = [];
    for (const cp of this.CPDataSource.data[pos].cps) {
      idsCP.push(cp.idTCLA);
    }

    this.crmservice.desasignarCPTCLA(idsCP, this.direccion.perfilTCLA).subscribe(
      () => {
        this.crmservice.desasignarCPFisico(this.CPDataSource.data[pos].cps, this.direccion._id).subscribe(
          () => {
            this.guardandoDatos = false;
          }, error2 => {
            console.error(error2);
            this.guardandoDatos = false;
          });
      }, error1 => {
        console.error(error1);
        this.guardandoDatos = false;
      });

  }
  /** RUTAS DE CONTACTO **/
  public verContrato(): void {
    window.open(this.crmservice.urlCRM + '/doc/contratos/' + this.direccion.contratoIDE.documento, '_blank');
  }
  public abrirInput(): void {
    document.getElementById('contratoIDE').click();
  }
  public subirPDF(files: FileList): void {
    this.guardandoDatos = true;
    this.docToUpload = files.item(0);

    const name = Math.random().toString(36).substr(2) + '.' + this.docToUpload.name.split('.').pop();

    this.crmservice.subirContrato(this.docToUpload, name).subscribe(
      () => {
        this.direccion.contratoIDE.documento = name;
      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al subir contrato', 'OK', {duration: 2000});

      }, () => {
        this.guardarContrato();
      }
    );
  }
  public borrarContrato(): void {
    this.guardandoDatos = true;

    this.direccion.contratoIDE.documento = null;
    this.guardarContrato();
  }
  public guardarContrato(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {

      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al guardar contrato', 'OK', {duration: 2000});
      }, () => {
        this.guardandoDatos = false;
        this.snackBar.open('Contrato guardado correctamente', 'OK', {duration: 2000});
      }
    );
  }
}
