<div class="cabecera-modal">
  <mat-icon class="icon-modal">business</mat-icon>
  <strong>Nuevo cliente</strong>
  <mat-icon class="cerrar-modal" (click)="cancelar()">close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargando" class="row bg-white ps-md-5 pe-md-5 pt-md-4 p-2 rounded m-0-auto" style="max-height: calc(100% - 55px); overflow-y: auto">
      <!-- AVISO A INFLUENCERS Y COMERCIALES-->
        <div *ngIf="crmservice.userLogin.rol === 'influencer' || crmservice.userLogin.rol === 'comercial'" class="col-12 d-flex flex-row mb-4 pb-2 pt-2 rounded" style="background-color: rgb(235, 171, 171);">
          <strong class="text-secondary">Aviso</strong>
          <mat-icon class="ms-auto" color="primary">warning</mat-icon>
        </div>
        <div *ngIf="crmservice.userLogin.rol === 'influencer' || crmservice.userLogin.rol === 'comercial'" class="col-sm-12 col-12" style="margin-bottom: 30px;">
          <strong>Los clientes que se registren no se le asignan hasta tener perfil Tecla creado y configurado en CRM.</strong>
          <br>
        </div>
      <!-- DATOS FISCALES -->
      <div class="col-12 d-flex flex-row mb-4 pb-2 pt-2 rounded bg-mat">
        <strong class="text-secondary">Datos Fiscales</strong>
        <mat-icon class="ms-auto" color="primary">business_center</mat-icon>
      </div>
      <div class="col-sm-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Tipo</mat-label>
          <mat-select [(ngModel)]="cliente.tipoCliente.tipo" required>
            <mat-option *ngFor="let item of crmservice.tiposCliente" [value]="item.id">
              {{item.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Categoria</mat-label>
          <mat-select [(ngModel)]="cliente.tipoCliente.categoria" required>
            <mat-option *ngFor="let item of crmservice.categoriasCliente" [value]="item.id">
              {{item.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Razón Social</mat-label>
          <input [(ngModel)]="cliente.razonSocial" matInput placeholder="Razón Social" required>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label *ngIf="cifRegistrable === 'indefinido'">CIF</mat-label> 
          <mat-label *ngIf="cifRegistrable === 'noRegistrable'" style="color: orange">CIF NO REGISTRABLE</mat-label>
          <mat-label *ngIf="cifRegistrable === 'registrable'" style="color: green">CIF REGISTRABLE</mat-label>
          <mat-label *ngIf="cifRegistrable === 'variasDirecciones'" style="color: rgb(0, 105, 128)">Póngase en contacto con fábrica, tiene varias direcciones</mat-label>
          <input  (focusout)="comprobarCifRegistrable();"
                  [(ngModel)]="cliente.cif" 
                  matInput placeholder="CIF" 
                  required>
        </mat-form-field>
      </div>

      <!-- DATOS COMERCIALES -->
      <div class="col-12 d-flex flex-row mb-4 mt-4 pb-2 pt-2 rounded bg-mat">
        <strong class="text-secondary">Datos Comerciales</strong>
        <mat-icon class="ms-auto" color="primary">store_mall_directory</mat-icon>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Nombre Comercial</mat-label>
          <input [(ngModel)]="direccion.nombreComercial" matInput placeholder="Nombre Comercial" required>
        </mat-form-field>
      </div>
      <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol !== 'influencer'" class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Equipo</mat-label>
          <mat-select [(ngModel)]="direccion.equipo" placeholder="Equipo" required>
            <mat-option *ngFor="let item of crmservice.equipos" value="{{item.id}}">{{item.id}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol !== 'influencer'" class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="direccion.estado" placeholder="Estado" required>
            <mat-option *ngFor="let item of crmservice.estados" value="{{item.id}}">{{item.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol !== 'influencer'" class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>TECLA</mat-label>
          <input [(ngModel)]="direccion.perfilTCLA" matInput placeholder="TECLA" required="{{['potencial', 'activo', 'activoI'].includes(direccion.estado)}}">
        </mat-form-field>
      </div>
      <div *ngIf="crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol !== 'influencer'" class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Ver en mapa</mat-label>
          <mat-select [(ngModel)]="direccion.verMapa" placeholder="Ver en mapa" required>
            <mat-option [value]="true">Si</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Tipo de dirección</mat-label>
          <mat-select [(ngModel)]="direccion.tipoDir" (ngModelChange)="tipoDirCambiado()" placeholder="Tipo de direccion" required>
            <mat-option *ngFor="let item of crmservice.tiposDirNCli" value="{{item.id}}">
              {{item.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Código Postal</mat-label>
          <input (change)="cambiaCP()" [(ngModel)]="direccion.codigoPostal" matInput placeholder="Código Postal" required>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Provincia</mat-label>
          <mat-select [(ngModel)]="direccion.provincia" placeholder="Provincia" required>
            <mat-option *ngFor="let item of crmservice.provincias" value="{{item.nombre}}">{{item.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Población</mat-label>
          <input [(ngModel)]="direccion.poblacion" matInput placeholder="Población" required>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Vía</mat-label>
          <mat-select [(ngModel)]="direccion.tipoVia" placeholder="Vía" required>
            <mat-option *ngFor="let item of crmservice.vias" value="{{item}}">
              {{item[0].toUpperCase() + item.substr(1).toLowerCase()}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Dirección</mat-label>
          <input [(ngModel)]="direccion.direccion" matInput placeholder="Dirección" required>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Detalles Dirección (opcional)</mat-label>
          <input [(ngModel)]="direccion.direccionDetalle" [ngModelOptions]="{standalone: true}" matInput placeholder="Detalles Dirección">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Email (opcional)</mat-label>
          <input [(ngModel)]="direccion.email" matInput placeholder="Email">
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Web (opcional)</mat-label>
          <input [(ngModel)]="direccion.webEmpresa" [ngModelOptions]="{standalone: true}" matInput placeholder="Web">
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field class="w-100">
          <mat-label>Telefono (opcional)</mat-label>
          <input [(ngModel)]="direccion.telefono" matInput placeholder="Telefono">
        </mat-form-field>
      </div>

      <!-- OBSERVACIONES -->
      <div class="col-12 d-flex flex-row mb-4 mt-4 pb-2 pt-2 rounded bg-mat">
        <strong class="text-secondary">Observaciones</strong>
        <mat-icon class="ms-auto" color="primary">comment</mat-icon>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Observaciones (opcional)</mat-label>
          <textarea [(ngModel)]="cliente.observaciones" matInput matTextareaAutosize matAutosizeMinRows="2" placeholder="Observaciones"></textarea>
        </mat-form-field>
      </div>

    </div>

  <div *ngIf="!cargando" class="d-flex p-2 mt-auto border-top bg-light">
    <div class="btn btn-primary-crm ms-auto" [class.w-100]="mobileQuery.matches" (click)="guardar()">CREAR</div>
  </div>

  <div class="spinner-container" *ngIf="cargando">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
