<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>{{muestra.nombre}} - {{muestra.tipo == 'pequeno' ? 'Pequeño' : 'Grande'}}</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div class="row" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">

    <div class="col-md-6 col-12">
      <img src="{{crmservice.urlCRM + '/img/muestras/' + muestra.imagen}}" class="rounded-2 shadow-sm" alt="">
    </div>

    <div class="col-md-6 col-12">
      <mat-list *ngIf="muestraDireccion >= 0">
        <h3 mat-subheader>Entregas/Retiradas</h3>
        <mat-list-item *ngFor="let movimiento of cajaSeleccionada.muestras[muestraDireccion].movimientos" class="border-bottom border-secondary">
          <mat-icon mat-list-icon *ngIf="movimiento.tipo === 0 && !mobileQuery.matches">check_circle_outline</mat-icon>
          <mat-icon mat-list-icon *ngIf="movimiento.tipo === 1 && !mobileQuery.matches">highlight_off</mat-icon>
          <h4 *ngIf="!mobileQuery.matches" mat-line>
            {{movimiento.tipo === 0 ? 'Entregado' : 'Retirado'}} por <br>
            {{movimiento.userMov.nombre}}
          </h4>
          <p *ngIf="mobileQuery.matches" mat-line class="mb-0 fs-6">
            {{movimiento.tipo === 0 ? 'Entregado' : 'Retirado'}} por <br>
            {{movimiento.userMov.nombre}}
          </p>
          <p mat-line class="text-secondary fs-6">{{movimiento.fechaMov | date:'dd/MM/yy HH:mm'}}</p>
        </mat-list-item>
      </mat-list>
      <h5 *ngIf="muestraDireccion==-1">No hay movimientos</h5>
    </div>

  </div>
</div>


