<div class="cabecera-modal">
  <mat-icon class="icon-modal">account_circle</mat-icon>
  <strong>Editar Perfil</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div
    *ngIf="!cargando"
    class="bg-white ps-md-5 pe-md-5 pt-md-4 pb-md-4 p-3 rounded m-0-auto"
    style="max-height: calc(100% - 55px); height: calc(100% - 55px); overflow-y: auto"
  >
    <div class="row h-100">
      <div class="col-12" id="imagenUsuarioSubida">
        <img [src]="usuarioEditar.imagen" alt="" />
        <button *ngIf="permiso" class="position-absolute end-0" id="subMenuUser" mat-icon-button [matMenuTriggerFor]="menuUser">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Distribuidor</mat-label>
          <input [(ngModel)]="usuarioEditar.codigoDistribuidor" matInput placeholder="Código distribuidor" />
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Nombre de Usuario</mat-label>
          <input [(ngModel)]="usuarioEditar.nombreUsuario" matInput placeholder="Nombre de Usuario" required disabled />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Rol</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.rol" placeholder="Rol" required disabled="{{ !crmservice.rol('admin') }}">
            <mat-option *ngFor="let rol of crmservice.roles" [value]="rol.id">{{ rol.id }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Equipo</mat-label>
          <mat-select
            [(ngModel)]="usuarioEditar.team"
            placeholder="Equipo"
            required="{{ usuarioEditar.rol === 'comercial' || usuarioEditar.rol === 'influencer' }}"
            disabled="{{ !crmservice.rol('admin') }}"
          >
            <mat-option *ngFor="let equipo of crmservice.equipos" [value]="equipo.id">{{ equipo.id }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="usuarioEditar.rol === 'comercial' || usuarioEditar.rol === 'influencer'" class="col-4">
        <button class="btn btn-primary-crm w-100 mt-2" (click)="addCodigoReperesentante()" [disabled]="!crmservice.rol('admin')">
          <div class="d-flex">
            <mat-icon class="me-2">control_point</mat-icon>
            <span>Añadir</span>
          </div>
        </button>
      </div>
      <div *ngIf="usuarioEditar.rol === 'comercial' || usuarioEditar.rol === 'influencer'" class="col-8">
        <mat-form-field class="w-100">
          <mat-label>Código Representante</mat-label>
          <mat-select
            [(ngModel)]="usuarioEditar.codigoRepresentante"
            placeholder="Código Representante"
            disabled="{{ !crmservice.rol('admin') }}"
            multiple
          >
            <mat-option *ngFor="let codigo of usuarioEditar.codigoRepresentante" [value]="codigo">{{ codigo }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label class="me-2">Nombre</mat-label>
          <input [(ngModel)]="usuarioEditar.nombre" matInput placeholder="Nombre" [disabled]="!permiso" required />
        </mat-form-field>
      </div>
      <div class="col-lg-8 col-12">
        <mat-form-field class="w-100">
          <mat-label>Apellidos</mat-label>
          <input [(ngModel)]="usuarioEditar.apellidos" matInput placeholder="Apellidos" [disabled]="!permiso" required />
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Población</mat-label>
          <input [(ngModel)]="usuarioEditar.poblacion" matInput placeholder="Población" [disabled]="!permiso" required />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Provincia</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.provincia" placeholder="Provincia" [disabled]="!permiso" required>
            <mat-option *ngFor="let provincia of crmservice.provincias" [value]="provincia.nombre">{{ provincia.nombre }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Teléfono</mat-label>
          <input [(ngModel)]="usuarioEditar.telefono" matInput placeholder="Teléfono" [disabled]="!permiso" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input type="email" [(ngModel)]="usuarioEditar.email" matInput placeholder="Email" [disabled]="!permiso" required />
        </mat-form-field>
      </div>
      <div *ngIf="usuarioEditar.rol == 'asesor'" class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Fiabilidad</mat-label>
          <input [(ngModel)]="usuarioEditar.fiabilidad" type="number" min="0" max="10" matInput placeholder="Fiabilidad" disabled />
        </mat-form-field>
      </div>
      <div *ngIf="usuarioEditar.rol == 'asesor'" class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>¿En Aprendizaje?</mat-label>
          <mat-select
            [(ngModel)]="usuarioEditar.aprendizaje"
            placeholder="¿En Aprendizaje?"
            required="{{ usuarioEditar.rol === 'asesor' }}"
            disabled="{{ !crmservice.rol('admin') }}"
          >
            <mat-option [value]="true">Si</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="usuarioEditar.rol == 'asesor'" class="col-lg-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Rol para DV</mat-label>
          <mat-select
            [(ngModel)]="usuarioEditar.rolParaDv"
            required="{{ usuarioEditar.rol === 'asesor' }}"
            disabled="{{ usuarioEditar.rol !== 'asesor' }}"
          >
            <mat-option [value]="'disenador'">Diseñador</mat-option>
            <mat-option [value]="'ventasYExpedicion'">Ventas y Expedición</mat-option>
            <mat-option [value]="'todos'">Todos</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="!cargando && permiso" class="d-flex p-2 mt-auto border-top bg-light">
    <div class="btn btn-primary-crm ms-auto" [class.w-100]="mobileQuery.matches" (click)="guardarUsuario()">GUARDAR</div>
  </div>

  <div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>

<input type="file" id="imagenUsuario" accept="image/*" hidden (change)="guardarImagen($event)" />
<mat-menu #menuUser="matMenu">
  <label for="imagenUsuario" mat-menu-item>
    <mat-icon>add_a_photo</mat-icon>
    <span>Subir imagen</span>
  </label>
  <button (click)="cambiarPassword()" mat-menu-item>
    <mat-icon>lock</mat-icon>
    <span>Cambiar Contraseña</span>
  </button>
  <button *ngIf="crmservice.userLogin.rol == 'admin' && usuarioEditar.desactivado != true" mat-menu-item (click)="desactivarUsuario()">
    <mat-icon>close</mat-icon>
    <span>Desactivar usuario</span>
  </button>
  <button *ngIf="crmservice.userLogin.rol == 'admin' && usuarioEditar.desactivado == true" mat-menu-item (click)="activarUsuario()">
    <mat-icon>person_add</mat-icon>
    <span>Activar usuario</span>
  </button>
</mat-menu>
