<div *ngIf="!ds.abrirGestorDescargas" role="button" class="btn-decargas" (click)="abrirDescargas()">
  <div class="badge" *ngIf="ds.numDescargando > 0">{{ds.numDescargando}}</div>
  <mat-icon style="font-size: 2em; color: #222">cloud_download</mat-icon>
</div>

<div *ngIf="ds.abrirGestorDescargas">
  <div class="cabecera">
    Descargas
    <mat-icon class="btn-cerrar" (click)="cerrarDescargas()">expand_more</mat-icon>
  </div>

  <div class="contenido">
    <div *ngFor="let download of ds.downloads" class="descarga-container" id="{{download.id}}" matTooltip="{{download.file}}"
         (mouseenter)="setDownloadOver(download.id)" (mouseleave)="setDownloadOver(null)">

      <div class="icono-fichero" (click)="btnIcon(download)">
        <mat-icon class="btn-icono-fichero" color="primary">{{getIconoDownload(download)}}</mat-icon>
      </div>
      <div class="nombre-fichero">
        <span>{{download.file}}</span>
        <mat-progress-bar *ngIf="mostrarProgressBar(download)" style="margin-top: 5px;" mode="determinate" [value]="getValueProgressBar(download) * 100"
                          color="{{download.estado === 'INTERRUMPIDA' ? 'warn' : 'primary'}}"></mat-progress-bar>
      </div>
      <div class="tam-fichero">
        <span *ngIf="ds.magnitud(download.total) == 'kb'">
          <span *ngIf="download.loaded != download.total">{{ds.truncar(download.loaded / 1000, 2)}} / </span>{{ds.truncar(download.total / 1000, 2)}} Kb {{download.estado}}
        </span>
        <span *ngIf="ds.magnitud(download.total) == 'mb'">
          <span *ngIf="download.loaded != download.total">{{ds.truncar(download.loaded / 1000000, 2)}} / </span>{{ds.truncar(download.total / 1000000, 2)}} Mb {{download.estado}}
        </span>
      </div>

    </div>
  </div>
</div>

