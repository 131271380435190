<div class="cabecera-modal">
  <mat-icon class="icon-modal">account_circle</mat-icon>
  <strong>Comerciales/Influencers del equipo {{equipo}}</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal p-4">
  <table class="table table-hover">
    <thead>
    <tr>
      <th>Usuario</th>
      <th>Nombre</th>
      <th>Apellidos</th>
      <th>Rol</th>
      <th class="text-end">Ultima Con.</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let usuario of usuarios" role="button" (click)="verUsuario(usuario)">
      <td>{{usuario.nombreUsuario}}</td>
      <td>{{usuario.nombre}}</td>
      <td>{{usuario.apellidos}}</td>
      <td>{{usuario.rol}}</td>
      <td class="text-end">{{usuario.fechaUltimaCon | date: 'dd/MM/yyyy'}}</td>
    </tr>
    </tbody>
  </table>
</div>
