<div *ngIf="crmservice.datosCargados" class="m-xl-4 m-lg-3 m-md-2 m-sm-2 m-1">

  <div class="direcciones-header">
    <div class="row m-auto" style="width: 99%">
      <div *ngIf="!mobileQuery.matches" class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">

        <label *ngIf="crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'"
               class="mt-3 me-3 mb-1" [ngStyle]="{'width' : !mobileQuery.matches ? '' : '100%'}">
          <select class="form-select form-select-sm" [ngClass]="!mobileQuery.matches ? 'form-control-sm' : ''" [(ngModel)]="crmservice.equipoMostrarClientes"
                  (ngModelChange)="cambiarDireccionesVer()" [disabled]="crmservice.userLogin.rol === 'comercial'  || crmservice.userLogin.rol === 'influencer'">
            <option [value]="'TODOS'">EQUIPO: TODOS</option>
            <option *ngFor="let equipo of crmservice.equipos" [value]="equipo.id">EQUIPO: {{equipo.id}}</option>
          </select>
        </label>

        <label class="me-3 mb-1" [ngStyle]="{'width' : !mobileQuery.matches ? '' : '100%'}">
          <select class="form-select form-select-sm" [ngClass]="!mobileQuery.matches ? 'form-control-sm' : ''" [(ngModel)]="crmservice.clasificacionMostrarClientes" (ngModelChange)="cambiarDireccionesVer()">
            <option [value]="'TODOS'">CLASIFICACIÓN: TODOS</option>
            <option [value]="'A'">CLASIFICACIÓN: A</option>
            <option [value]="'B'">CLASIFICACIÓN: B</option>
            <option [value]="'C'">CLASIFICACIÓN: C</option>
          </select>
        </label>

        <label class="me-3 mb-1" [ngStyle]="{'width' : !mobileQuery.matches ? '' : '100%'}">
          <select class="form-select form-select-sm" [ngClass]="!mobileQuery.matches ? 'form-control-sm' : ''" [(ngModel)]="crmservice.estadoMostrarClientes" (ngModelChange)="cambiarDireccionesVer()">
            <option [value]="'TODOS'">ESTADO: TODOS</option>
            <option *ngFor="let estado of crmservice.estados" [value]="estado.id">ESTADO: {{estado.nombre}}</option>
          </select>
        </label>

        <label class="me-3 mb-1" [ngStyle]="{'width': !mobileQuery.matches ? '' : '100%'}">
          <select class="form-select form-select-sm" [ngClass]="!mobileQuery.matches ? 'form-control-sm' : ''" [(ngModel)]="crmservice.tipoMostrarClientes" (ngModelChange)="cambiarDireccionesVer()">
            <option [value]="'TODOS'">TIPO: TODOS</option>
            <option *ngFor="let tipo of crmservice.tiposCliente" [value]="tipo.id">TIPO: {{tipo.nombre}}</option>
          </select>
        </label>

        <label class="me-3 mb-1" [ngStyle]="{'width': !mobileQuery.matches ? '' : '100%'}">
          <select class="form-select form-select-sm" [ngClass]="!mobileQuery.matches ? 'form-control-sm' : ''" [(ngModel)]="crmservice.categoriaMostrarClientes" (ngModelChange)="cambiarDireccionesVer()">
            <option [value]="'TODOS'">CATEGORIA: TODOS</option>
            <option *ngFor="let categoria of crmservice.categoriasCliente" [value]="categoria.id">TIPO: {{categoria.nombre}}</option>
          </select>
        </label>

      </div>

      <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 text-lg-end">
        <button style="width: 40px" color="primary" matTooltip="Nuevo cliente" (click)="nuevoCliente()" mat-icon-button>
          <mat-icon>add_to_photos</mat-icon>
        </button>
        <mat-form-field style="width: calc(100% - 40px)">
          <input type="text" autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="crmservice.filtroClientes" placeholder="Filtro">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="contenedorTabla">
    <table mat-table [dataSource]="dataSource" matSortActive="{{crmservice.matSortActive}}" matSortDirection="{{crmservice.matSortDirection}}" (matSortChange)="matSortChange($event)"
           id="tabla-clientes" matSort>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1"></th>
        <td mat-cell *matCellDef="let direccion" class="p-1">
<!--          <img src="../../assets/img/markers/{{direccion.equipo}}/{{direccion.estado}}.png" class="pt-2" alt="">-->
          <app-equipo [equipo]="direccion.equipo" [estado]="direccion.estado"></app-equipo>
        </td>
      </ng-container>

      <ng-container matColumnDef="cif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center p-1"> CIF</th>
        <td mat-cell *matCellDef="let direccion" class="nombreComercial" (click)="navegarA(direccion._id)" class="p-1 align-middle">
          {{direccion.cif}}
        </td>
      </ng-container>

      <ng-container matColumnDef="razonSocial">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" mat-sort-header class="p-1"> Razón Social</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)"
            [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" class="p-1 align-middle">
          <mat-icon *ngIf="crmservice.tieneAlertas(direccion._idCli, direccion._id) && crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'"
                    class="float-start text-danger">warning</mat-icon>
          <span *ngIf="!mobileQuery.matches">{{direccion.razonSocial}}</span>
          <span *ngIf="mobileQuery.matches">{{truncarString(direccion.razonSocial, 20)}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="clasificacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1"> Clas.</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)" class="class-char"
            [ngStyle]="{'color' : getColorClasificacion(direccion.clasificacion)}">
          {{getClasificacion(direccion.clasificacion)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="nombreComercial">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" mat-sort-header class="p-1"> Nombre comercial</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)"
            [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" class="p-1 align-middle">
          <span *ngIf="!mobileQuery.matches"><b>{{direccion.nombreComercial}}</b></span>
          <span *ngIf="mobileQuery.matches"><b>{{truncarString(direccion.nombreComercial, 20)}}</b></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="provincia">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" mat-sort-header class="p-1"> Provincia</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)"
            [ngStyle]="{'font-size' : mobileQuery.matches ? 'small' : ''}" class="p-1 align-middle">
          {{direccion.provincia}}
        </td>
      </ng-container>

      <ng-container matColumnDef="poblacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1"> Población</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)" class="p-1 align-middle"> {{direccion.poblacion}}</td>
      </ng-container>

      <ng-container matColumnDef="ultimaAccion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1"> Última acción</th>
        <td mat-cell *matCellDef="let direccion" (click)="navegarA(direccion._id)" class="p-1 align-middle"> {{direccion.ultimaAccion | date:'dd/MM/yyyy'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'dirDelete': row.desactivado, 'dirSel': (crmservice.direccionSeleccionada[1] === row._id)}"></tr>
    </table>
  </div>

  <div id="paginacion-clientes">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true" (page)="getPageEvent($event)"></mat-paginator>
  </div>

</div>
