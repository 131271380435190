<div class="m-xl-5 m-lg-5 m-md-2 m-sm-0">
  <div class="row">

    <div class="col-12 mb-5">
      <h4>CONFIGURACIÓN DE LICENCIAS 3D</h4>
    </div>


    <!-- PRIMERA RENOVACIÓN -->
    <div class="col-4">
      <label class="w-100">
        <span>Mínimo entre los 90 y 180 días</span><br>
        <span>({{formatNumber(configuracionLicencias.primeraRenovacion)}})</span>
        <input class="form-control" type="number" [(ngModel)]="configuracionLicencias.primeraRenovacion">
      </label>
    </div>

    <!-- SEGUNDA RENOVACIÓN -->
    <div class="col-4">
      <label class="w-100">
        <span>Mínimo entre los 180 y los 270 días</span><br>
        <span>({{formatNumber(configuracionLicencias.segundaRenovacion)}})</span>
        <input class="form-control" type="number" [(ngModel)]="configuracionLicencias.segundaRenovacion">
      </label>
    </div>

    <!-- TERCERA RENOVACIÓN -->
    <div class="col-4">
      <label class="w-100">
        <span>Minimo tras 270 días de uso</span><br>
        <span>({{formatNumber(configuracionLicencias.terceraRenovacion)}})</span>
        <input class="form-control" type="number" [(ngModel)]="configuracionLicencias.terceraRenovacion">
      </label>
    </div>

    <div *ngIf="mensajeError != ''" class="col-12 text-end">
      <span class="text-danger">
        <strong>{{mensajeError}}</strong>
      </span>
    </div>

    <div class="col-12 text-end mt-2">
      <button class="btn btn-success" (click)="guardarConfig()">Guardar</button>
    </div>

  </div>
</div>

