import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CrmService} from '../../../../servicios/crm.service';
import {ElementoRanking} from '../../../../modelos/ElementoRanking';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-ranking-detalle',
  templateUrl: './ranking-detalle.component.html',
  styleUrls: ['./ranking-detalle.component.css']
})
export class RankingDetalleComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargandoDatos = false;

  public clienteSel;

  public automatico = true;
  public clasificacion = 'C';
  public observaciones = '';

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<RankingDetalleComponent>,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.cargandoDatos = true;
    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.clienteSel = data.clienteSel;
    this.automatico = this.clienteSel.automatico;
    this.clasificacion = this.clienteSel.clasificacion === 'B1' || this.clienteSel.clasificacion === 'B2' ? 'B1' : this.clienteSel.clasificacion;

    this.clienteSel.historial.sort(function (a, b) {
      const fechaA = new Date(a.fCambio);
      const fechaB = new Date(b.fCambio);

      if (fechaA.getTime() > fechaB.getTime()) {
        return -1;
      } else if (fechaA.getTime() < fechaB.getTime()) {
        return 1;
      }
      return 0;
    });

    this.cargandoDatos = false;
  }

  ngOnInit() {
  }

  dameClasificacion(rank: string): string {
    if (rank === 'B1' || rank === 'B2') {
      return 'B';
    }
    return rank;
  }
  dameColorClasificacion(rank: string): string {
    if (rank === 'A') {
      return 'limegreen';
    }

    if (rank === 'B1' || rank === 'B2') {
      return 'gold';
    }

    if (rank === 'C') {
      return 'brown';
    }

    return 'pink';
  }
  aplicar() {

    this.cargandoDatos = true;

    this.clienteSel.automatico = this.automatico;

    if (!this.clienteSel.automatico) {

      this.clienteSel.clasificacion = this.clasificacion;

      this.clienteSel.historial.push({
        clasificacion: this.clasificacion,
        fCambio: new Date(),
        comentarios: this.observaciones,
        usuario: this.crmservice.userLogin._id,
        automatico: this.clienteSel.automatico
      });

      this.clienteSel.historial.sort(function (a, b) {
        const fechaA = new Date(a.fCambio);
        const fechaB = new Date(b.fCambio);

        if (fechaA.getTime() > fechaB.getTime()) {
          return -1;
        } else if (fechaA.getTime() < fechaB.getTime()) {
          return 1;
        }
        return 0;
      });

    }

    this.crmservice.recalcularRanking(this.clienteSel).subscribe(
      value => {

        if (this.clienteSel.automatico && value != null) {
          this.copiarCliente(this.clienteSel, value[0]);
        }

        for (const direccion of value[1].direcciones) {
          const pos = this.crmservice.direcciones.findIndex(e => e._id === direccion._id);
          if (pos !== -1) {
            this.crmservice.direcciones[pos].clasificacion = value[0].clasificacion;
          }
        }

        this.cargandoDatos = false;
        this.dialogRef.close(value);
      }, error1 => {
        console.log(error1);
        this.cargandoDatos = false;
      });

  }

  dameNombreUsuario(id: string): string {
    if (id === 'NodeCRM') {
      return id;
    } else {
      const pos = this.crmservice.usuarios.findIndex(e => e._id === id);

      if (pos !== -1) {
        return this.crmservice.usuarios[pos].nombre + ' ' + this.crmservice.usuarios[pos].apellidos;
      } else {
        return 'Desconocido';
      }
    }
  }
  copiarCliente (clienteSel: ElementoRanking, valueCliente) {
    this.clienteSel.cliente = valueCliente.cliente;
    this.clienteSel.razonSocial = valueCliente.razonSocial;
    this.clienteSel.cif = valueCliente.cif;
    this.clienteSel.clienteipdorma = valueCliente.clienteipdorma;
    this.clienteSel.primeraDir = valueCliente.primeraDir._id;
    this.clienteSel.nombresComerciales = valueCliente.nombresComerciales;
    this.clienteSel.totalFacturado = valueCliente.totalFacturado;
    this.clienteSel.totalFacturadoAnt = valueCliente.totalFacturadoAnt;
    this.clienteSel.clasificacion = valueCliente.clasificacion;
    this.clienteSel.automatico = valueCliente.automatico;
    this.clienteSel.historial = valueCliente.historial;
  }

  soyPrimero(cambio): boolean {
    const pos = this.clienteSel.historial.map(function (e) {
      return e.fCambio.toString();
    }).indexOf(cambio.fCambio.toString());

    return (pos + 1) === this.clienteSel.historial.length;
  }

  dameClasificacionAnt(cambio): string {

    const fCambio = new Date(cambio.fCambio);
    for (const c of this.clienteSel.historial) {
      if (fCambio.getTime() > new Date(c.fCambio).getTime()) {
        const asterisco = c.automatico ? '' : '*';
        return this.dameClasificacion(c.clasificacion) + asterisco;
      }
    }

    return 'A';
  }
  dameColorClasificacionAnt(cambio): string {
    const fCambio = new Date(cambio.fCambio);
    for (const c of this.clienteSel.historial) {
      if (fCambio.getTime() > new Date(c.fCambio).getTime()) {
        if (c.clasificacion === 'A') {
          return 'limegreen';
        }

        if (c.clasificacion === 'B1' || c.clasificacion === 'B2') {
          return 'gold';
        }

        if (c.clasificacion === 'C') {
          return 'brown';
        }

        return 'pink';
      }
    }

    return 'limegreen';
  }
}
