


<div class="text-center">
  <p>Sitio en construcción</p>

  <div class="d-flex justify-content-center" style="background-color: bisque; border-radius: 5px; box-shadow: 1px 1px 5px 2px lightgray; padding: 10px; width: 60%; margin-left: 20%">
    <div style="margin: 20px">
      <button mat-raised-button (click)="router.navigate(['informes'])">Informes Rendimiento</button>
    </div>
    <div style="margin: 20px">
      <button mat-raised-button (click)="router.navigate(['calendarios'])">Calendarios</button>
    </div>
    <div style="margin: 20px">
      <button mat-raised-button (click)="router.navigate(['gestor-acciones'])">Gestor Acciones</button>
    </div>
  </div>

</div>



