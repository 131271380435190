import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CrmService } from '../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuariosComponent } from '../usuarios/usuarios.component';
import { Usuario } from '../../modelos/Usuario';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-usuario-formulario',
  templateUrl: './usuario-formulario.component.html',
  styleUrls: ['./usuario-formulario.component.css']
})
export class UsuarioFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public usuarioEditar: Usuario = new Usuario;
  public pass1: string;
  public pass2: string;

  public cargando: boolean;

  constructor(
    public crmservice: CrmService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher) {

    console.log('UsuarioFormulario');

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {
  }

  public guardarUsuario(): void {
    this.comprobarFormulario()
      .then(() => {
        this.cargando = true;
        this.usuarioEditar.password = this.pass1;
        this.usuarioEditar.fiabilidad = 0;
        this.usuarioEditar.imagen = '/assets/img/otros/person.png';

        this.crmservice.newUser(this.usuarioEditar).subscribe(valueUser => {
          this.cargando = false;
          this.usuarioEditar._id = valueUser;
          this.snackBar.open('¡Usuario ' + this.usuarioEditar.nombreUsuario + ' (' + this.usuarioEditar.rol + ') creado con éxito!');
          this.dialogRef.close(this.usuarioEditar);

        }, () => {
          this.cargando = false;
          this.snackBar.open('Error al guardar usuario', 'OK', { duration: 2000 });
        });
      })
      .catch(err => this.snackBar.open(err, 'Ok', { duration: 3500 }));
  }

  private comprobarFormulario(): Promise<void> {
    return new Promise((resolve, reject) => {

      //Equipo
      if (this.usuarioEditar.team === "1") {
        reject('Este equipo no se puede elegir');
      }

      // Tipo de Cuenta
      if (this.usuarioEditar.nombreUsuario == null || !this.usuarioEditar.nombreUsuario.trim().length) {
        reject('Introduce nombre de usuario.');
      }
      if (this.crmservice.usuarios.some(u => u.nombreUsuario === this.usuarioEditar.nombreUsuario)) {
        reject('Ya existe otro usuario con ese nombre.');
      }
      if (!this.crmservice.roles.map(r => r.id).includes(this.usuarioEditar.rol)) {
        reject('Introduce un rol válido.');
      }
      if ((this.usuarioEditar.rol === 'comercial' || this.usuarioEditar.rol === 'influencer') && !this.crmservice.equipos.map(e => e.id).includes(this.usuarioEditar.team)) {
        reject('Los usuarios con rol "Comercial" tienen que pertenecer a un equipo.');
      }
      if (this.usuarioEditar.rol === 'asesor' && this.usuarioEditar.aprendizaje == null) {
        reject('Los usuarios con rol "Asesor" tienen que indicar si están en aprendizaje o no.');
      }

      // Datos Personales
      if (this.usuarioEditar.nombre == null || !this.usuarioEditar.nombre.trim().length) {
        reject('Introduce Nombre.');
      }
      if (this.usuarioEditar.apellidos == null || !this.usuarioEditar.apellidos.trim().length) {
        reject('Introduce Apellidos.');
      }
      if (this.usuarioEditar.poblacion == null || !this.usuarioEditar.poblacion.trim().length) {
        reject('Introduce la poblacion.');
      }
      if (!this.crmservice.provincias.map(p => p.nombre).includes(this.usuarioEditar.provincia)) {
        reject('Introduce una provincia válida.');
      }
      if (this.usuarioEditar.email == null || !this.usuarioEditar.email.trim().length) {
        reject('Introduce un email.');
      }

      // Contraseña
      if (this.pass1 == null || !this.pass1.trim().length) {
        reject('Introduce la contraseña');
      }
      if (this.pass2 == null || !this.pass2.trim().length) {
        reject('Vuelve a introducir la contraseña');
      }
      if (this.pass1 !== this.pass2) {
        reject('Las contraseñas no coinciden');
      }

      resolve();
    });
  }
}
