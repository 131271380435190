import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../../../servicios/crm.service';
import {ConfiguracionLicencias} from '../../../modelos/ConfiguracionLicencias';
import {Router} from '@angular/router';

@Component({
  selector: 'app-config-licencias',
  templateUrl: './config-licencias.component.html',
  styleUrls: ['./config-licencias.component.css']
})
export class ConfigLicenciasComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public mensajeError = '';

  public configuracionLicencias: ConfiguracionLicencias;

  constructor(public crmservice: CrmService,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol !== 'admin' && this.crmservice.userLogin.rol !== 'supervisor') {
      this.router.navigate(['agenda']).then();
    }

    this.crmservice.getConfiguracionLicencias().subscribe(
      configuracionLicencias => {

        if (configuracionLicencias != null) {
          this.configuracionLicencias = configuracionLicencias;
        } else {

          this.configuracionLicencias = {
            primeraRenovacion: 3000,
            segundaRenovacion: 5000,
            terceraRenovacion: 5000,
          };
          this.crmservice.nuevaConfiguracionLicencias(this.configuracionLicencias).subscribe(
            () => {
            }, errorNuevaConfig => {
              console.error(errorNuevaConfig);
            });
        }
      }, errorGetConfig => {
        console.error(errorGetConfig);
      });

  }

  ngOnInit() {
  }

  public guardarConfig(): void {
    if (this.comprobarFormulario()) {
      this.crmservice.updateConfiguracionLicencias(this.configuracionLicencias).subscribe(
        () => {
          this.mensajeError = '';
        }, errorUpdate => {
          console.error(errorUpdate);
          this.mensajeError = 'Se ha producido un error al actualizar la configuración.';
        });
    }
  }
  public comprobarFormulario(): boolean {

    if (this.configuracionLicencias.primeraRenovacion == null || this.configuracionLicencias.primeraRenovacion < 0) {
      this.mensajeError = 'La Primera Renovación debe ser mayor o igual a 0€';
      return false;
    }

    if (this.configuracionLicencias.segundaRenovacion == null || this.configuracionLicencias.segundaRenovacion < 0) {
      this.mensajeError = 'La Segunda Renovación debe ser mayor o igual a 0€';
      return false;
    }

    if (this.configuracionLicencias.terceraRenovacion == null || this.configuracionLicencias.terceraRenovacion < 0) {
      this.mensajeError = 'La Tercera Renovación debe ser mayor o igual a 0€';
      return false;
    }

    this.mensajeError = '';
    return true;
  }
  public formatNumber(numero: number, decimales: boolean = true) {

    const nString = numero.toString(10);
    const entera = nString.split('.')[0];

    let decimal = '';

    if (nString.split('.').length === 2) {
      decimal = ',' + nString.split('.')[1];
    }

    if (decimales) {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + decimal.slice(0, 3) + '€';
    } else {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '€';
    }
  }
}
