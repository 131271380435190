<div class="cabecera-modal">
  <mat-icon class="icon-modal">face</mat-icon>
  <strong>NUEVO CONTACTO</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div
    [hidden]="cargando"
    class="row ps-md-3 pe-md-3 pt-md-4 p-2 m-0-auto"
    style="
      min-height: calc(100% - 55px);
      max-height: calc(100% - 55px);
      overflow-y: auto;
    "
  >
    <mat-tab-group dynamicHeight (selectedTabChange)="tabChanged($event)">
      <mat-tab label="CREAR PERSONA">
        <ng-template mat-tab-label>
          <mat-icon class="me-1">add_circle</mat-icon>
          <span>CREAR</span>
        </ng-template>
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Dirección</mat-label>
              <mat-select
                [(ngModel)]="direccionSel"
                placeholder="Dirección"
                required
              >
                <mat-option
                  *ngFor="let direccion of direcciones"
                  [value]="direccion"
                >
                  {{ direccion.nombreComercial }}, {{ direccion.poblacion }},
                  {{ direccion.direccion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="getImagen() != ''" class="col-12 text-center">
            <img
              height="150px"
              class="rounded shadow"
              [src]="crmservice.urlCRM + '/img/contactos/' + getImagen()"
              alt=""
            />
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field class="w-100">
              <mat-label>Nombre</mat-label>
              <input
                [(ngModel)]="persona.nombre"
                matInput
                placeholder="Nombre"
                required
              />
            </mat-form-field>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field class="w-100">
              <mat-label>Apellidos</mat-label>
              <input
                [(ngModel)]="persona.apellidos"
                matInput
                placeholder="Apellidos"
              />
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>F. Nacimiento</mat-label>
              <input
                type="date"
                [(ngModel)]="persona.nacimiento"
                matInput
                placeholder="F. Nacimiento"
              />
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>Tratamiento</mat-label>
              <mat-select
                [(ngModel)]="persona.tratamiento"
                placeholder="Tratamiento"
                required
              >
                <mat-option
                  *ngFor="let item of crmservice.tratamientos"
                  [value]="item.id"
                  >{{ item.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>Cargo</mat-label>
              <mat-select
                [(ngModel)]="persona.cargo"
                placeholder="Cargo"
                required
              >
                <mat-option
                  *ngFor="let item of crmservice.cargos"
                  [value]="item.id"
                  >{{ item.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input [(ngModel)]="persona.email" matInput placeholder="Email" />
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>Teléfono</mat-label>
              <input
                [(ngModel)]="persona.telefono"
                matInput
                placeholder="Teléfono"
              />
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-form-field class="w-100">
              <mat-label>Móvil</mat-label>
              <input [(ngModel)]="persona.movil" matInput placeholder="Móvil" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Observaciones</mat-label>
              <textarea
                [(ngModel)]="persona.observaciones"
                matInput
                placeholder="Observaciones"
                rows="3"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="BUSCAR PERSONA">
        <ng-template mat-tab-label>
          <mat-icon class="me-1">search</mat-icon>
          <span>BUSCAR</span>
        </ng-template>
        <div class="row mt-3">
          <div class="col-12 text-end">
            <mat-form-field *ngIf="personaSel == null">
              <mat-label>Buscar...</mat-label>
              <input
                matInput
                placeholder="Buscar..."
                [(ngModel)]="patronBusqueda"
                (keyup.enter)="buscarPersonas(patronBusqueda)"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>

            <button
              *ngIf="personaSel != null"
              class="btn btn-sm btn-primary-crm"
              (click)="quitarPersonaSel()"
            >
              <div class="d-flex">
                <mat-icon class="me-2">disabled_by_default</mat-icon>
                <span style="padding-top: 1px">QUITAR PERSONA</span>
              </div>
            </button>
          </div>

          <div
            class="col-12 mt-3"
            *ngIf="
              personasFind != null && personasFind.length && personaSel == null
            "
          >
            <div *ngIf="textoFind != ''">
              <span>
                Se han encontrado
                <strong>{{ personasFind.length }}</strong> resultados para
                <strong>{{ textoFind }}</strong
                >.
              </span>
            </div>

            <table class="table table-bordered table-striped table-hover mt-3">
              <thead>
                <tr class="cabecera-tabla">
                  <th>Nombre</th>
                  <th>F.Nacimiento</th>
                  <th>TLF</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let personaFind of personasFind"
                  (click)="personaSel = personaFind"
                  role="button"
                >
                  <td>
                    <div class="d-flex">
                      <span
                        *ngIf="
                          personaFind.diploma != null &&
                          personaFind.diploma.trim().length
                        "
                      >
                        <mat-icon class="text-success">school</mat-icon>
                        &nbsp;
                      </span>
                      <span>{{ personaFind.nombre }}&nbsp;</span>
                      <span *ngIf="personaFind.apellidos != null">
                        {{ personaFind.apellidos }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span *ngIf="personaFind.nacimiento != null">{{
                      personaFind.nacimiento | date : "dd-MM-yyyy"
                    }}</span>
                  </td>
                  <td>
                    <span *ngIf="personaFind.telefono != null"
                      >{{ personaFind.telefono }}<br
                    /></span>
                    <span *ngIf="personaFind.movil != null">{{
                      personaFind.movil
                    }}</span>
                  </td>
                  <td>
                    <span *ngIf="personaFind.email != null">{{
                      personaFind.email
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12" *ngIf="personaSel != null">
            <div class="mb-3">
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td>
                      <strong>Nombre: </strong>
                    </td>
                    <td></td>
                    <td>
                      <div class="d-flex">
                        <span
                          *ngIf="
                            personaSel.diploma != null &&
                            personaSel.diploma.trim().length
                          "
                        >
                          <mat-icon class="text-success">school</mat-icon>&nbsp;
                        </span>
                        <span>{{ personaSel.nombre }}&nbsp;</span>
                        <span *ngIf="personaSel.apellidos != null">{{
                          personaSel.apellidos
                        }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>F. Nacimiento: </strong>
                    </td>
                    <td></td>
                    <td>
                      <span *ngIf="personaSel.nacimiento != null">{{
                        personaSel.nacimiento | date : "dd-MM-yyyy"
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>TLF: </strong>
                    </td>
                    <td></td>
                    <td>
                      <span *ngIf="personaSel.telefono != null"
                        >{{ personaSel.telefono }}<br
                      /></span>
                      <span *ngIf="personaSel.movil != null">{{
                        personaSel.movil
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email: </strong>
                    </td>
                    <td></td>
                    <td>
                      <span *ngIf="personaSel.email != null">
                        <a href="mailto:{{ personaSel.email }}">{{
                          personaSel.email
                        }}</a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle">
                      <strong>Dirección: </strong>
                    </td>
                    <td></td>
                    <td>
                      <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="direccionSel">
                          <mat-option
                            *ngFor="let direccion of direcciones"
                            [value]="direccion"
                          >
                            <span
                              >{{ direccion.poblacion }},
                              {{ direccion.direccion }}</span
                            >
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="!cargando" class="d-flex p-2 mt-auto border-top bg-light">
    <div
      class="btn btn-primary-crm ms-auto"
      [class.w-100]="mobileQuery.matches"
      (click)="guardarCambios()"
    >
      GUARDAR
    </div>
  </div>

  <div
    *ngIf="cargando"
    class="position-absolute start-50 top-50 translate-middle"
  >
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
