<div class="cabecera-modal">
  <mat-icon class="icon-modal">business</mat-icon>
  <strong>HISTÓRICO DE ACCIONES</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div class="row mb-3">
      <div class="col-md-6 col-12">
        <label class="w-100">
          Dirección
          <select class="form-select" [(ngModel)]="direccionSel">
            <option [ngValue]="null">TODAS</option>
            <option *ngFor="let direccion of direcciones" [ngValue]="direccion._id">
              {{direccion.nombreComercial}} ({{direccion.poblacion}})
            </option>
          </select>
        </label>
      </div>

      <div class="col-md-6 col-12">
        <label class="w-100">
          Hito
          <select class="form-select" [(ngModel)]="hitoSel">
            <option [ngValue]="null">TODOS</option>
            <option *ngFor="let hito of crmservice.hitos" [ngValue]="hito.id">
              {{hito.nombre}}
            </option>
          </select>
        </label>
      </div>
    </div>

    <ng-container *ngFor="let accion of acciones">
      <div *ngIf="mostrarAccion(accion)" class="contenedor-historico-item">

        <div *ngIf="!mobileQuery.matches" class="d-flex justify-content-between">
          <span>{{accion.fechaInicio | date:'dd/MM/yyyy HH:mm'}}</span>
          <span class="text-end">{{getHito(accion.hito)}} - {{getObjetivo(accion.objetivo)}} realizado por <br> <b>{{getUsuario(accion.de)}}</b></span>
        </div>

        <div *ngIf="mobileQuery.matches">
          <p class="mb-0">{{getObjetivo(accion.objetivo)}}<br><b>{{getUsuario(accion.de).split(' ')[0] + ' ' + getUsuario(accion.de).split(' ')[1]}}</b></p>
          <p class="mb-0">{{accion.fechaInicio | date:'dd/MM/yyyy HH:mm'}}</p>
        </div>

        <hr class="mt-0">

        <p [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}" class="observacionesAccion">{{accion.observaciones}}</p>

        <div>
          <p *ngIf="accion.hito == '6' && accion.objetivo == '4' && accion.formacion != null">Bloque: {{accion.formacion.bloque}}</p>
          <p>Personas: </p>
          <ul>
            <li *ngFor="let persona of accion.contactos">
              {{getPersona(persona).nombre}} {{getPersona(persona).apellidos != null ? getPersona(persona).apellidos : ''}}
            </li>
          </ul>
        </div>

        <p class="mb-0"><b>{{getResultado(accion.resultado)}}</b></p>

      </div>
    </ng-container>

  </div>
</div>
