import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { CrmService } from "../../../../servicios/crm.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UsuariosComponent } from "../../../usuarios/usuarios.component";
import { Direccion } from "../../../../modelos/Direccion";
import { Persona } from "../../../../modelos/Persona";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: "app-nueva-persona-formulario",
  templateUrl: "./nueva-persona-formulario.component.html",
  styleUrls: ["./nueva-persona-formulario.component.css"],
})
export class NuevaPersonaFormularioComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public tab: number;

  public cargando = false;

  public direcciones: Direccion[];
  public persona: Persona;
  public direccionSel: Direccion;

  public personasFind: Persona[];
  public personaSel: Persona;
  public patronBusqueda = "";
  public textoFind = "";

  constructor(
    public crmservice: CrmService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 1000px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.tab = 0;
    this.direcciones = data.direcciones.sort((a: any, b: any) => {
      if (a.nombreComercial < b.nombreComercial) {
        return -1;
      } else {
        return 1;
      }
    });
    this.persona = new Persona();
  }

  ngOnInit() {}

  public guardarCambios(): void {
    if (this.tab === 0) {
      this.guardar();
    } else if (this.tab === 1) {
      this.seleccionarPersona();
    }
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tab = tabChangeEvent.index;
    this.textoFind = "";

    this.direccionSel = null;
    this.personaSel = null;
  }
  public quitarPersonaSel(): void {
    this.personaSel = null;
    this.direccionSel = null;
  }

  private guardar(): void {
    const res = this.comprobarFormulario();
    if (res === "") {
      this.cargando = true;

      this.persona.usuarioCreador = this.crmservice.userLogin.nombreUsuario;
      this.persona.direccion = [this.direccionSel._id];
      this.persona.cliente = [this.direccionSel.cliente];

      this.crmservice.nuevaPersona([this.direccionSel, this.persona]).subscribe(
        (value) => {
          this.persona = value[1];
        },
        (error1) => {
          console.error(error1);
          this.cargando = false;
          this.toast("Error al guardar contacto");
        },
        () => {
          this.cargando = false;
          this.toast("Contacto guardado correctamente");
          this.dialogRef.close({
            direccion: this.direccionSel,
            persona: this.persona,
            nueva: true,
          });
        }
      );
    } else {
      this.toast(res);
    }
  }
  private comprobarFormulario(): string {
    if (this.direccionSel == null) {
      return "Indica una dirección.";
    }

    if (this.persona.nombre == null || !this.persona.nombre.trim().length) {
      return "Introduce el nombre.";
    }

    if (this.persona.tratamiento == null) {
      return "Indica el tratamiento.";
    }

    if (this.persona.cargo == null) {
      return "Indica el cargo";
    }

    return "";
  }
  public getImagen(): string {
    if (this.direccionSel != null && this.direccionSel.imagenes.length > 0) {
      return this.direccionSel.imagenes[0];
    } else {
      return "";
    }
  }

  public buscarPersonas(patron: string): void {
    if (patron != null && patron.trim().length) {
      this.cargando = true;
      this.crmservice.buscarPersona(patron).subscribe(
        (valuePersonas) => {
          this.personasFind = valuePersonas;
          this.textoFind = this.patronBusqueda;
          this.cargando = false;
        },
        (error1) => {
          this.cargando = false;
          console.error(error1);
        }
      );
    }
  }
  private seleccionarPersona(): void {
    const res = this.comprobarFormularioSelPersona();
    if (res === "") {
      this.cargando = true;

      this.crmservice
        .asignarPersonaDireccion(
          this.personaSel._id,
          this.direccionSel._id,
          this.direccionSel.cliente
        )
        .subscribe(
          () => {
            this.cargando = false;

            this.dialogRef.close({
              direccion: this.direccionSel,
              persona: this.personaSel,
              nueva: false,
            });
          },
          (error1) => {
            console.error(error1);
            this.cargando = false;
          }
        );
    } else {
      this.toast(res);
    }
  }
  private comprobarFormularioSelPersona(): string {
    if (this.personaSel == null) {
      return "¡Selecciona una Persona!";
    }

    if (this.direccionSel == null) {
      return "¡Selecciona una Dirección!";
    }

    if (this.direccionSel.personas.includes(this.personaSel._id)) {
      return (
        "¡" +
        this.personaSel.nombre +
        " ya está en la dirección " +
        this.direccionSel.nombreComercial +
        "!"
      );
    }

    return "";
  }

  private toast(msg: string): void {
    this.snackBar.open(msg, "Ok", { duration: 3500 });
  }
}
