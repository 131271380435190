<div *ngIf="crmservice.datosCargados" class="m-xl-5 m-lg-5 m-md-2 m-sm-2 m-2">

  <!-- USUARIO -->
  <div class="m-auto">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Usuario</mat-label>
      <mat-select [disabled]="crmservice.userLogin.rol != 'admin' && crmservice.userLogin.rol != 'supervisor'"
                  (selectionChange)="cambioUsuario()" [(ngModel)]="agendaservice.usuarioSeleccionadoAgenda"
                  placeholder="Usuario">
        <ng-container *ngFor="let usuario of crmservice.usuarios">
          <mat-option
            *ngIf="!usuario.desactivado && (crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor')"
            value="{{usuario._id}}">
            <img class="imagenSelect"
                 [src]="usuario.imagen != undefined ? usuario.imagen: '/assets/img/otros/person.png'"
                 alt=""> {{usuario.nombre}} {{usuario.apellidos}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-tab-group dynamicHeight class="demo-tab-group" [selectedIndex]="0">
    <!-- AGENDA -->
    <mat-tab label="Agenda">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">calendar_today</mat-icon>
        Agenda
      </ng-template>
      <div [hidden]="!tareasCargadas" id="calendario"></div>
    </mat-tab>

    <!-- LISTA DE TAREAS -->
    <mat-tab label="Lista de Tareas">
      <ng-template mat-tab-label class="tabLabel">
        <mat-icon class="iconHeader">assignment</mat-icon>
        <span [matBadge]="dataSource.data.length.toString(10)" matBadgeOverlap="false">Lista de Tareas</span>
      </ng-template>

      <!-- DESKTOP -->
      <div class="mt-3" *ngIf="tareasCargadas && !mobileQuery.matches">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="fecha">
            <mat-header-cell *matHeaderCellDef> Fecha</mat-header-cell>
            <mat-cell *matCellDef="let accion">{{accion.fechaPrevista | date:'dd/MM/yyyy HH:mm'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="medio">
            <mat-header-cell *matHeaderCellDef> Medio</mat-header-cell>
            <mat-cell *matCellDef="let accion"> {{getMedio(accion.medio[0])}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="objetivo">
            <mat-header-cell *matHeaderCellDef> Objetivo</mat-header-cell>
            <mat-cell *matCellDef="let accion"> {{getObjetivo(accion.objetivo)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="hito">
            <mat-header-cell *matHeaderCellDef> Hito</mat-header-cell>
            <mat-cell *matCellDef="let accion"> {{getHito(accion.hito)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="cliente">
            <mat-header-cell *matHeaderCellDef> Dirección</mat-header-cell>
            <mat-cell *matCellDef="let accion"> {{getDireccion(accion.direccion)}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="irAccion(row._id)"
                   [ngStyle]="{'background-color': agendaservice.dameColor(row.estado, row.de), 'cursor':'pointer'}"></mat-row>
        </mat-table>
      </div>

      <!-- MÓVIL -->
      <div class="mt-3" *ngIf="tareasCargadas && mobileQuery.matches">
        <table class="table-responsive-sm table-bordered tabla-acciones">
          <thead>
          <tr>
            <td>Fecha</td>
            <td>Med/Obj/Hit</td>
            <td>Dir.</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let accion of dataSource.data"
              [ngStyle]="{'background-color' : agendaservice.dameColor(accion.estado, accion.de)}"
              (click)="irAccion(accion._id)">
            <td>{{accion.fechaPrevista | date: 'dd/MM/yy HH:mm'}}</td>
            <td>
              {{getMedio(accion.medio[0])}}<br>
              {{getObjetivo(accion.objetivo)}}<br>
              {{getHito(accion.hito)}}<br>
            </td>
            <td>{{getDireccion(accion.direccion)}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <!-- LEYENDA -->
    <mat-tab label="Leyenda">
      <ng-template mat-tab-label class="tabLabel">
        <mat-icon class="iconHeader">help</mat-icon>
        <span>Leyenda</span>
      </ng-template>

      <!-- Leyenda -->
      <div class="mt-5">
        <p>
          <strong style="color: #00ae51">- Acción disponible:</strong> acción en la que estoy asignado, que aún no ha sido adquirida.
        </p>
        <div class="mb-2">
          <p>
            <strong style="color: #ff9000">- Acción adquirida:</strong> acción en la que estoy asignado, que ha sido adquirida por otro usuario.
          </p>
        </div>
        <div class="mb-2">
          <p>
            <strong style="color: #b2b8ff">- Acción adquirida (mía):</strong> acción en la que estoy asignado, que ha sido adquirida por mí.
          </p>
        </div>
        <div class="mb-2">
          <p>
            <strong style="color: #ff1500">- Acción finalizada:</strong> acción en la que estoy asignado, que ha sido finalizada.
          </p>
        </div>
        <div class="mb-2">
          <p>
            <strong style="color: #384950">- Otras acciones: </strong>Acciones de tienda, Gestor de Pedidos y CRM, realizadas mediante el Gestor de Tiempos.
          </p>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>

  <div *ngIf="!tareasCargadas" class="position-absolute start-50 top-50 translate-middle">
    <mat-spinner class="spinner"></mat-spinner>
  </div>

</div>
