export class Usuario {
  public nombreUsuario: string;
  public password: string;
  public rol: "admin" | "supervisor" | "pedidos" | "asesor" | "comercial" | "distribuidor" | "influencer";
  public team: string;
  public nombre: string;
  public apellidos: string;
  public email: string;
  public telefono: string;
  public poblacion: string;
  public provincia: string;
  public imagen: string;
  public desactivado: boolean;
  public _id: string;
  public fechaUltimaCon: Date;
  public disenadorVirtual: String;
  public fiabilidad: number;
  public aprendizaje: boolean;
  public codigoRepresentante: string[];
  public codigoDistribuidor: string[];
  public rolParaDv?: String;
}
