import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CrmService} from '../../../../../servicios/crm.service';
import {Direccion} from '../../../../../modelos/Direccion';
import {Cliente} from '../../../../../modelos/Cliente';
import {Licencia} from '../../../../../modelos/Licencia';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-editar-licencia',
  templateUrl: './editar-licencia.component.html',
  styleUrls: ['./editar-licencia.component.css']
})
export class EditarLicenciaComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public clienteSel: Cliente;
  public direccionSel: Direccion;

  public razonSocialCIF: string;
  public nombreComercial: string;
  public alias: string;
  public adaptRed: string;
  public llaveRed: string;
  public fActivacion: string;
  public fVencimiento: string;

  public nueva: boolean; // Es la primera licencia creada para esta dirección
  public mensajeError: string;

  public licenciaSel: Licencia;

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EditarLicenciaComponent>,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.razonSocialCIF = '';
    this.nombreComercial = '';
    this.alias = 'Nuevo Equipo';
    this.adaptRed = '';
    this.llaveRed = '';

    this.nueva = false;
    this.mensajeError = '';

    this.clienteSel = data.clienteSel;
    this.direccionSel = data.direccionSel;
    this.licenciaSel = data.licencias;

    this.razonSocialCIF = this.clienteSel.razonSocial + ' - (' + this.clienteSel.cif + ')';
    this.nombreComercial = this.direccionSel.nombreComercial;

    if (this.licenciaSel == null) {
      this.nueva = true;
    }

    const hoy = new Date();
    this.fActivacion = hoy.getFullYear() + '-' + this.formatDate(hoy.getMonth() + 1) + '-' + this.formatDate(hoy.getDate());
    hoy.setDate(hoy.getDate() + 90);
    this.fVencimiento = hoy.getFullYear() + '-' + this.formatDate(hoy.getMonth() + 1) + '-' + this.formatDate(hoy.getDate());

    if (!this.nueva) {
      this.alias = 'Equipo ' + (this.licenciaSel.equipos.length + 1);
    } else {
      this.alias = 'Equipo 1';
    }

  }


  ngOnInit() {
  }

  public crearLicencia(): void {
    if (this.comprobarFormularios()) {

      if (this.nueva) {

        this.licenciaSel = {
          _id: undefined,
          cliente: this.clienteSel._id,
          direccion: this.direccionSel._id,
          nombreDir: this.direccionSel.nombreComercial,
          equipos: [{
            aliasEquipo: this.alias,
            referencia: null,
            llaveRed: this.llaveRed,
            adaptRed: this.adaptRed,
            activaciones: [{
              usuario: this.crmservice.userLogin._id,
              fActivacion: new Date(this.fActivacion),
              fVencimiento: new Date(this.fVencimiento),
              caducada: this.getCaducada(new Date(this.fVencimiento))
            }]
          }]
        };

        this.crmservice.nuevaLicencia(this.licenciaSel).subscribe(
          valueLicencia => {
            this.dialogRef.close(valueLicencia);
          }, error1 => {
            console.log(error1);
          });

      } else {
        this.licenciaSel.equipos.push({
          aliasEquipo: this.alias,
          referencia: null,
          llaveRed: this.llaveRed,
          adaptRed: this.adaptRed,
          activaciones: [{
            usuario: this.crmservice.userLogin._id,
            fActivacion: new Date(this.fActivacion),
            fVencimiento: new Date(this.fVencimiento),
            caducada: this.getCaducada(new Date(this.fVencimiento))
          }]
        });

        this.crmservice.actualizarLicencia(this.licenciaSel).subscribe(
          valueLicencia => {
            this.dialogRef.close(valueLicencia);
          }, error1 => {
            console.log(error1);
          });
      }
    }
  }
  public comprobarFormularios(): boolean {

    if (!this.llaveRed.trim().length) {
      this.mensajeError = 'Introduce la Llave de Red.';
    }

    if (!this.adaptRed.trim().length) {
      this.mensajeError = 'Introduce el Adaptador de Red.';
      return false;
    }

    if (this.fActivacion == null || this.fActivacion === '') {
      this.mensajeError = 'Introduce fecha de activación';
      return false;
    }

    if (this.fVencimiento == null || this.fVencimiento === '') {
      this.mensajeError = 'Introduce fecha de vencimiento.';
      return false;
    }

    const dateActivacion = new Date(this.fActivacion);
    const dateVencimiento = new Date(this.fVencimiento);
    if (dateActivacion.getTime() >= dateVencimiento.getTime()) {
      this.mensajeError = 'La fecha de vencimiento no puede ser antes que la de activación.';
      return false;
    }

    this.mensajeError = '';
    return true;
  }

  public formatDate(n: number): string {
    if (n > 9) {
      return n.toString(10);
    } else {
      return '0' + n;
    }
  }

  public getCaducada(vencimiento: Date): boolean {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    vencimiento.setHours(0, 0, 0, 0);
    return vencimiento.getTime() <= hoy.getTime();
  }

}
