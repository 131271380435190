<div class="cabecera-modal">
  <mat-icon class="icon-modal">description</mat-icon>
  <strong>Contrato CreamosTuMueble (Físico)</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [hidden]="guardandoDatos" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div class="row">
      <!-- FORMULARIO -->
      <div class="col-12 col-md-3">
        <mat-form-field class="inputForm">
          <label for="inputInicio"></label>
          <input
            id="inputInicio"
            [matDatepicker]="vencimiento"
            matInput
            placeholder="Fecha de inicio"
            [(ngModel)]="direccion.contratoIDE.vencimiento"
          />
          <mat-datepicker-toggle matSuffix [for]="vencimiento"></mat-datepicker-toggle>
          <mat-datepicker #vencimiento></mat-datepicker>
          <mat-error> La fecha es <strong>obligatoria</strong> </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field class="inputForm">
          <label for="inputFin"></label>
          <input id="inputFin" [matDatepicker]="revision" matInput placeholder="Fecha de fin" [(ngModel)]="direccion.contratoIDE.revision" />
          <mat-datepicker-toggle matSuffix [for]="revision"></mat-datepicker-toggle>
          <mat-datepicker #revision></mat-datepicker>
          <mat-error> La fecha es <strong>obligatoria</strong> </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field class="inputForm">
          <label for="inputOMensuales"></label>
          <input id="inputOMensuales" matInput placeholder="O. Mensuales" type="number" [(ngModel)]="direccion.contratoIDE.objetivosMensuales" />
          <mat-error> Los objetivos son <strong>obligatorios</strong> </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field class="inputForm">
          <label for="inputColor"></label>
          <input id="inputColor" matInput placeholder="Color" type="color" [(ngModel)]="direccion.contratoIDE.color" />
          <mat-error> El color es <strong>obligatorio</strong> </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 d-flex justify-content-between">
        <!-- ASIGNADOS -->
        <div class="listaCPS">
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <td colspan="2" class="text-center">
                  <strong class="text-decoration-underline">Asignados</strong>
                </td>
              </tr>
              <ng-container *ngFor="let cp of direccion.contratoIDE.codigosPostalesAsociados">
                <tr>
                  <td (click)="irACP(cp)" class="btn-irACP">
                    <strong>{{ cp }}</strong> - {{ getProvincia(cp) }}
                  </td>
                  <td>
                    <mat-icon (click)="quitarCP(cp)" class="btn-remove-cp"> arrow_forward </mat-icon>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <!-- CÓDIGOS POSTALES -->
        <div class="listaCPS">
          <!-- FILTROS -->
          <div class="row">
            <div class="col-6 p-4 pt-0 pb-0">
              <label for="selectProvincia"></label>
              <select id="selectProvincia" class="form-select form-select-sm w-100 pt-0" [(ngModel)]="provincia">
                <ng-container *ngFor="let provincia of crmservice.provincias">
                  <option *ngIf="mostrarProvincia(provincia.nombre)">
                    {{ provincia.nombre }}
                  </option>
                </ng-container>
              </select>
            </div>

            <div class="col-6 p-4 pt-0 pb-0">
              <label for="inputLocalidad"></label>
              <input
                id="inputLocalidad"
                type="text"
                placeholder="Localidad..."
                class="form-control form-control-sm w-100 pt-0"
                (keyup)="applyFilter($event.target.value)"
                [(ngModel)]="filtro"
              />
            </div>
          </div>

          <!-- TABLA -->
          <table class="table table-borderless mt-4">
            <tbody>
              <ng-container *ngFor="let cp of CPDataSource.filteredData">
                <ng-container *ngIf="mostrarCP(cp)">
                  <tr style="border: 1px solid gray; background-color: aliceblue; color: black">
                    <td>
                      <mat-icon class="btn-add-cp" (click)="ponerCP(cp)" [hidden]="getDisponibilidadFisica(cp) != 'Disponible'">
                        arrow_back
                      </mat-icon>
                    </td>
                    <td>
                      <span>
                        <strong>CP: </strong>
                        {{ cp.codigo }}
                      </span>
                    </td>
                    <td class="text-end">
                      <strong style="font-size: 12px"
                        >Asignación Física:
                        <span [ngStyle]="{ color: getDisponibilidadFisica(cp) == 'Disponible' ? 'darkgreen' : 'brown' }">
                          {{ getDisponibilidadFisica(cp) }}
                        </span>
                      </strong>
                      <br />
                      <strong style="font-size: 12px"
                        >Asignación Virtual:
                        <span [ngStyle]="{ color: getDisponibilidadVirtual(cp) == 'Disponible' ? 'darkgreen' : 'brown' }">
                          {{ getDisponibilidadVirtual(cp) }}
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr style="border-bottom: 1px solid gray; border-right: 1px solid gray; border-left: 1px solid gray">
                    <td colspan="3">
                      <div style="background-color: aliceblue; border: 1px dashed gray; border-radius: 5px; padding: 5px">
                        <div>
                          <strong>Localidades:</strong>
                        </div>
                        <ul>
                          <li *ngFor="let linea of cp.cps">{{ linea.poblacion }}.</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-12 mt-2" *ngIf="direccion.contratoIDE.documento != null">
        <button (click)="verContrato()" mat-button color="primary">
          <mat-icon>visibility</mat-icon>
          Ver contrato
        </button>
        <button *ngIf="crmservice.rol('admin') || crmservice.rol('supervisor')" (click)="borrarContrato()" mat-button color="warn">
          <mat-icon>delete</mat-icon>
          Eliminar contrato
        </button>
      </div>

      <div class="col-12 mt-2" *ngIf="direccion.contratoIDE.documento == null">
        <button (click)="abrirInput()" mat-button color="primary">
          <mat-icon>cloud_upload</mat-icon>
          Subir contrato
        </button>
        <input type="file" id="contratoIDE" accept="application/pdf" hidden (change)="subirPDF($event.target.files)" />
      </div>

      <div class="col-12">
        <hr />
      </div>

      <!-- GUARDAR -->
      <div *ngIf="!mobileQuery.matches" class="col-12 d-flex justify-content-around">
        <button class="btn btn-secondary w-100" [mat-dialog-close]>Cancelar</button>
        <button class="btn btn-success" style="width: 100px" (click)="guardarContrato()">Guardar</button>
      </div>
      <div *ngIf="mobileQuery.matches" class="col-12 text-center mt-2">
        <button class="btn btn-secondary" style="width: 80%" [mat-dialog-close]>Cancelar</button>
      </div>
      <div *ngIf="mobileQuery.matches" class="col-12 text-center">
        <button class="btn btn-success" style="width: 80%" (click)="guardarContrato()">Guardar</button>
      </div>
    </div>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
