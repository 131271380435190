<div class="m-xl-3 m-lg-3 m-md-2 m-sm-2 m-1" [hidden]="cargando">
  <div class="text-secondary m-auto" style="width: 98%">
    <h2>IPDORMA sin Asignar</h2>
  </div>
  <div id="cabecera" class="d-flex">
    <div class="d-inline-block">
      <label>
        Mostrar:
        <select [(ngModel)]="mostrarIgnorados" class="form-select form-select-sm" (ngModelChange)="mostrarIgnoradosCambiado()">
          <option [ngValue]="null">TODOS</option>
          <option [ngValue]="false">NO IGNORADOS</option>
          <option [ngValue]="true">IGNORADOS</option>
        </select>
      </label>
    </div>
    <div class="d-inline-block ms-auto">
      <mat-form-field id="buscador" class="w-auto">
        <input autocomplete="off" matInput [(ngModel)]="textoBusqueda" (keyup)="applyFilter()" placeholder="Filtro">
      </mat-form-field>
    </div>
  </div>

  <div class="overflow-auto">
    <table mat-table [dataSource]="dataSource" matSortActive="ultimaActividad" matSortDirection="desc" id="tabla-ipdorma" matSort>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Código</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1">{{ipdorma.codigo}}</td>
      </ng-container>

      <ng-container matColumnDef="cif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center p-1">CIF</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.cif}}</td>
      </ng-container>

      <ng-container matColumnDef="razonSocial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Razón Social</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.razonSocial}}</td>
      </ng-container>

      <ng-container matColumnDef="nombreComercial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Nombre Comercial</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.nombreComercial}}</td>
      </ng-container>

      <ng-container matColumnDef="provincia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Provincia</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.provincia}}</td>
      </ng-container>

      <ng-container matColumnDef="poblacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Población</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.poblacion}}</td>
      </ng-container>

      <ng-container matColumnDef="ultimaActividad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Última actividad</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle">{{ipdorma.ultimaActividad | date:'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="nClientes">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Nº Clientes CRM</th>
        <td mat-cell *matCellDef="let ipdorma" class="p-1 align-middle text-center">{{ipdorma.nClientes}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.ignorar ? 'ignorar' : ''" (click)="abrirAsignados(row)"></tr>
    </table>
  </div>

  <div id="paginacion-ipdorma">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"></mat-paginator>
  </div>

  <div id="texto-info" class="d-flex justify-content-between">
    <span class="text-secondary">
      *En este listado se muestran las fichas de cliente que han facturado en los tres últimos años y no han sido asignadas a ningún cliente en el CRM o
      se han asignado a más de uno y no tienen el codigo de representante 90.<br>
      Si quieres buscar cualquier otro cliente haz click <span id="btn-buscar" role="button" (click)="buscarCodigo()">AQUÍ</span>
    </span>
  </div>
</div>


<div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
  <mat-spinner class="spinner"></mat-spinner>
</div>
