import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {CrmService} from '../../../../servicios/crm.service';
import {AsignarPerfilTeclaComponent} from './asignar-perfil-tecla/asignar-perfil-tecla.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {Direccion} from '../../../../modelos/Direccion';
import {Cliente} from '../../../../modelos/Cliente';
import {MatSnackBar} from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

const tarifas: {
  _id: string,
    nombre: string
}[] = [
  {_id: '59e458057b2f2f1403002c50', nombre: 'SERVICIOS'},
  {_id: '5947a28df26d6eb017000d9c', nombre: 'DESCANSO'},
  {_id: '5dc3075f7189130000007581', nombre: 'TAPIZADOS'},
  {_id: '609e4d615687924c1f000009', nombre: 'OPPORTUNITY'},
  {_id: '609e46f85687924c1f000006', nombre: 'PRECONFIGURADO'},
  {_id: '609e56bb5687924c1f000011', nombre: 'LIBRE CONFIGURACIÓN'}
];
const catalogos: {
  _id: string,
    nombre: string
}[] = [
  {_id: '60f9730af266949001000011', nombre: 'ARMARIOS Y VESTIDORES'},
  {_id: '610d11c0e850c4fc2300122c', nombre: 'CAMAS ABATIBLES'},
  {_id: '60f97250f26694900100000b', nombre: 'INFANTIL'},
  {_id: '60f90f92d65135480c0000e6', nombre: 'JUVENIL'},
  {_id: '60f97325f266949001000013', nombre: 'OFICINA'},
  {_id: '60f973c0f266949001000017', nombre: 'OPPORTUNITY'},
  {_id: '60f973f6f266949001000019', nombre: 'PROYECTOS CLASS'},
  {_id: '60f973a9f266949001000015', nombre: 'TAPIZADOS'},
  {_id: '60f97270f26694900100000d', nombre: 'ZONA DÍA'},
  {_id: '60f972f9f26694900100000f', nombre: 'ZONA NOCHE'}
];

class UsuarioTCLA {
  public _id: string;
  public amueblando: boolean;
  public _3d: boolean;
  public usuario: string;
  public activo: boolean;
  public factoresTarifas: {tarifa: string, factorconversion: number, nombre: string}[];
  public catalogos: {catalogo: string, nombre: string}[];
  public coeficienteSobreVenta: number;
  public puntoCompra: number;

  constructor (nTECLA: any) {
    this._id = nTECLA._id || '';
    this.amueblando = nTECLA.amueblando || false;
    this._3d = nTECLA._3d || false;
    this.usuario = nTECLA.usuario || '';
    this.activo = nTECLA.activo || false;
    this.coeficienteSobreVenta = nTECLA.coeficienteSobreVenta || 0;
    this.puntoCompra = nTECLA.puntoCompra || 0;

    this.factoresTarifas = [];
    this.catalogos = [];

    if (nTECLA.factoresTarifas) {
      for (const factorTarifa of nTECLA.factoresTarifas) {
        const tarifaFind = tarifas.find(t => t._id === factorTarifa.tarifa);
        this.factoresTarifas.push({
          tarifa: factorTarifa.tarifa,
          factorconversion: factorTarifa.factorconversion,
          nombre: tarifaFind ? tarifaFind.nombre : 'Desconocida'
        });
      }
    }
    if (nTECLA.catalogos) {
      for (const catalogo of nTECLA.catalogos) {
        const catalogoFind = catalogos.find(c => c._id === catalogo);
        this.catalogos.push({
          catalogo: catalogo,
          nombre: catalogoFind ? catalogoFind.nombre : 'Desconocido'
        });
      }
    }
  }
}

@Component({
  selector: 'app-detalles-perfil-tcla',
  templateUrl: './detalles-perfil-tcla.component.html',
  styleUrls: ['./detalles-perfil-tcla.component.css']
})
export class DetallesPerfilTCLAComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direccion: Direccion | null | undefined;
  public cliente: Cliente | null | undefined;
  public perfilTCLA: string | null | undefined;
  public usuarioTCLA: UsuarioTCLA | null | undefined;
  public cargandoDatos: boolean;
  public opcionValeVIP: string;
  public valeVIPpersonalizado: number;
  public rolPerfil: string;
  public ipdormas: any[]; 
  public promocionesVIPdisponibles: {activa: boolean, nombre: string, usada: boolean, descuento: number, valeDV?: boolean}[];

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public snackbar: MatSnackBar,
              public dialog: MatDialog) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargandoDatos = false;
    this.perfilTCLA = this.data.perfilTCLA;
    this.direccion = this.data.direccion;
    this.cliente = this.data.cliente;
    this.crmservice.getIpdormasCliente(this.cliente._id).subscribe(ipdormaData => {
      if (ipdormaData.length > 0) {
        this.ipdormas = ipdormaData;
      } else {
        this.ipdormas = [{ipdorma: {puntoCompra: 0}}];
      }
    })
    this.opcionValeVIP = "";
    // this.promocionesVIPdisponibles = this.usuarioTCLA.promocionesVIP.filter((item)=> item.activa === true && item.usada === false);

    if (this.perfilTCLA != null && this.perfilTCLA.trim().length) {
      this.cargarPerfil().then(() => { console.log('Cargado Usuario TECLA'); });
    }
  }

  ngOnInit() {
  }

  // Función que envía una petición a TECLA para crear un vale VIP solo visible al diseñador virtual.
  public activarValeVIP(): Promise<void> {

    if (this.opcionValeVIP === "") return;  // Cuando no hay opción seleccionada no enviar petición.


    let promocionVIP: any = {};
    let perfilTECLA: any = {};
    promocionVIP.descuento = parseInt(this.opcionValeVIP);
    promocionVIP.descuentoPersonalizado = this.valeVIPpersonalizado;
    perfilTECLA._id = this.usuarioTCLA._id;

    return new Promise(resolve=>{
      this.crmservice.crearValeVIP(promocionVIP, perfilTECLA).subscribe(usuarioActualizado=>{
        this.promocionesVIPdisponibles.push(usuarioActualizado.promocionesVIP[usuarioActualizado.promocionesVIP.length - 1])
        // this.promocionesVIPdisponibles = usuarioActualizado.promocionesVIP.filter((item)=> item.activa === true && item.usada === false && item.valeDV === true);
        resolve();
      })
    })
  }
  
  // Función que envía una petición a TECLA para borrar un vale VIP solo visible al diseñador virtual.
  public borrarValeVIP(promocion): Promise<void> {

    if (!promocion) return;  // Cuando no hay opción seleccionada no enviar petición.
    let promocionVIP: any = {};
    let perfilTECLA: any = {};

    promocionVIP = promocion;
    perfilTECLA._id = this.usuarioTCLA._id;

    return new Promise(resolve=>{
      this.crmservice.borrarValeVIP(promocionVIP, perfilTECLA).subscribe(usuarioActualizado=>{
        this.promocionesVIPdisponibles = usuarioActualizado.promocionesVIP.filter((item)=> item.activa === true && item.usada === false && item.valeDV === true);
        resolve();
      })
    })
  }



  public cargarPerfil(): Promise<void> {
    return new Promise(resolve => {
      this.cargandoDatos = true;
      this.crmservice.getFactorConversion(this.perfilTCLA).subscribe(valueUsuarioTCLA => {
        this.usuarioTCLA = new UsuarioTCLA(valueUsuarioTCLA[0]);
        this.promocionesVIPdisponibles = valueUsuarioTCLA[0].promocionesVIP.filter((item)=> item.activa === true && item.usada === false && item.valeDV === true);
        this.cargandoDatos = false;
        resolve();
      }, error => {
        if (error !== 'No se localizó el usuario.') {
          console.error(error);
        }
        this.usuarioTCLA = null;
        this.cargandoDatos = false;
        resolve();
      });
    });
  }

  public asignarPerfil(perfil: string | null = null): void {
    const dialogRef = this.dialog.open(AsignarPerfilTeclaComponent, {
      height: !this.mobileQuery.matches ? '800px' : '95%',
      width: !this.mobileQuery.matches ? '80%' : '95%',
      disableClose: false,
      autoFocus: false,
      panelClass: 'tcla-dialog-container',
      data: {
        direccion: this.direccion,
        cliente: this.cliente,
        perfil: perfil
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.perfilTCLA = this.direccion.perfilTCLA;
      this.direccion.perfilTCLAActivo = true;
      if (this.perfilTCLA != null && this.perfilTCLA.trim().length) {
        this.cargarPerfil().then(() => { console.log('Cargado Usuario TECLA'); });
      }
    });
  }

  public desactivarPerfil(): void {
    const dialogRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Quieres desactivar el perfil?',
      btnConfirm: 'DESACTIVAR',
      btnCancel: 'CANCELAR'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value && value.aceptar) {
        this.cargandoDatos = true;
        this.crmservice.modificarEstadoUsuarioTECLA(this.usuarioTCLA.usuario, false).subscribe(() => {
          this.snackbar.open('El usuario ' + this.usuarioTCLA.usuario + ' ha sido desactivado', 'Ok', {duration: 3000});
          this.usuarioTCLA.activo = false;
          this.direccion.perfilTCLAActivo = false;
        }, error => {
          console.error(error);
          this.snackbar.open('No ha sido posible desactivar el usuario', 'Ok', {duration: 3000});
        }, () => this.cargandoDatos = false);
      }
    });
  }

  public activarDV(perfilTCLA: string): void {
    this.cargandoDatos = true;
    this.crmservice.activarAmueblando(perfilTCLA).subscribe(
      () => {
        this.cargandoDatos = false;
        this.usuarioTCLA.amueblando = true;
      }, error => {
        console.error(error);
        this.cargandoDatos = false;
      });
  }
  public desactivarDV(perfilTCLA: string): void {
    this.cargandoDatos = true;
    this.crmservice.desactivarAmueblando(perfilTCLA).subscribe(
      () => {
        this.cargandoDatos = false;
        this.usuarioTCLA.amueblando = false;
      }, error => {
        this.cargandoDatos = false;
        console.error(error);
      });
  }
  public activar3D(perfilTCLA: string): void {
    this.cargandoDatos = true;
    this.crmservice.activar3D(perfilTCLA).subscribe(
      () => {
        this.cargandoDatos = false;
        this.usuarioTCLA._3d = true;
      }, error => {
        this.cargandoDatos = false;
        console.error(error);
      });
  }
  public desactivar3D(perfilTCLA: string): void {
    this.cargandoDatos = true;
    this.crmservice.desactivar3D(perfilTCLA).subscribe(
      () => {
        this.cargandoDatos = false;
        this.usuarioTCLA._3d = false;
      }, error => {
        this.cargandoDatos = false;
        console.error(error);
      });
  }

}
