import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Cliente} from '../../../../modelos/Cliente';
import {CrmService} from '../../../../servicios/crm.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-config-licencias-cli',
  templateUrl: './config-licencias-cli.component.html',
  styleUrls: ['./config-licencias-cli.component.css']
})
export class ConfigLicenciasCliComponent implements OnInit {

  public cargandoDatos: boolean;

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cliente: Cliente;
  public infoLicencias: {
    cantidad: number,         // Cantidad de licencias que debería tener si estuviera configurado a Automático.
    motivo: string,           // Información adicional.
    inputAdicionales: boolean // Si tiene permitido introducir licencias adicionales (tiene menos de 90 días de exeperiencia).
  };

  // Atributos del formulario.
  public fijo: boolean;
  public cantidad: number;
  public adicionales: number;
  public motivoCambio: string;

  public mensajeError: string;

  constructor(private crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ConfigLicenciasCliComponent>) {

    this.cargandoDatos = false;

    this.cliente = data.cliente;
    this.infoLicencias = data.infoLicencias;

    this.fijo = this.cliente.licenciasDisponibles.fijo;
    this.cantidad = this.cliente.licenciasDisponibles.cantidad;
    this.adicionales = this.cliente.licenciasDisponibles.adicionales;
    this.motivoCambio = '';

    this.mensajeError = '';

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

  public cambiar(): void {
    if (this.comprobarFormulario()) {

      this.cargandoDatos = true;

      // Se ha cambiado el tipo de configuración
      if (this.cliente.licenciasDisponibles.fijo !== this.fijo) {
        this.cliente.licenciasDisponibles.fijo = this.fijo;
        this.cliente.licenciasDisponibles.cantidad = this.cantidad;

        if (this.cliente.licenciasDisponibles.cambiosConfig == null) {
          this.cliente.licenciasDisponibles.cambiosConfig = [];
        }

        this.cliente.licenciasDisponibles.cambiosConfig.push({
          fijo: this.cliente.licenciasDisponibles.fijo,
          usuario: this.crmservice.userLogin._id,
          fecha: new Date(),
          motivo: this.motivoCambio
        });
      } else {
        this.cliente.licenciasDisponibles.cantidad = this.cantidad;
      }

      this.cliente.licenciasDisponibles.adicionales = this.adicionales;

      this.crmservice.actualizarLicenciasDisponibles(this.cliente).subscribe(
        () => {
          this.cargandoDatos = false;
          this.snackBar.open('Cambios realizados correctamente.', 'OK', {duration: 2000});
          this.dialogRef.close();
        }, error => {
          console.error(error);
          this.cargandoDatos = false;
          this.snackBar.open('Se ha producido un error al realizar los cambios.', 'OK', {duration: 2000});
        });
    }
  }
  public comprobarFormulario(): boolean {

    // Siempre que cambie la configuración, se debe escribir el motivo.
    if (this.fijo !== this.cliente.licenciasDisponibles.fijo) {
      if (this.motivoCambio == null || !this.motivoCambio.trim().length) {
        this.mensajeError = 'Indica el motivo del cambio de configuración';
        return false;
      }
    }

    if (this.cantidad < 0) {
      this.mensajeError = 'La cantidad de licencias no puede ser menor que 0';
      return false;
    }

    if (this.adicionales < 0) {
      this.mensajeError = 'Las licencias adicionales no pueden ser menor que 0';
      return false;
    }

    this.mensajeError = '';
    return true;
  }

  public cambioTipo(): void {
    if (this.fijo === false) {
      this.cantidad = this.infoLicencias.cantidad;     // Si no se pone a fijo, se asignan las licencias que debería tener en automático.
    }
  }

}
