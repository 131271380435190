<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>NUEVA MUESTRA</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div class="contenedor-formulario">
    <div class="row">

      <div class="col-12 mb-4">
        <label class="w-100">
          Nombre
          <input class="form-control" placeholder="Nombre" [(ngModel)]="nombre">
        </label>
      </div>

      <div class="col-12 mb-4">
        <label class="w-100">
          Tipo de Muestra
          <select class="form-select" [(ngModel)]="tipoMuestra">
            <option *ngFor="let tipo of crmservice.tiposMuestras" [value]="tipo.id">{{tipo.nombre}}</option>
          </select>
        </label>
      </div>

      <div class="col-12 mb-4">
        <div ngfDrop selectable="1" class="w-100 input-fichero text-center align-middle rounded" [(file)]="imagen">
          <span>{{imagen == null ? 'Selecciona una Imagen' : imagen.name}}</span>
        </div>
      </div>

      <div *ngIf="mensajeError != null" class="col-12 mb-4">
        <div class="alert alert-danger">
          <strong>{{mensajeError}}</strong>
        </div>
      </div>

      <div class="col-12">
        <div class="d-flex justify-content-around">
          <button class="btn btn-sm btn-secondary w-10em" [mat-dialog-close]>Cancelar</button>
          <button class="btn btn-sm btn-success w-10em" (click)="guardar()">Guardar</button>
        </div>
      </div>

    </div>
  </div>
</div>
