import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Direccion} from '../../../../modelos/Direccion';
import {Muestra} from '../../../../modelos/Muestra';
import {CajaMuestras} from '../../../../modelos/CajaMuestras';
import {DetalleMuestraComponent} from './detalle-muestra/detalle-muestra.component';
import {FormControl, FormGroup} from '@angular/forms';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-admin-muestras-formulario',
  templateUrl: './admin-muestras-formulario.component.html',
  styleUrls: ['./admin-muestras-formulario.component.css']
})
export class AdminMuestrasFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direccion: Direccion;
  public muestras: Muestra[];

  public cajasMuestras: CajaMuestras[];
  public cajaSeleccionada: CajaMuestras;

  public cajaMuestrasFormGroup: FormGroup;

  public cargandoDatos: boolean;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog) {

    this.cargandoDatos = true;

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direccion = this.data.direccion;
    this.crmservice.dameMuestras().subscribe(muestras => {
      this.muestras = muestras;
      this.crmservice.getCajasMuestrasDireccion(this.direccion).subscribe(cajas => {
        this.cajasMuestras = cajas;
        this.cajaSeleccionada = cajas[0];
        this.cargandoDatos = false;
      });
    });

    this.cajaMuestrasFormGroup = new FormGroup({
      cajaSeleccionada: new FormControl('', [])
    });
  }

  ngOnInit() {
  }

  public estadoMuestra(muestra: Muestra): string {
    let estado = 'cardMaterial';
    const pos = this.cajaSeleccionada.muestras.findIndex(e => e.muestra._id === muestra._id);

    if (pos !== -1) {
      if (this.cajaSeleccionada.muestras[pos].entregado) {
        estado += 'OK';
      } else {
        estado += 'KO';
      }
    }
    return estado;
  }

  public entregarMuestra(muestra: Muestra): void {
    const pos = this.cajaSeleccionada.muestras.findIndex(e => e.muestra._id === muestra._id);

    if (pos !== -1) {
      this.cajaSeleccionada.muestras[pos].entregado = true;
      this.cajaSeleccionada.muestras[pos].movimientos.push({tipo: 0, userMov: this.crmservice.userLogin, fechaMov: new Date()});
    } else {
      this.cajaSeleccionada.muestras.push({
        muestra: muestra,
        entregado: true,
        movimientos: [{
          fechaMov: new Date(),
          userMov: this.crmservice.userLogin,
          tipo: 0
        }]
      });
    }

    this.crmservice.updateCajaMuestras(this.cajaSeleccionada).subscribe(
      () => {
      }, error => {
        console.log(error);
        this.snackBar.open('Error al actualizar la caja de muestras', 'OK', {duration: 2000});
      }, () => {
        this.snackBar.open('Muestra entregada correctamente', 'OK', {duration: 1000});
      });
  }

  public retirarMuestra(muestra: Muestra): void {
    const pos = this.cajaSeleccionada.muestras.findIndex(e => e.muestra._id === muestra._id);
    if (pos !== -1) {
      this.cajaSeleccionada.muestras[pos].entregado = false;
      this.cajaSeleccionada.muestras[pos].movimientos.push({tipo: 1, userMov: this.crmservice.userLogin, fechaMov: new Date()});
    }

    this.crmservice.updateCajaMuestras(this.cajaSeleccionada).subscribe(
      () => {
      }, error1 => {
        console.error(error1);
        this.snackBar.open('Error actualizar la caja de muestras', 'OK', {duration: 2000});
      }, () => {
        this.snackBar.open('Muestra retirada correctamente', 'OK', {duration: 1000});
      });
  }

  public dameUltimoMovimiento(muestra: Muestra): { fecha: Date, persona: string } {
    const devolver = {fecha: null, persona: null};
    const pos = this.cajaSeleccionada.muestras.findIndex(e => e.muestra._id === muestra._id);

    if (pos !== -1) {
      const ultimo = this.cajaSeleccionada.muestras[pos].movimientos.length - 1;
      const fecha = new Date(this.cajaSeleccionada.muestras[pos].movimientos[ultimo].fechaMov);
      const cadena = this.cajaSeleccionada.muestras[pos].movimientos[ultimo].userMov.nombre;

      devolver.fecha = fecha;
      devolver.persona = cadena;
    }
    return devolver;
  }

  public verDetallesMuestra(muestra: Muestra): void {

    let width: string;
    let height: string;

    if (!this.mobileQuery.matches) {
      width = '60%';
      height = '60%';
    } else {
      width = '90%';
      height = '90%';
    }

    this.dialog.open(DetalleMuestraComponent, {
      width: width,
      height: height,
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        direccion: this.direccion,
        cajaSeleccionada: this.cajaSeleccionada,
        muestra: muestra,
      }
    });
  }

  public nuevaCajaMuestras(): void {
    const dialogRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: 'Nueva Caja',
      selects: [
        {id: 'tipoSeleccionado', placeholder: 'Tipo de Caja', required: true, options:
            [
              {text: 'Caja de muestras pequeñas', value: 'pequeno'},
              {text: 'Caja de muestras grandes', value: 'grande'},
              {text: 'Muestras lacadas', value: 'lacado'}
            ]
        }
      ]
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.inputValues && data.inputValues.tipoSeleccionado) {
        this.cargandoDatos = true;
        let nombreMuestra = 'desconocido';
        const nuevaCaja = new CajaMuestras();

        const pos = this.crmservice.tiposMuestras.findIndex(e => e.id === data.inputValues.tipoSeleccionado);
        if (pos !== -1) {
          nombreMuestra = this.crmservice.tiposMuestras[pos].nombreCompleto;
        }
        nuevaCaja.tipo = data.inputValues.tipoSeleccionado;
        nuevaCaja.nombre = nombreMuestra;
        nuevaCaja.direccion = this.direccion;
        nuevaCaja.muestras = [];

        this.crmservice.nuevaCajaMuestras(nuevaCaja).subscribe(value => {
          if (value != null) {
            nuevaCaja._id = value._id;
            this.cajaSeleccionada = nuevaCaja;
            this.cajasMuestras.push(nuevaCaja);
            this.snackBar.open('¡Caja de muestras creada con éxito!', 'Ok', {duration: 3500});
            this.cargandoDatos = false;
          }
        }, err => {
          this.cargandoDatos = false;
          console.error(err);
          this.snackBar.open('Se ha producido un error al crear la caja de muestras', 'Ok', {duration: 3500});
        });
      }
    });
  }
}
