<div class="cabecera-modal">
  <mat-icon class="icon-modal">alarm_off</mat-icon>
  <strong>POSPONER ACCIÓN</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!guardandoDatos" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'" [formGroup]="posponerFormGroup">
    <div>

      <div class="row mb-3 p-2 m-0 bg-light shadow rounded">

        <mat-label class="col-12">
          <h5>Planificación...</h5>
        </mat-label>

        <div class="col-12 col-md-6 mb-3">
          <mat-form-field class="w-100">
            <label class="w-100 m-0">
              <input [matDatepicker]="prevista" matInput formControlName="fechaPrevistaN" [(ngModel)]="fechaPrevistaN"
                     placeholder="Fecha prevista*">
            </label>
            <mat-datepicker-toggle matSuffix [for]="prevista"></mat-datepicker-toggle>
            <mat-datepicker #prevista></mat-datepicker>
            <mat-error>
              La fecha es <strong>obligatoria</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 mb-3">
          <mat-form-field class="w-100">
            <label class="w-100 m-0">
              <input type="time" matInput placeholder="Hora prevista" formControlName="horaPrevistaN"
                     [(ngModel)]="horaPrevistaN">
            </label>
            <mat-error>
              La hora es <strong>obligatoria</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 mb-2" *ngIf="crmservice.userLogin.rol != 'asesor' || !crmservice.modoPruebas">
          <mat-slide-toggle (change)="cambiarRequeridos()" color="primary" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="gastoTiempo">
            ¿Se invirtió tiempo en esta acción?
          </mat-slide-toggle>
        </div>

        <ng-container *ngIf="gastoTiempo">
          <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
              <label class="w-100 m-0">
                <input [matDatepicker]="inicio" matInput placeholder="Fecha*" [(ngModel)]="fechaInicioGasto"
                       formControlName="fechaInicioGasto">
              </label>
              <mat-datepicker-toggle matSuffix [for]="inicio"></mat-datepicker-toggle>
              <mat-datepicker #inicio></mat-datepicker>
              <mat-error>
                La fecha es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 col-md-4">
            <mat-form-field class="w-100">
              <label class="w-100 m-0">
                <input type="time" matInput placeholder="Hora de inicio*" [(ngModel)]="horaInicioGasto"
                       formControlName="horaInicioGasto">
              </label>
              <mat-error>
                La hora de inicio es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 col-md-4">
            <mat-form-field class="w-100">
              <label class="w-100 m-0">
                <input type="time" matInput placeholder="Hora de fin*" formControlName="horaFinGasto"
                       [(ngModel)]="horaFinGasto">
              </label>
              <mat-error>
                La hora de fin es <strong>obligatoria</strong>
              </mat-error>
              <mat-hint *ngIf="!calcularHoras()">
                La hora de fin no puede ser menor a la hora de inicio
              </mat-hint>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="row p-2 m-0 bg-light shadow rounded">

        <mat-label class="col-12">
          <h5>Motivos de pospuesta...</h5>
        </mat-label>

        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-select placeholder="Motivo*" formControlName="motivo" [(ngModel)]="motivo">
              <mat-option *ngFor="let motivo of crmservice.motivosPosponer" value="{{motivo.id}}">{{motivo.nombre}}
              </mat-option>
            </mat-select>
            <mat-error>
              El motivo es <strong>obligatorio</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="w-100">
            <label class="w-100 m-0">
              <textarea matTextareaAutosize matAutosizeMinRows="1" matInput placeholder="Observaciones*"
                        [(ngModel)]="observaciones" formControlName="observaciones"></textarea>
            </label>
            <mat-error>
              Las observaciones son <strong>obligatorias</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="col-12">
        <hr>
      </div>

      <div class="col-12 text-center">
        <button class="btn btn-info" (click)="posponerAccion()">Posponer</button>
      </div>

      <div class="col-12">
        <hr>
      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <h4>{{accion.pospuestas.length}} veces pospuesta</h4>
      </div>
      <div class="col-12 mt-3" *ngFor="let pospuesta of accion.pospuestas">
        <mat-card class="bg-light">
          <mat-card-header>
            <div class="row w-100">
              <div class="col-12">Pospuesto el día {{pospuesta.cuandoCambio | date:'dd/MM/yyyy HH:mm'}}</div>
            </div>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <br>
            <p><b>Motivo:</b> {{dameMotivo(pospuesta.motivo)}}</p>
            <p><b>Observaciones:</b> {{pospuesta.observaciones}}</p>
            <p><b>Tiempo gastado:</b> {{pospuesta.fechaInicio | date:'dd/MM/yyyy HH:mm:ss'}}
              - {{pospuesta.fechaFin | date:'dd/MM/yyyy HH:mm:ss'}}</p>
            <hr>
            <p>
              <s>{{pospuesta.fechaPrevistaA | date:'dd/MM/yyyy HH:mm'}}</s>&nbsp;--->&nbsp;{{pospuesta.fechaPrevistaN | date:'dd/MM/yyyy HH:mm'}}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
