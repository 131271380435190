<div class="cabecera-modal">
  <mat-icon class="icon-modal">schedule</mat-icon>
  <strong>HISTORIAL: {{equipoSel.aliasEquipo}}</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">

    <table class="table table-sm w-95 m-0-auto">
      <thead>
      <tr class="cabecera-tabla">
        <th>Activacion</th>
        <th>Vencimiento</th>
        <th>Dada por</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let activacion of equipoSel.activaciones">
        <td>{{activacion.fActivacion | date: 'dd-MM-yyyy'}}</td>
        <td>{{activacion.fVencimiento | date: 'dd-MM-yyyy'}}</td>
        <td>{{dameNombreUsuario(activacion.usuario)}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
