interface ContratoIDE {
  codigosPostalesAsociados: string[];
  vencimiento: Date;
  revision: Date;
  objetivosMensuales: number;
  color: string;
  documento: string;
}
interface ContratoWV {
  real: boolean;
  inicio: Date;
  fin: Date;
  cobrado: number;
  devolucion: number;
  documento: string;
}
interface ContratoAMUEBLANDO {
  codigosPostalesAsociados: string[];
  vencimiento: Date;
  revision: Date;
  objetivosMensuales: number;
  color: string;
  documento: string;
}
interface ContratoPreDV {
  documento: string;
}
interface ContratoBannerCtm {
  documento: string;
}

interface Horario {
  titulo: string;
  dias: string[];
  horaInicio: string;
  horaFinal: string;
  estado: boolean;
}
interface Hito {
  hito: string;
  obtenido: boolean;
  fecha: Date;
  usuario: String;
}

export class Direccion {
  public _id: string;
  public nombreComercial: string;
  public perfilTCLA: string;
  public perfilTCLAActivo: boolean;
  public perfilesSecundarios: {perfil: string, email: string}[];
  public estado: string;
  public motivo: string;
  public tipoVia: string;
  public direccion: string;
  public direccionDetalle: string;
  public tipoDir: string;
  public tieneMontadores: boolean;
  public tieneSmartTV: boolean;
  public manejaRRSS: boolean;
  public seguidorRRSS: boolean;
  public tipoMontadores: string;
  public numSmartTV: number;
  public informadoComision: Boolean;
  public permiteVideollamadas: boolean;
  public poblacion: string;
  public provincia: string;
  public codigoPostal: string;
  public contratoIDE: ContratoIDE;
  public contratoWV: ContratoWV;
  public contratoAMUEBLANDO: ContratoAMUEBLANDO;
  public contratoPreDV: ContratoPreDV;
  public contratoBannerCtm: ContratoBannerCtm;
  public email: string;
  public telefono: string;
  public webEmpresa: string;
  public observaciones: string;
  public fechaCreacion: Date;
  public fechaUltimaMod: Date;
  public usuarioCreador: string;
  public cliente: string;
  public equipo: string;
  public lat: number;
  public lng: number;
  public horarios: Horario[];
  public hitosObtenidos: Hito[];
  public personas: string[];
  public imagenes: string[];
  public acciones: string[];
  public ultimaAccion: Date;
  public desactivado: boolean;
  public clasificacion: string;
  public verMapa: boolean;
  public seguimiento: boolean;
  public tieneInfluencer: boolean;
  public tieneComercial: boolean;
  // public fechaRegistroInfluencer: Date;
  public fechaRegistroEquipo: Date;
  public rolRegistroEquipo: string;
  // public trackingInfluencer: [{idInfluencer: string, fechaRegistro: Date}];
  public trackingRegistroEquipo: [{idUsuario: string, rol: string, fechaRegistro: Date}];

  constructor (d: Direccion | any | null = null) {
    if (d != null) {
      this._id = d._id;
      this.nombreComercial = d.nombreComercial;
      this.perfilTCLA = d.perfilTCLA;
      this.perfilTCLAActivo = false;
      this.perfilesSecundarios = d.perfilesSecundarios || [];
      this.estado = d.estado;
      this.motivo = d.motivo;
      this.tipoVia = d.tipoVia;
      this.direccion = d.direccion;
      this.direccionDetalle = d.direccionDetalle;
      this.tipoDir = d.tipoDir;
      this.poblacion = d.poblacion;
      this.provincia = d.provincia;
      this.tieneMontadores = d.tieneMontadores;
      this.tipoMontadores = d.tipoMontadores;
      this.tieneSmartTV = d.tieneSmartTV;
      this.manejaRRSS = d.manejaRRSS;
      this.seguidorRRSS = d.seguidorRRSS;
      this.numSmartTV = d.numSmartTV;
      this.informadoComision = d.informadoComision;
      this.permiteVideollamadas = d.permiteVideollamadas;
      this.codigoPostal = d.codigoPostal;
      this.contratoIDE = d.contratoIDE;
      this.contratoWV = d.contratoWV;
      this.contratoAMUEBLANDO = d.contratoAMUEBLANDO;
      this.contratoPreDV = d.contratoPreDV;
      this.contratoBannerCtm = d.contratoBannerCtm;
      this.email = d.email;
      this.telefono = d.telefono;
      this.webEmpresa = d.webEmpresa;
      this.observaciones = d.observaciones;
      this.fechaCreacion = d.fechaCreacion != null ? new Date(d.fechaCreacion) : new Date();
      this.fechaUltimaMod = d.fechaUltimaMod != null ? new Date(d.fechaUltimaMod) : new Date();
      this.usuarioCreador = d.usuarioCreador;
      this.cliente = d.cliente;
      this.equipo = d.equipo;
      this.lat = d.lat;
      this.lng = d.lng;
      this.horarios = d.horarios || [];
      this.hitosObtenidos = d.hitosObtenidos || [];
      this.personas = d.personas || [];
      this.imagenes = d.imagenes || [];
      this.acciones = d.acciones || [];
      this.ultimaAccion = d.ultimaAccion != null ? new Date(d.ultimaAccion) : null;
      this.desactivado = d.desactivado;
      this.clasificacion = d.clasificacion;
      this.verMapa = d.verMapa;
      this.seguimiento = d.seguimiento;
      this.tieneInfluencer = d.tieneInfluencer;
      this.tieneComercial = d.tieneComercial;
      // this.fechaRegistroInfluencer = d.fechaRegistroInfluencer;
      // this.trackingInfluencer = d.trackingInfluencer;
      this.fechaRegistroEquipo = d.fechaRegistroEquipo;
      this.rolRegistroEquipo = d.rolRegistroEquipo;
      this.trackingRegistroEquipo = d.trackingRegistroEquipo;
    }
  }
}
