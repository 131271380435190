import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Direccion} from '../../../../modelos/Direccion';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {EditarLicenciaComponent} from './editar-licencia/editar-licencia.component';
import {Cliente} from '../../../../modelos/Cliente';
import {Licencia} from '../../../../modelos/Licencia';
import {RenovarLicenciaComponent} from './renovar-licencia/renovar-licencia.component';
import {HistorialLicenciaComponent} from './historial-licencia/historial-licencia.component';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-licencias3-d-formulario',
  templateUrl: './licencias3-d-formulario.component.html',
  styleUrls: ['./licencias3-d-formulario.component.css']
})
export class Licencias3DFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public clienteSel: Cliente;
  public direccionSel: Direccion;
  public licencia: Licencia;
  public licencias: Licencia[];

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<Licencias3DFormularioComponent>,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private snackBar: MatSnackBar) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.clienteSel = data.cliente;
    this.direccionSel = data.direccion;
    this.licencia = data.licencia;
    this.licencias = data.licencias;
  }

  ngOnInit() {

  }

  public dameNombreUsuario(id: string): string {

    if (id == null) {
      return 'Automático';
    }

    const pos = this.crmservice.usuarios.findIndex(e => e._id === id);

    if (pos !== -1) {
      return this.crmservice.usuarios[pos].nombre + ' ' + this.crmservice.usuarios[pos].apellidos;
    } else {
      return 'Desconocido';
    }
  }

  public nuevaLicencia3D(): void {
    const dialogRef = this.dialog.open(EditarLicenciaComponent, {
      width: !this.mobileQuery.matches ? '50%' : '100%',
      height: !this.mobileQuery.matches ? 'auto' : '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        clienteSel: this.clienteSel,
        direccionSel: this.direccionSel,
        licencias: this.licencia
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        this.licencia = value;
      }
    }, error1 => {
      console.log(error1);
    });
  }
  public renovarLicencia3D(equipo: any): void {
    const dialogRef = this.dialog.open(RenovarLicenciaComponent, {
      width: !this.mobileQuery.matches ? '50%' : '100%',
      height: !this.mobileQuery.matches ? 'auto' : '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        clienteSel: this.clienteSel,
        direccionSel: this.direccionSel,
        licencias: this.licencia,
        equipo: equipo
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        this.licencia = value;
      }
    }, error1 => {
      console.log(error1);
    });
  }
  public verHistorialEquipo(equipo: any): void {
    this.dialog.open(HistorialLicenciaComponent, {
      height: !this.mobileQuery.matches ? 'auto' : '100%',
      width: !this.mobileQuery.matches ? '50%' : '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        equipo: equipo
      }
    });
  }
  public eliminarEquipoLicencia(equipo: any): void {
    const dialogRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: 'Eliminar equipo: ' + equipo.aliasEquipo,
      textoSecundario: [{texto: '¿Quieres eliminar el equipo seleccionado?', css: 'text-center'}],
      btnCancel: 'CANCELAR',
      btnConfirm: 'ELIMINAR'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        const pos = this.licencia.equipos.findIndex(e => e.aliasEquipo === equipo.aliasEquipo);

        if (pos !== -1) {
          this.licencia.equipos.splice(pos, 1);
          this.crmservice.actualizarLicencia(this.licencia).subscribe(valueLicencia => {
            console.log(valueLicencia);
          }, error1 => {
            console.log(error1);
          });
        }
      }
    }, error1 => {
      console.log(error1);
    });
  }

  public cerrar(): void {
    this.dialogRef.close(this.licencia);
  }

  public permitirRenovar(): boolean {
    // Comprueba el número de licencias disponibles para el cliente y las
    // compara con las licencias activas actualmente del cliente.

    // Si no permite renovar, no se podrá crear un nuevo equipo ni renovar
    // uno ya caducado.

    let licenciasActivas = 0;

    for (const licencia of this.licencias) {
      for (const equipo of licencia.equipos) {
        if (!equipo.activaciones[equipo.activaciones.length - 1].caducada) {
          licenciasActivas++;
        }
      }
    }

    return licenciasActivas < (this.clienteSel.licenciasDisponibles.cantidad + this.clienteSel.licenciasDisponibles.adicionales);
  }
  public addReferencia(equipo: any): void {
    const dialogRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: 'Referencia',
      inputs: [{id: 'referencia', required: true, type: 'text', placeholder: 'Referencia'}],
      btnConfirm: 'ACEPTAR',
      btnCancel: 'CANCELAR'
    });
    const prevValue = equipo.referencia != null ? equipo.referencia : null;
    dialogRef.afterClosed().subscribe(value => {
      if (value != null && value.inputValues != null) {
        equipo.referencia = value.inputValues.referencia;
        this.crmservice.addReferenciaEquipo(this.licencia._id, equipo.aliasEquipo, value.inputValues.referencia).subscribe(() => {
        }, error => {
          console.error(error);
          this.snackBar.open('Error al cambiar la referencia del equipo', 'OK', {duration: 3000});
          equipo.referencia = prevValue;
        });
      }
    });
  }
}
