<div class="cabecera-modal">
  <mat-icon class="icon-modal">book</mat-icon>
  <strong>RENOVAR LICENCIA</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]="">close</mat-icon>
</div>

<div class="content-modal">
  <div class="container p-5">
    <div class="row form-group border border-secondary rounded shadow-sm bg-light p-5 ps-2 pe-2">

      <div class="col-lg-6 col-12">
        <label class="w-100">
          Cliente
          <input class="form-control" [(ngModel)]="clienteSel.razonSocial" readonly>
        </label>
      </div>
      <div class="col-lg-6 col-12">
        <label class="w-100">
          Direccion
          <input class="form-control" [(ngModel)]="direccionSel.nombreComercial" readonly>
        </label>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-xl-4 col-lg-6 col-12">
        <label class="w-100">
          Alias
          <input class="form-control" [(ngModel)]="equipo.aliasEquipo" readonly>
        </label>
      </div>
      <div class="col-xl-4 col-lg-6 col-12">
        <label class="w-100">
          Llave de Red
          <input class="form-control" [(ngModel)]="equipo.llaveRed" readonly>
        </label>
      </div>
      <div *ngIf="equipo.adaptRed != null" class="col-xl-4 col-lg-6 col-12">
        <label class="w-100">
          Adaptador de Red
          <input class="form-control" [(ngModel)]="equipo.adaptRed" readonly>
        </label>
      </div>

      <div class="col-lg-6 col-12">
        <label class="w-100">
          Fecha Activación
          <input type="date" class="form-control" [(ngModel)]="fActivacion">
        </label>
      </div>

      <div class="col-lg-6 col-12">
        <label class="w-100">
          Fecha Vencimiento
          <input type="date" class="form-control" [(ngModel)]="fVencimiento">
        </label>
      </div>

      <div class="col-12 text-center">
        <span *ngIf="mensajeError != ''" class="text-danger">
          <strong>{{mensajeError}}</strong>
        </span>
      </div>

      <div class="col-12">
        <hr>
      </div>

      <div class="col-12 d-flex justify-content-around">
        <button class="btn btn-secondary" [mat-dialog-close]>Cancelar</button>
        <button class="btn btn-success" (click)="renovarLicencia()">Renovar</button>
      </div>

    </div>
  </div>
</div>
