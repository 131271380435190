<div class="container-app">

  <mat-sidenav-container *ngIf="crmservice.datosCargados" class="sidenav-container">

    <!-- BARRA LATERAL -->
    <mat-sidenav *ngIf="sesionService.getUserLogeado()" id="sidenav" #sidenav [opened]="!mobileQuery.matches"
                 [disableClose]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'">

      <div class="d-flex flex-column h-100 overflow-hidden">
        <!-- LOGO Y VERSION -->
        <div class="d-block w-100 pt-3 pb-3">
          <img [routerLink]="['/']" id="sidenav-logo" #tooltip="matTooltip" (click)="tooltip.show();cerrarNavList()"
               src="../../assets/img/DormaCRM-new.png" matTooltip="Versión {{crmservice.version}}" alt="">
          <div class="text-end user-select-none text-light">
            <span id="text-version">{{crmservice.version}}</span>
          </div>
        </div>
        <!-- NAVEGACIÓN -->
        <div class="d-block w-100 scroll" style="overflow-x: hidden; overflow-y: auto">
          <mat-nav-list id="nav-list">
            <a (click)="cerrarNavList()" [routerLink]="['/direcciones/']" routerLinkActive="item-menu-activo" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>business_center</mat-icon>
                <span class="ms-3">Direcciones</span>
              </span>
            </a>
            <a *ngIf="crmservice.direccionSeleccionada[0]" (click)="cerrarNavList()"
               [routerLink]="router.url.indexOf('accion') >- 1 ? ['accion', crmservice.direccionSeleccionada[3]] : ['direccion', crmservice.direccionSeleccionada[1]]"
               [routerLinkActive]="'item-menu-activo'"
               mat-list-item>
              <span class="d-flex text-menu">
                <mat-icon>store</mat-icon>
                <span class="ms-3 text-menu">{{crmservice.direccionSeleccionada[2]}}</span>
              </span>
            </a>
            <a [routerLink]="['agenda']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>event</mat-icon>
                <span class="ms-3">Agenda</span>
              </span>
            </a>
            <a [routerLink]="['mapa']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>location_on</mat-icon>
                <span class="ms-3">Mapa</span>
              </span>
            </a>
            <a (click)="cerrarNavList()" [routerLink]="['alertas-clientes']" [routerLinkActive]="'item-menu-activo'" *ngIf="crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>warning</mat-icon>
                <span class="ms-3">Alertas Cliente</span>
              </span>
            </a>
            <a [routerLink]="['preguntas-clientes']" [routerLinkActive]="'item-menu-activo'" *ngIf="crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'" mat-list-item>
              <span class="text-menu d-flex">
                <div class="d-inline-block position-relative rounded shadow-lg" [class.bg-danger]="crmservice.numPreguntas > 0" style="width: 24px; height: 24px">
                  <div class="position-absolute start-50 top-50 translate-middle" style="font-size: 15px">
                    <b *ngIf="crmservice.numPreguntas > 0">{{crmservice.numPreguntas}}</b>
                    <mat-icon *ngIf="crmservice.numPreguntas == 0">question_answer</mat-icon>
                  </div>
                </div>
                <span class="ms-3">Preguntas</span>
              </span>
            </a>

            <div *ngIf="!crmservice.rol('comercial') && !crmservice.rol('asesor') && !crmservice.rol('influencer')" id="division"></div>

            <a *ngIf="!crmservice.rol('comercial') && !crmservice.rol('asesor') && !crmservice.rol('influencer')"
               (click)="cerrarNavList()" [routerLink]="['/ipdormas/']" [routerLinkActive]="'item-menu-activo'"
               mat-list-item>
              <span class="text-menu d-flex">
                <div class="d-inline-block position-relative rounded shadow-lg"
                     [ngClass]="crmservice.numIpdormas > 0 ? 'bg-danger' : ''" style="width: 24px; height: 24px">
                  <div class="position-absolute start-50 top-50 translate-middle" style="font-size: 15px">
                    <b *ngIf="crmservice.numIpdormas > 0">{{crmservice.numIpdormas}}</b>
                    <mat-icon *ngIf="crmservice.numIpdormas === 0">done</mat-icon>
                  </div>
                </div>
                <span class="ms-3" style="font-size: 13px"> IPDORMA sin asignar</span>
              </span>
            </a>
            <a *ngIf="crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor' || crmservice.userLogin.rol == 'pedidos'"
               (click)="cerrarNavList()" [routerLink]="['/encargos/']" [routerLinkActive]="'item-menu-activo'" mat-list-item>
               <span class="text-menu d-flex">
               <mat-icon>room_service</mat-icon>
              <span class="ms-3"> Encargos</span>
            </span>
            </a>
            <a *ngIf="crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor' || crmservice.userLogin.rol == 'pedidos'"
               (click)="cerrarNavList()" [routerLink]="['/supervision-influencers/']" [routerLinkActive]="'item-menu-activo'" mat-list-item>
               <span class="text-menu d-flex">
               <mat-icon>settings_input_antenna</mat-icon>
              <span class="ms-3" style="font-size: 12px;">Supervisión Influencers</span>
            </span>
            </a>
            <a *ngIf="crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor'"
               (click)="cerrarNavList()" [routerLink]="['/usuarios/']" [routerLinkActive]="'item-menu-activo'" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>account_circle</mat-icon>
                <span class="ms-3"> Usuarios</span>
              </span>
            </a>
            <a *ngIf="(crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor')"
               [routerLink]="['/informes/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>alarm</mat-icon>
                <span class="ms-3"> Gestor Tiempos</span>
              </span>
            </a>
            <a *ngIf="(crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor')"
               [routerLink]="['/control-asistencia/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>date_range</mat-icon>
                <span class="ms-3"> Asistencia</span>
              </span>
            </a>
            <a *ngIf="crmservice.userLogin.nombreUsuario === 'sgarrido'" [charset]=""
               [routerLink]="['/config-licencias/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>build</mat-icon>
                <span class="ms-3"> Licencias3D</span>
              </span>
            </a>
            <a *ngIf="(crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor')"
               [routerLink]="['/config-rankings/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>font_download</mat-icon>
                <span class="ms-3"> Clasificación</span>
              </span>
            </a>
            <a *ngIf="(crmservice.userLogin.rol == 'admin')"
               [routerLink]="['/balder-usuarios/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>local_shipping</mat-icon>
                <span class="ms-3"> Balder App</span>
              </span>
            </a>
            <a *ngIf="(crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol == 'supervisor')"
               [routerLink]="['/config-muestras/']" [routerLinkActive]="'item-menu-activo'" (click)="cerrarNavList()" mat-list-item>
              <span class="text-menu d-flex">
                <mat-icon>layers</mat-icon>
                <span class="ms-3"> Muestras</span>
              </span>
            </a>
          </mat-nav-list>
        </div>
        <!-- CMI -->
        <div class="d-block w-100 mt-auto">
          <div class="text-center btn-cmi" (click)="abrirCMI()">
            <img src="../../assets/img/dormaCMI_black.png" id="logo-cmi" alt="DORMA CMI">
          </div>
        </div>
      </div>

    </mat-sidenav>

    <!-- CABECERA DE LA PÁGINA -->
    <mat-sidenav-content>
      <div class="w-100 pt-2 pb-2 ps-md-4 pe-md-4 ps-3 pe-3 d-flex flex-row shadow-sm border-bottom" *ngIf="sesionService.getUserLogeado()">
        <div *ngIf="mobileQuery.matches" class="d-inline-block position-relative" style="width: 30px; height: 30px" (click)="sidenav.open()" role="button">
          <img src="/assets/img/menu/menu.png" style="height: 30px; width: 30px" class="position-absolute start-50 top-50 translate-middle" alt="Menú">
        </div>
        <div class="d-inline-block ms-auto position-relative" style="width: 30px; height: 30px" role="button" [matMenuTriggerFor]="menu">
          <img [src]="crmservice.userLogin.imagen" style="height: 30px; width: 30px; border-radius: 50%" class="position-absolute start-50 top-50 translate-middle"
               alt="Perfil" matTooltip="{{crmservice.userLogin.nombre.toUpperCase() + ' ' + crmservice.userLogin.apellidos.toUpperCase()}}">
        </div>
      </div>

      <div style="max-height: calc(100% - 58px); overflow-y: auto">
        <router-outlet></router-outlet>
      </div>
      <app-gestor-descargas *ngIf="sesionService.getUserLogeado() && crmservice.userLogin.rol === 'admin'" class="user-select-none"
                            [ngClass]="ds.abrirGestorDescargas ? 'gestor-descargas-abierto' : 'gestor-descargas-cerrado'"></app-gestor-descargas>
    </mat-sidenav-content>

  </mat-sidenav-container>

  <div *ngIf="!crmservice.datosCargados" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>

</div>

<mat-menu #menu="matMenu" xPosition="before">
  <button *ngIf="crmservice.userLogin != null && crmservice.userLogin.nombreUsuario === 'mjbour'" (click)="nuevaVersion()" mat-menu-item>Nueva Versión</button>
  <button (click)="notasActualizacion()" mat-menu-item>
    <mat-icon *ngIf="!crmservice.userCheckActualizacion" class="iconoAlerta">notifications_active</mat-icon>Notas Actualización</button>
  <button (click)="editarMiPerfil()" mat-menu-item>Mi perfil</button>
  <button (click)="logout()" mat-menu-item>Cerrar sesión</button>
</mat-menu>

<!--<app-thing2 class="position-absolute start-50 top-50 translate-middle" style="z-index: 1"></app-thing2>-->
