import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import {MediaMatcher} from '@angular/cdk/layout';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {Direccion} from '../../../../modelos/Direccion';

@Component({
  selector: 'app-contrato-banner-creamostumueble',
  templateUrl: './contrato-banner-creamostumueble.component.html',
  styleUrls: ['./contrato-banner-creamostumueble.component.css']
})
export class ContratoBannerCreamostumuebleComponent implements OnInit {

  
    public cargandoDatos: boolean;
    public guardandoDatos = false;
  
    public docToUpload: File = null;
  
    mobileQuery: MediaQueryList;
    private readonly _mobileQueryListener: () => void;
  
    public mostrarInfo: boolean;
  
    public direccion: Direccion;
    public provincia: string;
    public filtro: string;
  
    public busquedaAsignados: string;
    public filtroAsigandos: string;
  
    public CPDataSource: MatTableDataSource<any> = new MatTableDataSource();
    public color: string;
  
    
  
    constructor(public crmservice: CrmService,
                public snackbar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: any,
                changeDetectorRef: ChangeDetectorRef,
                media: MediaMatcher) {
  
      this.mobileQuery = media.matchMedia('(max-width: 750px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  
      this.cargandoDatos = false;
      this.mostrarInfo = true;
  
      this.direccion = data.direccion;
  
         
  
    }
  
    ngOnInit(): void {
     if (this.direccion.contratoBannerCtm == undefined){
      this.direccion.contratoBannerCtm = {documento: null}
      
     }
    }
  
  
    /** RUTAS DE CONTACTO **/
    public verContrato(): void {
      window.open(this.crmservice.urlCRM + '/doc/contratos/' + this.direccion.contratoBannerCtm.documento, '_blank');
    }
    public abrirInput(): void {
      document.getElementById('contratoBannerCtm').click();
    }
    public subirPDF(files: FileList): void {
      this.guardandoDatos = true;
      this.docToUpload = files.item(0);
  
      const name = Math.random().toString(36).substring(2) + '.' + this.docToUpload.name.split('.').pop();
  
      this.crmservice.subirContrato(this.docToUpload, name).subscribe(
        () => {
          this.direccion.contratoBannerCtm.documento = name;
        }, () => {
          this.guardandoDatos = false;
          this.snackbar.open('Error al subir contrato', 'OK', {duration: 2000});
  
        }, () => {
          this.guardarContrato();
        }
      );
    }
    public borrarContrato(): void {
      this.guardandoDatos = true;
  
      this.direccion.contratoBannerCtm.documento = null;
      this.guardarContrato();
    }
    public guardarContrato(): void {
      this.guardandoDatos = true;
      this.crmservice.updateDireccion(this.direccion).subscribe(
        () => {
  
        }, () => {
          this.guardandoDatos = false;
          this.snackbar.open('Error al guardar contrato', 'OK', {duration: 2000});
        }, () => {
          this.guardandoDatos = false;
          this.snackbar.open('Contrato guardado correctamente', 'OK', {duration: 2000});
        }
      );
    }
  
  }

  
