<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>ADMINISTRADOR DE MUESTRAS</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <!-- RESOLUCIÓN GRANDE -->
  <div *ngIf="!mobileQuery.matches" class="contenedor-formulario">

    <div *ngIf="cargandoDatos" class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>

    <!-- NUEVO DESPLEGABLE CON LAS CAJAS DISPONIBLES -->
    <div class="text-start">
      <button class="col-1" color="primary" mat-button (click)="nuevaCajaMuestras()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <mat-form-field appearance="standard" class="col-6" [formGroup]="cajaMuestrasFormGroup">
        <mat-label>Cajas de Muestras</mat-label>
        <mat-select [(ngModel)]="cajaSeleccionada" placeholder="Cajas de Muestras" formControlName="cajaSeleccionada">
          <ng-container *ngFor="let caja of cajasMuestras">
            <mat-option [value]="caja">{{caja.nombre}} </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!cargandoDatos" class="contenidoCaja">

      <div *ngIf="cajasMuestras.length" class="container-fluid">
        <div class="row p-0">
          <ng-container *ngFor="let muestra of muestras">
            <div *ngIf="muestra.tipo == cajaSeleccionada.tipo && !muestra.descontinuada" class="col-xl-4 col-lg-6 col-12 mb-2">
              <mat-card class="w-100 h-100" [ngClass]="estadoMuestra(muestra)"
                        [matMenuTriggerFor]="muestrasMenu" [matMenuTriggerData]="{'muestra':muestra}">
                <mat-card-header>
                  <mat-card-title>{{muestra.nombre}}</mat-card-title>
                  <mat-card-subtitle>
                    <span>{{dameUltimoMovimiento(muestra).fecha | date:'dd/MM/yyyy'}} - {{dameUltimoMovimiento(muestra).persona}}</span>
                  </mat-card-subtitle>
                  <div mat-card-avatar class="imagenMaterial rounded-3 shadow-sm"
                       [ngStyle]="{'background-image':'url(' + crmservice.urlCRM + '/img/muestras/' + muestra.imagen + ')', 'background-size':'cover'}">
                  </div>
                </mat-card-header>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!cajasMuestras.length" class="text-center">
        <span><strong>No hay ninguna caja creada</strong></span>
      </div>

    </div>

  </div>

  <!-- MÓVIL -->
  <div *ngIf="mobileQuery.matches" class="p-2">

    <div *ngIf="cargandoDatos" class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>

    <!-- DESPLEGABLE CON LAS CAJAS DISPONIBLES -->
    <div class="text-start">
      <button class="col-1" color="primary" mat-button (click)="nuevaCajaMuestras()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <mat-form-field appearance="standard" class="col-9" [formGroup]="cajaMuestrasFormGroup">
        <mat-label>Cajas de Muestras</mat-label>
        <mat-select [(ngModel)]="cajaSeleccionada" placeholder="Cajas de Muestras" formControlName="cajaSeleccionada">
          <ng-container *ngFor="let caja of cajasMuestras">
            <mat-option [value]="caja">{{caja.nombre}} </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!cargandoDatos" class="contenidoCaja">

      <div *ngIf="cajasMuestras.length" class="container-fluid">
        <div class="row p-0">
          <ng-container *ngFor="let muestra of muestras">
            <div class="col-12 pb-2" *ngIf="muestra.tipo == cajaSeleccionada.tipo">
              <mat-card class="w-100 h-100" [ngClass]="estadoMuestra(muestra)"
                        [matMenuTriggerFor]="muestrasMenu" [matMenuTriggerData]="{'muestra':muestra}">
                <mat-card-header>

                  <mat-card-title class="fs-6">{{muestra.nombre}}</mat-card-title>

                  <div class="imagenMaterial-movil" mat-card-avatar
                       [ngStyle]="{'background-image':'url(' + crmservice.urlCRM + '/img/muestras/' + muestra.imagen + ')', 'background-size':'cover'}">
                  </div>

                </mat-card-header>
                <mat-card-content>
                  <mat-card-subtitle>
                    <span>{{dameUltimoMovimiento(muestra).fecha | date:'dd/MM/yyyy'}} - {{dameUltimoMovimiento(muestra).persona}}</span>
                    <p *ngIf="muestra.descontinuada" class="text-danger"><strong>DESCONTINUADA</strong></p>
                  </mat-card-subtitle>
                </mat-card-content>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!cajasMuestras.length" class="text-center">
        <span><strong>No hay ninguna caja creada</strong></span>
      </div>

    </div>

  </div>
</div>


<!-- MENÚ -->
<mat-menu #muestrasMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-muestra="muestra">
    <button mat-menu-item (click)="verDetallesMuestra(muestra)">Ver en detalle</button>
    <button mat-menu-item *ngIf="estadoMuestra(muestra) != 'cardMaterialOK' && !muestra.descontinuada" (click)="entregarMuestra(muestra)">Entregar muestra</button>
    <button mat-menu-item *ngIf="estadoMuestra(muestra) == 'cardMaterialOK'" (click)="retirarMuestra(muestra)">Retirar muestra</button>
  </ng-template>
</mat-menu>
