<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>FOTOGRAFÍAS</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!guardandoDatos" class="m-4">
    <div class="row">
      <div class="col-12 col-md-4 margenBottom" *ngFor="let foto of imagenes">
        <div class="containerFoto">
          <img (click)="fullscreen('imagen'+foto.nombre)" id="imagen{{foto.nombre}}" width="100%"
               src="{{crmservice.urlCRM}}/img/contactos/{{foto.nombre}}" alt="">
        </div>
        <mat-form-field class="w-100">
          <mat-select [(ngModel)]="foto.direccion" placeholder="Dirección" (selectionChange)="cambiarDireccion(foto)">
            <mat-option *ngFor="let direccion of direcciones" value="{{direccion._id}}">{{direccion.poblacion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="eliminarFoto(foto)" class="w-100" color="warn" mat-raised-button>
          <mat-icon class="text-white float-start mt-1">delete</mat-icon>
          <span class="text-white"><strong>Eliminar</strong></span>
        </button>
      </div>
      <div class="col-12 col-md-4 margenBottom">
        <button (click)="selImagen()" class="addAFoto" mat-button>
          <mat-icon class="iconAddAFoto">add_a_photo</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container" >
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>

<input type="file" id="inputImagen" accept="image/*" hidden (change)="mostrarImagen($event)">
<canvas hidden id="canvasImagenDir"></canvas>
