import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { CrmService } from "../../servicios/crm.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UsuariosComponent } from "../usuarios/usuarios.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Usuario } from "../../modelos/Usuario";

@Component({
  selector: "app-editar-usuario",
  templateUrl: "./editar-usuario.component.html",
  styleUrls: ["./editar-usuario.component.css"],
})
export class EditarUsuarioComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public usuarioEditar = new Usuario();
  public foto: File;

  public cargando: boolean;

  constructor(
    public crmservice: CrmService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 1000px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargando = true;
    this.crmservice.getUser(this.data.userID).subscribe(
      (user) => {
        this.usuarioEditar._id = user._id;
        this.usuarioEditar.nombre = user.nombre;
        this.usuarioEditar.apellidos = user.apellidos;
        this.usuarioEditar.poblacion = user.poblacion;
        this.usuarioEditar.provincia = user.provincia;
        this.usuarioEditar.telefono = user.telefono;
        this.usuarioEditar.email = user.email;
        this.usuarioEditar.team = user.team;
        this.usuarioEditar.rol = user.rol;
        this.usuarioEditar.nombreUsuario = user.nombreUsuario;
        this.usuarioEditar.desactivado = user.desactivado;
        this.usuarioEditar.imagen = user.imagen;
        this.usuarioEditar.disenadorVirtual = user.disenadorVirtual;
        this.usuarioEditar.fiabilidad = user.fiabilidad != null ? user.fiabilidad : 0;
        this.usuarioEditar.aprendizaje = user.aprendizaje != null ? user.aprendizaje : false;
        this.usuarioEditar.codigoRepresentante = user.codigoRepresentante || [];
        this.usuarioEditar.rolParaDv = user.rolParaDv ? user.rolParaDv : "";

        if (this.usuarioEditar.imagen == null) {
          this.usuarioEditar.imagen = "/assets/img/otros/person.png";
        }
        this.cargando = false;
      },
      (error1) => {
        console.error(error1);
        this.cargando = false;
      }
    );
  }

  ngOnInit() {}

  public get permiso(): boolean {
    return this.crmservice.userLogin._id === this.usuarioEditar._id || this.crmservice.rol("admin");
  }

  public guardarUsuario(): void {
    this.comprobarFormularioInfo()
      .then(() => {
        this.cargando = true;
        this.crmservice.updateUser(this.usuarioEditar).subscribe(
          () => {
            this.cargando = false;
            this.snackBar.open("Cambios guardados con éxito.", "OK", { duration: 3000 });
            this.dialogRef.close(this.usuarioEditar);
          },
          (error) => {
            console.error(error);
            this.cargando = false;
            this.snackBar.open("Se ha producido un error al guardar los cambios.", "OK", { duration: 3000 });
          }
        );
      })
      .catch((e) => {
        this.snackBar.open(e, "Ok", { duration: 3500 });
      });
  }
  public cambiarPassword(msg: string | null = null): void {
    const popUpPass1Ref = this.crmservice.popUpConfirmacion({
      textoPrincipal: "Nueva Contraseña",
      textoSecundario: msg ? [{ texto: msg }] : null,
      inputs: [
        { id: "pass1", type: "password", placeholder: "Introduce la nueva contraseña", required: true },
        { id: "pass2", type: "password", placeholder: "Vuelve a introducir la contraseña", required: true },
      ],
      btnConfirm: "CONFIRMAR",
      btnCancel: "CANCELAR",
    });

    popUpPass1Ref.afterClosed().subscribe((value) => {
      if (value && value.inputValues) {
        if (value.inputValues.pass1 === value.inputValues.pass2) {
          this.cargando = true;
          this.crmservice.updatePassword(this.usuarioEditar.nombreUsuario, value.inputValues.pass1).subscribe(
            () => {
              this.cargando = false;
              this.snackBar.open("La contraseña ha sido modificada con éxito", "Ok", { duration: 3500 });
            },
            (err) => {
              console.error(err);
              this.cargando = false;
              this.snackBar.open("Error al cambiar la contraseña", "Ok", { duration: 3500 });
            }
          );
        } else {
          this.cambiarPassword("Las contraseñas no coinciden. Vuelve a escribirlas.");
        }
      }
    });
  }
  public guardarImagen(e: any) {
    const fileName = this.usuarioEditar.nombreUsuario + "_" + e.target.files[0].name;

    const extension = fileName.split(".")[fileName.split(".").length - 1].toLowerCase();
    if (extension !== "png" && extension !== "jpg" && extension !== "jpge") {
      this.snackBar.open("Solo se admiten ficheros con extensión PNG o JPG", "OK", { duration: 3000 });
      return;
    }

    try {
      this.foto = new File([e.target.files[0]], fileName, {
        type: "image/jpeg",
        lastModified: Date.now(),
      });
    } catch (error) {
      console.error(error);
      this.snackBar.open("Error al procesar el fichero", "OK", { duration: 3000 });
      return;
    }

    this.cargando = true;
    this.crmservice.subirImagenUsuario(this.foto, fileName).subscribe(
      () => {
        const imgPath = this.crmservice.urlCRM + "/img/usuarios/" + fileName;
        this.crmservice.updateImagenUser(this.usuarioEditar.nombreUsuario, imgPath).subscribe(
          () => {
            this.snackBar.open("Imagen actualizada correctamente.", "OK", { duration: 3000 });
            this.usuarioEditar.imagen = imgPath;
            this.cargando = false;

            const pos = this.crmservice.usuarios.findIndex((u) => u._id === this.usuarioEditar._id);
            if (pos !== -1) {
              this.crmservice.usuarios[pos].imagen = imgPath;
            }
            if (this.crmservice.userLogin._id === this.usuarioEditar._id) {
              this.crmservice.userLogin.imagen = imgPath;
            }
          },
          (error2) => {
            console.error(error2);
            this.snackBar.open("Error al actualizar la imagen.", "OK", { duration: 3000 });
            this.cargando = false;
          }
        );
      },
      (error) => {
        console.error(error);
        this.snackBar.open("Error al actualizar la imagen.", "OK", { duration: 3000 });
        this.cargando = false;
      }
    );
  }

  public addCodigoReperesentante(): void {
    const popUpCodRep = this.crmservice.popUpConfirmacion({
      textoPrincipal: "Codigo Representante",
      inputs: [{ id: "codigo", type: "text", placeholder: "Introduce nuevo codigo", required: true }],
      btnConfirm: "CONFIRMAR",
      btnCancel: "CANCELAR",
    });
    popUpCodRep.afterClosed().subscribe((value) => {
      if (value && value.inputValues) {
        const codigo = value.inputValues.codigo;
        if (!this.usuarioEditar.codigoRepresentante.includes(codigo)) {
          this.usuarioEditar.codigoRepresentante.push(codigo);
        } else {
          this.snackBar.open("El codigo " + codigo + " ya ha sido indicado.");
        }
      }
    });
  }

  public desactivarUsuario(): void {
    this.cargando = true;
    this.crmservice.modificarEstadoUsuario(this.usuarioEditar.nombreUsuario, true).subscribe(
      () => {
        this.cargando = false;
        this.snackBar.open("El usuario " + this.usuarioEditar.nombreUsuario + " a sido desactivado.", "OK", { duration: 3000 });

        const pos = this.crmservice.usuarios.findIndex((e) => e._id === this.usuarioEditar._id);
        if (pos !== -1) {
          this.crmservice.usuarios[pos].desactivado = true;
        }
        if (this.crmservice.userLogin._id === this.usuarioEditar._id) {
          this.crmservice.userLogin.desactivado = true;
        }
      },
      () => {
        this.cargando = false;
        this.snackBar.open("Se ha producido un error al intentar desactivar el usuario.", "OK", { duration: 3000 });
      }
    );
  }
  public activarUsuario(): void {
    this.cargando = true;
    this.crmservice.modificarEstadoUsuario(this.usuarioEditar.nombreUsuario, false).subscribe(
      () => {
        this.cargando = false;
        this.snackBar.open("El usuario " + this.usuarioEditar.nombreUsuario + " a sido activado.", "OK", { duration: 3000 });

        const pos = this.crmservice.usuarios.findIndex((e) => e._id === this.usuarioEditar._id);
        if (pos !== -1) {
          this.crmservice.usuarios[pos].desactivado = false;
        }
        if (this.crmservice.userLogin._id === this.usuarioEditar._id) {
          this.crmservice.userLogin.desactivado = false;
        }
      },
      () => {
        this.cargando = false;
        this.snackBar.open("Se ha producido un error al intentar activar el usuario.", "OK", { duration: 3000 });
      }
    );
  }

  private comprobarFormularioInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!["asesor", "comercial", "pedidos", "supervisor", "admin", "distribuidor", "influencer"].includes(this.usuarioEditar.rol)) {
        reject("Indica un rol de usuario válido");
      }
      if ((this.usuarioEditar.rol === "comercial" || "influencer") && !this.crmservice.equipos.some((e) => e.id === this.usuarioEditar.team)) {
        reject("Indica un equipo válido");
      }
      if (this.usuarioEditar.nombre == null || !this.usuarioEditar.nombre.trim().length) {
        reject("Indica el nombre");
      }
      if (this.usuarioEditar.apellidos == null || !this.usuarioEditar.apellidos.trim().length) {
        reject("Indica los apellidos");
      }
      if (this.usuarioEditar.poblacion == null || !this.usuarioEditar.poblacion.trim().length) {
        reject("Indica la población");
      }
      if (!this.crmservice.provincias.some((p) => p.nombre === this.usuarioEditar.provincia)) {
        reject("Indica la provincia");
      }
      if (this.usuarioEditar.email == null || !this.usuarioEditar.email.trim().length) {
        reject("Indica el email");
      }
      if (this.usuarioEditar.rol === "asesor" && this.usuarioEditar.aprendizaje == null) {
        reject("Indica si el usuario se encuentra en aprendizaje");
      }

      resolve();
    });
  }
}
