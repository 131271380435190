<div *ngIf="cargandoDatos || !crmservice.datosCargados" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div *ngIf="crmservice.datosCargados && !cargandoDatos" class="m-xl-5 m-lg-5 m-md-2 m-sm-2 mt-4 m-2">
  <h2 class="text-center text-md-start">Alertas de Clientes</h2>
  <mat-tab-group dynamicHeight>

    <mat-tab label="Alertas">
      <ng-template mat-tab-label>
        <mat-icon class="me-1">assignment_late</mat-icon>
        Alertas
      </ng-template>
      <div class="row" style="overflow-x: scroll">
        <div class="col-12 mt-3">
          <label>
            Tipo:
            <select class="form-select form-select-sm" [(ngModel)]="configSel" (ngModelChange)="aplicarSelects()">
              <option [ngValue]="null">TODAS ({{numAlertasConfig()}})</option>
              <ng-container *ngFor="let config of alertaClienteConfigs">
                <option *ngIf="numAlertasConfig(config) > 0" [ngValue]="config">{{config.nombre}}
                  ({{numAlertasConfig(config)}})
                </option>
              </ng-container>
            </select>
          </label>

          <label class="ms-3">
            Estado:
            <select *ngIf="configSel == null" class="form-select form-select-sm"
                    [(ngModel)]="alertasMostrarEstado" (ngModelChange)="aplicarSelects()">
              <option [value]="'todos'">TODOS</option>
              <ng-container *ngFor="let estado of crmservice.estados">
                <option [value]="estado.id">{{estado.nombre}}</option>
              </ng-container>
            </select>

            <select *ngIf="configSel != null" class="form-select form-select-sm"
                    [(ngModel)]="alertasMostrarEstado" (ngModelChange)="aplicarSelects()">
              <option [value]="'todos'">TODOS</option>
              <ng-container *ngFor="let objetivo of configSel.objetivo">
                <option [value]="objetivo">{{getNombreEstado(objetivo)}}</option>
              </ng-container>
            </select>
          </label>

          <label *ngIf="!crmservice.rol('comercial') && !crmservice.rol('influencer')" class="ms-3">
            Equipo:
            <select class="form-select form-select-sm" [(ngModel)]="alertasMostrarEquipo" (ngModelChange)="aplicarSelects()">
              <option [value]="'todos'">TODOS</option>
              <option *ngFor="let equipo of equiposMostrar" [value]="equipo">{{equipo}}</option>
            </select>
          </label>

          <label class="ms-3">
            Cliente:
            <select class="form-select form-select-sm" [(ngModel)]="alertasMostrarRazonSocial" (ngModelChange)="aplicarSelects()">
              <option [value]="'todos'">TODOS</option>
              <option *ngFor="let rs of razonesSocialesMostrar" [value]="rs">{{rs}}</option>
            </select>
          </label>

          <label class="ms-3 float-end">
            Buscar:
            <input class="form-control form-control-sm" placeholder="Buscar..." [(ngModel)]="filtroAlertas"
                   (ngModelChange)="applyFilterAlertas()">
          </label>
        </div>

        <div *ngIf="configSel != null" class="col-12 mt-3 mb-0">
          <div class="alert alert-info">
            <p><strong>{{getDescripcionAlerta(configSel.tipo)}}</strong></p>
            <p>Se aplica a: <span *ngFor="let objetivo of configSel.objetivo; let i = index" [attr.data-index]="i">
                    <strong>{{getNombreEstado(objetivo)}}<span
                      *ngIf="i != configSel.objetivo.length - 1">, </span></strong></span>
            </p>
          </div>
        </div>

        <div class="col-12 mt-3">
          <table class="table table-sm">
            <thead class="user-select-none">
            <tr style="background-color: #428bca; color: whitesmoke; text-align: center">
              <th role="button" (click)="sortAlertas({field: 'fecha', sort: (sortByAlertas.field === 'fecha') ? -sortByAlertas.sort : 1})">
                <div class="d-flex justify-content-center">
                  <mat-icon *ngIf="sortByAlertas.field === 'fecha'">arrow_{{sortByAlertas.sort > 0 ? 'downward' : 'upward'}}</mat-icon>
                  <span>Fecha</span>
                </div>
              </th>
              <th>Gestionado</th>
              <th></th>
              <th role="button" (click)="sortAlertas({field: 'razonSocial', sort: (sortByAlertas.field === 'razonSocial') ? -sortByAlertas.sort : 1})">
                <div class="d-flex justify-content-center">
                  <mat-icon *ngIf="sortByAlertas.field === 'razonSocial'">arrow_{{sortByAlertas.sort > 0 ? 'downward' : 'upward'}}</mat-icon>
                  <span>Cliente</span>
                </div>
              </th>
              <th style="max-width: 25%; width: 25%; min-width: 25%">Alerta</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let alertaRow of dataSourceAlertas.filteredData" class="alerta-tr" [class.alerta-nueva]="!alertaRow.userVisto(crmservice.userLogin._id)">
              <td class="align-middle text-center" style="white-space: pre">
                <div *ngIf="!alertaRow.userVisto(crmservice.userLogin._id)" class="alerta-nueva-icon"></div>
                <span>{{alertaRow.fecha | date: 'dd/MM/yyyy'}}</span>
              </td>
              <td class="align-middle text-center">
                <span *ngIf="alertaRow.gestionado != null">
                  {{getUsuario(alertaRow.gestionado)}}
                  <div *ngIf="mostrarAccionPlanificada(alertaRow)" class="fs-small text-secondary">Acción Planificada</div>
                </span>
              </td>
              <td class="text-center align-middle">
                <app-equipo [equipo]="alertaRow.equipo" [estado]="alertaRow.estado"></app-equipo>
              </td>
              <td class="align-middle" style="min-width: 40%; width: 40%; max-width: 40%">
                <div>
                  <span>{{alertaRow.razonSocial != null ? alertaRow.razonSocial : 'Desconocido'}}</span>
                </div>
                <div *ngIf="alertaRow.ambito === 'direccion'">
                  <span>{{alertaRow.nombreComercial != null ? alertaRow.nombreComercial : 'Desconocido'}}</span>
                </div>
              </td>
              <td class="align-middle">
                <span>{{alertaRow.msg}}</span>
              </td>
              <td class="text-center align-middle">
                <button mat-icon-button [matMenuTriggerFor]="menuAlertaRow"
                        [matMenuTriggerData]="{alertaRow: alertaRow}">
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pérdida de Interés">
      <ng-template mat-tab-label>
        <mat-icon class="me-1">thumbs_up_down</mat-icon>
        Pérdida de Interés
      </ng-template>
      <div class="mt-3">

        <div class="mt-3">
          <label>
            <select class="form-select form-select-sm" placeholder="Equipo" [(ngModel)]="equipoSel"
                    [disabled]="crmservice.rol('comercial') && !crmservice.rol('influencer')" (ngModelChange)="aplicarSelectsInteres()">
              <option value="todos">Equipo: Todos</option>
              <option *ngFor="let equipo of crmservice.equipos" [value]="equipo.id">Equipo: {{equipo.id}}</option>
            </select>
          </label>

          <label class="float-end">
            <input class="form-control form-control-sm" placeholder="Buscar..." [(ngModel)]="filtroInteres"
                   (ngModelChange)="applyFilterInteres()">
          </label>
        </div>

        <table class="table table-sm bg-white mt-3">
          <thead>
          <tr style="background-color: #428bca; color: whitesmoke; text-align: center">
            <th>Cliente</th>
            <th class="w-25" role="button" (click)="sortInteres({field: 'diffYear', sort: (sortByInteres.field === 'diffYear') ? -sortByInteres.sort : 1})">
              <div class="d-flex justify-content-center">
                <mat-icon *ngIf="sortByInteres.field === 'diffYear'">arrow_{{sortByInteres.sort > 0 ? 'downward' : 'upward'}}</mat-icon>
                <span>Todo Año Actual y Anterior</span>
              </div>
            </th>
            <th class="w-25" role="button" (click)="sortInteres({field: 'diffMonth', sort: (sortByInteres.field === 'diffMonth') ? -sortByInteres.sort : 1})">
              <div class="d-flex justify-content-center">
                <mat-icon *ngIf="sortByInteres.field === 'diffMonth'">arrow_{{sortByInteres.sort > 0 ? 'downward' : 'upward'}}</mat-icon>
                <span>Mes Actual y Mes Anterior</span>
              </div>
            </th>
            <th class="w-25" role="button" (click)="sortInteres({field: 'diffYearMonth', sort: (sortByInteres.field === 'diffYearMonth') ? -sortByInteres.sort : 1})">
              <div class="d-flex justify-content-center">
                <mat-icon *ngIf="sortByInteres.field === 'diffYearMonth'">arrow_{{sortByInteres.sort > 0 ? 'downward' : 'upward'}}</mat-icon>
                <span>Mismo mes año Actual y Anterior</span>
              </div>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let alerta of dataSourceInteres.filteredData">
            <td class="text-center">
              <div>{{alerta.cliente.razonSocial}}</div>
              <div>{{alerta.cliente.nombreComercial}}</div>
            </td>
            <td>
              <table class="table table-sm table-borderless w-75 m-auto">
                <tr>
                  <td>Este año:</td>
                  <td class="text-end">{{alerta.factCurrentYearFmat}} €</td>
                </tr>
                <tr>
                  <td>Año anterior:</td>
                  <td class="text-end">{{alerta.factLastYearFmat}} €</td>
                </tr>
                <tr>
                  <td>Balance:</td>
                  <td class="text-end" [class.balance-negativo]="alerta.diffYear < 0">{{alerta.diffYearFmat}} €</td>
                </tr>
              </table>
            </td>
            <td>
              <table class="table table-sm table-borderless w-75 m-auto">
                <tr>
                  <td>Este mes:</td>
                  <td class="text-end">{{alerta.factCurrentMonthFmat}} €</td>
                </tr>
                <tr>
                  <td>Mes anterior:</td>
                  <td class="text-end">{{alerta.factLastMonthFmat}} €</td>
                </tr>
                <tr>
                  <td>Balance:</td>
                  <td class="text-end" [class.balance-negativo]="alerta.diffMonth < 0">{{alerta.diffMonthFmat}} €</td>
                </tr>
              </table>
            </td>
            <td>
              <table class="table table-sm table-borderless w-75 m-auto">
                <tr>
                  <td>Este mes:</td>
                  <td class="text-end">{{alerta.factCurrentMonthFmat}} €</td>
                </tr>
                <tr>
                  <td>Año anterior:</td>
                  <td class="text-end">{{alerta.factLastYearMonthFmat}} €</td>
                </tr>
                <tr>
                  <td>Balance:</td>
                  <td class="text-end" [class.balance-negativo]="alerta.diffYearMonth < 0">{{alerta.diffYearMonthFmat}} €</td>
                </tr>
              </table>
            </td>
            <td class="align-middle text-center">
              <button mat-icon-button [matMenuTriggerFor]="menuAlertaConsumo" [matMenuTriggerData]="{alerta: alerta}">
                <mat-icon>chevron_right</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <mat-tab *ngIf="crmservice.userLogin.rol == 'admin' || crmservice.userLogin.rol === 'supervisor'" label="Configuración">
      <ng-template mat-tab-label>
        <mat-icon class="me-1">settings</mat-icon>
        Configuración
      </ng-template>
      <div>

        <div class="mt-3">
          <label>
            <input autocomplete="off" class="form-control form-control-sm" [(ngModel)]="filtroConfig"
                   (ngModelChange)="applyFilterConfig()" placeholder="Buscar...">
          </label>
          <button class="btn btn-sm btn-info float-end" (click)="guardarGenerar()">Guardar y Generar
            Alertas
          </button>
        </div>

        <div class="row mt-3 m-1 p-4 bg-white rounded" style="box-shadow: inset 1px 1px 3px 0 gray;">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 border-end border-light" *ngFor="let configuracion of dataSourceConfig.filteredData">
            <div class="row" style="display: flex; height: 100%">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <h5 class="bg-light">
                  <span>{{configuracion.nombre}}</span>
                  <mat-icon *ngIf="configuracion.excepciones != null && configuracion.excepciones.length" class="float-end" role="button"
                            style="color: gray;"
                            (click)="verExcepcionesAlerta(configuracion)">
                    report
                  </mat-icon>
                </h5>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <p>{{getDescripcionAlerta(configuracion.tipo)}}</p>
                <p>Se aplica a: <span *ngFor="let objetivo of getObjetivosAlerta(configuracion.tipo); let i = index"
                                      [attr.data-index]="i">
                    <strong>{{getNombreEstado(objetivo)}}<span
                      *ngIf="i != getObjetivosAlerta(configuracion.tipo).length - 1">, </span></strong></span>
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>ACTIVA:</mat-label>
                  <mat-select [(ngModel)]="configuracion.activa" placeholder="ACTIVA:">
                    <mat-option [value]="true">SÍ</mat-option>
                    <mat-option [value]="false">NO</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>OBJETIVO:</mat-label>
                  <mat-select [(ngModel)]="configuracion.objetivo" placeholder="OBJETIVO:"
                              [disabled]="!configuracion.activa" multiple>
                    <mat-option *ngFor="let estado of crmservice.estados" [value]="estado.id">
                      {{estado.nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"></div>
              <div *ngFor="let parametro of configuracion.parametros" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <label class="w-100">
                  <strong>{{parametro.nombre}}:</strong>
                  <input class="form-control form-control-sm" type="{{parametro.tipo}}" [(ngModel)]="parametro.valor">
                </label>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 border-bottom border-light"></div>
            </div>
          </div>

        </div>

      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<mat-menu #menuAlertaRow="matMenu">
  <ng-template matMenuContent let-alertaRow="alertaRow">
    <button (click)="abrirCliente(alertaRow)" mat-menu-item>
      <mat-icon>search</mat-icon>
      <span>ABRIR DIRECCIÓN</span>
    </button>
    <button *ngIf="crmservice.rol('admin') || crmservice.rol('supervisor') || crmservice.rol('pedidos')" (click)="ignorarAlerta(alertaRow)" mat-menu-item>
      <mat-icon>report</mat-icon>
      <span>NO VOLVER A GENERAR ESTA ALERTA</span>
    </button>
    <button *ngIf="alertaRow.gestionado == null" (click)="adquirirAlerta(alertaRow)" mat-menu-item>
      <mat-icon>support_agent</mat-icon>
      <span>GESTIONAR ALERTA</span>
    </button>
    <button *ngIf="alertaRow.gestionado != null && (alertaRow.gestionado === crmservice.userLogin._id || crmservice.rol('admin'))"
            (click)="rechazarAlerta(alertaRow)" mat-menu-item>
      <mat-icon>highlight_off</mat-icon>
      <span>RECHAZAR ALERTA</span>
    </button>
  </ng-template>
</mat-menu>
<mat-menu #menuAlertaConsumo>
  <ng-template matMenuContent let-alerta="alerta">
    <button mat-menu-item (click)="abrirDireccionAlertaConsumo(alerta.cliente._id)">
      <mat-icon>search</mat-icon>
      <span>ABRIR DIRECCION</span>
    </button>
    <button mat-menu-item (click)="abrirCMI(alerta.cliente._idCli)">
      <mat-icon>analytics</mat-icon>
      <span>ABRIR CMI</span>
    </button>
  </ng-template>
</mat-menu>
