import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../../../../servicios/crm.service';
import {Accion} from '../../../../modelos/Accion';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Persona} from '../../../../modelos/Persona';
import {Direccion} from '../../../../modelos/Direccion';

@Component({
  selector: 'app-formacion-persona',
  templateUrl: './formacion-persona.component.html',
  styleUrls: ['./formacion-persona.component.css']
})
export class FormacionPersonaComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direccion: Direccion;
  public accionesFormacionN: Accion[];
  public persona: Persona;
  public personas: Persona[];

  public files: File [];
  public subirFicherosPanel = false;
  public verFicherosPanel = false;

  public ficherosEliminar = [];

  public bloques: {numero: string, acciones: any[]}[] = [
    {numero: '1', acciones: []},
    {numero: '2', acciones: []},
    {numero: '3', acciones: []},
    {numero: '4', acciones: []},
    {numero: '5', acciones: []},
    {numero: '6', acciones: []},
    {numero: 'CARPINTERO DIGITAL', acciones: []},
  ];
  public cargandoDatos = false;

  public mostrarWarningApellidos = false;
  public mostrarWarningHitos = false;

  constructor(public dialogRef: MatDialogRef<FormacionPersonaComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public crmservice: CrmService,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {


    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.persona = this.data.persona;
    this.personas = this.data.personas;
    this.direccion = this.data.direccion;

    this.crmservice.getAccionesFormacionPersona(this.persona._id).subscribe(valueAcciones => {
      this.accionesFormacionN = valueAcciones;

      for (const accion of this.accionesFormacionN) {
        const pos = this.bloques.findIndex(e => e.numero === accion.formacion.bloque);
        this.bloques[pos].acciones.push(accion);
      }
      this.bloques.sort((a, b) => a.numero > b.numero ? 1 : (a.numero < b.numero ? -1 : 0));
      for (const bloque of this.bloques) {
        this.ordenarBloque(bloque.acciones);
      }

      this.mostrarWarningApellidos = this.persona.apellidos == null || !this.persona.apellidos.trim().length;

      const TCLA = this.comprobarHito(this.direccion, 'TCLA', true);
      const tresD = this.comprobarHito(this.direccion, '3D', true);

      this.mostrarWarningHitos = !TCLA || !tresD || this.direccion.perfilTCLA == null || !this.direccion.perfilTCLA.trim().length;

    }, error1 => {
      console.error(error1);
    });
  }

  ngOnInit() {
  }

  public dameColorBloque(bloque): string {
    if (!bloque.length) {
      return 'white';
    } else {
      let color = 'palegoldenrod';
      let practicaReciente = null; // Buscar la práctica más reciente realizada en el bloque.
      for (const accion of bloque) {
        if (accion.formacion.tipo === 'practica' && accion.estado === '0') {
          if (practicaReciente == null) {
            practicaReciente = accion;
          } else {
            if (new Date(practicaReciente.fechaInicio).getTime() < new Date(accion.fechaInicio).getTime()) {
              practicaReciente = accion;
            }
          }
        }
      }

      if (practicaReciente != null) {
        color = practicaReciente.formacion.superado ? 'palegreen' : 'palevioletred';
      }

      return color;
    }
  }
  public ordenarBloque(bloque): void {
    bloque.sort(function (a, b) {
      let fechaA: Date;
      let fechaB: Date;

      if (a.fechaInicio != null) {
        fechaA = new Date(a.fechaInicio);
      } else {
        fechaA = new Date(a.fechaPrevista);
      }

      if (b.fechaInicio != null) {
        fechaB = new Date(b.fechaInicio);
      } else {
        fechaB = new Date(b.fechaPrevista);
      }

      if (fechaA.getTime() > fechaB.getTime()) {
        return -1;
      } else if (fechaA.getTime() < fechaB.getTime()) {
        return 1;
      }
      return 0;

    });
  }

  public generarDiploma(): void {
    this.cargandoDatos = true;
    this.crmservice.generarDiploma(this.persona).subscribe(valueDiploma => {
      this.persona.diploma = valueDiploma;
      this.descargarDiploma();
      this.cargandoDatos = false;
      }, err => {
        console.error(err);
        this.cargandoDatos = false;
    });
  }
  public descargarDiploma(): void {
    window.open(this.crmservice.urlCRM + '/doc/diplomas/' + this.persona.diploma, '_black');
  }

  public mostrarGenerarDiploma(): boolean {
    if (this.cargandoDatos) {
      return false;
    }

    // Si no tiene los apellidos
    if (this.persona.apellidos == null || !this.persona.apellidos.trim().length) {
      return false;
    }

    // Si no tiene los hitos 3D o TCLA
    const TCLA = this.comprobarHito(this.direccion, 'TCLA', true);
    const tresD = this.comprobarHito(this.direccion, '3D', true);

    if (!TCLA || !tresD || this.direccion.perfilTCLA == null || !this.direccion.perfilTCLA.trim().length) {
      return false;
    }

    const resultados = [];
    for (const bloque of this.bloques) {
      resultados.push(this.dameColorBloque(bloque.acciones));
    }

    for (const resultado of resultados) {
      if (resultado !== 'palegreen') {
        return false;
      }
    }

    return true;
  }
  public mostararDescargarDiploma(): boolean {
    if (this.cargandoDatos || this.persona.apellidos == null || !this.persona.apellidos.trim().length) {
      return false;
    }
    return (this.persona.diploma != null && this.persona.diploma.trim().length > 0);
  }

  public concederFormacion(bloque: string): void {
    const accionTeoriaNueva = {
      medio: ['9'],
      contactos: [this.persona._id],
      de: [this.crmservice.userLogin._id],
      direccion: this.direccion._id,
      hito: '6',
      objetivo: '4',
      resultado: '0',
      observaciones: this.crmservice.userLogin.nombre + ' ' + this.crmservice.userLogin.apellidos + ' ha concedido el ' + bloque + ' bloque de formación 3D.',
      estado: '0',
      cliente: this.direccion.cliente,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      id: Math.random().toString(36).substr(2),
      formacion: {
        bloque: bloque,
        tipo: 'teoria',
        superado: true
      }
    };
    const accionPracticaNueva = {
      medio: ['9'],
      contactos: [this.persona._id],
      de: [this.crmservice.userLogin._id],
      direccion: this.direccion._id,
      hito: '6',
      objetivo: '4',
      resultado: '0',
      observaciones: this.crmservice.userLogin.nombre + ' ' + this.crmservice.userLogin.apellidos + ' ha concedido el ' + bloque + ' bloque de formación 3D.',
      estado: '0',
      cliente: this.direccion.cliente,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      id: Math.random().toString(36).substr(2),
      formacion: {
        bloque: bloque,
        tipo: 'practica',
        superado: true
      }
    };

    this.cargandoDatos = true;
    this.crmservice.accionesFormacionAutom(accionTeoriaNueva, accionPracticaNueva).subscribe(
      accionesRes => {
        const accionTeoria = accionesRes.accionTeoria;
        const accionPractica = accionesRes.accionPractica;

        this.direccion.acciones.push(accionTeoria._id);
        this.direccion.acciones.push(accionPractica._id);

        this.crmservice.getAccionesFormacionPersona(this.persona._id).subscribe(
          valueAcciones => {
            this.accionesFormacionN = valueAcciones;

            for (const accion of this.accionesFormacionN) {
              const pos = this.bloques.findIndex(e => e.numero === accion.formacion.bloque);
              this.bloques[pos].acciones.push(accion);
            }
            this.bloques.sort((a, b) => {
              return (a.numero > b.numero) ? 1 : ((a.numero < b.numero) ? -1 : 0);
            });
            for (const bloqueF of this.bloques) {
              this.ordenarBloque(bloqueF.acciones);
            }
          });

        this.cargandoDatos = false;
      }, error => {
        this.cargandoDatos = false;
        console.error(error);
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  /** FICHEROS **/
  public quitarFicheroPreview(file: string): void {
    const pos = this.files.findIndex(e => e.name === file);
    if (pos !== -1) {
      this.files.splice(pos, 1);
    }
  }
  public subirFicheros(): void {
    const nombres = [];
    const ficheros = [];
    for (const file of this.files) {
      const nombre = this.generarCadena(3) + '_' + file.name;
      nombres.push(nombre);

      ficheros.push({
        nombre: file.name,
        ficheroId: nombre,
        fecha: new Date()
      });
    }

    this.crmservice.subirFicherosPersona(this.files, nombres).subscribe(
      () => {

        if (this.persona.ficheros == null) {
          this.persona.ficheros = ficheros;
        } else {

          for (const fichero of ficheros) {
            const pos = this.persona.ficheros.findIndex(e => e.nombre === fichero.nombre);
            if (pos === -1) {
              this.persona.ficheros.push({
                nombre: fichero.nombre,
                ficheroId: fichero.ficheroId,
                fecha: fichero.fecha
              });
            } else {
              this.persona.ficheros[pos] = {
                nombre: fichero.nombre,
                ficheroId: fichero.ficheroId,
                fecha: fichero.fecha
              };
            }
          }
        }

        this.crmservice.updateFicherosPersona(this.persona._id, this.persona.ficheros).subscribe(
          () => {
            this.subirFicherosPanel = false;
            this.verFicherosPanel = true;
            this.files = [];
          }, error2 => {
            console.error(error2);
          });
      }, error1 => {
        console.error(error1);
      });
  }

  public abrirFichero(file: string): void {
    window.open(this.crmservice.urlCRM + '/doc/ficherosPersonas/' + file, '_blank');
  }

  public generarCadena(n): string {
    return Math.random().toString(36).substr(13 - n);
  }

  public mandarAPapelera(fichero: any): void {
    const pos = this.ficherosEliminar.findIndex(e => e.nombre === fichero.nombre);
    if (pos === -1) {
      this.ficherosEliminar.push(fichero);
    } else {
      this.ficherosEliminar.splice(pos, 1);
    }
  }
  public estaEnPapelera(fichero: any): boolean {
    return this.ficherosEliminar.some(e => e.nombre === fichero.nombre);
  }
  public borrarFicheros(): void {
    for (const fichero of this.ficherosEliminar) {
      const pos = this.persona.ficheros.findIndex(e => e.nombre === fichero.nombre);
      if (pos !== -1) {
        this.persona.ficheros.splice(pos, 1);
      }
    }
    this.ficherosEliminar = [];
    this.crmservice.updateFicherosPersona(this.persona._id, this.persona.ficheros).subscribe(
      () => {

      }, error1 => {
        console.error(error1);
      });
  }

  public establecerFotoPerfil(ficheroId: string): void {
    if (this.persona.perfil == null) {
      this.persona.perfil = ficheroId;
    } else {
      if (this.persona.perfil !== ficheroId) {
        this.persona.perfil = ficheroId;
      } else {
        this.persona.perfil = null;
      }
    }
    this.crmservice.updatePerfilPersona(this.persona._id, this.persona.perfil).subscribe(
      () => {
      }, error1 => {
        console.error(error1);
      });
  }
  public esPerfil(ficheroId: string): boolean {
    return ficheroId === this.persona.perfil;
  }
  public mostrarBotonPerfil(nombre: string): boolean {
    const extensiones = ['jpeg', 'jpg', 'png', 'raw', 'bmp', 'gif', 'tiff', 'tif', 'psd'];
    const extension = nombre.split('.')[nombre.split('.').length - 1].toLowerCase();

    return extensiones.includes(extension);
  }

  public comprobarHito(direccion: Direccion, hito: string, obtenido: boolean): boolean {
    const hitos = direccion.hitosObtenidos.filter(e => e.hito === hito);
    if (!hitos.length) {
      return false;
    } else {
      hitos.sort((a, b) => {
        const aDate = new Date(a.fecha);
        const bDate = new Date(b.fecha);
        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        } else if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
      const masReciente = hitos[hitos.length - 1];
      return masReciente.obtenido === obtenido;
    }
  }
}
