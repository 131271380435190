import { Component, OnInit } from "@angular/core";
import { Usuario } from "../../modelos/Usuario";
import { CrmService } from "../../servicios/crm.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SesionService } from "../../servicios/SesionService";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public login: Usuario = new Usuario();
  public recuerdame: boolean;
  public mensaje_login: string | null | undefined;

  public cargando: boolean;

  constructor(
    public crmservice: CrmService,
    private sesionService: SesionService,
    public snackBar: MatSnackBar,
    private router: Router
  ) {
    console.log("Login");

    this.mensaje_login = this.sesionService.mensaje_login;
    if (this.mensaje_login != null) {
      this.sesionService.remove_mensaje_login();
    }

    this.login.nombreUsuario = "";
    this.login.password = "";
    this.recuerdame = false;

    this.cargando = false;

    if (this.sesionService.getUserLogeado()) {
      this.router.navigate(["direcciones"]).then();
    }
  }

  ngOnInit() {}

  public iniciarSesion(): void {
    localStorage.clear();

    this.cargando = true;

    this.crmservice
      .login(this.login.nombreUsuario, this.login.password, this.recuerdame)
      .subscribe(
        (access_token) => {
          if (access_token == null) {
            this.snackBar.open("Usuario o contraseña incorrecta", "OK", {
              duration: 2000,
            });
          } else {
            this.sesionService.setToken(access_token.access_token);
            location.reload();
          }
          this.cargando = false;
        },
        (error) => {
          console.error(error);
          this.cargando = false;
        }
      );
  }
}
