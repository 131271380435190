import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {PreguntaCliente} from '../../../modelos/PreguntaCliente';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../../../servicios/crm.service';

@Component({
  selector: 'app-responder-pregunta',
  templateUrl: './responder-pregunta.component.html',
  styleUrls: ['./responder-pregunta.component.css']
})
export class ResponderPreguntaComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargando: boolean;
  public pregunta: PreguntaCliente;
  public respuesta: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ResponderPreguntaComponent>,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              private snackBar: MatSnackBar) {

    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.pregunta = this.data.pregunta;
    this.cargando = false;
  }

  ngOnInit() {
  }

  public enviar(): void {
    if (this.comprobarFormulario()) {
      this.cargando = true;
      this.crmservice.asignarPreguntaUsuarioCRM(this.pregunta._id, this.crmservice.userLogin._id).subscribe(() => {
        this.crmservice.anadirIntervencionAPregunta(this.pregunta._id, this.respuesta).subscribe(() => {
          this.snackBar.open('La respuesta ha sido enviada correctamente', 'OK', {duration: 3000});

          const intervencion = {
            fecha: new Date(),
            origen: 'tienda',
            destino: 'cliente',
            pregunta: this.respuesta
          };
          this.pregunta.idCliente = this.crmservice.userLogin._id;
          this.pregunta.respondida = this.crmservice.userLogin.nombre + ' ' + this.crmservice.userLogin.apellidos.split(' ')[0];
          this.pregunta.intervenciones.push(intervencion);

          this.respuesta = '';
          this.cargando = false;
        }, error => {
          console.error(error);
          this.snackBar.open('Error al responder al cliente', 'OK');
          this.cargando = false;
        });
      }, error => {
        console.error(error);
        this.snackBar.open('Error al responder al cliente', 'OK');
        this.cargando = false;
      });
    }
  }
  public comprobarFormulario(): boolean {
    if (this.respuesta == null || !this.respuesta.trim().length) {
      this.snackBar.open('¡Escribe una respuesta!', 'Ok', {duration: 3500});
      return false;
    }
    return true;
  }
}
