interface LicenciasDisponibles {
  cantidad: number;
  adicionales: number;
  activas: number;
  fijo: boolean;
  cambiosConfig: {
    usuario: string,
    fijo: boolean,
    fecha: Date,
    motivo: string
  }[];
}
interface TipoCliente {
  tipo: string;
  categoria: string;
}

export class Cliente {
  public _id: string;
  public razonSocial: string;
  public cif: string;
  public fechaCreacion: Date;
  public fechaUltimaMod: Date;
  public usuarioCreador: string;
  public observaciones: string;
  public direcciones: string[];
  public desactivado: boolean;
  public esExtranjero: string;
  public clasificacion: string;
  public licenciasDisponibles: LicenciasDisponibles;
  public tipoCliente: TipoCliente;

  constructor(c: Cliente | any | null = null) {
    if (c != null ) {
      this._id = c._id;
      this.razonSocial = c.razonSocial;
      this.cif = c.cif;
      this.fechaCreacion = c.fechaCreacion != null ? new Date(c.fechaCreacion) : new Date();
      this.fechaUltimaMod = c.fechaUltimaMod != null ? new Date(c.fechaUltimaMod) : new Date();
      this.usuarioCreador = c.usuarioCreador;
      this.observaciones = c.observaciones;
      this.direcciones = c.direcciones;
      this.desactivado = c.desactivado;
      this.esExtranjero = c.esExtranjero;
      this.clasificacion = c.clasificacion;
      this.licenciasDisponibles = c.licenciasDisponibles;
      this.tipoCliente = c.tipoCliente;
    }
  }
}
