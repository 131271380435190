import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { CrmService } from "../../../../servicios/crm.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Direccion } from "../../../../modelos/Direccion";

@Component({
  selector: "app-contrato-amueblando",
  templateUrl: "./contrato-amueblando.component.html",
  styleUrls: ["./contrato-amueblando.component.css"],
})
export class ContratoAmueblandoComponent implements OnInit {
  public cargandoDatos: boolean;
  public guardandoDatos = false;

  public docToUpload: File = null;

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public mostrarInfo: boolean;

  public direccion: Direccion;
  public provincia: string;
  public filtro: string;

  public busquedaAsignados: string;
  public filtroAsigandos: string;

  public CPDataSource: MatTableDataSource<any> = new MatTableDataSource();
  public color: string;

  constructor(
    public crmservice: CrmService,
    public snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 750px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargandoDatos = false;
    this.mostrarInfo = true;

    this.direccion = data.direccion;

    if (this.direccion.contratoAMUEBLANDO.color != null) {
      this.color = this.direccion.contratoAMUEBLANDO.color;
    } else if (this.direccion.contratoIDE.color != null) {
      this.color = this.direccion.contratoIDE.color;
    } else {
      this.color = "#000";
    }

    this.CPDataSource.data = [];
    for (const cp of this.crmservice.codigosPostales) {
      let pos = this.CPDataSource.data.findIndex(
        (e) => e.codigo === cp.codigoPostal
      );
      if (pos === -1) {
        this.CPDataSource.data.push({
          codigo: cp.codigoPostal,
          poblaciones: "",
          cps: [],
        });
        pos = this.CPDataSource.data.findIndex(
          (e) => e.codigo === cp.codigoPostal
        );
      }
      this.CPDataSource.data[pos].poblaciones += cp.poblacion + " ";
      this.CPDataSource.data[pos].cps.push(cp);
    }

    this.CPDataSource.data.sort((a, b) => {
      if (a.codigo > b.codigo) {
        return 1;
      } else if (a.codigo < b.codigo) {
        return -1;
      }
      return 0;
    });
  }

  ngOnInit(): void {}
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.CPDataSource.filter = filterValue;
  }

  public getDisponibilidadFisica(codigoPostal: any): string {
    const asignadoFisico = codigoPostal.cps[0].asignadoIDE;
    return asignadoFisico != null ? asignadoFisico : "Disponible";
  }
  public getDisponibilidadVirtual(codigoPostal: any): string {
    const asignadoVirtual = codigoPostal.cps[0].asignadoVirtual;
    return asignadoVirtual != null ? asignadoVirtual : "Disponible";
  }

  public irACP(codigoPostal: string): void {
    const pos = this.CPDataSource.data.findIndex(
      (e) => e.codigo.toString(10) === codigoPostal
    );
    if (pos !== -1) {
      this.provincia = this.CPDataSource.data[pos].cps[0].provincia;
      this.filtro =
        this.CPDataSource.data[pos].cps[0].codigoPostal.toString(10);
      this.applyFilter(this.filtro);
    }
  }

  // Solo quitar si el CP no es idehabita, cuidado.
  public ponerCP(codigoPostal: any): void {
    this.cargandoDatos = true;

    const idsCP = [];
    for (const cp of codigoPostal.cps) {
      idsCP.push(cp.idTCLA);
    }

    this.crmservice.asignarCPTCLA(idsCP, this.direccion.perfilTCLA).subscribe(
      () => {
        this.crmservice
          .asignarCPVirtual(
            codigoPostal.cps,
            this.direccion._id,
            this.direccion.perfilTCLA
          )
          .subscribe(
            () => {
              if (
                !this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.includes(
                  codigoPostal.codigo
                )
              ) {
                this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.push(
                  codigoPostal.codigo.toString()
                );
              }

              for (const cp of codigoPostal.cps) {
                cp.asignadoVirtual = this.direccion.perfilTCLA;
              }

              this.cargandoDatos = false;
            },
            (error2) => {
              console.error(error2);
              this.cargandoDatos = false;
            }
          );
      },
      (error1) => {
        console.error(error1);
        this.snackbar.open(
          "En estos momentos no es posible conectar con TCLA para completar la solicitud. Por favor, inténtalo más tarde.",
          "OK",
          { duration: 5000 }
        );
        this.cargandoDatos = false;
      }
    );
  }
  public quitarCP(codigo: string): void {
    this.cargandoDatos = true;

    let posCPVirtual = this.CPDataSource.data.findIndex((e) => {
      if (e.codigo) {
        return e.codigo.toString(10) === codigo;
      } else {
        return false;
      }
    });

    if (posCPVirtual !== -1) {
      // Si el CP está dentro del contrato IDEHABITA, no se permite realizar el cambio.
      if (this.CPDataSource.data[posCPVirtual].cps[0].asignadoIDE != null) {
        this.snackbar.open(
          "¡No puedes editar un codigo postal que ha sido asignado en el contrato IDEHÁBITA!",
          "Ok",
          { duration: 5000 }
        );
        this.cargandoDatos = false;
      } else {
        // Se elimina la asignación del código y se incluyen los _id que se van a enviar a TCLA
        const idsCP = [];

        for (const cp of this.CPDataSource.data[posCPVirtual].cps) {
          idsCP.push(cp.idTCLA);
        }

        this.crmservice
          .desasignarCPTCLA(idsCP, this.direccion.perfilTCLA)
          .subscribe(
            () => {
              this.crmservice
                .desasignarCPVirtual(
                  this.CPDataSource.data[posCPVirtual].cps,
                  this.direccion._id
                )
                .subscribe(
                  () => {
                    for (const cp of this.CPDataSource.data[posCPVirtual].cps) {
                      cp.asignadoVirtual = null;
                    }

                    // Se retira el Codigo Postal de los virtuales en la dirección
                    posCPVirtual =
                      this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.findIndex(
                        (e) => e === codigo
                      );
                    if (posCPVirtual !== -1) {
                      this.direccion.contratoAMUEBLANDO.codigosPostalesAsociados.splice(
                        posCPVirtual,
                        1
                      );
                    }

                    this.cargandoDatos = false;
                  },
                  (error2) => {
                    console.error(error2);
                    this.cargandoDatos = false;
                  }
                );
            },
            (error1) => {
              console.error(error1);
              this.snackbar.open(
                "En estos momentos no es posible conectar con TCLA para completar la solicitud. Por favor, inténtalo más tarde.",
                "OK",
                { duration: 5000 }
              );
              this.cargandoDatos = false;
            }
          );
      }
    } else {
      this.cargandoDatos = false;
    }
  }

  public mostrarCP(codigoPostal: any): boolean {
    return codigoPostal.cps[0].provincia === this.provincia;
  }
  public mostrarProvincia(provincia: string): boolean {
    if (
      this.crmservice.userLogin.rol !== "comercial" &&
      this.crmservice.userLogin.rol !== "influencer"
    ) {
      return true;
    } else {
      const team = this.crmservice.userLogin.team;
      const posTeam = this.crmservice.provinciasEquipos.findIndex(
        (e) => e.equipo === team
      );
      if (posTeam !== -1) {
        return (
          this.crmservice.provinciasEquipos[posTeam].provincias.findIndex(
            (e) => e === provincia
          ) !== -1
        );
      } else {
        return false;
      }
    }
  }

  public cambiarColor(): void {
    this.cargandoDatos = true;

    this.direccion.contratoAMUEBLANDO.color = this.color;
    this.crmservice
      .cambiarColorAMUEBLANDO(this.color, this.direccion._id)
      .subscribe(
        (value) => {
          if (value != null) {
            console.log(value);
            this.snackbar.open("No ha sido posible cambiar el color.", "OK", {
              duration: 3000,
            });
          } else {
            this.snackbar.open("Se ha cambiado el color correctamente.", "OK", {
              duration: 3000,
            });
          }
          this.cargandoDatos = false;
        },
        (error1) => {
          console.error(error1);
          this.snackbar.open("No ha sido posible cambiar el color.", "OK", {
            duration: 3000,
          });
          this.cargandoDatos = false;
        }
      );

    this.cargandoDatos = false;
  }

  public aplicarFiltroAsignados(): void {
    this.filtroAsigandos = this.busquedaAsignados;
  }
  public mostrarCPAsignado(cp: string): boolean {
    if (this.filtroAsigandos == null || !this.filtroAsigandos.trim().length) {
      return true;
    }

    return cp.includes(this.filtroAsigandos);
  }

  /** RUTAS DE CONTACTO **/
  public verContrato(): void {
    window.open(
      this.crmservice.urlCRM +
        "/doc/contratos/" +
        this.direccion.contratoAMUEBLANDO.documento,
      "_blank"
    );
  }
  public abrirInput(): void {
    document.getElementById("contratoAMUEBLANDO").click();
  }
  public subirPDF(files: FileList): void {
    this.guardandoDatos = true;
    this.docToUpload = files.item(0);

    const name =
      Math.random().toString(36).substring(2) +
      "." +
      this.docToUpload.name.split(".").pop();

    this.crmservice.subirContrato(this.docToUpload, name).subscribe(
      () => {
        this.direccion.contratoAMUEBLANDO.documento = name;
      },
      () => {
        this.guardandoDatos = false;
        this.snackbar.open("Error al subir contrato", "OK", { duration: 2000 });
      },
      () => {
        this.guardarContrato();
      }
    );
  }
  public borrarContrato(): void {
    this.guardandoDatos = true;

    this.direccion.contratoAMUEBLANDO.documento = null;
    this.guardarContrato();
  }
  public guardarContrato(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {},
      () => {
        this.guardandoDatos = false;
        this.snackbar.open("Error al guardar contrato", "OK", {
          duration: 2000,
        });
      },
      () => {
        this.guardandoDatos = false;
        this.snackbar.open("Contrato guardado correctamente", "OK", {
          duration: 2000,
        });
      }
    );
  }
}

// public generarProtocoloDV(): void {
//   this.cargandoDatos = true;
//   this.crmservice.generarProtocoloDV(this.direccion._id).subscribe(
//     nombreDocumento => {
//       this.cargandoDatos = false;
//       if (nombreDocumento != null) {
//         this.direccion.contratoAMUEBLANDO.documento = nombreDocumento;
//         this.descargarProtocoloDV();
//       }
//     }, error => {
//       console.error(error);
//       this.cargandoDatos = false;
//     });
// }
// public descargarProtocoloDV(): void {
//   window.open(this.crmservice.urlCRM + '/doc/protocolosDV/' + this.direccion.contratoAMUEBLANDO.documento, '_blank');
// }
// public eliminarProtocoloDV(): void {
//   this.cargandoDatos = true;
//   this.crmservice.eliminarProtocoloDV(this.direccion._id).subscribe(
//     () => {
//       this.cargandoDatos = false;
//       this.direccion.contratoAMUEBLANDO.documento = '';
//     }, error => {
//       this.cargandoDatos = false;
//       console.error(error);
//     });
// }

// public permitirDescargarEliminar(): boolean {
//   return (this.direccion.contratoAMUEBLANDO.documento != null && this.direccion.contratoAMUEBLANDO.documento.trim().length > 0);
// }
