<div class="cabecera-modal">
  <img alt="" id="img-dv" class="icon-modal" src="../../../../../assets/img/hitos/disenadorvirtual.png" />
  <strong>PRESENTACIÓN DISEÑADOR VIRTUAL</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [hidden]="cargandoDatos" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div class="row">
      <div class="col-12 mt-2" *ngIf="direccion.contratoPreDV.documento != null">
        <button (click)="verContrato()" mat-button color="primary">
          <mat-icon>visibility</mat-icon>
          Ver contrato
        </button>
        <button *ngIf="crmservice.rol('admin') || crmservice.rol('supervisor')" (click)="borrarContrato()" mat-button color="warn">
          <mat-icon>delete</mat-icon>
          Eliminar contrato
        </button>
      </div>

      <div class="col-12 mt-2" *ngIf="direccion.contratoPreDV.documento == null">
        <button (click)="abrirInput()" mat-button color="primary">
          <mat-icon>cloud_upload</mat-icon>
          Subir contrato
        </button>
        <input type="file" id="contratoPreDV" accept="application/pdf" hidden (change)="subirPDF($event.target.files)" />
      </div>

      <div class="col-12">
        <hr />
      </div>

      <div class="spinner-container" *ngIf="cargandoDatos">
        <mat-spinner class="spinner"></mat-spinner>
      </div>
    </div>
  </div>
</div>
