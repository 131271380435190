<div class="cabecera-modal">
  <mat-icon class="icon-modal">store</mat-icon>
  <strong>CLIENTES ASOCIADOS</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]>close</mat-icon>
</div>

<div *ngIf="!cargando" class="content-modal p-3 position-relative">
  <div *ngIf="ipdormaItem.clientes.length !== 1" class="text-end mb-3">
    <button class="btn" [ngClass]="!ipdormaItem.ignorar ? 'btn-danger' : 'btn-primary-crm'" (click)="btn_notificar()">
      <div class="d-flex">
        <mat-icon class="me-2">{{!ipdormaItem.ignorar ? 'notifications_active' : 'notifications_off'}}</mat-icon>
        <span>{{!ipdormaItem.ignorar ? 'IGNORAR ALERTA' : 'DEJAR DE IGNORAR'}}</span>
      </div>
    </button>
  </div>

  <div *ngIf="!ipdormaItem.clientes.length" class="alert alert-warning">
    <p>{{ipdormaItem.razonSocial}} ({{ipdormaItem.codigo}}) aún no ha sido asignado a un cliente del CRM. Haz click
      <span class="btn-asignar" role="button" (click)="vincularCliente()">AQUÍ</span> para asignarlo. Mientras la ficha no se asigne a un
      cliente del CRM, los datos de facturación de {{ipdormaItem.razonSocial}} no están disponibles para su consulta en el CMI
      o catálogo TECLA.
    </p>
  </div>

  <div *ngIf="ipdormaItem.clientes.length > 2" class="alert alert-warning">
    <p>No es recomendable que varios clientes del CRM compartan la misma ficha de IPDORMA. Puede dar lugar a <strong class="text-danger">errores</strong> en el cálculo de la
      facturación.</p>
  </div>

  <div *ngIf="ipdormaItem.clientes.length === 1" class="alert alert-success">
    <p>La ficha de IPDORMA está asociada a un único cliente del CRM.</p>
  </div>

  <div *ngIf="ipdormaItem.clientes.length">
    <table class="table text-secondary table-hover">
      <thead>
      <tr>
        <th style="white-space: nowrap; width: 5%; max-width: 5%"></th>
        <th style="white-space: nowrap; width: 5%; max-width: 5%"></th>
        <th style="white-space: nowrap; width: 10%; max-width: 10%">CIF</th>
        <th style="white-space: nowrap; width: 20%; max-width: 20%">Razón Social</th>
        <th style="white-space: nowrap; width: 20%; max-width: 20%">Nombre Comercial</th>
        <th style="white-space: nowrap; width: 10%; max-width: 10%">Provincia</th>
        <th style="white-space: nowrap; width: 10%; max-width: 10%">Poblacion</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let direccion of direcciones">
        <td style="white-space: nowrap; vertical-align: middle">
          <img src="../../assets/img/markers/{{direccion.equipo}}/{{direccion.estado}}.png" alt="Estado">
        </td>
        <td style="white-space: nowrap" class="class-char"
            [ngStyle]="{'color' : getColorClasificacion(direccion.clasificacion)}">
          {{getClasificacion(direccion.clasificacion)}}
        </td>
        <td style="white-space: nowrap">{{direccion.cif}}</td>
        <td style="white-space: nowrap" (click)="abrirDireccion(direccion._id)">
          <span class="btn-asignar" role="button">{{direccion.razonSocial}}</span>
        </td>
        <td style="white-space: nowrap" (click)="abrirDireccion(direccion._id)">
          <span class="btn-asignar" role="button">{{direccion.nombreComercial}}</span>
        </td>
        <td style="white-space: nowrap">{{direccion.provincia}}</td>
        <td style="white-space: nowrap">{{direccion.poblacion}}</td>
        <td class="text-end">
          <mat-icon *ngIf="ipdormaItem.clientes.length !== 1" class="user-select-none" role="button" (click)="desvincularCliente(direccion)">cancel</mat-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
  <mat-spinner class="spinner"></mat-spinner>
</div>
