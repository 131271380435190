import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CrmService} from '../../../../../servicios/crm.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-historial-licencia',
  templateUrl: './historial-licencia.component.html',
  styleUrls: ['./historial-licencia.component.css']
})
export class HistorialLicenciaComponent implements OnInit {

  public equipoSel: {
    aliasEquipo: string,
    adaptRed: string,
    llaveRed: string,
    activaciones: {
      usuario: string,
      fActivacion: Date,
      fVencimiento: Date,
      caducada: boolean
    }[]
  };

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.equipoSel = data.equipo;
  }

  ngOnInit() {
  }

  public dameNombreUsuario(id: string): string {

    if (id == null) {
      return 'Automático';
    }

    const pos = this.crmservice.usuarios.findIndex(e => e._id === id);

    if (pos !== -1) {
      return this.crmservice.usuarios[pos].nombre + ' ' + this.crmservice.usuarios[pos].apellidos;
    } else {
      return 'Desconocido';
    }
  }

}
