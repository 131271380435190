<div *ngIf="!cargandoDatos" class="m-xl-5 m-lg-5 m-md-2 m-sm-0">
  <h2>Control de Asistencia/Fichajes</h2>

  <div class="container mt-4">
    <div class="row">

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <label class="w-100 m-0">
          Usuario:
          <select class="form-select" [(ngModel)]="userSel" (ngModelChange)="buscarFichajes()"
                  [disabled]="(crmservice.userLogin.rol != 'admin' && crmservice.userLogin.rol != 'supervisor')">
            <ng-container *ngFor="let usuario of crmservice.usuarios">
              <option
                *ngIf="(usuario.rol == 'asesor' || usuario._id == crmservice.userLogin._id) && usuario.desactivado != true"
                [value]="usuario._id">
                {{usuario.nombre}} {{usuario.apellidos}}
              </option>
            </ng-container>
          </select>
        </label>
      </div>

      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <label class="w-100 m-0">
          Año:
          <input type="number" class="form-select" [(ngModel)]="year" (ngModelChange)="buscarFichajes()">
        </label>
      </div>

      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <label class="w-100 m-0">
          Mes:
          <select class="form-select" [(ngModel)]="mes" (ngModelChange)="buscarFichajes()">
            <option *ngFor="let mesSel of meses" [ngValue]="mesSel.n">
              {{mesSel.mes}}
            </option>
          </select>
        </label>
      </div>

      <!-- <div *ngIf="jornadas != null && jornadas.length" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 text-right">
        <button class="btn btn-sm btn-success">EXCEL</button>
      </div> -->

      <div *ngIf="jornadas != null && jornadas.length" class="col-12 mt-4">
        <table class="table table-sm table-responsive table-bordered text-center bg-white w-auto">
          <thead>
          <tr>
            <th class="w-64px">Día</th>
            <ng-container *ngFor="let tiempo of getColumnas()">
              <th class="w-8px"></th>
              <th class="w-96px">Entrada</th>
              <th class="w-96px">Salida</th>
            </ng-container>
            <th class="w-128px">Total</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let dia of calendario">
            <td>{{dia.dia}}</td>
            <ng-container *ngFor="let tiempo of dia.tiempos">
              <td></td>
              <td
                [ngStyle]="{'background-color' : (tiempo != null && tiempo.fechaEntrada != null) ? 'whitesmoke' : ''}">
                <img *ngIf="tiempo != null && tiempo.entradaDNI" src="../../../../assets/img/dnie.png" alt="DNIe" class="img-dni">
                <span>{{(tiempo != null && tiempo.fechaEntrada != null) ? (tiempo.fechaEntrada | date : 'HH:mm') : ''}}</span>
              </td>
              <td [ngStyle]="{'background-color' : (tiempo != null && tiempo.fechaSalida != null) ? 'whitesmoke' : ''}">
                <img *ngIf="tiempo != null && tiempo.salidaDNI" src="../../../../assets/img/dnie.png" alt="DNIe" class="img-dni">
                <span>{{(tiempo != null && tiempo.fechaSalida != null) ? (tiempo.fechaSalida | date : 'HH:mm') : ''}}</span>
              </td>
            </ng-container>
            <td>
              <div *ngIf="dia.tiempos[0] != null">
                <span>{{formatoHH(dia.total.horas)}}:</span>
                <span>{{formatoHH(dia.total.minutos)}}:</span>
                <span>{{formatoHH(dia.total.segundos)}}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td [colSpan]="getColspan()" class="text-end">
              <strong class="me-2">
                TOTAL:
              </strong>
              <span>{{formatoHH(totalHoras.horas)}}:</span>
              <span>{{formatoHH(totalHoras.minutos)}}:</span>
              <span>{{formatoHH(totalHoras.segundos)}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>

<div *ngIf="cargandoDatos" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>
