export class AlertaCliente {
  public _id: string;
  public cliente: string;
  public direccion: string;
  public msg: string;
  public tipo: string;
  public ambito: string;
  public activa: boolean;
  public gravedad: number;
  public visto: string[];
  public gestionado: string;
  public fecha: Date;

  constructor(a: AlertaCliente | any | null) {
    if (a != null) {
      this._id = a._id;
      this.cliente = a.cliente;
      this.direccion = a.direccion;
      this.msg = a.msg;
      this.tipo = a.tipo;
      this.ambito = a.ambito;
      this.activa = a.activo;
      this.gravedad = a.gravedad;
      this.visto = a.visto;
      this.gestionado = a.gestionado;
      this.fecha = new Date(a.fecha);
    }
  }
}

export class AlertaClienteRow {
  public _id: string;
  public idCliente?: string;
  public idDireccion?: string;
  public razonSocial?: string;
  public nombreComercial?: string;
  public clasificacion?: string;
  public estado?: string;
  public equipo?: string;
  public msg: string;
  public tipo: string;
  public ambito: string;
  public activa: boolean;
  public gravedad: number;
  public visto: string[];
  public gestionado: string;
  public fecha: Date;

  constructor(a: AlertaCliente | any | null, direccion) {
    if (a != null) {
      if (direccion != null) {
        this._id = a._id;
        this.razonSocial = direccion.razonSocial;
        this.idCliente = a.cliente;
        this.idDireccion = a.direccion;
        this.nombreComercial = direccion.nombreComercial;
        this.clasificacion = direccion.clasificacion;
        this.estado = direccion.estado;
        this.equipo = direccion.equipo;
        this.msg = a.msg;
        this.tipo = a.tipo;
        this.ambito = a.ambito;
        this.activa = a.activa;
        this.gravedad = a.gravedad;
        this.visto = a.visto;
        this.gestionado = a.gestionado;
        this.fecha = new Date(a.fecha);
      } else {
        this._id = a._id;
        this.msg = a.msg;
        this.tipo = a.tipo;
        this.ambito = a.ambito;
        this.activa = a.activa;
        this.gravedad = a.gravedad;
        this.visto = a.visto;
        this.fecha = a.fecha;
        this.gestionado = a.gestionado;
      }
    }
  }

  public get iconoAlerta(): string {
    if (this.estado != null && this.equipo != null) {
      return '/assets/img/markers/' + this.equipo + '/' + this.estado + '.png';
    } else {
      return 'Desconocido';
    }
  }
  public get Clasificacion(): string {
    switch (this.clasificacion) {
      case 'A':
        return 'A';
      case 'B1':
        return 'B';
      case 'B2':
        return 'B';
      case 'C':
        return 'C';
      default:
        return '';
    }
  }
  public get ColorClasificacion(): string {
    if (this.clasificacion === 'A') {
      return 'limegreen';
    }
    if (this.clasificacion === 'B1' || this.clasificacion === 'B2') {
      return 'gold';
    }
    if (this.clasificacion === 'C') {
      return 'brown';
    }
    return 'pink';
  }

  public userVisto(id: string): boolean {
    return this.visto.includes(id);
  }

}
