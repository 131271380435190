import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Direccion} from '../../../../modelos/Direccion';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Cliente} from '../../../../modelos/Cliente';
import {MediaMatcher} from '@angular/cdk/layout';
import {MapaserviceService} from '../../../../servicios/mapaservice.service';

@Component({
  selector: 'app-nueva-direccion-formulario',
  templateUrl: './nueva-direccion-formulario.component.html',
  styleUrls: ['./nueva-direccion-formulario.component.css']
})
export class NuevaDireccionFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direccion: Direccion;
  public cliente: Cliente;
  public cargando: boolean;

  public mensajeError = '';
  public estadoPerfilTCLA = 1;
  public estadosPerfilTCLA: {id: number, estado: string}[] = [
    {id: 0, estado: 'El perfil TCLA es obligatorio en potenciales y activos.'},
    {id: 1, estado: 'Correcto.'},
    {id: 2, estado: 'El perfil TCLA indicado contiene caracteres no permitidos.'},
    {id: 3, estado: 'El perfil TCLA indicado no se correponde con ninguno existente.'},
    {id: 4, estado: 'Se ha producido un error durante la validación del perfil TCLA.'}
    ];

  constructor(public crmservice: CrmService,
              public mapaservice: MapaserviceService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {
    console.log('NuevaDireccionFormulario');

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cliente = data.cliente;
    this.direccion = new Direccion();
    this.cargando = false;

    this.direccion.equipo = (this.crmservice.userLogin.rol === 'comercial' || this.crmservice.userLogin.rol === 'influencer') ? this.crmservice.userLogin.team : null;
    this.direccion.estado = 'candidato';
    this.direccion.verMapa = true;
    this.direccion.tipoDir = '0';
  }

  ngOnInit() {
  }

  public guardar(): void {
    this.comprobarFormulario()
      .then(() => {
        this.cargando = true;

        this.direccion.usuarioCreador = this.crmservice.userLogin.nombreUsuario;

        // Pasar a mayúsculas
        this.direccion.nombreComercial = this.normalizarTexto(this.direccion.nombreComercial);
        this.direccion.poblacion = this.normalizarTexto(this.direccion.poblacion);
        this.direccion.direccion = this.normalizarTexto(this.direccion.direccion);
        this.direccion.direccionDetalle = this.normalizarTexto(this.direccion.direccionDetalle);
        this.direccion.webEmpresa = this.normalizarTexto(this.direccion.webEmpresa);
        this.direccion.observaciones = this.normalizarTexto(this.direccion.observaciones);

        this.direccion.cliente = this.cliente._id;

        this.crmservice.nuevaDireccion(this.direccion).subscribe(value => {
          this.direccion = value.direccion;

          // Actualizar tabla
          this.crmservice.direcciones.push(this.direccion);

          const pos = this.crmservice.direcciones.findIndex(e => e._id === this.direccion._id);
          this.crmservice.direcciones[pos].cif = this.cliente.cif;
          this.crmservice.direcciones[pos].razonSocial = this.cliente.razonSocial;

          this.mapaservice.generarCoordenadas(this.direccion).then(() => {
            this.cargando = false;
            this.toast('Dirección guardada correctamente');
            this.dialogRef.close({cliente: this.cliente, direccion: this.direccion});
          });
        }, error => {
          console.error(error);
          this.cargando = false;
          this.toast('Error al guardar dirección');
        });
      })
      .catch(err => {
        this.cargando = false;
        this.toast(err);
      });
  }
  public cancelar(): void {
    const popUpRef = this.crmservice.popUpConfirmacion({textoPrincipal: '¿Cancelar nueva dirección?'});
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  public cambiaCP(): void {
    if (this.direccion.codigoPostal != null && this.direccion.codigoPostal.length === 5) {
      const cpFind = this.crmservice.codigosPostales.find(cp => this.crmservice.FormatNumberLength(cp.codigoPostal, 5) === this.direccion.codigoPostal);
      if (cpFind) {
        this.direccion.provincia = cpFind.provincia;
        this.direccion.poblacion = cpFind.poblacion;
      }
    }
  }
  public tipoDirCambiado(direccion: Direccion): void {
    direccion.verMapa = direccion.tipoDir === '0' || direccion.tipoDir === '6' || direccion.tipoDir === '8';
  }

  private comprobarFormulario(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.direccion.nombreComercial == null || !this.direccion.nombreComercial.trim().length) {
        reject('Introduce nombre comercial.');
      }
      if (this.direccion.equipo == null || !this.direccion.equipo.trim().length) {
        reject('Indica un equipo.');
      }
      if (this.direccion.estado == null || !this.direccion.estado.trim().length) {
        reject('Indica el estado.');
      }
      if (['potencial', 'activo', 'activoI'].includes(this.direccion.estado) && (this.direccion.perfilTCLA == null || !this.direccion.perfilTCLA.trim().length)) {
        reject('El perfil TECLA es obligatorio en potenciales y activos.');
      }
      if (this.direccion.tipoDir == null || !this.direccion.tipoDir.trim().length) {
        reject('Indica el tipo de dirección.');
      }
      if (this.direccion.verMapa == null) {
        reject('Indica el valor de la propiedad "Ver en mapa"');
      }
      if (this.direccion.codigoPostal == null || !this.direccion.codigoPostal.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('Introduce el codigo postal.');
        }
      }
      if (this.direccion.provincia == null || !this.direccion.provincia.trim().length) {
        reject('Indica la provincia.');
      }
      if (this.direccion.poblacion == null || !this.direccion.poblacion.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('Introduce la población.');
        }
      }
      if (this.direccion.tipoVia == null || !this.direccion.tipoVia.trim().length) {
        reject('Indica el tipo de vía.');
      }
      if (this.direccion.direccion == null || !this.direccion.direccion.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('Introduce la dirección.');
        }
      }
      if (this.direccion.perfilTCLA != null && this.direccion.perfilTCLA.trim().length) {
        this.crmservice.getFactorConversion(this.direccion.perfilTCLA).subscribe(value => {
          if (value[0] != null) {
            resolve();
          } else {
            reject('El TECLA indicado no existe.');
          }
        }, () => reject('El TECLA indicado no existe.'));
      } else {
        resolve();
      }
    });
  }
  private normalizarTexto(texto: string): string {
    if (texto != null) {
      return texto
        .normalize('NFD')
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
        .normalize()
        .toUpperCase();
    }
    return undefined;
  }
  private toast(msg: string = 'Desconocido'): void {
    this.snackBar.open(msg, 'OK', {duration: 3500});
  }
}


