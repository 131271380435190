<div class="cabecera-modal">
  <mat-icon class="icon-modal">swap_vert</mat-icon>
  <strong>{{nuevo ? 'NUEVO USUARIO' : 'EDITAR USUARIO'}}</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">

    <div class="row">

      <div class="col-12 col-md-6 mb-4">
        <label class="w-100">
          Nombre
          <input class="form-control" [(ngModel)]="nombre">
        </label>
      </div>

      <div class="col-12 col-md-6 mb-4">
        <label class="w-100">
          Apellidos
          <input class="form-control" [(ngModel)]="apellidos">
        </label>
      </div>

      <div class="col-12 col-md-6 mb-4">
        <label class="w-100">
          Nombre de Usuario
          <input class="form-control" [(ngModel)]="nombreUsuario">
        </label>
      </div>

      <div class="col-12 col-md-6 mb-4" *ngIf="nuevo || cambiarPass">
        <label class="w-100">
          Contraseña
          <input class="form-control" [(ngModel)]="password" type="password">
        </label>
      </div>

      <div class="col-12 text-center mb-4" *ngIf="!nuevo">
        <button *ngIf="!nuevo" (click)="cambiarPass = !cambiarPass" class="btn btn-outline-primary w-50">
          {{!cambiarPass ? 'Cambiar Contraseña' : 'No Cambiar'}}
        </button>
      </div>

      <div class="col-12 col-md-4 mb-4">
        <label class="w-100">
          Rol
          <select class="form-select" [(ngModel)]="rol">
            <option *ngFor="let rol of roles" [value]="rol.rol">{{rol.desc}}</option>
          </select>
        </label>
      </div>

      <div class="col-12 text-start mb-4" *ngIf="!nuevo">
        <div class="form-check">
          <input class="form-check-input" id="desactivadoCheck" type="checkbox" [(ngModel)]="desactivado">
          <label class="form-check-label" for="desactivadoCheck">Login Desactivado</label>
        </div>
      </div>

      <div class="col-12">
        <hr class="m-0-auto w-75 text-secondary">
      </div>

      <div class="col-12 text-end mb-2">
        <span *ngIf="mensajeError != ''">
          <strong class="text-danger">{{mensajeError}}</strong>
        </span>
      </div>

      <div class="col-12 text-end">
        <button class="btn btn-success" (click)="guardar()">Guardar</button>
      </div>

    </div>

  </div>
</div>
