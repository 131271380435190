import { Component, OnInit } from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import {JornadaLaboral} from '../../../modelos/JornadaLaboral';

@Component({
  selector: 'app-control-asistencia',
  templateUrl: './control-asistencia.component.html',
  styleUrls: ['./control-asistencia.component.css']
})
export class ControlAsistenciaComponent implements OnInit {

  public cargandoDatos: boolean;

  public year: number;
  public mes: number;
  public userSel: string;
  public meses = [
    {mes: 'Enero', n: 0, dias: 31},
    {mes: 'Febrero', n: 1, dias: 28},
    {mes: 'Marzo', n: 2, dias: 31},
    {mes: 'Abril', n: 3, dias: 30},
    {mes: 'Mayo', n: 4, dias: 31},
    {mes: 'Junio', n: 5, dias: 30},
    {mes: 'Julio', n: 6, dias: 31},
    {mes: 'Agosto', n: 7, dias: 31},
    {mes: 'Septiembre', n: 8, dias: 30},
    {mes: 'Octubre', n: 9, dias: 31},
    {mes: 'Noviembre', n: 10, dias: 30},
    {mes: 'Diciembre', n: 11, dias: 31}
    ];

  public calendario: {
    dia: number,
    fecha: string,
    tiempos: {
      fechaEntrada: Date,
      fechaSalida: Date,
      entradaDNI: boolean,
      salidaDNI: boolean
    }[],
    total: {
      horas: number,
      minutos: number,
      segundos: number
    }
  }[];
  public jornadas: JornadaLaboral [];
  public maxTiempos: number;
  public totalHoras: {horas: number, minutos: number, segundos: number};

  constructor(public crmservice: CrmService) {
    this.cargandoDatos = false;

    const hoy = new Date();

    this.year = hoy.getFullYear();
    this.mes = hoy.getMonth();
    this.userSel = this.crmservice.userLogin._id;

    this.totalHoras = {horas: 0, minutos: 0, segundos: 0};

    this.buscarFichajes();

  }

  ngOnInit() {
  }

  public buscarFichajes(): void {

    this.cargandoDatos = true;

    const inicio = new Date((this.mes + 1) + '-' + 1 + '-' + this.year);
    inicio.setHours(0, 0, 0, 0);

    const fin = new Date((this.mes + 1) + '-' + this.getDiasMes() + '-' + this.year);
    fin.setHours(23, 59, 59, 999);

    this.crmservice.getJornadasRango(this.userSel, inicio, fin).subscribe(
      valueJornadas => {
        this.jornadas = valueJornadas;

        this.maxTiempos = 0;
        this.calendario = [];

        for (const jornada of this.jornadas) {
          if (jornada.tiempos.length > this.maxTiempos) {
            this.maxTiempos = jornada.tiempos.length;
          }
        }

        const diasMes = this.getDiasMes();
        for (let i = 1; i <= diasMes; i++) {
          this.calendario.push({
            dia: i,
            fecha: (this.mes + 1) + '-' + i + '-' + this.year,
            tiempos: [],
            total: {horas: 0, minutos: 0, segundos: 0}
          });
        }

        for (const dia of this.calendario) {
          for (let i = 0; i < this.maxTiempos; i++) {
            dia.tiempos.push(null);
          }
        }

        let totalMes = 0;

        for (const jornada of this.jornadas) {
          const pos = this.calendario.findIndex(e => e.fecha === jornada.fechaJustificada);
          if (pos !== -1) {
            for (let i = 0; i < jornada.tiempos.length; i++) {
              this.calendario[pos].tiempos[i] = jornada.tiempos[i];

              if (jornada.tiempos[i].fechaEntrada != null && jornada.tiempos[i].fechaSalida != null) {
                const fechaEntrada = new Date(jornada.tiempos[i].fechaEntrada);
                const fechaSalida = new Date(jornada.tiempos[i].fechaSalida);
                const total = Math.round((fechaSalida.getTime() - fechaEntrada.getTime()) / 1000);

                // const {horas, minutos, segundos} = this.getHMS(total);
                // this.calendario[pos].total.horas += horas;
                // this.calendario[pos].total.minutos += minutos;
                this.calendario[pos].total.segundos += total;
                totalMes += total;
              }

            }
          }
        }

        for (const dia of this.calendario) {
          const {horas, minutos, segundos} = this.getHMS(dia.total.segundos);
          dia.total.horas = horas;
          dia.total.minutos = minutos;
          dia.total.segundos = segundos;
        }

        this.totalHoras.horas = this.getHMS(totalMes).horas;
        this.totalHoras.minutos = this.getHMS(totalMes).minutos;
        this.totalHoras.segundos = this.getHMS(totalMes).segundos;

        this.cargandoDatos = false;
      }, errorJornadas => {
        console.error(errorJornadas);
        this.cargandoDatos = false;
      });
  }

  public getColumnas(): number[] {
    const columnas = [];

    for (let i = 0; i < this.maxTiempos; i++) {
      columnas.push(0);
    }

    return columnas;
  }
  public getDiasMes(): number {
    if (this.mes === 1) {
      if (this.getBisiesto()) {
        return 29;
      } else {
        return 28;
      }
    } else {
      const posMes = this.meses.findIndex(e => e.n === this.mes);
      return this.meses[posMes].dias;
    }
  }
  public getBisiesto(): boolean {
    if (this.year == null) {
      return false;
    }
    return this.year % 4 === 0;
  }

  public getHMS(total: number): {horas: number, minutos: number, segundos: number} {
    let horas = 0;
    let minutos = 0;
    let segundos = total;

    while (segundos >= 60) {
      segundos = segundos - 60;
      minutos++;
    }

    while (minutos >= 60) {
      minutos = minutos - 60;
      horas++;
    }

    return {horas: horas, minutos: minutos, segundos: segundos};
  }
  public formatoHH(n: number, l: number = 2): string {
    let str = n.toString(10);
    while (str.length < l) { str = '0' + str; }
    return str;
  }

  public getColspan(): number {
    return (this.calendario.map(e => e.tiempos.length)
      .reduce((a, b) => a >= b ? a : b) * 3) + 2;
  }
}
