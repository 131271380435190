<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>NUEVA ACCIÓN</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div
    *ngIf="!guardandoDatos"
    [ngClass]="
      !mobileQuery.matches
        ? 'contenedor-formulario'
        : 'contenedor-formulario-movil'
    "
  >
    <!-- FORMULARIO DE NUEVA ACCIÓN -->
    <mat-accordion class="headers-align">
      <!-- PRIMER PASO - DATOS BÁSICOS -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 0"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Datos básicos </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">flag</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Dirección*</mat-label>
              <mat-select
                [(ngModel)]="accion1.direccion"
                placeholder="Dirección*"
              >
                <mat-option
                  *ngFor="let direccion of direcciones"
                  value="{{ direccion._id }}"
                >
                  {{ direccion.nombreComercial }} ({{ direccion.poblacion }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Contacto*</mat-label>
              <mat-select
                [(ngModel)]="accion1.contactos"
                placeholder="Contacto*"
                [disabled]="accion1.direccion == null"
                multiple
              >
                <ng-container *ngFor="let persona of personas">
                  <mat-option
                    *ngIf="mostrarPersona(persona, accion1.direccion)"
                    value="{{ persona._id }}"
                  >
                    {{ persona.nombre }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Hito*</mat-label>
              <mat-select [(ngModel)]="accion1.hito" placeholder="Hito*">
                <ng-container *ngFor="let hito of crmservice.hitos">
                  <mat-option *ngIf="mostrarHito(hito)" value="{{ hito.id }}">
                    {{ hito.nombre }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Objetivo*</mat-label>
              <mat-select
                [(ngModel)]="accion1.objetivo"
                placeholder="Objetivo*"
              >
                <mat-option
                  *ngFor="let objetivo of crmservice.objetivos"
                  value="{{ objetivo.id }}"
                >
                  {{ objetivo.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Medio*</mat-label>
              <mat-select
                [(ngModel)]="accion1.medio"
                placeholder="Medio*"
                multiple
              >
                <mat-option
                  *ngFor="let medio of crmservice.medios"
                  value="{{ medio.id }}"
                >
                  {{ medio.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div
            *ngIf="accion1.hito == '13' && accion1.objetivo == '15'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Curso*</mat-label>
              <mat-select [(ngModel)]="campusA1.curso" placeholder="Curso*">
                <mat-option
                  *ngFor="let curso of crmservice.cursosCampus"
                  value="{{ curso }}"
                >
                  Curso - {{ curso }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div
            *ngIf="
              accion1.hito == '13' &&
              (accion1.objetivo == '15' ||
                accion1.objetivo == '16' ||
                accion1.objetivo == '17')
            "
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Curso*</mat-label>

              <mat-select
                [(ngModel)]="campusA1.curso"
                placeholder="Curso*"
                (selectionChange)="filtrarBloquesCursoCampus($event.value)"
              >
                <mat-option
                  *ngFor="let curso of crmservice.cursosBloquesCampus"
                  value="{{ curso.curso }}"
                >
                  Curso - {{ curso.curso }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="
              accion1.hito == '13' &&
              (accion1.objetivo == '15' || accion1.objetivo == '16') &&
              bloquesCursoSeleccionado.length > 0
            "
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Bloque*</mat-label>
              <mat-select [(ngModel)]="campusA1.bloque" placeholder="Bloque*">
                <mat-option
                  *ngFor="let bloque of bloquesCursoSeleccionado"
                  value="{{ bloque }}"
                >
                  Bloque - {{ bloque }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="accion1.hito == '6' && accion1.objetivo == '4'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Bloque*</mat-label>
              <mat-select
                [(ngModel)]="formacionA1.bloque"
                placeholder="Bloque*"
              >
                <mat-option
                  *ngFor="let bloque of crmservice.bloquesFormacion3D"
                  value="{{ bloque }}"
                >
                  Bloque - {{ bloque }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="accion1.hito == '6' && accion1.objetivo == '4'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Tipo*</mat-label>
              <mat-select [(ngModel)]="formacionA1.tipo" placeholder="Tipo*">
                <mat-option value="teoria">Teoría</mat-option>
                <mat-option value="practica">Práctica</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>

        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep(1)">
            Prevista
          </button>
          <button
            *ngIf="
              crmservice.userLogin.rol != 'asesor' || !crmservice.modoPruebas
            "
            mat-button
            color="primary"
            (click)="nextStep(2)"
          >
            Finalizada
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- SEGUNDO PASO - ACCIÓN PREVISTA -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 1"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Acción prevista </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">date_range</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha prevista*</mat-label>
              <label class="w-100 m-0">
                <input
                  [matDatepicker]="previstaA1"
                  [(ngModel)]="fechaPrevistaA1"
                  matInput
                  placeholder="Fecha prevista*"
                />
              </label>
              <mat-datepicker-toggle
                matSuffix
                [for]="previstaA1"
              ></mat-datepicker-toggle>
              <mat-datepicker #previstaA1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Hora prevista</mat-label>
              <label class="w-100 m-0">
                <input
                  [(ngModel)]="horaPrevistaA1"
                  type="time"
                  matInput
                  placeholder="Hora prevista"
                />
              </label>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Observaciones*</mat-label>
              <label class="w-100 m-0">
                <textarea
                  [(ngModel)]="accion1.observaciones"
                  matTextareaAutosize
                  matAutosizeMinRows="1"
                  matInput
                  placeholder="Observaciones*"
                ></textarea>
              </label>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(0)">
            Volver
          </button>
          <button mat-button color="primary" (click)="guardarPrevista()">
            Guardar
          </button>
          <!-- La opción 'enviar a' de momento no va a estar disponible para las acciones cambpus -->
          <button
            *ngIf="accion1.hito !== '13'"
            mat-button
            color="primary"
            (click)="nextStep(6)"
          >
            Enviar a
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- TERCER PASO - FECHA DE REALIZACIÓN -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 2"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Fecha de realización </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">event_note</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha*</mat-label>
              <label class="w-100 m-0">
                <input
                  [matDatepicker]="inicioA1"
                  [(ngModel)]="fechaInicioA1"
                  matInput
                  placeholder="Fecha*"
                />
              </label>
              <mat-datepicker-toggle
                matSuffix
                [for]="inicioA1"
              ></mat-datepicker-toggle>
              <mat-datepicker #inicioA1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Hora de inicio*</mat-label>
              <label class="w-100 m-0">
                <input
                  type="time"
                  [(ngModel)]="horaInicioA1"
                  matInput
                  placeholder="Hora de inicio*"
                />
              </label>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Hora de fin*</mat-label>
              <label class="w-100 m-0">
                <input
                  type="time"
                  [(ngModel)]="horaFinA1"
                  matInput
                  placeholder="Hora de fin*"
                />
              </label>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(0)">
            Volver
          </button>
          <button mat-button color="primary" (click)="nextStep(3)">
            Siguiente
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- CUARTO PASO  - RESULTADO-->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 3"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Resultado </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">thumbs_up_down</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Resultado*</mat-label>
              <mat-select
                [(ngModel)]="accion1.resultado"
                placeholder="Resultado*"
              >
                <mat-option
                  *ngFor="let resultado of crmservice.resultados"
                  value="{{ resultado.id }}"
                >
                  {{ resultado.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Observaciones*</mat-label>
              <label class="w-100 m-0">
                <textarea
                  [(ngModel)]="accion1.observaciones"
                  matTextareaAutosize
                  matAutosizeMinRows="1"
                  matInput
                  placeholder="Observaciones*"
                ></textarea>
              </label>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>

        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(2)">
            Volver
          </button>
          <button
            mat-button
            color="primary"
            *ngIf="mostrarFinalizar()"
            (click)="guardarFinalizada()"
          >
            Finalizar
          </button>
          <button
            mat-button
            color="primary"
            *ngIf="
              (accion1.resultado == '0' || accion1.resultado == '-1') &&
              accion1.hito !== '13'
            "
            (click)="nextStep(4)"
          >
            Trasladar a
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- QUINTO PASO - DATOS DE LA NUEVA ACCIÓN -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 4"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Datos de la nueva acción </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">outlined_flag</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Objetivo*</mat-label>
              <mat-select
                [(ngModel)]="accion2.objetivo"
                placeholder="Objetivo*"
              >
                <mat-option
                  *ngFor="let objetivo of crmservice.objetivos"
                  value="{{ objetivo.id }}"
                >
                  {{ objetivo.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Medio*</mat-label>
              <mat-select
                [(ngModel)]="accion2.medio"
                placeholder="Medio*"
                multiple
              >
                <mat-option
                  *ngFor="let medio of crmservice.medios"
                  value="{{ medio.id }}"
                >
                  {{ medio.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Contacto*</mat-label>
              <mat-select
                [(ngModel)]="accion2.contactos"
                placeholder="Contacto*"
              >
                <ng-container *ngFor="let persona of personas">
                  <mat-option
                    *ngIf="mostrarPersona(persona, accion1.direccion)"
                    value="{{ persona._id }}"
                  >
                    {{ persona.nombre }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="accion1.hito == '6' && accion2.objetivo == '4'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Bloque*</mat-label>
              <mat-select
                [(ngModel)]="formacionA2.bloque"
                placeholder="Bloque*"
              >
                <mat-option
                  *ngFor="let bloque of crmservice.bloquesFormacion3D"
                  value="{{ bloque }}"
                >
                  Bloque - {{ bloque }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="accion1.hito == '13' && accion1.objetivo == '15'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Cursos*</mat-label>
              <mat-select [(ngModel)]="campusA1.curso" placeholder="Curso*">
                <mat-option
                  *ngFor="let curso of crmservice.cursosCampus"
                  value="{{ curso }}"
                >
                  Curso - {{ curso }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="accion1.hito == '6' && accion2.objetivo == '4'"
            class="col-12 col-md-6"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Tipo*</mat-label>
              <mat-select [(ngModel)]="formacionA2.tipo" placeholder="Tipo*">
                <mat-option value="teoria">Teoría</mat-option>
                <mat-option value="practica">Práctica</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha prevista*</mat-label>
              <label class="w-100 m-0">
                <input
                  [matDatepicker]="previstaA2"
                  [(ngModel)]="fechaPrevistaA2"
                  matInput
                  placeholder="Fecha prevista*"
                />
              </label>
              <mat-datepicker-toggle
                matSuffix
                [for]="previstaA2"
              ></mat-datepicker-toggle>
              <mat-datepicker #previstaA2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Hora prevista</mat-label>
              <label class="w-100 m-0">
                <input
                  [(ngModel)]="horaPrevistaA2"
                  type="time"
                  matInput
                  placeholder="Hora prevista"
                />
              </label>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(3)">
            Volver
          </button>
          <button mat-button color="primary" (click)="nextStep(5)">
            Siguiente
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- SEXTO PASO - DESCRIPCIÓN NUEVA ACCIÓN -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 5"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Descripción nueva acción </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">people_outline</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Asesores</mat-label>
              <mat-select
                [(ngModel)]="asesores"
                placeholder="Asesores"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'asesor' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Pedidos</mat-label>
              <mat-select [(ngModel)]="pedidos" placeholder="Pedidos" multiple>
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'pedidos' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Comerciales</mat-label>
              <mat-select
                [(ngModel)]="comerciales"
                placeholder="Comerciales"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      (usuario.rol == 'comercial' ||
                        usuario.rol == 'influencer') &&
                      usuario.desactivado != true &&
                      (usuario.team == dameEquipo() ||
                        (usuario.nombreUsuario == 'aperales' &&
                          dameEquipo() == 'E'))
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Supervisores</mat-label>
              <mat-select
                [(ngModel)]="supervisores"
                placeholder="Supervisores"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'supervisor' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Descripción de la siguiente acción</mat-label>
              <label class="w-100 m-0">
                <textarea
                  [(ngModel)]="accion2.observaciones"
                  matTextareaAutosize
                  matAutosizeMinRows="1"
                  matInput
                  placeholder="Descripción de la siguiente acción*"
                ></textarea>
              </label>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(4)">
            Volver
          </button>
          <button mat-button color="primary" (click)="guardarTrasladada()">
            Guardar
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- SÉPTIMO PASO - ENVIAR A -->
      <mat-expansion-panel
        class="bg-white"
        [expanded]="step === 6"
        disabled
        hideToggle="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Enviar a </mat-panel-title>
          <mat-panel-description>
            <span> </span>
            <mat-icon color="primary">people_outline</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Asesores</mat-label>
              <mat-select
                [(ngModel)]="asesoresEnviar"
                placeholder="Asesores"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'asesor' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Pedidos</mat-label>
              <mat-select
                [(ngModel)]="pedidosEnviar"
                placeholder="Pedidos"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'pedidos' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Comerciales</mat-label>
              <mat-select
                [(ngModel)]="comercialesEnviar"
                placeholder="Comerciales"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      (usuario.rol == 'comercial' ||
                        usuario.rol == 'influencer') &&
                      usuario.desactivado != true &&
                      (usuario.team == dameEquipo() ||
                        (usuario.nombreUsuario == 'aperales' &&
                          dameEquipo() == 'E') ||
                        dameEquipo() == '1')
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Supervisores</mat-label>
              <mat-select
                [(ngModel)]="supervisoresEnviar"
                placeholder="Supervisores"
                multiple
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option
                    *ngIf="
                      usuario.rol == 'supervisor' && usuario.desactivado != true
                    "
                    value="{{ usuario._id }}"
                  >
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="mensajeError != ''" class="col-12 text-end">
            <span class="text-danger">
              <strong>{{ mensajeError }}</strong>
            </span>
          </div>
        </div>

        <mat-action-row>
          <button mat-button color="primary" (click)="prevStep(1)">
            Volver
          </button>
          <button mat-button color="primary" (click)="guardarEnviadaA()">
            Guardar
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
