import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {EditarUsuarioBalderComponent} from './editar-usuario-balder/editar-usuario-balder.component';
import {UsuarioBalder} from '../../../modelos/UsuarioBalder';
import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {DownloadService} from '../../../servicios/DownloadService';

@Component({
  selector: 'app-balder-usuarios',
  templateUrl: './balder-usuarios.component.html',
  styleUrls: ['./balder-usuarios.component.css']
})
export class BalderUsuariosComponent implements OnInit, AfterViewInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;
  public cargandoDatos = false;

  public dataSource: MatTableDataSource<UsuarioBalder> = new MatTableDataSource();
  public displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'fechaCreacion'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.mobileQuery.matches) {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'fechaCreacion'];
    } else {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol'];
    }
  }

  constructor(public crmservice: CrmService,
              private ds: DownloadService,
              public dialog: MatDialog,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public router: Router) {

    if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol !== 'admin' && this.crmservice.userLogin.rol !== 'supervisor') {
      this.router.navigate(['agenda']).then();
    }

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (!this.mobileQuery.matches) {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'fechaCreacion'];
    } else {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol'];
    }

    this.crmservice.balder_getAllUsuarios().subscribe(
      valueUsuarios => {
        this.dataSource.data = valueUsuarios;
      }, error1 => {
        console.log(error1);
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public editarUsuario(nuevo: boolean = true, usuario: UsuarioBalder = null): void {

    let width: string;
    let heigth: string;

    if (!this.mobileQuery.matches) {
      width = '50%';
      heigth = 'auto';
    } else {
      width = '95%';
      heigth = '95%';
    }

    const dialogRef = this.dialog.open(EditarUsuarioBalderComponent, {
      width: width,
      height: heigth,
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        nuevo: nuevo,
        usuario: usuario
      }
    });

    dialogRef.afterClosed().subscribe(
      value => {
        if (value != null) {
          this.crmservice.balder_getAllUsuarios().subscribe(
            valueUsuarios => {
              this.dataSource.data = valueUsuarios;
            }, error1 => {
              console.log(error1);
            });
        }
      });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public descargarApkDescargas(): void {
    // this.ds.descargarBalderDes();
    window.open(this.crmservice.urlBalder + '/update/balderDes.apk', '_blank');
  }
  public descargarApkDescargasNueva(): void {
    window.open(this.crmservice.urlBalder + '/update/balderDes_110.apk', '_blank');
  }
  public descargarApkInventario(): void {
    // this.ds.descargarBalderInv();
    window.open(this.crmservice.urlBalder + '/update/balderInv.apk', '_blank');
  }
  public descargarApkRecuperacion(): void {
    // this.ds.descargarBalderRec();
    window.open(this.crmservice.urlBalder + '/update/balderRec.apk', '_blank');
  }
}
