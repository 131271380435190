import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

class Input {
  id: string;
  placeholder: string|null;
  required: boolean;
  type: string;
  constructor(nId: string, nPlaceholder: string, nRequired: boolean, nType: string) {
    this.id = nId;
    this.placeholder = nPlaceholder || null;
    this.required = nRequired || false;
    this.type = nType || 'text';
  }
}
class Select {
  id: string;
  placeholder: string|null;
  required: boolean;
  options: {text: string, value: any}[];
  constructor(nId: string, nPlaceholder: string, nRequired: boolean, nOptions: {text: string, value: any}[]) {
    this.id = nId;
    this.placeholder = nPlaceholder || null;
    this.required = nRequired || false;
    this.options = nOptions || [];
  }
}
type Control = Input|Select;

@Component({
  selector: 'app-dialog-confirmacion',
  templateUrl: './dialog-confirmacion.component.html',
  styleUrls: ['./dialog-confirmacion.component.css']
})
export class DialogConfirmacionComponent implements OnInit {

  public textoPrincipal: string;
  public textoSecundario: {texto: string, css: string}[];
  public controls: Control[];
  public btnConfirm: string;
  public btnCancel: string;
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private snakbar: MatSnackBar,
              public dialogRef: MatDialogRef<DialogConfirmacionComponent>) {
    this.textoPrincipal = this.data.textoPrincipal || 'Sin título';
    this.textoSecundario = this.data.textoSecundario || [];
    this.btnConfirm = this.data.btnConfirm || 'SI';
    this.btnCancel = this.data.btnCancel || 'NO';
    this.controls = [];

    if (this.data.inputs) {
      for (const input of this.data.inputs) {
        if (!this.controls.some(c => c.id === input.id)) {
          this.controls.push(new Input(input.id, input.placeholder, input.required, input.type));
        }
      }
    }
    if (this.data.selects) {
      for (const select of this.data.selects) {
        if (!this.controls.some(c => c.id === select.id)) {
          this.controls.push(new Select(select.id, select.placeholder, select.required, select.options));
        }
      }
    }

    this.formGroup = this.fb.group(
      this.controls.reduce((fromGroup, control) => { fromGroup[control.id] = [null, control.required ? Validators.required : []]; return fromGroup; }, {})
    );
  }

  ngOnInit(): void {
  }

  public confirmar(): void {
    if (this.comprobarFormulario()) {
      this.dialogRef.close({
        aceptar: true,
        inputValues: this.controls.reduce((iv, control) => { iv[control.id] = this.formGroup.controls[control.id].value; return iv; }, {})
      });
    } else {
      this.snakbar.open('Hay campos obligatorios sin completar', 'Ok', {duration: 3500});
    }
  }

  public isInput(control: Control): boolean {
    return control instanceof Input;
  }
  public isSelect(control: Control): boolean {
    return control instanceof Select;
  }

  private comprobarFormulario(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

}
