import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AlertaCliente, AlertaClienteRow} from '../../../../modelos/AlertaCliente';
import {CrmService} from '../../../../servicios/crm.service';

@Component({
  selector: 'app-alertas-cliente-detalle',
  templateUrl: './alertas-cliente-detalle.component.html',
  styleUrls: ['./alertas-cliente-detalle.component.css']
})
export class AlertasClienteDetalleComponent implements OnInit {

  public cargandoDatos: boolean;
  public alertasCliente: AlertaCliente[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              public crmservice: CrmService,
              private snackBar: MatSnackBar) {

    this.cargandoDatos = false;

    this.alertasCliente = data.alertasCliente;

    const alertasVistas: string[] = [];
    this.alertasCliente.forEach(alerta => {
      if (!alerta.visto.includes(this.crmservice.userLogin._id)) {
        alertasVistas.push(alerta._id);
        alerta.visto.push(this.crmservice.userLogin._id);
      }
    });

    if (alertasVistas.length) {
      this.crmservice.addVistoAlertas(alertasVistas).subscribe();
    }

  }

  ngOnInit() {
  }

  public revisar(): void {
    // Se envian los id de las alertas del cliente.
    // Se comprueban en el servidor y se devuelven los id
    // de aquellas que ya se han resuelto.
    this.cargandoDatos = true;
    this.crmservice.revisarAlertasCliente(this.alertasCliente[0].cliente).subscribe(idAlertas => {
      idAlertas.forEach(id => {
        let pos: number = this.alertasCliente.findIndex(e => e._id === id);
        if (pos !== -1) {
          this.alertasCliente.splice(pos, 1);
        }
        pos = this.crmservice.alertasClientes.findIndex(e => e._id === id);
        if (pos !== -1) {
          this.crmservice.alertasClientes.splice(pos, 1);
        }
      });
      this.snackBar.open('Se han corregido ' + idAlertas.length + ' alertas', 'OK', {duration: 3000});
      this.cargandoDatos = false;
    }, error => {
      console.error(error);
      this.cargandoDatos = false;
    });
  }

  public getUsuario(idUsuario: string): string {
    const usuario = this.crmservice.usuarios.find(u => u._id === idUsuario);
    return (usuario != null ? (usuario.nombre + ' ' + usuario.apellidos) : 'Desconocido');
  }

  public adquirirAlerta(alerta: AlertaCliente): void {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Gestionar esta alerta?',
      btnConfirm: 'ADQUIRIR',
      btnCancel: 'CANCELAR'
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.addGestionado(alerta._id).subscribe(idUser => {
          alerta.gestionado = idUser;
          this.snackBar.open(
            (idUser === this.crmservice.userLogin._id) ? 'Has adquirido la alerta' : 'Esta alerta ya ha sido adquirida por ' + this.getUsuario(idUser),
            'Ok',
            {duration: 3000}
          );
        }, error => console.error(error));
      }
    });
  }
  public rechazarAlerta(alerta: AlertaCliente): void {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Dejar de gestionar esta alerta?',
      btnConfirm: 'DEJAR LIBRE',
      btnCancel: 'CANCELAR'
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.removeGestionado(alerta._id).subscribe(() => {
          alerta.gestionado = null;
          this.snackBar.open('Has dejado de gestionar la alerta', 'Ok', {duration: 3000});
        }, error => console.error(error));
      }
    });
  }
}
