import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../usuarios/usuarios.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {Actualizacion} from '../../modelos/Actualizacion';

@Component({
  selector: 'app-notas-actualizacion',
  templateUrl: './notas-actualizacion.component.html',
  styleUrls: ['./notas-actualizacion.component.css']
})
export class NotasActualizacionComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargando: boolean;

  public actualizaciones: Actualizacion[];
  public actualizacionSel: Actualizacion;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    console.log('NotasActualizacion');

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargando = true;

    this.crmservice.getListVersiones().subscribe(actualizaciones => {
      this.actualizaciones = actualizaciones;
      const pos = this.actualizaciones.findIndex(e => e.version === this.crmservice.version);
      if (pos !== -1) {
        this.actualizacionSel = this.actualizaciones[pos];
        if (!this.userHasChecked(this.actualizacionSel)) {
          this.actualizacionSel.usuarios.push(this.crmservice.userLogin._id);
          this.crmservice.setUserCheckedActualizacion(this.actualizacionSel.version, this.crmservice.userLogin._id).subscribe(
            () => {
              this.cargando = false;
              this.crmservice.userCheckActualizacion = true;
            }, error2 => {
              console.error(error2);
              this.cargando = false;
            });
        } else {
          this.cargando = false;
        }
      } else {
        this.cargando = false;
      }
    }, errorAct => {
      console.error(errorAct);
      this.cargando = false;
    });
  }

  ngOnInit() {
  }

  public getNombreUsuario(id: string): string {
    const pos = this.crmservice.usuarios.findIndex(e => e._id === id);
    if (pos !== -1) {
      return this.crmservice.usuarios[pos].nombreUsuario;
    }
    return 'Desconocido';
  }
  public userHasChecked(actualizacion: Actualizacion): boolean {
    return actualizacion.usuarios.findIndex(e => e === this.crmservice.userLogin._id) !== -1;
  }
  public cambiarVersion(): void {
    if (!this.userHasChecked(this.actualizacionSel)) {
      this.cargando = true;
      this.actualizacionSel.usuarios.push(this.crmservice.userLogin._id);
      this.crmservice.setUserCheckedActualizacion(this.actualizacionSel.version, this.crmservice.userLogin._id).subscribe(
        () => {
          this.cargando = false;
        }, error => {
          console.error(error);
          this.cargando = false;
        });
    }
  }

}


/**
 <<--- Resumen de Versiones --->>

  - V2.4.2: Versión estable cuando me incorporé a la empresa en Julio de 2019.

  - V2.5: Esta actualización tiene como principal objetivo restringir y acotar los permisos de edición de cada usuario y
  corregir errores en los datos de todos los clientes. Se crean las cajas de muestras.

  - V2.6: Esta actualización tiene como objetivo implantar el Gestor de Tiempos (nodeCRM) y mostrar graficas
  sobre los tiempos (apartado Rendimiento).

  - V2.7: Principales añadidos en esta versión: apartado de admin. Crm que recoge los Calendarios, Usuarios, Rankings de Clientes,
  Gestor de Acciones, Rendimiento y Gestión de usuarios de Balder APP. También se adapta gran parte del contenido del CRM a
  dispositivos móviles.

 - V2.8: Versión de Diseñador Virtual. La mayor parte de los cambios van orientados al proyecto de Diseañdor Virtual:
 formación, codigos postales, hitos, etc. También se ha mejorado la seguridad de la aplicación (tanto cliente como servidor)
 y se ha pulido el codigo para hacerlo más legible. Se ha reescrito gran parte del código del anterior programador.

 - V2.9: Sistema de alertas de cliente, tipos de cliente, migrar Angular y Bootstrap a versiones más recientes.

 - 2.10: Se ha cambiado la manera de gestionar la facturación de los clientes. Ahora los clientes no tienen un codigoCliente y codigoClienteB, sino
 que tienen asociada una lista de codigos de cliente. Otros cambios menores en la interfaz para que se adapte mejor en dispositivos móviles y se vea
 mejor en general.
 **/

/*
- Notas de nuevo desarrollador:
  La versión actual es v2.10.18.
  El cambio de número de versión se hace "hard coded" en la línea 25 del archivo crm.service.ts.
  Las notas de cambio de versión se guardan en la BBDD, con un formulario que sólo puede ver el usuario 
  definido en la línea 171 de app.component.html, que se muestra en el menú de la esquina superior derecha.
*/  
