import { Injectable } from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient, HttpEventType} from '@angular/common/http';

type Status = 'PENDIENTE' | 'DESCARGANDO' | 'TERMINADA' | 'INTERRUMPIDA' | 'ELIMINADA';

export class Download {
  public readonly id: string;
  public readonly file: string;
  public request?: Subscription | null;
  public loaded: number;
  public total: number;
  public estado: Status;

  constructor(nId: string, nFile: string, nLoaded: number, nTotal: number, nEstado: Status) {
    this.id = nId;
    this.file = nFile;
    this.loaded = nLoaded;
    this.total = nTotal;
    this.estado = nEstado;
    this.request = null;
  }

  public stopDownload(): void {
    if (this.estado === 'DESCARGANDO' && this.request != null) {
      this.request.unsubscribe();
    }
    this.estado = 'INTERRUMPIDA';
  }
  public removeDownload(): void {
    this.estado = 'ELIMINADA';
  }
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private n: number;

  private _downloads: Download[];
  public urlServer: string;
  public urlServerBalder: string;
  public abrirGestorDescargas: boolean;

  constructor(public http: HttpClient) {
    this.urlServer = 'https://mueblesdormacrm.com:3008';
    this.urlServerBalder = 'https://mueblesdormacrm.com:3010';
    this._downloads = [];
    this.abrirGestorDescargas = false;
    this.n = 0;
  }

  public descargarBalderDes(): void {
    this._downloadFile('balderDes.apk', this.urlServerBalder + '/app/balderDes');
  }
  public descargarBalderRec(): void {
    this._downloadFile('balderRec.apk', this.urlServerBalder + '/app/balderRec');
  }
  public descargarBalderInv(): void {
    this._downloadFile('balderInv.apk', this.urlServerBalder + '/app/balderInv');
  }

  private _downloadFile(fileName: string, url: string): void {
    const download = this._newDownload(fileName);
    download.request = this.http.get(url, {reportProgress: true, observe: 'events', responseType: 'blob' as 'json'}).subscribe(
      (response: any) => {

        if (response.type === HttpEventType.DownloadProgress) {
          download.estado = 'DESCARGANDO';
          download.loaded = response.loaded;
          download.total = response.total;
        }

        if (response.type === HttpEventType.Response) {
          this._genDownload(response.body, download.file).then(
            () => download.estado = 'TERMINADA'
          );
        }
      });
  }
  private _genDownload(data: Blob, nombre: string): Promise<string> {
    return new Promise(resolve => {
      const dataType = data.type;
      const binaryData = [];
      binaryData.push(data);

      const objectURL = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));

      const downloadLink = document.createElement('a');
      downloadLink.href = objectURL;
      downloadLink.setAttribute('download', nombre);

      document.body.appendChild(downloadLink);
      downloadLink.click();

      window.URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);

      resolve('OK');
    });
  }
  private _newDownload(file: string): Download {
    const download: Download = new Download(this.n + '_' + file, file, 0, 0, 'PENDIENTE');
    this._downloads.push(download);
    this.abrirGestorDescargas = true;
    this.n++;
    return download;
  }

  public getDownloadById(id: string): Download | null {
    return this._downloads.find(e => e.id === id);
  }

  public get numDescargando(): number {
    return this._downloads.filter(e => e.estado === 'DESCARGANDO').length;
  }
  public truncar(n: number, l: number = 2): string {
    const nStr = n.toString(10);
    const int = nStr.split('.')[0];
    let dec = nStr.split('.')[1];
    if (dec == null) {
      dec = '';
    } else {
      dec = ',' + dec.substr(0, l);
    }

    return int + dec;
  }
  public magnitud(n: number): 'kb' | 'mb' {
    if (n < 9999) {
      return 'kb';
    } else {
      return 'mb';
    }
  }

  public get downloads(): Download[] {
    return this._downloads.filter(e => e.estado !== 'ELIMINADA').reverse();
  }
}
