import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {EncargosList} from '../../modelos/EncargosList';
import {PageEvent} from '@angular/material/paginator';


@Component({
  selector: 'app-supervision-influencers',
  templateUrl: './supervision-influencers.component.html',
  styleUrls: ['./supervision-influencers.component.css']
})


export class SupervisionInfluencersComponent implements OnInit, AfterViewInit {

  paginatorPage : number
  arrDirecciones = []

  displayedColumns = ['razonSocial', 'equipo', 'cliente.cif', 'cliente.poblacion', 'cliente.provincia', 'cliente.fechaCreacion', 'fechaRegistroEquipo', 'ultimoHito', 'fechaUltimoHito', 'fechaUltimaAccion', 'rolUltimaAccion'];
  public dataSource: MatTableDataSource<EncargosList> = new MatTableDataSource();
  public cargando: boolean;
  public textoBusqueda: any = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;


  applyFilter(filterValue:string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.paginatorPage = 0      
    }  
  
  constructor(public crmservice: CrmService,

    ) { 
    this.cargando = false;
    this.textoBusqueda = '';
    // this.getTodosPresupuestosPedidosDV()

    if (this.textoBusqueda.trim().length) {
      this.applyFilter(this.textoBusqueda)
    }

    this.crmservice.getTodasDireccionesTiendaComercialTieneInfluencerConDatosCliente().subscribe((data)=>{
      this.arrDirecciones = data;
      this.arrDirecciones = this.arrDirecciones.filter(item=> (!this.comprobarTieneAccionesConRegistroPosteriorUnDia(item) || !this.comprobarTieneAccionesRolAdminSupervisorPedidosTrasPeriodo(item)) );
      this.dataSource.data = this.arrDirecciones;
    })
    
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
  }

  private calcularTiempoTranscurrido(fecha) {
    let ahora = Date.now();
    let tiempoTranscurrido = ahora - Date.parse(fecha);

    return tiempoTranscurrido;
  }

  private comprobarTieneAccionesConRegistroPosteriorUnDia(item) {
    
    if (this.calcularTiempoTranscurrido(item.fechaRegistroEquipo) > 1000 * 60 * 60 * 24) {
      
      if (item.acciones.length > 0) {
        return true
      } else {
        return false
      }

    } else {
      return true
    }

  }


  private comprobarTieneAccionesRolAdminSupervisorPedidosTrasPeriodo(item) {
    
    // Si no han pasado más de 3 días devuelve true (true hace ).
    if (this.calcularTiempoTranscurrido(item.fechaRegistroEquipo) > 1000 * 60 * 60 * 24 * 3) {
      
      //Si no hay acciones devuelve false
      if (item.acciones.length > 0) {
        
        // Filtra acciones que sean realizadas por admins, supervisores, pedidos o asesores,
        let arrAccionesDorma = item.acciones.filter(element => element.de[0].rol === 'admin' || element.de[0].rol === 'supervisor' || element.de[0].rol === 'pedidos' || element.de[0].rol === 'asesor')  

        // Si no hay acciones filtradas devuelve false
        if (arrAccionesDorma.length > 0) {
          return true
        } else {
          return false
        }

      } else {
        return false
      }

    } else {
      return true
    }

  }
   

    public getPageEvent($event: any): void {
      this.paginatorPage = $event.pageIndex;
    }
    
  }

