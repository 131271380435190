import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../usuarios/usuarios.component';
import {Cliente} from '../../modelos/Cliente';
import {Direccion} from '../../modelos/Direccion';
import {MediaMatcher} from '@angular/cdk/layout';
import {MapaserviceService} from '../../servicios/mapaservice.service';

@Component({
  selector: 'app-cliente-formulario',
  templateUrl: './cliente-formulario.component.html',
  styleUrls: ['./cliente-formulario.component.css']
})
export class ClienteFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cliente: Cliente = new Cliente();
  public direccion: Direccion = new Direccion();
  public cargando: boolean;
  public cifRepetido: boolean;
  public cifRegistrable: string;
  public id_cifRepetido: string;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              public mapaservice: MapaserviceService,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    console.log('ClienteFormulario');

    this.cargando = true;
    this.cifRepetido = false;
    this.cifRegistrable = 'indefinido';

    this.cliente.tipoCliente = {tipo: 'distribuidor', categoria: 'tienda'};

    this.direccion.equipo = (this.crmservice.userLogin.rol === 'comercial' || this.crmservice.userLogin.rol === "influencer" ) ? this.crmservice.userLogin.team : null;
    this.direccion.estado = 'candidato';
    this.direccion.verMapa = true;
    this.direccion.tipoDir = '0';

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.crmservice.getAllCifsClientesIpDorma().subscribe(value=>{
      this.crmservice.clientesIpdormaCif = value;
    })

    this.crmservice.dameClientesCIFRazonSocial().subscribe(value => {
        this.crmservice.clientesCIFRazonSocial = value;

        if (this.crmservice.codigosPostales == null || !this.crmservice.codigosPostales.length) {
          this.crmservice.getAllCP().subscribe(
            valueCP => {
              this.crmservice.codigosPostales = valueCP;
              this.cargando = false;
            }, err => {
              console.error(err);
              this.cargando = false;
            });
        } else {
          this.cargando = false;
        }
      }, error1 => {
        console.error(error1);
        this.cargando = false;
      });
  }

  ngOnInit() {
  }

  public async guardar(): Promise<void> {
    let tieneCifRepetido: boolean;
    tieneCifRepetido = await this.comprobacionCIFRepetido(); // Aquí comprobamos tanto si ya hay registro de cif en direcciones como en ipdormas (Pero si hay relación con alguna dirección, si nó devuelve 'false' y podemos hacer registro)

    this.comprobarFormulario() // Validación de los datos del formulario
      .then(() => {
        
        this.cargando = true;
        
        if (!tieneCifRepetido) {
          this.cliente.usuarioCreador = this.crmservice.userLogin.nombreUsuario;
          this.direccion.usuarioCreador = this.crmservice.userLogin.nombreUsuario;
          this.direccion.observaciones = this.cliente.observaciones;

          if (this.cliente.cif != null && this.cliente.cif.trim().length) {
            this.cliente.cif = this.cliente.cif.toUpperCase().replace(/[\s\-]+/g, '');
          }

          // Pasar a mayúsculas los campos input text
          this.cliente.razonSocial = this.normalizarTexto(this.cliente.razonSocial);
          this.cliente.observaciones = this.normalizarTexto(this.cliente.observaciones);
          this.direccion.nombreComercial = this.normalizarTexto(this.direccion.nombreComercial);
          this.direccion.poblacion = this.normalizarTexto(this.direccion.poblacion);
          this.direccion.direccionDetalle = this.normalizarTexto(this.direccion.direccionDetalle);
          this.direccion.webEmpresa = this.normalizarTexto(this.direccion.webEmpresa);
          this.direccion.observaciones = this.normalizarTexto(this.direccion.observaciones);

          // Añadir los valores del influencer si el rol del usuario es de tipo influencer
          if (this.crmservice.userLogin.rol === 'influencer') {
            this.direccion.tieneInfluencer = true;
          }
          
          // Añadimos los valores del comercial si el rol de usuario es de tipo comercial
          if (this.crmservice.userLogin.rol === 'comercial') {
            this.direccion.tieneComercial = true;
          }
          
          this.direccion.fechaRegistroEquipo = new Date();
          this.direccion.rolRegistroEquipo = this.crmservice.userLogin.rol;
          this.direccion.trackingRegistroEquipo = [{ idUsuario: this.crmservice.userLogin._id, rol: this.crmservice.userLogin.rol, fechaRegistro: this.direccion.fechaRegistroEquipo }];
          
          this.crmservice.nuevoCliente(this.cliente, this.direccion).subscribe(value => {
            if (value) {
              this.cliente._id = value.cliente._id;
              this.cliente.direcciones = value.cliente.direcciones;
              this.direccion._id = value.direccion._id;

              this.crmservice.direcciones.push(this.direccion);

              const pos = this.crmservice.direcciones.findIndex(e => e._id === this.direccion._id);
              this.crmservice.direcciones[pos].cif = this.cliente.cif;
              this.crmservice.direcciones[pos].razonSocial = this.cliente.razonSocial;

              this.cargando = false;
              this.mostrarError('Cliente guardado correctamente');

              this.mapaservice.generarCoordenadas(this.direccion).then(() => this.dialogRef.close(this.direccion));
            }
          })
        } else if (this.crmservice.userLogin.rol === 'influencer') {

          // Buscamos el cliente en la BBDD
          this.crmservice.getCliente(this.id_cifRepetido).subscribe((value)=>{
            let clienteBBDD: any = value[0];
            let direccionesClienteBBDD: any;

            // Buscamos las direcciones que tiene ese cliente
            this.crmservice.getDirecciones(clienteBBDD._id).subscribe((value2)=>{
              direccionesClienteBBDD = value2;
              
              // Si el cliente tiene varias direcciones no desactivadas de tipo comercial o tienda no se le puede registrar.
              let direccionesActivasTipoComercialOTipoTienda = direccionesClienteBBDD.filter(item => (item.desactivado !== true && (item.tipoDir === "0" || item.tipoDir === "6")))
  
              if (direccionesActivasTipoComercialOTipoTienda.length > 1) {
                
                // Avisar al influencer de que no se puede registrar, que se ponga en contacto con nosotros.
                this.cargando = false;
                this.mostrarErrorLargo('No se puede crear este cliente. Varias direcciones activas tipo comercial o tienda. Póngase en contacto con nosotros.');
                this.dialogRef.close(this.direccion);
  
              } else if (direccionesActivasTipoComercialOTipoTienda.length === 1) {
                
              //* Actualizamos las direcciones del cliente con el influencer *//

                // Calculamos cuanto tiempo hace que se le asignó al equipo anterior
                let fechaRegistro: any;
                
                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo) !== 'undefined') {
                  fechaRegistro = direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo;
                } else { fechaRegistro = Date()};
                
                let fechaActual: any = Date()
                
                let tiempoTranscurridoRegistro = (Date.parse(fechaActual) - Date.parse(fechaRegistro))

                // Determinamos si tiene asignación actualmente a un comercial o a un influencer
                let tieneComercial: boolean;
                let tieneInfluencer: boolean;
                
                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneComercial) !== 'undefined') {
                  tieneComercial = direccionesActivasTipoComercialOTipoTienda[0].tieneComercial;
                } else { tieneComercial = false};

                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer) !== 'undefined') {
                  tieneInfluencer = direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer;
                } else { tieneInfluencer = false};
  
                
                // Comprobamos que las direcciones tengan estado 'noactivo', 'potencialnoactivo' o 'candidato', y que o no tenga influencer o que lo tenga desde hace más de un año
                if ((direccionesActivasTipoComercialOTipoTienda[0].estado === 'noactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'potencialnoactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'candidato') && ((!tieneInfluencer && !tieneComercial) || tiempoTranscurridoRegistro > 1000 * 60 * 60 * 24 * 30 * 4)) {
                
                  //Actualizar equipo, tieneInfluencer, fechaRegistroEquipo y trackingRegistroEquipo.
                  direccionesActivasTipoComercialOTipoTienda[0].equipo = this.crmservice.userLogin.team;
                  direccionesActivasTipoComercialOTipoTienda[0].tieneComercial = false;
                  direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer = true;
                  direccionesActivasTipoComercialOTipoTienda[0].rolRegistroEquipo = this.crmservice.userLogin.rol;
                  direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo = new Date();
                  if (typeof (direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo) !== 'undefined') {
                    direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo.push({idUsuario: this.crmservice.userLogin._id, rol: this.crmservice.userLogin.rol, fechaRegistro: direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo});
                  } else {
                    direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo =[{idUsuario: this.crmservice.userLogin._id, rol: this.crmservice.userLogin.rol, fechaRegistro: direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo}];
                  }
      
                  this.crmservice.updateDireccion(direccionesActivasTipoComercialOTipoTienda[0]).subscribe((value)=>{
  
                    const pos = this.crmservice.direcciones.findIndex(e => e._id === direccionesActivasTipoComercialOTipoTienda[0]._id);
                    if (pos !== -1) {
                      this.crmservice.direcciones[pos].equipo = direccionesActivasTipoComercialOTipoTienda[0].equipo;
                    }
        
                    this.cargando = false;
                    this.mostrarError('Cliente guardado correctamente');
        
                    this.dialogRef.close(this.direccion);
                  })
    
                } else {
    
                  this.cargando = false;
                  this.mostrarError('No se pudo actualizar el cliente. El cliente ya estaba asignado');
                  this.dialogRef.close(this.direccion);
    
                }
              } else {
  
                // No hay nada que actualizar, sin direcciones tipo comercial o tienda activas
                this.cargando = false;
                this.mostrarError('No se pudo actualizar el cliente. El cliente no tiene direcciones disponibles tipo comercial o tienda activas');
                this.dialogRef.close(this.direccion);
              }

            })
          })       
        } else if (this.crmservice.userLogin.rol === 'comercial') {
          // Buscamos el cliente en la BBDD
          this.crmservice.getCliente(this.id_cifRepetido).subscribe((value)=>{
            let clienteBBDD: any = value[0];
            let direccionesClienteBBDD: any;

            // Buscamos las direcciones que tiene ese cliente
            this.crmservice.getDirecciones(clienteBBDD._id).subscribe((value2)=>{
              direccionesClienteBBDD = value2;
              
              // Si el cliente tiene varias direcciones no desactivadas de tipo comercial o tienda no se le puede registrar.
              let direccionesActivasTipoComercialOTipoTienda = direccionesClienteBBDD.filter(item => (item.desactivado !== true && (item.tipoDir === "0" || item.tipoDir === "6")))
  
              if (direccionesActivasTipoComercialOTipoTienda.length > 1) {
                
                // Avisar al influencer de que no se puede registrar, que se ponga en contacto con nosotros.
                this.cargando = false;
                this.mostrarErrorLargo('No se puede crear este cliente. Varias direcciones activas tipo comercial o tienda. Póngase en contacto con nosotros.');
                this.dialogRef.close(this.direccion);
  
              } else if (direccionesActivasTipoComercialOTipoTienda.length === 1) {
                
              //* Actualizamos las direcciones del cliente con el influencer *//

                // Calculamos cuanto tiempo hace que se le asignó al equipo anterior
                let fechaRegistro: any;
                
                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo) !== 'undefined') {
                  fechaRegistro = direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo;
                } else { fechaRegistro = Date()};
                
                let fechaActual: any = Date()
                
                let tiempoTranscurridoRegistro = (Date.parse(fechaActual) - Date.parse(fechaRegistro))
                
                // Determinamos si tiene asignación actualmente a un comercial o a un influencer
                let tieneComercial: boolean;
                let tieneInfluencer: boolean;
                
                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneComercial) !== 'undefined') {
                  tieneComercial = direccionesActivasTipoComercialOTipoTienda[0].tieneComercial;
                } else { tieneComercial = false};

                if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer) !== 'undefined') {
                  tieneInfluencer = direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer;
                } else { tieneInfluencer = false};
  
                
                // Comprobamos que las direcciones tengan estado 'noactivo', 'potencialnoactivo' o 'candidato', y que o no tenga comercial o que lo tenga desde hace más de cuatro meses (por estar en "candidato", "noactivo" o "potencial no activo");
                if ((direccionesActivasTipoComercialOTipoTienda[0].estado === 'noactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'potencialnoactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'candidato') && ((!tieneComercial && !tieneInfluencer) || tiempoTranscurridoRegistro > 1000 * 60 * 60 * 24 * 30 * 4)) {
                
                  //Actualizar equipo, tieneInfluencer, fechaRegistroEquipo y trackingRegistroEquipo.
                  direccionesActivasTipoComercialOTipoTienda[0].equipo = this.crmservice.userLogin.team;
                  direccionesActivasTipoComercialOTipoTienda[0].tieneComercial = true;
                  direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer = false;
                  direccionesActivasTipoComercialOTipoTienda[0].rolRegistroEquipo = this.crmservice.userLogin.rol;
                  direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo = new Date();
                  if (typeof (direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo) !== 'undefined') {
                    direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo.push({idUsuario: this.crmservice.userLogin._id, rol: this.crmservice.userLogin.rol, fechaRegistro: direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo});
                  } else {
                    direccionesActivasTipoComercialOTipoTienda[0].trackingRegistroEquipo =[{idUsuario: this.crmservice.userLogin._id, rol: this.crmservice.userLogin.rol, fechaRegistro: direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo}];
                  }
      
                  this.crmservice.updateDireccion(direccionesActivasTipoComercialOTipoTienda[0]).subscribe((value)=>{
  
                    const pos = this.crmservice.direcciones.findIndex(e => e._id === direccionesActivasTipoComercialOTipoTienda[0]._id);
                    if (pos !== -1) {
                      this.crmservice.direcciones[pos].equipo = direccionesActivasTipoComercialOTipoTienda[0].equipo;
                    }
        
                    this.cargando = false;
                    this.mostrarError('Cliente guardado correctamente');
        
                    this.dialogRef.close(this.direccion);
                  })
    
                } else {
                  //Devolvemos error por estar el registro con un comercial aún vigente
                  this.cargando = false;
                  this.mostrarError('No se pudo actualizar el cliente. El cliente ya estaba asignado');
                  this.dialogRef.close(this.direccion);
    
                }
              } else {
  
                // No hay nada que actualizar, sin direcciones tipo comercial o tienda activas
                this.cargando = false;
                this.mostrarError('No se pudo actualizar el cliente. El cliente no tiene direcciones disponibles tipo comercial o tienda activas');
                this.dialogRef.close(this.direccion);
              }

            })
          }) 
        } else {
          // Como lo estamos intentando registrar con un usuario con rol distinto a comercial o a influencer devolvemos error por ya estar registrado el CIF
          this.cargando = false;
          this.mostrarError('No se puede registrar el cliente. El cliente ya tiene registros asignados a este CIF.');
          this.dialogRef.close(this.direccion);
        }
      }).catch((e)=>{
        this.mostrarError(e);
        this.cargando = false
        });
  }

  public async comprobarCifRegistrable(): Promise<void> {
    let tieneCifRepetido: boolean;
    tieneCifRepetido = await this.comprobacionCIFRepetido(); // Aquí comprobamos tanto si ya hay registro de cif en direcciones como en ipdormas (Pero si hay relación con alguna dirección, si nó devuelve 'false' y podemos hacer registro)
    if (this.cliente.cif === undefined || this.cliente.cif === '') {
      this.cifRegistrable = 'indefinido';
    } else {

      if (!tieneCifRepetido) {

        this.cifRegistrable = 'registrable';
        this.cliente.tipoCliente.tipo = "distribuidor";
        this.cliente.tipoCliente.categoria = "tienda";
        this.cliente.razonSocial = undefined;
        this.direccion.nombreComercial = undefined;
        this.direccion.tipoDir = "0";
        this.direccion.codigoPostal = undefined;
        this.direccion.provincia = undefined;
        this.direccion.poblacion = undefined;
        this.direccion.tipoVia = undefined;
        this.direccion.direccion = undefined;

      } else if (this.crmservice.userLogin.rol === 'influencer') {
  
        // Buscamos el cliente en la BBDD
        this.crmservice.getCliente(this.id_cifRepetido).subscribe((value)=>{
          let clienteBBDD: any = value[0];
          let direccionesClienteBBDD: any;
  
          // Buscamos las direcciones que tiene ese cliente
          this.crmservice.getDirecciones(clienteBBDD._id).subscribe((value2)=>{
            direccionesClienteBBDD = value2;
            
            // Si el cliente tiene varias direcciones no desactivadas de tipo comercial o tienda no se le puede registrar.
            let direccionesActivasTipoComercialOTipoTienda = direccionesClienteBBDD.filter(item => (item.desactivado !== true && (item.tipoDir === "0" || item.tipoDir === "6")))
  
            if (direccionesActivasTipoComercialOTipoTienda.length > 1) {
              
              // No se puede determinar si es registrable o no, que se ponga en contacto con nosotros.
              this.cifRegistrable = 'variasDirecciones';
            
            // Tiene sólo una dirección
            } else if (direccionesActivasTipoComercialOTipoTienda.length === 1) {
              
              // Calculamos cuanto tiempo hace que se le asignó al equipo anterior
              let fechaRegistro: any;
              
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo) !== 'undefined') {
                fechaRegistro = direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo;
              } else { fechaRegistro = Date()};
              
              let fechaActual: any = Date()
              
              let tiempoTranscurridoRegistro = (Date.parse(fechaActual) - Date.parse(fechaRegistro))
  
              // Determinamos si tiene asignación actualmente a un comercial o a un influencer
              let tieneComercial: boolean;
              let tieneInfluencer: boolean;
              
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneComercial) !== 'undefined') {
                tieneComercial = direccionesActivasTipoComercialOTipoTienda[0].tieneComercial;
              } else { tieneComercial = false};
  
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer) !== 'undefined') {
                tieneInfluencer = direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer;
              } else { tieneInfluencer = false};
  
              
              // Comprobamos que las direcciones tengan estado 'noactivo', 'potencialnoactivo' o 'candidato', y que o no tenga influencer o que lo tenga desde hace más de un año
              if ((direccionesActivasTipoComercialOTipoTienda[0].estado === 'noactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'potencialnoactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'candidato') && ((!tieneInfluencer && !tieneComercial) || tiempoTranscurridoRegistro > 1000 * 60 * 60 * 24 * 30 * 4)) {
  
                this.cifRegistrable = 'registrable';
                this.cliente.tipoCliente.tipo = clienteBBDD.tipoCliente.tipo;
                this.cliente.tipoCliente.categoria = clienteBBDD.tipoCliente.categoria;
                this.cliente.razonSocial = clienteBBDD.razonSocial;
                this.direccion.nombreComercial = direccionesActivasTipoComercialOTipoTienda[0].nombreComercial;
                this.direccion.tipoDir = direccionesActivasTipoComercialOTipoTienda[0].tipoDir;
                this.direccion.codigoPostal = direccionesActivasTipoComercialOTipoTienda[0].codigoPostal;
                this.direccion.provincia = direccionesActivasTipoComercialOTipoTienda[0].provincia;
                this.direccion.poblacion = direccionesActivasTipoComercialOTipoTienda[0].poblacion;
                this.direccion.tipoVia = direccionesActivasTipoComercialOTipoTienda[0].tipoVia;
                this.direccion.direccion = direccionesActivasTipoComercialOTipoTienda[0].direccion;
  
              } else {
  
                this.cifRegistrable = 'noRegistrable';
                this.cliente.tipoCliente.tipo = "distribuidor";
                this.cliente.tipoCliente.categoria = "tienda";
                this.cliente.razonSocial = undefined;
                this.direccion.nombreComercial = undefined;
                this.direccion.tipoDir = "0";
                this.direccion.codigoPostal = undefined;
                this.direccion.provincia = undefined;
                this.direccion.poblacion = undefined;
                this.direccion.tipoVia = undefined;
                this.direccion.direccion = undefined;
  
              }
            } else {
  
              this.cifRegistrable = 'noRegistrable';
              this.cliente.tipoCliente.tipo = "distribuidor";
              this.cliente.tipoCliente.categoria = "tienda";
              this.cliente.razonSocial = undefined;
              this.direccion.nombreComercial = undefined;
              this.direccion.tipoDir = "0";
              this.direccion.codigoPostal = undefined;
              this.direccion.provincia = undefined;
              this.direccion.poblacion = undefined;
              this.direccion.tipoVia = undefined;
              this.direccion.direccion = undefined;
  
            }
  
          })
        })       
      } else if (this.crmservice.userLogin.rol === 'comercial') {
        // Buscamos el cliente en la BBDD
        this.crmservice.getCliente(this.id_cifRepetido).subscribe((value)=>{
          let clienteBBDD: any = value[0];
          let direccionesClienteBBDD: any;
  
          // Buscamos las direcciones que tiene ese cliente
          this.crmservice.getDirecciones(clienteBBDD._id).subscribe((value2)=>{
            direccionesClienteBBDD = value2;
            
            // Si el cliente tiene varias direcciones no desactivadas de tipo comercial o tienda no se le puede registrar.
            let direccionesActivasTipoComercialOTipoTienda = direccionesClienteBBDD.filter(item => (item.desactivado !== true && (item.tipoDir === "0" || item.tipoDir === "6")))
  
            if (direccionesActivasTipoComercialOTipoTienda.length > 1) {
              
              this.cifRegistrable = 'noRegistrable';
              this.cliente.tipoCliente.tipo = "distribuidor";
              this.cliente.tipoCliente.categoria = "tienda";
              this.cliente.razonSocial = undefined;
              this.direccion.nombreComercial = undefined;
              this.direccion.tipoDir = "0";
              this.direccion.codigoPostal = undefined;
              this.direccion.provincia = undefined;
              this.direccion.poblacion = undefined;
              this.direccion.tipoVia = undefined;
              this.direccion.direccion = undefined;
  
            } else if (direccionesActivasTipoComercialOTipoTienda.length === 1) {
              
              // Calculamos cuanto tiempo hace que se le asignó al equipo anterior
              let fechaRegistro: any;
              
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo) !== 'undefined') {
                fechaRegistro = direccionesActivasTipoComercialOTipoTienda[0].fechaRegistroEquipo;
              } else { fechaRegistro = Date()};
              
              let fechaActual: any = Date()
              
              let tiempoTranscurridoRegistro = (Date.parse(fechaActual) - Date.parse(fechaRegistro))
              
              // Determinamos si tiene asignación actualmente a un comercial o a un influencer
              let tieneComercial: boolean;
              let tieneInfluencer: boolean;
              
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneComercial) !== 'undefined') {
                tieneComercial = direccionesActivasTipoComercialOTipoTienda[0].tieneComercial;
              } else { tieneComercial = false};
  
              if (typeof(direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer) !== 'undefined') {
                tieneInfluencer = direccionesActivasTipoComercialOTipoTienda[0].tieneInfluencer;
              } else { tieneInfluencer = false};
  
              
              // Comprobamos que las direcciones tengan estado 'noactivo', 'potencialnoactivo' o 'candidato', y que o no tenga comercial o que lo tenga desde hace más de cuatro meses (por estar en "candidato", "noactivo" o "potencial no activo");
              if ((direccionesActivasTipoComercialOTipoTienda[0].estado === 'noactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'potencialnoactivo' || direccionesActivasTipoComercialOTipoTienda[0].estado === 'candidato') && ((!tieneComercial && !tieneInfluencer) || tiempoTranscurridoRegistro > 1000 * 60 * 60 * 24 * 30 * 4)) {
              
                this.cifRegistrable = 'registrable';
                this.cliente.tipoCliente.tipo = clienteBBDD.tipoCliente.tipo;
                this.cliente.tipoCliente.categoria = clienteBBDD.tipoCliente.categoria;
                this.cliente.razonSocial = clienteBBDD.razonSocial;
                this.direccion.nombreComercial = direccionesActivasTipoComercialOTipoTienda[0].nombreComercial;
                this.direccion.tipoDir = direccionesActivasTipoComercialOTipoTienda[0].tipoDir;
                this.direccion.codigoPostal = direccionesActivasTipoComercialOTipoTienda[0].codigoPostal;
                this.direccion.provincia = direccionesActivasTipoComercialOTipoTienda[0].provincia;
                this.direccion.poblacion = direccionesActivasTipoComercialOTipoTienda[0].poblacion;
                this.direccion.tipoVia = direccionesActivasTipoComercialOTipoTienda[0].tipoVia;
                this.direccion.direccion = direccionesActivasTipoComercialOTipoTienda[0].direccion;
  
              } else {

                //Devolvemos error por estar el registro con un comercial aún vigente
                this.cifRegistrable = 'noRegistrable';
                this.cliente.tipoCliente.tipo = "distribuidor";
                this.cliente.tipoCliente.categoria = "tienda";
                this.cliente.razonSocial = undefined;
                this.direccion.nombreComercial = undefined;
                this.direccion.tipoDir = "0";
                this.direccion.codigoPostal = undefined;
                this.direccion.provincia = undefined;
                this.direccion.poblacion = undefined;
                this.direccion.tipoVia = undefined;
                this.direccion.direccion = undefined;
              }
            } else {
  
              // No hay nada que actualizar, sin direcciones tipo comercial o tienda activas
              this.cifRegistrable = 'noRegistrable';
              this.cliente.tipoCliente.tipo = "distribuidor";
              this.cliente.tipoCliente.categoria = "tienda";
              this.cliente.razonSocial = undefined;
              this.direccion.nombreComercial = undefined;
              this.direccion.tipoDir = "0";
              this.direccion.codigoPostal = undefined;
              this.direccion.provincia = undefined;
              this.direccion.poblacion = undefined;
              this.direccion.tipoVia = undefined;
              this.direccion.direccion = undefined;
            }
  
          })
        }) 
      } else {
        
        // Como lo estamos intentando registrar con un usuario con rol distinto a comercial o a influencer devolvemos error por ya estar registrado el CIF
        this.cifRegistrable = 'noRegistrable';
        this.cliente.tipoCliente.tipo = "distribuidor";
        this.cliente.tipoCliente.categoria = "tienda";
        this.cliente.razonSocial = undefined;
        this.direccion.nombreComercial = undefined;
        this.direccion.tipoDir = "0";
        this.direccion.codigoPostal = undefined;
        this.direccion.provincia = undefined;
        this.direccion.poblacion = undefined;
        this.direccion.tipoVia = undefined;
        this.direccion.direccion = undefined;
      }

    }
  }


  public cancelar(): void {
    const popUpRef = this.crmservice.popUpConfirmacion({textoPrincipal: '¿Quieres cancelar la creación?'});

    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  public cambiaCP(): void {
    if (this.direccion.codigoPostal.length === 5) {
      const cpFind = this.crmservice.codigosPostales.find(cp => this.crmservice.FormatNumberLength(cp.codigoPostal, 5) === this.direccion.codigoPostal);
      if (cpFind) {
        this.direccion.provincia = cpFind.provincia;
        this.direccion.poblacion = cpFind.poblacion;
      }
    }
  }
  public tipoDirCambiado(): void {
    this.direccion.verMapa = this.direccion.tipoDir === '0' || this.direccion.tipoDir === '6';
  }

  private comprobarRelacionIpDormas (id_ipDorma: string): boolean {
    let respuesta;
    this.crmservice.getClientesRelacionIpDormaById(id_ipDorma).subscribe( value => {
      if (value.clientes.length > 0) {
        respuesta = true;
      } else {
        respuesta = false;
      }
    })
    return respuesta;
  }

  private comprobarFormulario(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cliente.razonSocial == null || !this.cliente.razonSocial.trim().length) {
        reject('¡La Razón Social es obligatoria!');
      }
      if ((this.cliente.cif == null || !this.cliente.cif.trim().length)) {
        reject('¡El CIF es obligatorio!');
      }
      if (this.cliente.cif != null && !this.verificarCIF(this.cliente.cif)) {
        reject('¡El CIF introducido es incorrecto!');
      }
      if (this.direccion.nombreComercial == null || !this.direccion.nombreComercial.trim().length) {
        reject('¡El Nombre Comercial es obligatorio!');
      }
      if (this.direccion.equipo == null || !this.direccion.equipo.trim().length) {
        reject('¡El Equipo es obligatorio!');
      }
      if (this.direccion.estado == null || !this.direccion.estado.trim().length) {
        reject('¡El Estado es obligatorio!');
      }
      if (this.direccion.estado === 'potencial' || this.direccion.estado === 'activo' || this.direccion.estado === 'activoI') {
        if (this.direccion.perfilTCLA == null || !this.direccion.perfilTCLA.trim().length) {
          reject('¡TECLA es obligatorio en Potenciales y Activos!');
        }
      }
      if (this.direccion.tipoDir == null || !this.direccion.tipoDir.trim().length) {
        reject('¡El Tipo de dirección es obligatorio!');
      }
      if (this.direccion.verMapa == null) {
        reject('¡Ver en mapa es obligatorio!');
      }
      if (this.direccion.codigoPostal == null || !this.direccion.codigoPostal.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('¡El Código Postal es obligatorio!');
        }
      }
      if (this.direccion.provincia == null || !this.direccion.provincia.trim().length) {
        reject('¡La Provincia es obligatoria!');
      }
      if (this.direccion.poblacion == null || !this.direccion.poblacion.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('¡La Población es obligatoria!');
        }
      }
      if (this.direccion.tipoVia == null || !this.direccion.tipoVia.trim().length) {
        reject('¡El Tipo de Vía es obligatorio!');
      }
      if (this.direccion.direccion == null || !this.direccion.direccion.trim().length) {
        if (this.direccion.tipoDir !== '7' || (this.direccion.tipoDir === '7' && this.direccion.verMapa)) {
          reject('¡La Dirección es obligatoria!');
        }
      }

      if (this.direccion.perfilTCLA != null && this.direccion.perfilTCLA.trim().length) {
        this.crmservice.getFactorConversion(this.direccion.perfilTCLA).subscribe(value => {
          if (value[0] != null) {
            resolve();
          } else {
            reject('¡El TECLA indicado no existe!');
          }
        }, () => reject('¡El TECLA indicado no existe!'));
      } else {
        resolve();
      }
    });
  }
  public async comprobacionCIFRepetido(): Promise<boolean> {
    return new Promise<boolean> ( resolve => {
      if (this.cliente.cif != null && this.cliente.cif.trim().length) {
        const cli = this.crmservice.clientesCIFRazonSocial.find(c => c.cif === this.cliente.cif.toUpperCase());
        const cli2 = this.crmservice.clientesIpdormaCif.find(c => c.cif === this.cliente.cif.toUpperCase());
        
        if (cli) {
          this.id_cifRepetido = cli._id;
        } 
        
        let hayRelacionCli2Ipdorma = false;
        if (cli2) {
          hayRelacionCli2Ipdorma = this.comprobarRelacionIpDormas(cli2._id);
        } 
          
        if (cli || hayRelacionCli2Ipdorma) {
          this.cifRepetido = true;
          resolve(true);
        } else {
          this.cifRepetido = false;
          resolve(false);
        }
      } else {
        this.cifRepetido = false;
        resolve(false);
      }
    })
  };

  private mostrarError(msg: string = 'Desconocido'): void {
    this.snackBar.open(msg, 'OK', {duration: 3500});
  }
  private mostrarErrorLargo(msg: string = 'Desconocido'): void {
    this.snackBar.open(msg, 'OK', {duration: 6500});
  }
  private verificarCIF(nif: string): boolean {
    nif = nif.toUpperCase().replace(/[\s\-]+/g, '');

    // PARA DNI O NIE
    if (/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
      let num = nif.match(/\d+/);
      // @ts-ignore
      num = (nif[0] != 'Z' ? nif[0] != 'Y' ? 0 : 1 : 2) + num;
      // @ts-ignore
      if (nif[8] == 'TRWAGMYFPDXBNJZSQVHLCKE'[num % 23]) {
        return true;
      }

      // PARA NIF - CIF
    } else if (/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
      let sum = 0;
      for (let i = 1; i < 8; ++i) {
        // @ts-ignore
        const num = nif[i] << i % 2;
        const uni = num % 10;
        sum += (num - uni) / 10 + uni;
      }
      const c = (10 - sum % 10) % 10;
      // @ts-ignore
      if (nif[8] == c || nif[8] == 'JABCDEFGHI'[c]) {
        return true;
      }
    }
    return false; // Si se introduce algo, debe ser correcto
  }
  private normalizarTexto(texto: string): string {
    if (texto != null) {
      return texto
        .normalize('NFD')
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
        .normalize()
        .toUpperCase();
    }
    return null;
  }
}
