import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SesionService} from './SesionService';
import {MatDialog} from '@angular/material/dialog';
import {CrmService} from './crm.service';

// Servicio encargado de interceptar todas las peticiones/respuestas al servidor.
// Para las peticiones, añade el token de autenticación en el header de la petición.
// Para todas las respuestas, comprueba que los permisos de acceso sean correctos, sino
// (no se ha mandado token, el token es incorrecto o ha caducado), se reenvía a la ventana
// de login.

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private sesionService: SesionService,
              private dialogRef: MatDialog,
              private crmservice: CrmService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.sesionService.getToken();

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        // No se ha enviado token.
        if (err.status === 403) {
          this.sesionService.setUserLogeado(false);
          this.dialogRef.closeAll();
          this.router.navigate(['/login']).then();  // Enviar al login. Se utiliza el router.navigate en vez de location.href para evitar volver cargar la aplicacion.
          this.crmservice.datosCargados = true;
        }

        // Token expirado o no válido.
        if (err.status === 401) {
          this.sesionService.removeToken();                   // Quitar el token del almacenamiento local (ya no es válido).
          this.sesionService.mensaje_login = err.error.message || 'Se produjo un error imprevisto con tu sesión. Vuelve a hacer login para continuar.';
          this.sesionService.setUserLogeado(false);
          this.dialogRef.closeAll();
          this.router.navigate(['/login']).then();  // Enviar al login.
          this.crmservice.datosCargados = true;
        }

        return throwError(err);

      })
    );
  }

}

