import { Component, OnInit } from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.css']
})
export class GestionComponent implements OnInit {

  constructor(public crmservice: CrmService, public router: Router) {

    console.log('Gestion');

    this.router.navigate(['config-rankings']).then();
  }

  ngOnInit() {
  }

}
