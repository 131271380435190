<div class="cabecera-modal">
  <img src="../../../../../assets/img/tcla_icon.ico" alt="TCLA" id="img-tcla" class="icon-modal">
  <strong>DETALLE DE USUARIO</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargandoDatos" class="m-4 rounded p-4 bg-light shadow-sm">

    <div class="col-12 text-end mb-3">
      <button *ngIf="usuarioTCLA != null && usuarioTCLA.activo" class="btn btn-outline-danger me-4" style="width: 200px;" (click)="desactivarPerfil()">
        <span class="d-flex justify-content-between">
          <mat-icon>highlight_off</mat-icon>
          <span>DESACTIVAR</span>
        </span>
      </button>
      <button class="btn btn-outline-success" style="width: 200px" (click)="asignarPerfil()">
          <span class="d-flex justify-content-between">
            <mat-icon>add_to_photos</mat-icon>
            <span>{{(direccion.perfilTCLA != null && direccion.perfilTCLA.trim().length) ? 'CAMBIAR TECLA' : 'ASIGNAR TECLA'}}</span>
          </span>
      </button>
      <button *ngIf="usuarioTCLA != null && !usuarioTCLA.activo" class="btn btn-success ms-3" style="width: 200px" (click)="asignarPerfil(usuarioTCLA.usuario)">
        <span class="d-flex justify-content-between">
          <mat-icon>done</mat-icon>
          <span>ACTIVAR PERFIL</span>
        </span>
      </button>
    </div>

    <div *ngIf="usuarioTCLA != null" class="row">

      <div class="col-md-6 col-12 mb-4">
        <label class="w-100">
          <strong>Usuario</strong>
          <input class="form-control" [(ngModel)]="usuarioTCLA.usuario" readonly>
        </label>
      </div>

      <div class="col-md-6 col-12 mb-4">
        <label class="w-100">
          <strong>Estado del Perfil</strong>
          <input class="form-control" readonly [ngStyle]="{'color' : usuarioTCLA.activo ? 'green' : 'red'}"
                 [defaultValue]="usuarioTCLA.activo ? 'ACTIVO' : 'NO ACTIVO'">
        </label>
      </div>

      <div class="col-md-6 col-12 mb-4">
        <label class="w-100">
          <strong>Solicitar Licentas <img src="../../../../../assets/img/otros/3d.png" alt="3D" id="img-3d">:</strong>
          <input class="form-control" readonly [defaultValue]="usuarioTCLA._3d ? 'ACTIVADO' : 'NO ACTIVADO'">
        </label>
      </div>

      <div class="col-md-6 col-12 mb-4">
        <label class="w-100">
          <strong>Diseñador Virtual:</strong>
          <input class="form-control" readonly [defaultValue]="usuarioTCLA.amueblando ? 'ACTIVADO' : 'NO ACTIVADO'">
        </label>
      </div>

      <div class="col-12 mb-4">
        <div class="border-bottom-dash border-secondary"></div>
      </div>

      <div class="col-md-4 col-12 mb-4">
        <strong>Catalogos: </strong>
        <table class="table table-borderless table-sm mt-2">
          <tr *ngFor="let catalogo of usuarioTCLA.catalogos">
            <td>{{catalogo.nombre}}</td>
          </tr>
        </table>
      </div>

      <div class="col-md-8 col-12 mb-4">
        <strong>Tarifas: </strong>
        <table *ngIf="usuarioTCLA.factoresTarifas != null && usuarioTCLA.factoresTarifas.length" class="table table-borderless table-sm mt-2">
          <thead>
            <tr>
              <th>Tarifas</th>
              <th>Factor Conversión</th>
              <th>Punto Compra</th>
              <th style="font-size: 14px;">Descuento para margen del 100%</th>
            </tr>
          </thead>
          <tr *ngFor="let tarifa of usuarioTCLA.factoresTarifas">
            <td class="text-start">{{tarifa.nombre}}</td>
            <!-- <td class="text-end" *ngIf="crmservice.userLogin.rol !== 'comercial'"> -->
              <!-- Se cambia por orden de Jesús Cano el día 21/03/2023. dejandole a los comerciales accesos a ver el valor del punto de los clientes -->
            <td class="text-center">
              <strong class="text-secondary">{{tarifa.factorconversion}}</strong>
            </td>
            <td class="text-center">
              <strong class="text-secondary">{{ipdormas !== undefined ? ipdormas[0].ipdorma.puntoCompra : '0'}}</strong>
            </td>
            <td class="text-center">
              <strong class="text-secondary">{{ ipdormas !== undefined ? (((2*ipdormas[0].ipdorma.puntoCompra)/tarifa.factorconversion) < 1 ? ( 100 - (((2*ipdormas[0].ipdorma.puntoCompra)/tarifa.factorconversion))*100).toFixed(0) : "Ya es inferior") : '0'}}</strong>
            </td>
          </tr>
        </table>
        <div *ngIf="crmservice.userLogin.rol != 'distribuidor' && crmservice.userLogin.rol != 'comercial'  && crmservice.userLogin.rol != 'influencer'">
          <div>
            <strong>Vales VIP disponibles activados por DV:</strong>
            <table *ngIf="promocionesVIPdisponibles.length === 0">
              <tr>
                <td>Actualmente no hay ningún vale VIP disponible</td>
              </tr>
            </table>
            <table>
              <tr *ngFor="let promocion of promocionesVIPdisponibles">
                <td>Vale descuento del {{promocion.descuento}}%</td>
                <td><mat-icon (click)="borrarValeVIP(promocion)">delete_forever</mat-icon></td>
              </tr>
            </table>
          </div>
          <br>
          <div *ngIf="promocionesVIPdisponibles.length === 0">
            <select [(ngModel)]="opcionValeVIP" style="width: 250px; height: 2em; border-radius: 3px;">
              <option value="" >Elige el tipo de descuento</option>
              <!-- <option value="20" >Descuento del 20%</option> -->
              <!-- <option value="15" >Descuento del 15%</option> -->
              <!-- <option value="10" >Descuento del 10%</option> -->
              <option value="0" >Descuento Presonalizado</option>
            </select>
            <input type="number" style="height: 2em; border-radius: 3px; border: 1px solid black; margin-left: 10px;" *ngIf="opcionValeVIP === '0' " placeholder="Introduce el valor del vale" [(ngModel)]="valeVIPpersonalizado">
            <button *ngIf="opcionValeVIP !== '' " (click)="activarValeVIP()" style="border: 1px solid black; border-radius: 5px; margin-left: 10px; height: 2em;">Activar nuevo Vale VIP</button>
          </div>
        </div>
      </div>

      <div  *ngIf="crmservice.userLogin.rol === 'admin'" class="col-12">
        <div class="row p-4 m-4 border-dash border-secondary rounded">

          <div class="col-12 mb-4 text-center">
            <div class="alert alert-info">
              Botones son para <strong>testeo</strong>, para activar/descactivar estas características, dar o quitar los respectivos hitos
              mediante acciones.
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <button class="btn btn-sm btn-block btn-secondary" (click)="activarDV(usuarioTCLA.usuario)">Activar DV</button>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <button class="btn btn-sm btn-block btn-secondary" (click)="desactivarDV(usuarioTCLA.usuario)">Quitar DV</button>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <button class="btn btn-sm btn-block btn-secondary" (click)="activar3D(usuarioTCLA.usuario)">Activar 3D</button>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <button class="btn btn-sm btn-block btn-secondary" (click)="desactivar3D(usuarioTCLA.usuario)">Quitar 3D</button>
          </div>

        </div>
      </div>

    </div>

    <div *ngIf="usuarioTCLA == null" class="row">

      <div *ngIf="perfilTCLA == null || perfilTCLA === ''" class="col-12 text-center">
        <div class="alert alert-info">
          La dirección aún no tiene asignado un perfil <strong>TECLA</strong>.
        </div>
      </div>

      <div *ngIf="perfilTCLA != null && perfilTCLA !== ''" class="col-12 text-center">
        <div class="alert alert-warning">
          No se encuentra el perfil TECLA <strong>{{perfilTCLA}}</strong>.
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="cargandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
