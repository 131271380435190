export class Accion {
  _id: string;
  id: string;
  hito: string;
  objetivo: string;
  medio: [string];
  direccion: string;
  cliente: string;
  contactos: [string];
  usuariosInvolucrados: [string];
  fechaInicio: Date;
  fechaFin: Date;
  registroTiempo: [];
  pedido: string;
  solicitudDV: string;
  accionTienda: boolean;
  fechaPrevista: Date;
  observaciones: string;
  resultado: string;
  de: string[];
  para: any;
  estado: string;
  pospuesto: number;
  minutosAcumulados: number;
  pospuestas: [
    {
      fechaPrevistaA: Date;
      fechaPrevistaN: Date;
      motivo: string;
      observaciones: string;
      cuandoCambio: Date;
      gastoTiempo: boolean;
      fechaInicio: Date;
      fechaFin: Date;
    }
  ];
  formacion: {
    bloque: string;
    tipo: string;
    superado: boolean;
  };
  campus: {
    curso: string;
    bloque: string;
    superado: boolean;
  };
}
