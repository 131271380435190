<div class="cabecera-modal">
  <mat-icon class="icon-modal">description</mat-icon>
  <strong>CONTRATO WALLVIEWER</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!guardandoDatos" [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">

    <div class="row">

      <div class="col-12 col-md-3">
        <mat-form-field class="w-100">
          <label for="inputInicio"></label>
          <input id="inputInicio" [matDatepicker]="inicio" matInput placeholder="Fecha de inicio"
                 [(ngModel)]="direccion.contratoWV.inicio">
          <mat-datepicker-toggle matSuffix [for]="inicio"></mat-datepicker-toggle>
          <mat-datepicker #inicio></mat-datepicker>
          <mat-error>
            La fecha es <strong>obligatoria</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3">
        <mat-form-field class="w-100">
          <label for="inputFin"></label>
          <input id="inputFin" [matDatepicker]="final" matInput placeholder="Fecha de fin" [(ngModel)]="direccion.contratoWV.fin">
          <mat-datepicker-toggle matSuffix [for]="final"></mat-datepicker-toggle>
          <mat-datepicker #final></mat-datepicker>
          <mat-error>
            La fecha es <strong>obligatoria</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3">
        <mat-form-field class="w-100">
          <label for="inputBaseImponible"></label>
          <input id="inputBaseImponible" matInput placeholder="Base imponible" type="number" [(ngModel)]="direccion.contratoWV.cobrado">
          <mat-error>
            La base imponible es <strong>obligatoria</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3">
        <mat-form-field class="w-100">
          <label for="inputDevolucion"></label>
          <input id="inputDevolucion" matInput placeholder="% devolución" type="number" [(ngModel)]="direccion.contratoWV.devolucion">
          <mat-error>
            El % devolución es <strong>obligatorio</strong>
          </mat-error>
        </mat-form-field>
      </div>


      <div class="col-12" *ngIf="direccion.contratoWV.documento != null">
        <button (click)="verContrato()" mat-button color="primary">
          <mat-icon>visibility</mat-icon>
          Ver contrato
        </button>
        <button (click)="borrarContrato()" mat-button color="warn">
          <mat-icon>delete</mat-icon>
          Eliminar contrato
        </button>
      </div>

      <div class="col-12" *ngIf="direccion.contratoWV.documento == null">
        <button (click)="abrirInput()" mat-button color="primary">
          <mat-icon>cloud_upload</mat-icon>
          Subir contrato
        </button>
        <input type="file" id="contratoWV" accept="application/pdf" hidden (change)="subirPDF($event.target.files)">
      </div>

      <div class="col-12">
        <hr>
      </div>

      <div *ngIf="!mobileQuery.matches" class="col-12 d-flex justify-content-around">
        <button class="btn btn-secondary w-100px" [mat-dialog-close]>Cancelar</button>
        <button class="btn btn-success w-100px" (click)="guardarContrato()">Guardar</button>
      </div>

      <div *ngIf="mobileQuery.matches" class="col-12 text-center mb-2">
        <button class="btn btn-secondary w-80" [mat-dialog-close]>Cancelar</button>
      </div>

      <div *ngIf="mobileQuery.matches" class="col-12 text-center mb-2">
        <button class="btn btn-success w-80" (click)="guardarContrato()">Guardar</button>
      </div>

    </div>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
