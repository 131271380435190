<div class="todoAltura">
  <div class="row sinMargin" *ngIf="!mobileQuery.matches">
    <div class="col-md-6 col-12">
      <mat-form-field appearance="outline" class="textInput">
        <mat-label>Estados</mat-label>
        <mat-select
          (selectionChange)="ponerMarkers()"
          [(ngModel)]="mapaservicio.estadosSeleccionados"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Estados"
          multiple
        >
          <mat-option (click)="quitarFiltro(0)"> Quitar todos </mat-option>
          <mat-option (click)="seleccionarTodos(0)"> Seleccionar Todos </mat-option>
          <mat-option *ngFor="let item of crmservice.estados" value="{{ item.id }}">
            <img height="30px" src="../assets/img/markers/{{ item.id }}.png" alt="" /> {{ item.nombre }}
          </mat-option>
          <mat-option value="{{ 'pre_dvirtual' }}">
            <img style="height: 30px" src="../../../assets/img/markers/pre_dvirtual.png" alt="" /> Presentar Diseñador Virtual
          </mat-option>
          <mat-option value="{{ 'amueblando' }}">
            <img style="height: 30px" src="../../../assets/img/markers/marcaLogoIdehabita.png" alt="" /> Idehábita (Virtual)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <mat-form-field appearance="outline" class="textInput">
        <mat-label>Equipos</mat-label>
        <mat-select
          [disabled]="crmservice.userLogin.rol === 'influencer' || crmservice.userLogin.rol === 'comercial'"
          (selectionChange)="ponerMarkers()"
          [(ngModel)]="mapaservicio.equiposSeleccionados"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Equipos"
          multiple
        >
          <mat-option (click)="quitarFiltro(1)"> Quitar todos </mat-option>
          <mat-option (click)="seleccionarTodos(1)"> Seleccionar Todos </mat-option>
          <mat-option *ngFor="let item of crmservice.equipos" value="{{ item.id }}">
            {{ item.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <form class="col-6 mb-3" action="">
      <div class="input-group">
        <label for="buscarCP"></label>
        <input
          id="buscarCP"
          class="form-control form-control-sm"
          placeholder="Código Postal..."
          [(ngModel)]="cpBusqueda"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="input-group-append">
          <button class="btn btn-sm btn-warning" type="submit" (click)="buscarCP()">Buscar</button>
        </div>
      </div>
    </form>

    <div *ngIf="cpMostrar != null" class="col-6 mb-3">
      <table class="table table-sm">
        <tr>
          <td><strong>Ubicación: </strong></td>
          <td style="width: 20px"></td>
          <td>
            <strong style="color: gray">{{ cpMostrar.codigoPostal }}, {{ cpMostrar.provincia }}</strong>
          </td>
        </tr>
        <tr>
          <td><strong>Asignación Física: </strong></td>
          <td style="width: 20px"></td>
          <td [ngStyle]="{ color: cpMostrar.asignadoIDE != null ? 'brown' : 'green' }">
            <strong>
              {{ cpMostrar.asignadoIDE != null ? cpMostrar.asignadoIDE : "Disponible" }}
            </strong>
          </td>
        </tr>
        <tr>
          <td><strong>Asignación Virtual: </strong></td>
          <td style="width: 20px"></td>
          <td [ngStyle]="{ color: cpMostrar.asignadoVirtual != null ? 'brown' : 'green' }">
            <strong>
              {{ cpMostrar.asignadoVirtual != null ? cpMostrar.asignadoVirtual : "Disponible" }}
            </strong>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-6 mb-3">
      <div class="row">
        <div class="col-12 btn-group">
          <button
            class="btn"
            [ngClass]="mapaservicio.verEspacioPuntoCompra ? 'btn-info' : 'btn-outline-info'"
            [disabled]="mapaservicio.btnEspacioDisabled() || crmservice.userLogin.rol === 'comercial' || crmservice.userLogin.rol === 'influencer'"
            style="width: 175px"
            (click)="mapaservicio.visualizarEspacioPuntoCompra()"
          >
            <div class="d-flex justify-content-between">
              <span *ngIf="mapaservicio.cargandoEspacioPuntoCompra" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
              <span *ngIf="mapaservicio.cargandoEspacioPuntoCompra">Calculando...</span>
              <mat-icon *ngIf="!mapaservicio.cargandoEspacioPuntoCompra">{{
                mapaservicio.verEspacioPuntoCompra ? "visibility" : "visibility_off"
              }}</mat-icon>
              <span *ngIf="!mapaservicio.cargandoEspacioPuntoCompra">Punto Compra</span>
            </div>
          </button>
          <button
            class="btn"
            [ngClass]="mapaservicio.verEspacioFisico ? 'btn-info' : 'btn-outline-info'"
            [disabled]="mapaservicio.btnEspacioDisabled()"
            style="width: 175px"
            (click)="mapaservicio.visualizarEspacioFisico()"
          >
            <div class="d-flex justify-content-between">
              <span *ngIf="mapaservicio.cargandoEspacioFisico" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
              <span *ngIf="mapaservicio.cargandoEspacioFisico">Calculando...</span>
              <mat-icon *ngIf="!mapaservicio.cargandoEspacioFisico">{{ mapaservicio.verEspacioFisico ? "visibility" : "visibility_off" }}</mat-icon>
              <span *ngIf="!mapaservicio.cargandoEspacioFisico">CTM (EF)</span>
            </div>
          </button>
          <button
            class="btn"
            [ngClass]="mapaservicio.verEspacioVirtual ? 'btn-info' : 'btn-outline-info'"
            [disabled]="mapaservicio.btnEspacioDisabled()"
            style="width: 175px"
            (click)="mapaservicio.visualizarEspacioVirutal()"
          >
            <div class="d-flex justify-content-between">
              <span *ngIf="mapaservicio.cargandoEspacioVirtual" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
              <span *ngIf="mapaservicio.cargandoEspacioVirtual">Calculando...</span>
              <mat-icon *ngIf="!mapaservicio.cargandoEspacioVirtual">{{ mapaservicio.verEspacioVirtual ? "visibility" : "visibility_off" }}</mat-icon>
              <span *ngIf="!mapaservicio.cargandoEspacioVirtual">Idehabita (EV)</span>
            </div>
          </button>
        </div>
        <div class="col-12">
          <label class="m-2">
            <input
              type="radio"
              name="calidad"
              [(ngModel)]="mapaservicio.calidad"
              value="alta"
              (ngModelChange)="mapaservicio.eliminarPoligonos()"
              [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
            />
            Calidad Alta
          </label>
          <label class="m-2">
            <input
              type="radio"
              name="calidad"
              [(ngModel)]="mapaservicio.calidad"
              value="media"
              (ngModelChange)="mapaservicio.eliminarPoligonos()"
              [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
            />
            Calidad Media
          </label>
          <label class="m-2">
            <input
              type="radio"
              name="calidad"
              [(ngModel)]="mapaservicio.calidad"
              value="baja"
              (ngModelChange)="mapaservicio.eliminarPoligonos()"
              [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
            />
            Calidad Baja
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="row sinMargin" *ngIf="mobileQuery.matches">
    <div class="col-12" style="margin-top: 10px">
      <mat-form-field class="textInput-movil" style="font-size: 12px">
        <mat-label>Estados</mat-label>
        <mat-select
          (selectionChange)="ponerMarkers()"
          [(ngModel)]="mapaservicio.estadosSeleccionados"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Estados"
          multiple
        >
          <mat-option (click)="quitarFiltro(0)"> Quitar todos </mat-option>
          <mat-option (click)="seleccionarTodos(0)"> Seleccionar Todos </mat-option>
          <mat-option *ngFor="let item of crmservice.estados" value="{{ item.id }}">
            <img height="30px" src="../assets/img/markers/{{ item.id }}.png" alt="" /> {{ item.nombre }}
          </mat-option>
          <mat-option value="{{ 'pre_dvirtual' }}">
            <img style="height: 30px" src="../../../assets/img/markers/pre_dvirtual.png" alt="" /> Presentar DV
          </mat-option>
          <mat-option value="{{ 'amueblando' }}">
            <img style="height: 30px" src="../../../assets/img/markers/marcaLogoIdehabita.png" alt="" /> Idehábita (Virtual)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="textInput-movil" style="font-size: 12px">
        <mat-label>Equipos</mat-label>
        <mat-select
          (selectionChange)="ponerMarkers()"
          [(ngModel)]="mapaservicio.equiposSeleccionados"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Equipos"
          multiple
        >
          <mat-option (click)="quitarFiltro(1)"> Quitar todos </mat-option>
          <mat-option (click)="seleccionarTodos(1)"> Seleccionar Todos </mat-option>
          <mat-option *ngFor="let item of crmservice.equipos" value="{{ item.id }}">
            {{ item.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <form class="col-12 mb-3">
      <div class="input-group">
        <label for="buscarCPMovil"></label>
        <input id="buscarCPMovil" class="form-control form-control-sm" [(ngModel)]="cpBusqueda" [ngModelOptions]="{ standalone: true }" />
        <div class="input-group-append">
          <button class="btn btn-sm btn-warning" type="submit" (click)="buscarCP()">Buscar</button>
        </div>
      </div>
    </form>

    <div class="col-12 mb-3" *ngIf="cpMostrar != null">
      <table class="table table-sm">
        <tr>
          <td><strong>Ubicación: </strong></td>
          <td style="width: 20px"></td>
          <td>
            <strong style="color: gray">{{ cpMostrar.codigoPostal }}, {{ cpMostrar.provincia }}</strong>
          </td>
        </tr>
        <tr>
          <td><strong>Asignación Física: </strong></td>
          <td style="width: 20px"></td>
          <td [ngStyle]="{ color: cpMostrar.asignadoIDE != null ? 'brown' : 'green' }">
            <strong>
              {{ cpMostrar.asignadoIDE != null ? cpMostrar.asignadoIDE : "Disponible" }}
            </strong>
          </td>
        </tr>
        <tr>
          <td><strong>Asignación Virtual: </strong></td>
          <td style="width: 20px"></td>
          <td [ngStyle]="{ color: cpMostrar.asignadoVirtual != null ? 'brown' : 'green' }">
            <strong>
              {{ cpMostrar.asignadoVirtual != null ? cpMostrar.asignadoVirtual : "Disponible" }}
            </strong>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-12 mb-3">
      <div class="btn-group w-100">
        <button
          class="btn btn-sm"
          [ngClass]="mapaservicio.verEspacioPuntoCompra ? 'btn-info' : 'btn-outline-info'"
          [disabled]="mapaservicio.btnEspacioDisabled() || crmservice.userLogin.rol === 'comercial' || crmservice.userLogin.rol === 'influencer'"
          (click)="mapaservicio.visualizarEspacioPuntoCompra()"
        >
          <div class="d-flex justify-content-between">
            <span *ngIf="mapaservicio.cargandoEspacioPuntoCompra" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
            <span *ngIf="mapaservicio.cargandoEspacioPuntoCompra">Calculando...</span>
            <mat-icon *ngIf="!mapaservicio.cargandoEspacioPuntoCompra">{{
              mapaservicio.verEspacioPuntoCompra ? "visibility" : "visibility_off"
            }}</mat-icon>
            <span *ngIf="!mapaservicio.cargandoEspacioPuntoCompra">Punto Compra</span>
          </div>
        </button>

        <button
          class="btn btn-sm"
          [ngClass]="mapaservicio.verEspacioFisico ? 'btn-info' : 'btn-outline-info'"
          [disabled]="mapaservicio.btnEspacioDisabled()"
          (click)="mapaservicio.visualizarEspacioFisico()"
        >
          <div class="d-flex justify-content-between">
            <span *ngIf="mapaservicio.cargandoEspacioFisico" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
            <span *ngIf="mapaservicio.cargandoEspacioFisico">Calculando...</span>
            <mat-icon *ngIf="!mapaservicio.cargandoEspacioFisico">{{ mapaservicio.verEspacioFisico ? "visibility" : "visibility_off" }}</mat-icon>
            <span *ngIf="!mapaservicio.cargandoEspacioFisico">CTM (EF)</span>
          </div>
        </button>

        <button
          class="btn btn-sm"
          [ngClass]="mapaservicio.verEspacioVirtual ? 'btn-info' : 'btn-outline-info'"
          [disabled]="mapaservicio.btnEspacioDisabled()"
          (click)="mapaservicio.visualizarEspacioVirutal()"
        >
          <div class="d-flex justify-content-between">
            <span *ngIf="mapaservicio.cargandoEspacioVirtual" class="spinner-border text-warning" style="width: 24px; height: 24px"></span>
            <span *ngIf="mapaservicio.cargandoEspacioVirtual">Calculando...</span>
            <mat-icon *ngIf="!mapaservicio.cargandoEspacioVirtual">{{ mapaservicio.verEspacioVirtual ? "visibility" : "visibility_off" }}</mat-icon>
            <span *ngIf="!mapaservicio.cargandoEspacioVirtual">Idehabita (EV)</span>
          </div>
        </button>
      </div>

      <div style="padding: 10px">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="calidadAltaMV"
            name="calidad"
            [(ngModel)]="mapaservicio.calidad"
            value="alta"
            (ngModelChange)="mapaservicio.eliminarPoligonos()"
            [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
          />
          <label class="form-check-label" for="calidadAltaMV">Calidad Alta (50MB)</label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="calidadMediaMV"
            name="calidad"
            [(ngModel)]="mapaservicio.calidad"
            value="media"
            (ngModelChange)="mapaservicio.eliminarPoligonos()"
            [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
          />
          <label class="form-check-label" for="calidadMediaMV">Calidad Recomendada (7MB)</label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="calidadBajaMV"
            name="calidad"
            [(ngModel)]="mapaservicio.calidad"
            value="baja"
            (ngModelChange)="mapaservicio.eliminarPoligonos()"
            [disabled]="mapaservicio.cargandoEspacioFisico || mapaservicio.cargandoEspacioVirtual"
          />
          <label class="form-check-label" for="calidadBajaMV">Móvil/Tablet (4MB)</label>
        </div>
      </div>
    </div>
  </div>

  <div class="spinner-container" [hidden]="!cargandoDatos">
    <mat-spinner class="spinner"></mat-spinner>
  </div>

  <div class="row contenedorMapa" [hidden]="cargandoDatos">
    <div class="col-12">
      <div #googleMap class="mapa"></div>
    </div>
  </div>
</div>

<button id="linkDireccion" hidden (click)="linkDireccion()"></button>
