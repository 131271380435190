<div class="usuariosContainer m-xl-5 m-lg-5 m-md-2 m-sm-0" [hidden]="cargandoDatos">

  <h2>
    <span>Balder App</span>
  </h2>

  <mat-tab-group dynamicHeight class="tab-group">

    <mat-tab label="Usuarios">
      <ng-template mat-tab-label>
        <mat-icon class="me-1">person</mat-icon>
        Usuarios
      </ng-template>
      <div class="tab-content">
        <div class="usuarios-header">

          <mat-form-field class="itemsCabecera">
            <label class="w-100 m-0">
              <input autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
            </label>
          </mat-form-field>

          <button class="itemsCabecera" color="primary" matTooltip="Nuevo usuario" (click)="editarUsuario()" mat-icon-button>
            <mat-icon>person_add</mat-icon>
          </button>

        </div>
        <div id="tabla-usuarios">
          <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="nombreUsuario">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</mat-header-cell>
              <mat-cell *matCellDef="let usuario">{{usuario.nombreUsuario}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="nombre">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</mat-header-cell>
              <mat-cell *matCellDef="let usuario">{{usuario.nombre}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="apellidos">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</mat-header-cell>
              <mat-cell *matCellDef="let usuario">{{usuario.apellidos}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="rol">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Rol</mat-header-cell>
              <mat-cell *matCellDef="let usuario">{{usuario.rol}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fechaCreacion">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Creación</mat-header-cell>
              <mat-cell *matCellDef="let usuario">{{usuario.fechaCreacion | date: 'dd/MM/yyyy HH:mm:SS'}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" (click)="editarUsuario(false, row)"></mat-row>

          </mat-table>
        </div>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" id="paginacion-usuarios" [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </mat-tab>

    <mat-tab label="Apps">
      <ng-template mat-tab-label>
        <mat-icon class="me-1">phone_android</mat-icon>
        Apps
      </ng-template>
      <div class="tab-content">

        <div class="m-md-5 m-2 mb-3">
          <table class="table table-bordered table-striped table-hover m-0-auto">
            <thead>
            <tr class="cabecera-apps">
              <th></th>
              <th>Aplicación</th>
              <th>Vesión</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <!-- DESCARGAS -->
            <tr>
              <td class="text-center">
                <mat-icon class="text-success">local_shipping</mat-icon>
              </td>
              <td>Balder Descargas</td>
              <td>1.0.8 / 1.1.0</td>
              <td class="text-center">
                <div class="mb-2">
                  <button class="btn btn-sm btn-success btn-block text-center" (click)="descargarApkDescargas()">
                    DESCARGAR 1.0.8
                  </button>
                </div>
                <div>
                  <button class="btn btn-sm btn-success btn-block text-center" (click)="descargarApkDescargasNueva()">
                    DESCARGAR 1.1.0
                  </button>
                </div>
              </td>
            </tr>

            <!-- INVENTARIO -->
            <tr>
              <td class="text-center">
                <mat-icon class="text-info">description</mat-icon>
              </td>
              <td>Balder Inventario</td>
              <td>
                <span>1.0.3 (Android)</span> / <span>1.0.6 (iOS)</span>
              </td>
              <td class="text-center">
                <button class="btn btn-sm btn-info btn-block text-center" (click)="descargarApkInventario()">
                  DESCARGAR
                </button>
              </td>
            </tr>

            <!-- RECUPERACIÓN -->
            <tr>
              <td class="text-center">
                <mat-icon class="text-warning">refresh</mat-icon>
              </td>
              <td>Balder Recuperación</td>
              <td>1.0.0</td>
              <td class="text-center">
                <button class="btn btn-sm btn-warning btn-block text-center" (click)="descargarApkRecuperacion()">
                  DESCARGAR
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </mat-tab>

  </mat-tab-group>

</div>

<div *ngIf="cargandoDatos" class="spinner-container">
  <mat-spinner class="spinner"></mat-spinner>
</div>
