import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { CrmService } from "../../servicios/crm.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { EncargosList } from "../../modelos/EncargosList";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-encargos",
  templateUrl: "./encargos.component.html",
  styleUrls: ["./encargos.component.css"],
})
export class EncargosComponent implements OnInit {
  etiquetaTodas: Boolean = true;
  etiquetaPresupuestos: boolean = false;
  etiquetaDv: boolean = false;
  etiquetaNini: Boolean = false;
  etiquetaPendtComisionar: boolean = true;
  etiquetaComisionados: boolean = false;
  arrConDuplicados = [];
  arrIntConDuplicados = [];
  arrEncargos = [];
  arrSinDuplicados = [];
  arrConDuplicadosParaOperar: any;
  arraySinRepetidos = [];
  paginatorPage: number;
  arrEncargosT: any;

  displayedColumns = [
    "tipoEncargo",
    "cliente.codigo",
    "cliente.cif",
    "cliente.razonSocial",
    "cliente.nombreComercial",
    "cliente.provincia",
    "cliente.referencia",
    "BNA",
    "fechaCreacion",
    "tieneComisionFacturada",
  ];
  public dataSource: MatTableDataSource<EncargosList> = new MatTableDataSource();
  public cargando: boolean;
  public textoBusqueda: any = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.paginatorPage = 0;
  }

  constructor(public crmservice: CrmService) {
    this.cargando = false;
    this.textoBusqueda = "";
    this.getTodosPresupuestosPedidosDV();

    if (this.textoBusqueda.trim().length) {
      this.applyFilter(this.textoBusqueda);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {}

  public cambiarEstadoEtiquetaTodas() {
    if (this.etiquetaTodas) {
      this.etiquetaPresupuestos = false;
      this.etiquetaDv = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaTodas = true;
      this.etiquetaPresupuestos = false;
      this.etiquetaDv = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  public cambiarEstadoEtiquetaPresupuestos() {
    if (this.etiquetaPresupuestos) {
      this.etiquetaTodas = false;
      this.etiquetaDv = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaPresupuestos = true;
      this.etiquetaTodas = false;
      this.etiquetaDv = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  public cambiarEstadoEtiquetaDv() {
    if (this.etiquetaDv) {
      this.etiquetaTodas = false;
      this.etiquetaPresupuestos = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaDv = true;
      this.etiquetaTodas = false;
      this.etiquetaPresupuestos = false;
      this.etiquetaNini = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  public cambiarEstadoEtiquetaNini() {
    if (this.etiquetaNini) {
      this.etiquetaTodas = false;
      this.etiquetaDv = false;
      this.etiquetaPresupuestos = false;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaNini = true;
      this.etiquetaTodas = false;
      this.etiquetaDv = false;
      this.etiquetaPresupuestos = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  public cambiarEstadoEtiquetaPendtComisionar() {
    if (this.etiquetaPendtComisionar) {
      this.etiquetaPendtComisionar = false;
      this.etiquetaComisionados = true;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaPendtComisionar = true;
      this.etiquetaComisionados = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  public cambiarEstadoEtiquetaComisionados() {
    if (this.etiquetaComisionados) {
      this.etiquetaComisionados = false;
      this.etiquetaPendtComisionar = true;
      this.getTodosPresupuestosPedidosDV();
    } else {
      this.etiquetaComisionados = true;
      this.etiquetaPendtComisionar = false;
      this.getTodosPresupuestosPedidosDV();
    }
  }

  private getTodosPresupuestosPedidosDV(): Promise<void> {
    return new Promise(() => {
      this.crmservice.getTodosPresupuestosPedidosDV().subscribe((items) => {
        this.arrEncargos = items.filter((encargo) => encargo.cliente !== null);

        this.arrEncargos.forEach((item) => {
          item.codigo = item.cliente.codigo;
          item.cif = item.cliente.cif;
          item.razonSocial = item.cliente.razonSocial;
          item.nombreComercial = item.cliente.nombreComercial;
          item.provincia = item.cliente.provincia;
          item.pedidoId = { id: item._id };
          delete item._id;
        });

        if (this.etiquetaPendtComisionar) {
          if (this.etiquetaTodas) {
            const arrEtiquetaPendtComisionar = items.filter(
              (encargo) => (encargo.tieneComisionFacturada === false || encargo.tieneComisionFacturada === undefined) && encargo.cliente !== null
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaPendtComisionar);
          }
          if (this.etiquetaPresupuestos) {
            const arrEtiquetaPendtComisionar = items.filter(
              (encargo) =>
                (encargo.tieneComisionFacturada === false || encargo.tieneComisionFacturada === undefined) &&
                encargo.cliente !== null &&
                encargo.presupuestoRealizacionAceptada === true
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaPendtComisionar);
          }
          if (this.etiquetaDv) {
            const arrEtiquetaPendtComisionar = items.filter(
              (encargo) =>
                (encargo.tieneComisionFacturada === false || encargo.tieneComisionFacturada === undefined) &&
                encargo.cliente !== null &&
                encargo.solicitudDV
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaPendtComisionar);
          }
          if (this.etiquetaNini) {
            const arrEtiquetaPendtComisionar = items.filter(
              (encargo) =>
                (encargo.tieneComisionFacturada === false || encargo.tieneComisionFacturada === undefined) &&
                encargo.cliente !== null &&
                encargo.nini === true
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaPendtComisionar);
          }
        }
        if (this.etiquetaComisionados) {
          if (this.etiquetaTodas) {
            const arrEtiquetaComisionados = items.filter((encargo) => encargo.tieneComisionFacturada === true && encargo.cliente !== null);
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaComisionados);
          }
          if (this.etiquetaPresupuestos) {
            const arrEtiquetaComisionados = items.filter(
              (encargo) => encargo.tieneComisionFacturada === true && encargo.cliente !== null && encargo.presupuestoRealizacionAceptada === true
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaComisionados);
          }
          if (this.etiquetaDv) {
            const arrEtiquetaComisionados = items.filter(
              (encargo) => encargo.tieneComisionFacturada === true && encargo.cliente !== null && encargo.solicitudDV !== false
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaComisionados);
          }
          if (this.etiquetaNini) {
            const arrEtiquetaComisionados = items.filter(
              (encargo) => encargo.tieneComisionFacturada === true && encargo.cliente !== null && encargo.nini === true
            );
            this.arrConDuplicados = this.arrConDuplicados.concat(arrEtiquetaComisionados);
          }
        }

        let sinDuplicar = new Set(this.arrConDuplicados);
        let arrSinDuplicar = Array.from(sinDuplicar);

        let arrParaOrdenar = arrSinDuplicar.filter((encargo: any) => {
          return (
            !encargo.razonSocial.includes("DORMA EXPANSION") &&
            !encargo.razonSocial.includes("DORMA DISEÑO") &&
            encargo.razonSocial !== "JESUS CANO CASAS" &&
            encargo.razonSocial !== "DISTRIBUIDOR"
          );
        });

        arrParaOrdenar.sort((a, b) => {
          if (a.razonSocial.toLowerCase() < b.razonSocial.toLowerCase()) {
            return -1;
          }
          if (a.razonSocial.toLowerCase() > b.razonSocial.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.arraySinRepetidos = arrParaOrdenar;

        this.dataSource.data = this.arraySinRepetidos;
        this.arrConDuplicados = [];
        this.arrEncargosT = this.arraySinRepetidos;
        console.log(this.arraySinRepetidos);

        if (this.paginatorPage == null) {
          this.paginatorPage = 0;
        }
      });
    });
  }

  comisionar(pEncargo) {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: "¿Realizada la factura de comisión de ese encargo?",
      btnConfirm: "Si",
      btnCancel: "No",
    });
    popUpRef.afterClosed().subscribe((value) => {
      if (value) {
        this.crmservice.cambiarEncargoDeNoComisionadoAComisionado(pEncargo).subscribe((items) => {
          this.getTodosPresupuestosPedidosDV();
        });
      }
    });
  }

  public getPageEvent($event: any): void {
    this.paginatorPage = $event.pageIndex;
  }
}
