import {Injectable} from '@angular/core';

@Injectable()
export class SesionService {
  private userLogeado: boolean;

  constructor() {
    console.log('SesionService');
  }

  public get mensaje_login(): string|null|undefined {
    return localStorage.getItem('mensaje_login');
  }
  public set mensaje_login(mensaje: string) {
    localStorage.setItem('mensaje_login', mensaje);
  }
  public remove_mensaje_login(): void {
    localStorage.removeItem('mensaje_login');
  }

  public getUserLogeado(): boolean {
    return this.userLogeado;
  }
  public setUserLogeado(estado: boolean): void {
    this.userLogeado = estado;
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }
  public setToken(access_token: string): void {
    localStorage.setItem('access_token', access_token);
  }
  public removeToken(): void {
    localStorage.removeItem('access_token');
  }
}
