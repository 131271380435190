<div
  *ngIf="!cargandoDatos && crmservice.datosCargados"
  class="m-lg-5 m-sm-2 m-2"
>
  <h2>Gestor de Tiempos</h2>
  <mat-tab-group dynamicHeight class="tab-group">
    <mat-tab *ngIf="crmservice.userLogin.rol == 'admin'" label="Configuracion">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">settings_applications</mat-icon>
        Configuración
      </ng-template>

      <div class="tab-content">
        <div class="container">
          <div class="row mt-5">
            <div class="col-12">
              <h5>Realización de Acciones</h5>
            </div>

            <div class="col-12">
              <div class="alert alert-info">
                Si desactivas el Gestor de Tiempos, el programa seguirá
                funcionando normalmente, pero los asesores podrán realizar
                acciones normalmente en el CRM.
              </div>
            </div>

            <div class="col-12">
              <button
                class="btn btn-sm"
                [ngClass]="
                  crmservice.modoPruebas ? 'btn-warning' : 'btn-secondary'
                "
                (click)="cambiarModoPruebas()"
              >
                {{ crmservice.modoPruebas ? "Desactivar" : "Activar" }} Gestor
                Tiempos
              </button>
            </div>

            <div class="col-12">
              <hr class="border-top border-secondary" />
            </div>

            <div class="col-12">
              <h5>Configuración de DNIe</h5>
            </div>

            <div class="col-12">
              <div class="alert alert-info">
                Si el DNIe está activo en una tienda, sólo se puede hacer login
                con el DNIe.<br />
                En caso contrario, se puede hacer login con usuario y contraseña
                y con DNIe.
              </div>
            </div>

            <div class="col-lg-8 col-sm-12 col-12">
              <table
                class="table table-sm table-striped table-bordered text-center w-600px"
              >
                <thead class="cabecera-tabla">
                  <tr>
                    <th>Tienda</th>
                    <th>DNIe</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let config of configDNIeTiendas">
                    <td class="align-middle">
                      <strong>{{ config.nombreTienda }}</strong>
                    </td>
                    <td>
                      <img
                        [src]="
                          config.activo
                            ? 'assets/img/dnie.png'
                            : 'assets/img/dnie_false.png'
                        "
                        alt="DNIe"
                        class="img-dni"
                      />
                    </td>
                    <td class="w-100px">
                      <button
                        class="btn btn-sm btn-warning btn-block"
                        (click)="cambiarConfig(config)"
                      >
                        {{ config.activo ? "Desactivar" : "Activar" }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-12 text-end mb-4">
              <button
                class="btn btn-sm btn-info mb-4 w-100px"
                [ngClass]="!addConfig ? 'btn-info' : 'btn-danger'"
                (click)="addConfig = !addConfig"
              >
                {{ !addConfig ? "Añadir" : "Cancelar" }}
              </button>

              <div *ngIf="addConfig" class="mb-4">
                <div class="row m-0">
                  <div class="col-12 p-0 mb-4">
                    <div class="input-group">
                      <label for="inputTienda"></label>
                      <input
                        id="inputTienda"
                        class="form-control form-control-sm"
                        placeholder="Tienda..."
                        [(ngModel)]="nombreTienda"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-sm btn-success w-100px"
                          (click)="nuevaConfig()"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="errAddConfig != ''" class="col-12">
                    <span class="text-danger"
                      ><strong>{{ errAddConfig }}</strong></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Rendimiento">
      <ng-template mat-tab-label>
        <mat-icon class="iconHeader">timeline</mat-icon>
        Rendimiento
      </ng-template>

      <div class="tab-content">
        <div
          class="row"
          *ngIf="
            crmservice.userLogin.rol == 'admin' ||
            crmservice.userLogin.rol == 'supervisor'
          "
        >
          <div class="col-12 col-md-4 centrarCol">
            <mat-form-field appearance="outline" class="inputForm">
              <mat-label>Usuario</mat-label>
              <mat-select
                placeholder="Usuario"
                (selectionChange)="cambioUsuarioNuevo()"
                [(ngModel)]="usuarioSel"
              >
                <ng-container *ngFor="let usuario of crmservice.usuarios">
                  <mat-option *ngIf="!usuario.desactivado" [value]="usuario">
                    <img
                      class="imagenSelect"
                      [src]="
                        usuario.imagen != undefined
                          ? usuario.imagen
                          : 'assets/img/otros/person.png'
                      "
                      alt=""
                    />
                    {{ usuario.nombre }} {{ usuario.apellidos }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="usuarioSel != null">
          <div *ngIf="tiemposUsuario.length">
            <div class="row" [formGroup]="opcionesFormGroup">
              <div
                *ngIf="
                  crmservice.userLogin.rol == 'admin' ||
                  crmservice.userLogin.rol == 'supervisor'
                "
                class="col-4"
              >
                <mat-form-field appearance="outline">
                  <mat-label>Tipo de selección</mat-label>
                  <mat-select
                    [(ngModel)]="busquedaSimple"
                    formControlName="busquedaSimple"
                    placeholder="Tipo de búsqueda"
                  >
                    <mat-option [value]="true">Simple</mat-option>
                    <mat-option [value]="false">Más de un día</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field appearance="outline" class="inputForm">
                  <mat-label>{{
                    busquedaSimple ? "Día Seleccionado" : "Desde"
                  }}</mat-label>
                  <label class="w-100 m-0">
                    <input
                      [matDatepicker]="desde"
                      formControlName="fDesde"
                      [(ngModel)]="fDesde"
                      matInput
                      placeholder="Desde"
                    />
                  </label>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="desde"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #desde></mat-datepicker>
                </mat-form-field>
                <label *ngIf="mensajeInfo == 'fecha'" class="text-danger"
                  >La fecha 'Desde' debe ser menor que 'Hasta'.</label
                >
              </div>

              <div class="col-4">
                <div style="display: flex; align-items: center; height: 63px">
                  <button
                    (click)="actualizarFechas()"
                    class="btn btn-sm btn-primary w-100px"
                  >
                    Cargar
                  </button>
                </div>
              </div>

              <div class="col-4">
                <mat-form-field
                  *ngIf="!busquedaSimple"
                  appearance="outline"
                  class="inputForm"
                >
                  <mat-label>Hasta</mat-label>
                  <label class="w-100 m-0">
                    <input
                      [matDatepicker]="hasta"
                      formControlName="fHasta"
                      [(ngModel)]="fHasta"
                      matInput
                      placeholder="Hasta"
                    />
                  </label>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="hasta"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #hasta></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="!tiemposUsuario.length" class="text-center">
            <h6>
              El usuario
              <strong
                >{{ usuarioSel.nombre }} {{ usuarioSel.apellidos }}</strong
              >
              no tiene tiempos registrados.
            </h6>
          </div>
        </div>

        <div *ngIf="usuarioSel != null && tiemposUsuario.length" class="mt-4">
          <mat-card class="bg-light">
            <mat-card-content>
              <div
                *ngIf="tiemposUsuario.length && mensajeInfo == ''"
                class="mb-5"
              >
                <div
                  echarts
                  [options]="chartOption"
                  class="h-700px w-100"
                ></div>
              </div>

              <div *ngIf="mensajeInfo != ''" class="text-center">
                <h5 *ngIf="busquedaSimple">
                  No hay tiemos que mostrar en la fecha seleccionada
                </h5>
                <h5 *ngIf="!busquedaSimple">
                  No hay tiemos que mostrar en las fechas seleccionadas
                </h5>
              </div>

              <mat-card class="col-12">
                <mat-card-header>
                  <mat-card-title> Opciones </mat-card-title>
                </mat-card-header>
                <mat-card-content [formGroup]="opcionesFormGroup">
                  <div class="col-3">
                    <mat-checkbox
                      [(ngModel)]="tiempoExtra"
                      (ngModelChange)="actualizarDiagrama()"
                      formControlName="tiempoAsignando"
                      color="primary"
                      ><span class="color-asignando">
                        <strong>Tiempo Asignando</strong></span
                      >
                      - Muestra el tiempo que ha invertido el usuario en asignar
                      los tiempos a las acciones.
                    </mat-checkbox>
                  </div>

                  <div class="col-3">
                    <mat-checkbox
                      [(ngModel)]="tiempoJornadas"
                      (ngModelChange)="actualizarDiagrama()"
                      formControlName="tiempoJornadas"
                      color="primary"
                      ><span class="color-jornada">
                        <strong>Jornada Laboral</strong></span
                      >
                      - Muestra el tiempo de lasjornadas laborales indicadas por
                      el usuario en el Gestor de Tiempos.
                    </mat-checkbox>
                  </div>

                  <div class="col-3">
                    <mat-checkbox
                      [(ngModel)]="tiempoNoJustificado"
                      (ngModelChange)="actualizarDiagrama()"
                      formControlName="tiempoNoJustificado"
                      color="primary"
                      ><span class="color-no-justificado">
                        <strong>Tiempo no Justificado</strong></span
                      >
                      - Muestra el tiempo no justificado del usuario.
                    </mat-checkbox>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="tiempoNoJustificado" class="col-12">
                <mat-card-header>
                  <mat-card-title>
                    TIEMPO NO JUSTIFICADO -
                    <span
                      ><strong>{{ totalNoJustificado }}</strong></span
                    >
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="busquedaSimple">
                  <div class="col-6">
                    De {{ usuarioSel.nombre }} {{ usuarioSel.apellidos }} el dia
                    {{ fDesde | date : "dd-MM-yyyy" }}.
                  </div>
                </mat-card-content>
                <mat-card-content *ngIf="!busquedaSimple">
                  <div class="col-6">
                    De {{ usuarioSel.nombre }} {{ usuarioSel.apellidos }} desde
                    {{ fDesde | date : "dd-MM-yyyy" }} hasta
                    {{ fHasta | date : "dd-MM-yyyy" }}.
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab class="btn-descargar" label="Descargar" disabled>
      <ng-template mat-tab-label>
        <button
          mat-button
          class="btn-descargar"
          (click)="descargarGestorTiempos()"
        >
          <mat-icon>download</mat-icon>
          Descargar
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div
  *ngIf="cargandoDatos || !crmservice.datosCargados"
  class="spinner-container"
>
  <mat-spinner class="spinner"></mat-spinner>
</div>
