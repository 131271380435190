import {ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, HostListener} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {CrmService} from '../../servicios/crm.service';
import {Usuario} from '../../modelos/Usuario';
import {MediaMatcher} from '@angular/cdk/layout';
import {UsuarioFormularioComponent} from '../usuario-formulario/usuario-formulario.component';
import {Router} from '@angular/router';
import {EditarUsuarioComponent} from '../editar-usuario/editar-usuario.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit, AfterViewInit {

  displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'equipo', 'fechaUltimaCon'];
  public dataSource: MatTableDataSource<Usuario> = new MatTableDataSource();
  public usuariosMostrar: string;

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.mobileQuery.matches) {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol'];
    } else {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'equipo', 'fechaUltimaCon'];
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.usuariosMostrar = 'activos';
    this.cambiarUsuariosMostrar();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public router: Router) {

    if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol !== 'admin' && this.crmservice.userLogin.rol !== 'supervisor') {
      this.router.navigate(['agenda']).then();
    }

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (this.mobileQuery.matches) {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol'];
    } else {
      this.displayedColumns = ['nombreUsuario', 'nombre', 'apellidos', 'rol', 'equipo', 'fechaUltimaCon'];
    }

    this.crmservice.dataSourceUsuarios = this.dataSource;
    this.usuariosMostrar = 'activos';
  }

  ngOnInit() {
  }

  public nuevoUser(): void {
    const dialogRef = this.dialog.open(UsuarioFormularioComponent, {
      height: !this.mobileQuery.matches ? '800px' : '100%',
      width: !this.mobileQuery.matches ? '800px' : '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(nuevoUser => {
      if (nuevoUser != null) {
        this.crmservice.usuarios.push(nuevoUser);
      }
    });
  }

  public editarUser(_id: string): void {
    if (this.crmservice.userLogin.rol === 'admin') {
      const dialogRef = this.dialog.open(EditarUsuarioComponent, {
        height: !this.mobileQuery.matches ? '700px' : '100%',
        width: !this.mobileQuery.matches ? '800px' : '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: {
          userID: _id
        }
      });

      dialogRef.afterClosed().subscribe(usuarioEditar => {
          if (usuarioEditar != null) {
            const pos = this.crmservice.usuarios.findIndex(e => e._id === usuarioEditar._id);
            if (pos !== -1) {
              this.crmservice.usuarios[pos].nombre = usuarioEditar.nombre;
              this.crmservice.usuarios[pos].apellidos = usuarioEditar.apellidos;
              this.crmservice.usuarios[pos].poblacion = usuarioEditar.poblacion;
              this.crmservice.usuarios[pos].provincia = usuarioEditar.provincia;
              this.crmservice.usuarios[pos].telefono = usuarioEditar.telefono;
              this.crmservice.usuarios[pos].email = usuarioEditar.email;
              this.crmservice.usuarios[pos].team = usuarioEditar.team;
              this.crmservice.usuarios[pos].rol = usuarioEditar.rol;

              if (this.crmservice.usuarios[pos]._id === this.crmservice.userLogin._id) {
                this.crmservice.userLogin.nombre = usuarioEditar.nombre;
                this.crmservice.userLogin.apellidos = usuarioEditar.apellidos;
                this.crmservice.userLogin.poblacion = usuarioEditar.poblacion;
                this.crmservice.userLogin.provincia = usuarioEditar.provincia;
                this.crmservice.userLogin.telefono = usuarioEditar.telefono;
                this.crmservice.userLogin.email = usuarioEditar.email;
                this.crmservice.userLogin.team = usuarioEditar.team;
                this.crmservice.userLogin.rol = usuarioEditar.rol;
              }
            }
          }
        },
        () => {
          this.snackBar.open('Ex0001', 'OK', {duration: 2000});
        },
        () => {
          this.dataSource._updateChangeSubscription();
        });
    }
  }

  public cambiarUsuariosMostrar(): void {
    if (this.usuariosMostrar === 'todos') {
      this.dataSource.data = this.crmservice.usuarios;
    } else if (this.usuariosMostrar === 'activos') {
      this.dataSource.data = this.crmservice.usuarios.filter(e => e.desactivado !== true);
    } else if (this.usuariosMostrar === 'noActivos') {
      this.dataSource.data = this.crmservice.usuarios.filter(e => e.desactivado === true);
    }
  }
  public numUsuarios(mostrar: string): number {
    if (mostrar === 'todos') {
      return this.crmservice.usuarios.length;
    } else if (mostrar === 'activos') {
      return this.crmservice.usuarios.filter(e => e.desactivado !== true).length;
    } else if (mostrar === 'noActivos') {
      return this.crmservice.usuarios.filter(e => e.desactivado === true).length;
    }
  }
}
