import {CrmService} from '../servicios/crm.service';

interface Cliente {
  readonly _id: string;
  readonly _idCli: string;
  readonly nombreComercial: string;
  readonly razonSocial: string;
  readonly equipo: string;
}

export class AlertaClienteConsumo {
  public _id: string;
  public cliente: Cliente;
  public nombreComercial: string;
  public razonSocial: string;
  public factTotal: number;
  public factCurrentYear: number;
  public factLastYear: number;
  public factCurrentMonth: number;
  public factLastMonth: number;
  public factLastYearMonth: number;

  public balanceCurrentYear: boolean;
  public balanceCurrentYearDate: Date;
  public balanceCurrentMonth: boolean;
  public balanceCurrentMonthDate: Date;
  public balanceLastYearMonth: boolean;
  public balanceLastYearMonthDate: Date;

  constructor(a: AlertaClienteConsumo | any | null, crmService: CrmService) {
    if (a != null) {
      this._id = a._id;

      const cliente = crmService.direcciones.find(d => d._idCli === a.cliente);
      if (cliente != null) {
        this.cliente = cliente;
        this.razonSocial = cliente.razonSocial;
        this.nombreComercial = cliente.nombreComercial;
      } else {
        this.cliente = null;
      }

      this.factTotal = Math.round(a.factTotal);
      this.factCurrentYear = Math.round(a.factCurrentYear);
      this.factLastYear = Math.round(a.factLastYear);
      this.factCurrentMonth = Math.round(a.factCurrentMonth);
      this.factLastMonth = Math.round(a.factLastMonth);
      this.factLastYearMonth = Math.round(a.factLastYearMonth);

      this.balanceCurrentYear = a.balanceCurrentYear;
      this.balanceCurrentYearDate = new Date(a.balanceCurrentYearDate);
      this.balanceCurrentMonth = a.balanceCurrentMonth;
      this.balanceCurrentMonthDate = new Date(a.balanceCurrentMonthDate);
      this.balanceLastYearMonth = a.balanceLastYearMonth;
      this.balanceLastYearMonthDate = new Date(a.balanceLastYearMonthDate);
    }
  }

  public get factCurrentYearFmat(): string {
    return this.formatNumber(this.factCurrentYear);
  }
  public get factLastYearFmat(): string {
    return this.formatNumber(this.factLastYear);
  }
  public get factCurrentMonthFmat(): string {
    return this.formatNumber(this.factCurrentMonth);
  }
  public get factLastMonthFmat(): string {
    return this.formatNumber(this.factLastMonth);
  }
  public get factLastYearMonthFmat(): string {
    return this.formatNumber(this.factLastYearMonth);
  }

  public get diffYear(): number {
    return Math.round(this.factCurrentYear - this.factLastYear);
  }
  public get diffMonth(): number {
    return Math.round(this.factCurrentMonth - this.factLastMonth);
  }
  public get diffYearMonth(): number {
    return Math.round(this.factCurrentMonth - this.factLastYearMonth);
  }

  public get diffYearFmat(): string {
    return this.formatNumber(Math.round(this.factCurrentYear - this.factLastYear));
  }
  public get diffMonthFmat(): string {
    return this.formatNumber(Math.round(this.factCurrentMonth - this.factLastMonth));
  }
  public get diffYearMonthFmat(): string {
    return this.formatNumber(Math.round(this.factCurrentMonth - this.factLastYearMonth));
  }

  private formatNumber(numero: number, decimales: boolean = true) {

    const nString = numero.toString(10);
    const entera = nString.split('.')[0];

    let decimal = '';

    if (nString.split('.').length === 2) {
      decimal = ',' + nString.split('.')[1];
    }

    if (decimales) {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + decimal.slice(0, 3);
    } else {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }
}

