<div class="cabecera-modal">
  <img src="../../../../../../assets/img/tcla_icon.ico" alt="TECLA" id="img-tcla" class="icon-modal">
  <strong>ASIGNAR PERFIL TECLA</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">

  <div class="m-4">
    <mat-tab-group dynamicHeight [selectedIndex]="tabIndex" [hidden]="cargando">

      <!-- SELECCIONAR TECLA -->
      <mat-tab label="Perfil TECLA" disabled>
        <ng-template mat-tab-label>
          <span class="user-select-none" [ngClass]="tabIndex === 0 ? 'fw-bold text-primary' : ''">1.- PERFIL TECLA</span>
        </ng-template>

        <div class="tab-content mt-3">
          <div class="row mb-3">
            <div class="col-12 col-md-6">
              <select class="form-select" [(ngModel)]="provincia" (ngModelChange)="getPerfiles()">
                <option *ngIf="crmservice.userLogin.rol !== 'comercial' && crmservice.userLogin.rol !== 'influencer'" [value]="'todas'">Todas</option>
                <option *ngFor="let p of provincias" [value]="p">{{p}}</option>
              </select>
            </div>

            <div class="col-12 col-md-6">
              <input class="form-control" type="search" placeholder="Buscar..." autocomplete="false" [(ngModel)]="textoBusqueda" (ngModelChange)="applyFilter()">
            </div>
          </div>

          <div>
            <table mat-table [dataSource]="dataSourcePerfiles" matSortActive="fechacreacion" matSortDirection="desc" matSort class="w-100 user-select-none">

              <ng-container matColumnDef="usuario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
                <td mat-cell *matCellDef="let perfil">{{perfil.usuario}}</td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let perfil">{{perfil.email}}</td>
              </ng-container>

              <ng-container matColumnDef="nombreFiscal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Razón Social</th>
                <td mat-cell *matCellDef="let perfil">{{perfil.nombreFiscal}}</td>
              </ng-container>

              <ng-container matColumnDef="provincia">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Provincia</th>
                <td mat-cell *matCellDef="let perfil">{{perfil.provincia}}</td>
              </ng-container>

              <ng-container matColumnDef="fechacreacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Registro</th>
                <td mat-cell *matCellDef="let perfil">
                  {{perfil.fechacreacion | date: 'dd/MM/yyyy'}}<br>
                  {{perfil.fechacreacion | date: 'HH:mm:SS'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="activo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activo</th>
                <td mat-cell *matCellDef="let perfil" [ngClass]="perfil.activo ? 'text-success fw-bold' : 'text-danger fw-bold'">{{perfil.activo ? 'ACTIVO' : 'NO ACTIVO'}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-tabla" (click)="seleccionarPerfil(row)"></tr>
            </table>

            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true" ></mat-paginator>
          </div>
        </div>
      </mat-tab>

      <!-- COMPROBAR DATOS -->
      <mat-tab label="COMPROBAR DATOS" disabled>
        <ng-template mat-tab-label>
          <span class="user-select-none" [ngClass]="tabIndex === 1 ? 'fw-bold text-primary' : ''">2.- COMPROBAR DATOS</span>
        </ng-template>

        <div class="tab-content mt-3">

          <div *ngIf="perfilDetalle != null" class="alert alert-info">
            Has seleccionado el usuario <b>{{perfilDetalle.usuario}}</b><br>
            <span class="d-flex">
              En <span class="fw-bold text-warning ms-1 me-1">AMARILLO</span> se muestran los datos que no coinciden en <b class="ms-1 me-1">TECLA</b> y <b class="ms-1 me-1">CRM</b>.
              Utiliza <mat-icon class="ms-1 me-1">send</mat-icon> para pasar un dato de <b class="ms-1 me-1">TECLA</b> a <b class="ms-1 me-1">CRM</b> o <mat-icon class="ms-1 me-1">refresh</mat-icon>
              para restablecer un dato cambiado.
            </span>
          </div>

          <div *ngIf="dirTECLARepetido.length > 0" class="alert alert-danger">
            <p><strong>¡YA HAY CLIENTES CON ESTE PERFIL TECLA!</strong></p>
            <p>Puedes asignar el perfil a la dirección, pero lo recomendable es utlizar otro o crear uno nuevo si es necesario.</p>
            <ul>
              <li *ngFor="let nc of dirTECLARepetido">{{nc}}</li>
            </ul>
          </div>

          <table *ngIf="perfilDetalle != null" class="table table-bordered">
            <thead>
            <tr class="bg-light text-center">
              <th style="width: 20%">Campo</th>
              <th style="width: 35%">TECLA</th>
              <th style="width: 35%">CRM</th>
              <th style="width: 10%">Copiar</th>
            </tr>
            </thead>
            <tbody>
            <!-- CIF -->
            <tr class="text-center" [ngClass]="perfilDetalle.cif_dni !== datosCopiar.cif ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">CIF</td>
              <td>{{perfilDetalle.cif_dni}}</td>
              <td>{{datosCopiar.cif || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.cif = perfilDetalle.cif_dni">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.cif = cliente.cif || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- RAZÓN SOCIAL -->
            <tr class="text-center" [ngClass]="perfilDetalle.nombreFiscal !== datosCopiar.razonSocial ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Razón Social</td>
              <td>{{perfilDetalle.nombreFiscal}}</td>
              <td>{{datosCopiar.razonSocial || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.razonSocial = perfilDetalle.nombreFiscal">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.razonSocial = cliente.razonSocial || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- NOMBRE COMERCIAL -->
            <tr class="text-center" [ngClass]="perfilDetalle.nombreComercial !== datosCopiar.nombreComercial ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Nombre Comercial</td>
              <td>{{perfilDetalle.nombreComercial}}</td>
              <td>{{datosCopiar.nombreComercial || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.nombreComercial = perfilDetalle.nombreComercial">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.nombreComercial = direccion.nombreComercial || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- PROVINCIA -->
            <tr class="text-center" [ngClass]="perfilDetalle.provincia !== datosCopiar.provincia ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Provincia</td>
              <td>{{perfilDetalle.provincia}}</td>
              <td>{{datosCopiar.provincia || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.provincia = perfilDetalle.provincia">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.provincia = direccion.provincia || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- POBLACIÓN -->
            <tr class="text-center" [ngClass]="perfilDetalle.poblacion !== datosCopiar.poblacion ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Población</td>
              <td>{{perfilDetalle.poblacion}}</td>
              <td>{{datosCopiar.poblacion || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.poblacion = perfilDetalle.poblacion">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.poblacion = direccion.poblacion || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- CÓDIGO POSTAL -->
            <tr class="text-center" [ngClass]="perfilDetalle.cp !== datosCopiar.codigoPostal ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Código Postal</td>
              <td>{{perfilDetalle.cp}}</td>
              <td>{{datosCopiar.codigoPostal || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.codigoPostal = perfilDetalle.cp">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.codigoPostal = direccion.codigoPostal || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- TELÉFONO -->
            <tr class="text-center" [ngClass]="perfilDetalle.telefono !== datosCopiar.telefono ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Teléfono</td>
              <td>{{perfilDetalle.telefono}}</td>
              <td>{{datosCopiar.telefono || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.telefono = perfilDetalle.telefono">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.telefono = direccion.telefono || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            <!-- EMAIL -->
            <tr class="text-center" [ngClass]="perfilDetalle.email !== datosCopiar.email ? 'bg-warning' : ''">
              <td class="bg-light fw-bold">Email</td>
              <td>{{perfilDetalle.email}}</td>
              <td>{{datosCopiar.email || 'No Tiene'}}</td>
              <td>
                <button class="btn btn-sm btn-info text-white float-start" (click)="datosCopiar.email = perfilDetalle.email">
                  <mat-icon class="d-flex">send</mat-icon>
                </button>
                <button class="btn btn-sm btn-success text-white float-end" (click)="datosCopiar.email = direccion.email || null">
                  <mat-icon class="d-flex">refresh</mat-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="text-center">
            <button class="btn btn-outline-secondary w-150px" (click)="volver()">
              <span class="d-flex justify-content-around">
                <mat-icon>navigate_before</mat-icon>
                <span>VOLVER</span>
              </span>
            </button>
            <button *ngIf="perfilDetalle != null && !perfilDetalle.activo" class="btn btn-success ms-5 w-150px" (click)="siguiente()">
              <span class="d-flex justify-content-around">
                <span>SIGUIENTE</span>
                <mat-icon>navigate_next</mat-icon>
              </span>
            </button>
            <button *ngIf="perfilDetalle != null && perfilDetalle.activo" class="btn btn-success ms-5 w-150px">
              <span class="d-flex justify-content-around" (click)="asignar()">
                <span>ASIGNAR</span>
                <mat-icon>check</mat-icon>
              </span>
            </button>
          </div>
        </div>
      </mat-tab>

      <!-- PUNTO DE COMPRA -->
      <mat-tab label="PUNTO DE COMPRA" disabled>
        <ng-template mat-tab-label>
          <span class="user-select-none" [ngClass]="tabIndex === 2 ? 'fw-bold text-primary' : ''">3.- PUNTO DE COMPRA</span>
        </ng-template>

        <div class="tab-content mt-3">
          <div class="w-50 m-auto">
            <label class="form-label fw-bold">Punto Compra:</label>
            <input type="number" [(ngModel)]="puntoCompra" class="form-control" min="0" max="1">
          </div>

          <div class="text-center mt-3">
            <button class="btn btn-outline-secondary w-150px" (click)="volver()">
              <span class="d-flex justify-content-around">
                <mat-icon>navigate_before</mat-icon>
                <span>VOLVER</span>
              </span>
            </button>
            <button class="btn btn-success ms-5 w-150px" (click)="siguiente()">
              <span class="d-flex justify-content-around">
                <mat-icon>done</mat-icon>
                <span>ACTIVAR</span>
              </span>
            </button>
          </div>
        </div>
      </mat-tab>

      <!-- FINALIZAR -->
      <mat-tab label="FINALIZAR" disabled>
        <ng-template mat-tab-label>
          <span class="user-select-none" [ngClass]="tabIndex === 3 ? 'fw-bold text-primary' : ''">4.- FINALIZAR</span>
        </ng-template>

        <div class="tab-content">
          <h3 class="text-center mt-5 mb-5">¿Desea envíar un email de comunicación de activación de cuenta al usuario?</h3>
          <div class="text-center">
            <button class="btn btn-outline-secondary w-150px me-3" (click)="volver()">
              <span class="d-flex justify-content-around">
                <mat-icon>navigate_before</mat-icon>
                <span>VOLVER</span>
              </span>
            </button>
            <button class="btn btn-danger ms-3 me-3 w-150px" (click)="activarAsignar(false)">
              <span class="d-flex justify-content-around">
                <mat-icon>unsubscribe</mat-icon>
                <span>NO ENVIAR</span>
              </span>
            </button>
            <button class="btn btn-success ms-3 w-150px" (click)="activarAsignar(true)">
              <span class="d-flex justify-content-around">
                <mat-icon>mark_email_read</mat-icon>
                <span>ENVIAR</span>
              </span>
            </button>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>

  <div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
    <mat-spinner></mat-spinner>
  </div>

</div>
