<div [hidden]="!crmservice.datosCargados || cargando" class="m-xl-4 m-lg-3 m-md-2 m-sm-2 m-1">
  <div>
    <div class="mb-3">
      <h2 class="text-secondary">Preguntas de Clientes</h2>
    </div>
    <table mat-table [dataSource]="preguntas" matSortActive="fechacreacion" matSortDirection="desc" id="tabla-preguntas" matSort>
      <ng-container matColumnDef="fechacreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let pregunta">
          <div *ngIf="pregunta.respondida == 'Sin Responder'" [class.sinResponderIcon]="pregunta.respondida == 'Sin Responder'"></div>
          {{pregunta.fechacreacion | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="emailCliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let pregunta">{{pregunta.emailCliente}}</td>
      </ng-container>
      <ng-container matColumnDef="cpCliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Código Postal</th>
        <td mat-cell *matCellDef="let pregunta">{{pregunta.cpCliente}}</td>
      </ng-container>
      <ng-container matColumnDef="provinciaCliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provincia</th>
        <td mat-cell *matCellDef="let pregunta">{{pregunta.provinciaCliente}}</td>
      </ng-container>
      <ng-container matColumnDef="respondida">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Respondida</th>
        <td mat-cell *matCellDef="let pregunta">{{pregunta.respondida}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.fw-bold]="row.respondida == 'Sin Responder'" (click)="responderPregunta(row)"></tr>
    </table>
  </div>
  <div id="paginacion-preguntas">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"></mat-paginator>
  </div>
</div>

<div *ngIf="cargando || !crmservice.datosCargados" class="position-absolute start-50 top-50 translate-middle">
  <mat-spinner class="spinner"></mat-spinner>
</div>
