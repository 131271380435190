import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../../servicios/crm.service';
import {ClienteFormularioComponent} from '../cliente-formulario/cliente-formulario.component';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {DireccionRow} from '../../modelos/DireccionRow';

@Component({
  selector: 'app-direcciones',
  templateUrl: './direcciones.component.html',
  styleUrls: ['./direcciones.component.css']
})
export class DireccionesComponent implements OnInit, AfterViewInit {

  displayedColumns = ['estado', 'clasificacion', 'cif', 'razonSocial', 'nombreComercial', 'provincia', 'poblacion', 'ultimaAccion', 'telefono', 'email'];
  public dataSource: MatTableDataSource<DireccionRow> = new MatTableDataSource();

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.mobileQuery.matches) {
      this.displayedColumns = ['estado', 'razonSocial', 'nombreComercial'];
    } else {
      this.displayedColumns = ['estado', 'clasificacion', 'cif', 'razonSocial', 'nombreComercial', 'provincia', 'poblacion', 'ultimaAccion'];
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cargarDatosDirecciones();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.crmservice.paginatorPage = 0;
  }

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private router: Router) {

    console.log('Direcciones');

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (this.mobileQuery.matches) {
      this.displayedColumns = ['estado', 'razonSocial', 'nombreComercial'];
    } else {
      this.displayedColumns = ['estado', 'clasificacion', 'cif', 'razonSocial', 'nombreComercial', 'provincia', 'poblacion', 'ultimaAccion'];
    }

    this.crmservice.dataSourceDirecciones = this.dataSource;

    if (this.crmservice.filtroClientes.trim().length) {
      this.applyFilter(this.crmservice.filtroClientes);
    }
  }
  

  ngOnInit() {
  }

  public nuevoCliente(): void {
    const dialogRef = this.dialog.open(ClienteFormularioComponent,
      {
        panelClass: 'custom-dialog-container',
        disableClose: true,
        height: !this.mobileQuery.matches ? '800px' : '100%',
        width: !this.mobileQuery.matches ? '700px' : '100%',
        maxWidth: '100%',
        maxHeight: '100%'
      });

    dialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        this.router.navigate(['direccion', value._id]).then();
      }
    }, () => {
      this.snackBar.open('Ex0002', 'OK', {duration: 2000});
    }, () => {
      this.dataSource._updateChangeSubscription();
    });
  }

  public navegarA(_id: string): void {
    this.crmservice.direccionSeleccionada[0] = false;
    this.router.navigate(['direccion', _id]).then();
  }

  public cargarDatosDirecciones(): void {
    if (this.crmservice == null || !this.crmservice.datosCargados) {
      window.setTimeout(this.cargarDatosDirecciones, 100);
    } else {
      if (this.crmservice.equipoMostrarClientes == null) {
        this.crmservice.equipoMostrarClientes = (this.crmservice.userLogin.rol !== 'comercial' && this.crmservice.userLogin.rol !== 'influencer') ? 'TODOS' : this.crmservice.userLogin.team;
      }
      if (this.crmservice.clasificacionMostrarClientes == null) {
        this.crmservice.clasificacionMostrarClientes = 'TODOS';
      }
      if (this.crmservice.estadoMostrarClientes == null) {
        this.crmservice.estadoMostrarClientes = 'TODOS';
      }
      if (this.crmservice.tipoMostrarClientes == null) {
        this.crmservice.tipoMostrarClientes = 'TODOS';
      }
      if (this.crmservice.categoriaMostrarClientes == null) {
        this.crmservice.categoriaMostrarClientes = 'TODOS';
      }
      if (this.crmservice.paginatorPage == null) {
        this.crmservice.paginatorPage = 0;
      }
      this.cambiarDireccionesVer();

      setTimeout(() => {
        this.paginator.pageIndex = this.crmservice.paginatorPage;
        if (this.paginator.hasNextPage()) {
          this.paginator.nextPage();
          this.paginator.previousPage();
        } else {
          this.paginator.previousPage();
          this.paginator.nextPage();
        }
      }, 10);
    }
  }

  public getClasificacion(clasificacion: string): string {
    return (clasificacion === 'B1' || clasificacion === 'B2') ? 'B' : clasificacion;
  }

  public getColorClasificacion(clasificacion: string): string {
    if (clasificacion === 'A') {
      return 'limegreen';
    }

    if (clasificacion === 'B1' || clasificacion === 'B2') {
      return 'gold';
    }

    if (clasificacion === 'C') {
      return 'brown';
    }

    return 'pink';
  }

  // Se da un string y el tamaño máximo deseado. Devuelve el string trucando al
  // número indicado seguido de '...'
  public truncarString(s: string, n: number): string {
    if (s.length > n + 3) {
      return s.slice(0, n) + '...';
    }
    return s;
  }

  public cambiarDireccionesVer(): void {
    this.dataSource.data = [];

    // EQUIPOS
    if (this.crmservice.equipoMostrarClientes === 'TODOS') {
      this.dataSource.data = this.crmservice.direcciones;
    } else {
      this.dataSource.data = this.crmservice.direcciones.filter(e => e.equipo === this.crmservice.equipoMostrarClientes);
    }

    // CLASIFICACIÓN
    if (this.crmservice.clasificacionMostrarClientes !== 'TODOS') {
      if (this.crmservice.clasificacionMostrarClientes === 'A' || this.crmservice.clasificacionMostrarClientes === 'C') {
        this.dataSource.data = this.dataSource.data.filter(e => e.clasificacion === this.crmservice.clasificacionMostrarClientes);
      } else if (this.crmservice.clasificacionMostrarClientes === 'B') {
        this.dataSource.data = this.dataSource.data.filter(e => e.clasificacion === 'B1' || e.clasificacion === 'B2');
      }
    }

    // ESTADO
    if (this.crmservice.estadoMostrarClientes !== 'TODOS') {
      this.dataSource.data = this.dataSource.data.filter(e => e.estado === this.crmservice.estadoMostrarClientes);
    }

    // TIPO
    if (this.crmservice.tipoMostrarClientes !== 'TODOS') {
      this.dataSource.data = this.dataSource.data.filter(e => e.tipoCliente.tipo === this.crmservice.tipoMostrarClientes);
    }

    // CATEGORIA
    if (this.crmservice.categoriaMostrarClientes !== 'TODOS') {
      this.dataSource.data = this.dataSource.data.filter(e => e.tipoCliente.categoria === this.crmservice.categoriaMostrarClientes);
    }

  }

  public getPageEvent($event: PageEvent): void {
    this.crmservice.paginatorPage = $event.pageIndex;
  }

  public matSortChange($event: Sort): void {
    this.crmservice.matSortActive = $event.active;
    this.crmservice.matSortDirection = $event.direction;
  }

}
