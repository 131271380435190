<div class="m-xl-4 m-lg-4 m-md-2 m-sm-2 m-1">

  <div class="usuarios-header d-flex m-auto" style="width: 98%;">
    <div class="d-inline-block">
      <label id="select-tipo-user">
        <strong>USUARIOS VER: </strong>
        <select class="form-select form-select-sm" [(ngModel)]="usuariosMostrar" (ngModelChange)="cambiarUsuariosMostrar()">
          <option [value]="'todos'">TODOS ({{numUsuarios('todos')}})</option>
          <option [value]="'activos'">ACTIVOS ({{numUsuarios('activos')}})</option>
          <option [value]="'noActivos'">NO ACTIVOS ({{numUsuarios('noActivos')}})</option>
        </select>
      </label>
    </div>
    <div class="d-inline-block ms-auto">
      <button style="width: 40px" color="primary" matTooltip="Nuevo usuario" (click)="nuevoUser()" mat-icon-button>
        <mat-icon>person_add</mat-icon>
      </button>
      <mat-form-field>
        <label class="w-100 m-0">
          <input autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
        </label>
      </mat-form-field>
    </div>
  </div>

  <div class="mt-1 w-100">
    <mat-table [dataSource]="dataSource" matSort id="tabla-usuarios" class="text-center">
      <ng-container matColumnDef="nombreUsuario">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          Usuario
        </mat-header-cell>
        <mat-cell *matCellDef="let usuario" [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          {{usuario.nombreUsuario}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          Nombre
        </mat-header-cell>
        <mat-cell *matCellDef="let usuario" [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          {{usuario.nombre}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="apellidos">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          Apellidos
        </mat-header-cell>
        <mat-cell *matCellDef="let usuario" [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}"> {{usuario.apellidos}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="rol">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          Rol
        </mat-header-cell>
        <mat-cell *matCellDef="let usuario" [ngStyle]="{'font-size' : !mobileQuery.matches ? 'medium' : 'small'}">
          {{usuario.rol}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="equipo">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Equipo</mat-header-cell>
        <mat-cell *matCellDef="let usuario"> {{usuario.team}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="fechaUltimaCon">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Última Conexión</mat-header-cell>
        <mat-cell *matCellDef="let usuario">{{usuario.fechaUltimaCon ? (usuario.fechaUltimaCon | date:'dd/MM/yyyy hh:mm:ss a') : 'Desconocido'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'userDelete':row.desactivado}"
               (click)="editarUser(row._id)"></mat-row>

    </mat-table>
  </div>

  <div id="paginacion-usuarios">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"></mat-paginator>
  </div>
</div>
