export class Persona {
  public _id: string;
  public nombre: string;
  public apellidos: string;
  public cargo: string;
  public tratamiento: string;
  public observaciones: string;
  public email: string;
  public telefono: string;
  public direccion: string[];
  public cliente: string[];
  public fechaCreacion: Date;
  public fechaUltimaMod: Date;
  public nacimiento: Date;
  public usuarioCreador: string;
  public movil: string;
  public diploma: string;
  public ficheros: {fecha: Date, ficheroId: string, nombre: string}[];
  public perfil: string;

  constructor (p: Persona | any | null = null) {
    if (p != null) {
      this._id = p._id;
      this.nombre = p.nombre;
      this.apellidos = p.apellidos;
      this.cargo = p.cargo;
      this.tratamiento = p.tratamiento;
      this.observaciones = p.observaciones;
      this.email = p.email;
      this.telefono = p.telefono;
      this.direccion = p.direccion || [];
      this.cliente = p.cliente || [];
      this.fechaCreacion = p.fechaCreacion != null ? new Date(p.fechaCreacion) : new Date();
      this.fechaUltimaMod = p.fechaUltimaMod != null ? new Date(p.fechaUltimaMod) : new Date();
      this.nacimiento = p.nacimiento != null ? new Date(p.nacimiento) : null;
      this.usuarioCreador = p.usuarioCreador;
      this.movil = p.movil;
      this.diploma = p.diploma;
      this.ficheros = p.ficheros;
      this.perfil = p.perfil;
    }
  }
}
