import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Direccion} from '../../../../modelos/Direccion';
import {Accion} from '../../../../modelos/Accion';
import {MediaMatcher} from '@angular/cdk/layout';
import {Persona} from '../../../../modelos/Persona';

@Component({
  selector: 'app-historico-acciones',
  templateUrl: './historico-acciones.component.html',
  styleUrls: ['./historico-acciones.component.css']
})
export class HistoricoAccionesComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public direcciones: Direccion[];
  public acciones: Accion[];
  public personas: Persona[];

  public hitoSel: number;
  public direccionSel: string;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.direcciones = this.data.direcciones;
    this.acciones = this.data.acciones;

    this.direccionSel = null;
    this.hitoSel = null;

    this.acciones.sort((a, b) => {
      const aDate = new Date(a.fechaInicio);
      const bDate = new Date(b.fechaInicio);
      return (aDate > bDate) ? -1 : ((aDate < bDate) ? 1 : 0);
    });

    this.personas = this.data.personas;

    if (this.data.hito != null) {
      this.hitoSel = this.data.hito;
      this.direccionSel = this.data.direccion;
    }
  }

  ngOnInit() {
  }

  public mostrarAccion(accion: Accion): boolean {
    console.log(this.hitoSel);
    if (this.direccionSel != null && this.hitoSel != null) {
      return accion.direccion === this.direccionSel && accion.hito === this.hitoSel.toString(10);
    } else if (this.direccionSel != null && this.hitoSel == null) {
      return accion.direccion === this.direccionSel;
    } else if (this.direccionSel == null && this.hitoSel != null) {
      return accion.hito === this.hitoSel.toString(10);
    } else if (this.direccionSel == null && this.hitoSel == null) {
      return true;
    }
  }

  public getHito(hito: string): string {
    const res = this.crmservice.hitos.find(e => e.id === hito);
    return res != null ? res.nombre : '';
  }
  public getObjetivo(objetivo: string): string {
    const res = this.crmservice.objetivos.find(e => e.id === objetivo);
    return res != null ? res.nombre : '';
  }
  public getUsuario(de: [string]): string {
    if (de == null) { return 'Sin asignar'; }

    const usuario = this.crmservice.usuarios.find(e => e._id === de.toString());
    return usuario != null ? (usuario.nombre + ' ' + usuario.apellidos) : 'Sin asignar';
  }
  public getResultado(resultado: string): string {
    if (resultado == null) { return ''; }
    const res = this.crmservice.resultados.find(e => e.id === resultado);
    return res != null ? res.nombre : '';
  }
  public getPersona(idPersona: string): Persona {
    return this.personas.find(e => e._id === idPersona) || new Persona();
  }
}
