<div class="cabecera-modal">
  <img alt="" class="icon-modal" id="img-3d" src="../../../../../assets/img/otros/3d.png">
  <strong>ADMINISTRAR LICENCIAS 3D</strong>
  <mat-icon class="cerrar-modal" (click)="cerrar()">close</mat-icon>
</div>

<div class="content-modal">
  <div [ngClass]="!mobileQuery.matches ? 'contenedor-formulario' : 'contenedor-formulario-movil'">
    <div *ngIf="permitirRenovar()" class="text-right mb-3">
      <button *ngIf="crmservice.userLogin.rol != 'asesor' && crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'" color="primary"
              (click)="nuevaLicencia3D()" mat-button>
        <mat-icon>add_to_photos</mat-icon>
        Nueva Licencia
      </button>
    </div>

    <div *ngIf="!permitirRenovar()">
      <div class="alert alert-danger">
        El cliente ha alcanzado su número máximo de licencias simultaneas.
      </div>
    </div>

    <div *ngIf="licencia == null || !licencia.equipos.length" class="text-center mb-5">
      <div class="alert alert-dark w-75 m-0-auto">
        <span>La dirección tiene Hito 3D, pero aún no se ha creado una licencia.</span>
      </div>
    </div>

    <div class="m-2">
      <hr>
    </div>

    <ng-container *ngIf="licencia != null && licencia.equipos.length">

      <mat-accordion>
        <mat-expansion-panel *ngFor="let equipo of licencia.equipos">

          <mat-expansion-panel-header [ngStyle]="{'background-color' : equipo.activaciones[equipo.activaciones.length - 1].caducada ? 'lightgray' : 'white'}">

            <div class="col-12 d-flex justify-content-between">
              <span>
                <strong>{{equipo.aliasEquipo}}</strong>
                <span *ngIf="equipo.referencia != null" class="text-secondary ms-2">
                  <i>({{equipo.referencia}})</i>
                </span>
              </span>
              <span class="text-secondary float-right me-5">
                <i>{{equipo.activaciones[equipo.activaciones.length - 1].caducada ? 'Caducada' : ''}}</i>
              </span>
            </div>

          </mat-expansion-panel-header>

          <div class="pt-4 border-top-dotted">
            <table class="table table-sm table-borderless table-striped">
              <tr>
                <td class="w-175px"><strong>Licencia dada por:</strong></td>
                <td [ngClass]="!mobileQuery.matches ? 'text-start' : 'text-end'">{{dameNombreUsuario(equipo.activaciones[equipo.activaciones.length - 1].usuario)}}</td>
              </tr>
              <tr *ngIf="equipo.adaptRed != null">
                <td class="w-175px">
                  <img class="logo-quote" alt="" src="../../../../../assets/img/otros/Logo_quote.png">
                  <strong>Adaptador Red: </strong>
                </td>
                <td [ngClass]="!mobileQuery.matches ? 'text-start' : 'text-end'">{{equipo.adaptRed}}</td>
              </tr>
              <tr *ngIf="equipo.llaveRed != null">
                <td class="w-175px">
                  <img class="logo-3d" alt="" src="../../../../../assets/img/otros/Logo_3D.png">
                  <strong>Llave de Red: </strong>
                </td>
                <td [ngClass]="!mobileQuery.matches ? 'text-start' : 'text-end'">{{equipo.llaveRed}}</td>
              </tr>
              <tr>
                <td class="w-175px"><strong>Inicio:</strong></td>
                <td [ngClass]="!mobileQuery.matches ? 'text-start' : 'text-end'">{{equipo.activaciones[equipo.activaciones.length - 1].fActivacion | date: 'dd-MM-yyyy'}}</td>
              </tr>
              <tr>
                <td class="w-175px"><strong>Fin:</strong></td>
                <td [ngClass]="!mobileQuery.matches ? 'text-start' : 'text-end'">{{equipo.activaciones[equipo.activaciones.length - 1].fVencimiento | date: 'dd-MM-yyyy'}}</td>
              </tr>
            </table>
          </div>


          <div class="d-flex mt-2" [ngClass]="!mobileQuery.matches ? 'justify-content-end' : 'justify-content-around'">
            <button *ngIf="crmservice.userLogin.rol != 'asesor' && crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer' && permitirRenovar()"
                    [disabled]="!equipo.activaciones[equipo.activaciones.length - 1].caducada"
                    (click)="renovarLicencia3D(equipo)"
                    class="btn p-0">
              <mat-icon
                [ngClass]="!equipo.activaciones[equipo.activaciones.length - 1].caducada ? 'btn-renovar-desact' : 'btn-renovar'">
                replay
              </mat-icon>
            </button>

            <button mat-button (click)="addReferencia(equipo)">
              Añadir Referencia
            </button>

            <button class="btn p-0" (click)="verHistorialEquipo(equipo)">
              <mat-icon class="btn-historial">schedule</mat-icon>
            </button>

            <button *ngIf="crmservice.userLogin.rol != 'asesor' && crmservice.userLogin.rol != 'comercial' && crmservice.userLogin.rol != 'influencer'"
                    class="btn p-0" (click)="eliminarEquipoLicencia(equipo)">
              <mat-icon class="btn-eliminar">delete</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>

  </div>
</div>
