import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CrmService} from '../../../servicios/crm.service';
import {ElementoRanking} from '../../../modelos/ElementoRanking';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import EChartOption = echarts.EChartOption;

import {RankingDetalleComponent} from './ranking-detalle/ranking-detalle.component';
import {MediaMatcher} from '@angular/cdk/layout';

class ConfigRankings {
  public minimoFacturado: number;
  public fDesde: string;
  public fHasta: string;
  public hitosReq: {
    hitoTCLA: boolean
    hitoWV: boolean,
    hito3D: boolean,
    hitoRevistas: boolean,
    hitoMuestras: boolean,
    hitoIdehabita: boolean,
    hitoCLASS: boolean,
    hitoDVIRTUAL: boolean,
    hitoBannerCreamostumueble: boolean
  };
}

@Component({
  selector: 'app-config-rankings',
  templateUrl: './config-rankings.component.html',
  styleUrls: ['./config-rankings.component.css']
})
export class ConfigRankingsComponent implements OnInit, AfterViewInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargandoDatos = false;

  /*** Atributos de la tabla de clasificación de clientes ***/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSourceClasificacion: MatTableDataSource<ElementoRanking> = new MatTableDataSource();
  public displayedColumnsClasificacion: string[] = ['ranking', 'clasificacion', 'cif', 'razonSocial', 'provincia', 'totalFacturado', 'totalFacturadoAnt', 'diferencia', 'crecimiento', 'CMI'];
  // Clasificacion: A, B o C; Hitos: si tiene o no los hitos requeridos; Inforpyme: si tiene ficha en inforpymes; Ir: ir al cliente.

  /*** Atributos de las gráficas ***/
  chartOption: EChartOption;
  public totalFacturado = 0;
  public totalFacturadoAnt = 0;

  public porcentajeTotal = 100;

  public rec = [];
  public res = [];
  public data_xaxis = [];
  public data_yaxis_left = [];

  public clientesGrafica = [];

  /*** Atributos de la configuración de la clasificación ***/
  public configuracion: ConfigRankings = null;

  public minimoFacturado = 0;
  public fDesde = '';
  public fHasta = '';

  public hitoTCLA = false;
  public hitoWV = false;
  public hito3D = false;
  public hitoRevistas = false;
  public hitoMuestras = false;
  public hitoIdehabita = false;
  public hitoCLASS = false;
  public hitoDVIRTUAL = false;

  public hitoBannerCreamostumueble = false; 

  public mensajeError = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.mobileQuery.matches) {
      this.displayedColumnsClasificacion = ['ranking', 'clasificacion', 'cif', 'razonSocial', 'provincia', 'totalFacturado', 'totalFacturadoAnt', 'diferencia', 'crecimiento', 'CMI'];
    } else {
      this.displayedColumnsClasificacion = ['ranking', 'clasificacion', 'razonSocial', 'totalFacturado'];
    }
  }

  constructor(public crmservice: CrmService,
              public router: Router,
              public dialog: MatDialog,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    if (this.crmservice.userLogin == null || this.crmservice.userLogin.rol !== 'admin' && this.crmservice.userLogin.rol !== 'supervisor') {
      this.router.navigate(['agenda']).then();
    }

    this.cargandoDatos = true;
    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (!this.mobileQuery.matches) {
      this.displayedColumnsClasificacion = ['ranking', 'clasificacion', 'cif', 'razonSocial', 'provincia', 'totalFacturado', 'totalFacturadoAnt', 'diferencia', 'crecimiento', 'CMI'];
    } else {
      this.displayedColumnsClasificacion = ['ranking', 'clasificacion', 'razonSocial', 'totalFacturado'];
    }

    this.crmservice.getElementosFacturados().subscribe(
      valueElementos => {

        this.cargarDataSource(valueElementos);

        /* Para dibujar el diagrama */
        this.drawPareto();

        this.crmservice.getConfiguracionRanking().subscribe(
          valueConfig => {
            this.configuracion = valueConfig;

            if (this.configuracion === null) {

              this.configuracion = {
                minimoFacturado: 0,
                fDesde: '2020-01-01',
                fHasta: '2020-12-31',
                hitosReq: {
                  hitoTCLA: false,
                  hitoWV: false,
                  hito3D: false,
                  hitoRevistas: false,
                  hitoMuestras: false,
                  hitoIdehabita: false,
                  hitoCLASS: false,
                  hitoDVIRTUAL: false,
                  hitoBannerCreamostumueble: false
                }
              };

              this.crmservice.nuevaConfiguracionRanking(this.configuracion).subscribe(
                valueNueva => {
                  this.configuracion = valueNueva;
                  this.cargandoDatos = false;
                }, error3 => {
                  console.log(error3);
                  this.cargandoDatos = false;
                });
            } else {

              this.minimoFacturado = this.configuracion.minimoFacturado;
              this.fDesde = this.configuracion.fDesde;
              this.fHasta = this.configuracion.fHasta;

              this.hitoTCLA = this.configuracion.hitosReq.hitoTCLA;
              this.hitoWV = this.configuracion.hitosReq.hitoWV;
              this.hito3D = this.configuracion.hitosReq.hito3D;
              this.hitoRevistas = this.configuracion.hitosReq.hitoRevistas;
              this.hitoMuestras = this.configuracion.hitosReq.hitoMuestras;
              this.hitoIdehabita = this.configuracion.hitosReq.hitoIdehabita;
              this.hitoCLASS = this.configuracion.hitosReq.hitoCLASS;
              this.hitoDVIRTUAL = this.configuracion.hitosReq.hitoDVIRTUAL;
              this.hitoBannerCreamostumueble = this.configuracion.hitosReq.hitoBannerCreamostumueble;

              this.cargandoDatos = false;
            }
          }, error2 => {
            console.log(error2);
            this.cargandoDatos = false;
          });

      }, error1 => {
        console.log(error1);
        this.cargandoDatos = false;
      });
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSourceClasificacion.paginator = this.paginator;
      this.dataSourceClasificacion.sort = this.sort;
    }, 5000);
  }

  public getClientesFacturacion(): number {
    let n = 0;
    for (const cliente of this.dataSourceClasificacion.data) {
      if (cliente.totalFacturado > 0) {
        n++;
      }
    }
    return n;
  }

  /*** Métodos de la clasificación ***/
  // Cambia a formato '.' y sólo se queda con los dos primeros decimales.
  public formatNumber(numero: number, decimales: boolean = true): string {

    const nString = numero.toString(10);
    const entera = nString.split('.')[0];

    let decimal = '';

    if (nString.split('.').length === 2) {
      decimal = ',' + nString.split('.')[1];
    }

    if (decimales) {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + decimal.slice(0, 3);
    } else {
      return entera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }

  // Devuelve A, B o C dependiendo de la clasificación. Es necesario puesto
  // que B puede ser B1 o B2 pero al usuario sólo se le muestra como B.
  public dameClasificacion(rank: string): string {
    if (rank === 'B1' || rank === 'B2') {
      return 'B';
    }
    return rank;
  }

  // Color correspondiente a cada clasificación.
  public dameColorClasificacion(rank: string): string {
    if (rank === 'A') {
      return 'limegreen';
    }

    if (rank === 'B1' || rank === 'B2') {
      return 'gold';
    }

    if (rank === 'C') {
      return 'brown';
    }

    return 'pink';
  }

  // Se da un string y el tamaño máximo deseado. Devuelve el string trucando al
  // número indicado seguido de '...'
  public truncarString(s: string, n: number): string {
    if (s.length > n + 3) {
      return s.slice(0, n) + '...';
    }
    return s;
  }

  // Abre la ficha del cliente
  public navegarA(_id: string): void {
    // this.crmservice.direccionSeleccionada[0] = false;
    this.router.navigate(['direccion', _id]).then();
    // window.open(this.crmservice.urlCRM + '/direccion/' + _id);
  }

  public navegarANuevaPest(_id: string): void {
    window.open(this.crmservice.urlCRM2 + '/direccion/' + _id);
  }

  public applyFilter(filterValue: string, dataSource: MatTableDataSource<any>): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    dataSource.filter = filterValue;
  }

  // Carga los datos en el datasource
  public cargarDataSource(valueElementos): void {

    valueElementos.sort(function (a, b) {
      if (a.totalFacturado > b.totalFacturado) {
        return -1;
      } else if (a.totalFacturado < b.totalFacturado) {
        return 1;
      }
      return 0;
    });

    this.dataSourceClasificacion.data = valueElementos;

    let ranking = 1;
    for (const elemento of this.dataSourceClasificacion.data) {
      elemento.ranking = ranking;
      this.calcularAtDerivados(elemento);
      ranking ++;
    }
  }

  // Calcula algunos atributos derivados que no están almacenados en la bbdd.
  public calcularAtDerivados(elemento): void {
    elemento.diferencia = elemento.totalFacturado - elemento.totalFacturadoAnt;

    if (elemento.totalFacturadoAnt === 0) {
      elemento.crecimiento = elemento.totalFacturado;
    } else {
      elemento.crecimiento = ((elemento.totalFacturado - elemento.totalFacturadoAnt) / elemento.totalFacturadoAnt) * 100;
    }

    const pos = this.crmservice.direcciones.findIndex(e => e._id === elemento.primeraDir);

    if (pos !== -1) {
      elemento.provincia = this.crmservice.direcciones[pos].provincia;
    } else {
      elemento.provincia = 'Desconocido';
    }

    // elemento.hitos = (elemento.clasificacion === 'A' || elemento.clasificacion === 'B2') ? 1 : 0;
  }

  // Genera un EXCEL con todos los clientes del ranking
  public generarExcel(): void {
  }

  // Cambia los clientes que se muestran, todos, automáticos o manuales.
  public recargar(): void {
    this.router.navigate(['gestion']).then();
  }

  /*** Métodos de la creación de gráficos ***/
  // Dibuja el gráfico de pareto
  public drawPareto(): void {

    this.totalFacturado = 0;
    this.totalFacturadoAnt = 0;
    this.rec = [];
    this.res = [];
    this.data_xaxis = [];
    this.data_yaxis_left = [];

    this.clientesGrafica = [];

    for (const elemento of this.dataSourceClasificacion.data) {
      if (elemento.totalFacturado > 0) {
        this.totalFacturado += elemento.totalFacturado;
      }
      if (elemento.totalFacturadoAnt > 0) {
        this.totalFacturadoAnt += elemento.totalFacturadoAnt;
      }
    }
    let acumulado = 0;
    for (const elemento of this.dataSourceClasificacion.data) {
      if (elemento.totalFacturado > 0 && acumulado * 100 < this.porcentajeTotal) {
        acumulado += elemento.totalFacturado / this.totalFacturado;
        this.rec.push(acumulado);
        this.res.push(acumulado * 100);
        this.data_xaxis.push(elemento.ranking + '.-' + elemento.razonSocial);
        this.data_yaxis_left.push(elemento.totalFacturado);

        this.clientesGrafica.push(elemento);
      }
    }

    for (let i = 0; i < this.data_xaxis.length; i++) {
      let porcentaje = (((i + 1) / this.data_xaxis.length) * 100).toString(10);
      if (porcentaje.length > 4) {
        porcentaje = porcentaje.slice(0, 4);
      }

      this.data_xaxis[i] += ' (' + porcentaje + '%)';
    }

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#283b56'
          }
        }
      },
      legend: {
        data: ['L1', 'L2']
      },
      xAxis: [{
        type: 'category',
        boundaryGap: true,
        data: this.data_xaxis
        /*axisLabel: {
          rotate: 45,
          interval: 0,
          margin: 5
        }*/
      }, {
        type: 'category',
        boundaryGap: true,
        data: []
      }],
      yAxis: [{
        type: 'value',
        scale: true,
        name: 'Total Facturado',
        max: this.totalFacturado * (this.porcentajeTotal / 100),
        min: 0,
        boundaryGap: [0.2, 0.2]
      }, {
        axisLabel: {
          formatter: function(value) {
            return value + '%';
          }
        },
        type: 'value',
        scale: true,
        name: '% Total',
        max: this.porcentajeTotal,
        min: 0,
        boundaryGap: [0.2, 0.2]
      }],
      series: [{
        name: 'Facturado',
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: this.data_yaxis_left
      }, {
        name: '% acumulado: ',
        yAxisIndex: 1,
        data: this.res,
        type: 'line',
        smooth: true
      }]
    };
  }
  public generarExcelPareto(): void {
  }

  /*** Métodos de la configuración de la clasificación ***/
  // Actualiza la configuración del ranking y recalcula la clasificacion de todos los clientes.
  public actualizar(): void {
    if (this.comprobarInput()) {

      this.cargandoDatos = true;

      this.configuracion.minimoFacturado = this.minimoFacturado;
      this.configuracion.fDesde = this.fDesde;
      this.configuracion.fHasta = this.fHasta;

      this.configuracion.hitosReq.hitoTCLA = this.hitoTCLA;
      this.configuracion.hitosReq.hitoWV = this.hitoWV;
      this.configuracion.hitosReq.hito3D = this.hito3D;
      this.configuracion.hitosReq.hitoRevistas = this.hitoRevistas;
      this.configuracion.hitosReq.hitoMuestras = this.hitoMuestras;
      this.configuracion.hitosReq.hitoIdehabita = this.hitoIdehabita;
      this.configuracion.hitosReq.hitoCLASS = this.hitoCLASS;
      this.configuracion.hitosReq.hitoBannerCreamostumueble = this.hitoBannerCreamostumueble

      this.crmservice.actualizarConfiguracionRanking(this.configuracion).subscribe(
        valueAct => {
          this.configuracion = valueAct;

          this.crmservice.recalcularRankings().subscribe(
            () => {
              this.crmservice.getAllElementosRanking().subscribe(
                valueElementos => {

                  this.cargarDataSource(valueElementos);
                  this.drawPareto();

                  this.cargandoDatos = false;
                }, error3 => {
                  console.log(error3);
                  this.cargandoDatos = false;
                });
            }, error2 => {
              console.log(error2);
              this.cargandoDatos = false;
            });
        }, error1 => {
          console.log(error1);
          this.cargandoDatos = false;
        });
    }
  }
  public comprobarInput(): boolean {

    if (this.minimoFacturado == null) {
      this.mensajeError = 'Introduce minimo facturado.';
      return false;
    }

    this.mensajeError = '';
    return true;
  }

  /*** popUp ranking-detalle ***/
  public abrirRankingDetalle (elementoRanking: ElementoRanking): void {

    let width: string;
    let heigth: string;

    if (!this.mobileQuery.matches) {
      width = '40%';
      heigth = '60%';
    } else {
      width = '95%';
      heigth = '95%';
    }

    const dialogRef = this.dialog.open(RankingDetalleComponent, {
      width: width,
      height: heigth,
      panelClass: 'custom-dialog-container',
      disableClose: true,
      data: {
        clienteSel: elementoRanking
      }
    });

    dialogRef.afterClosed().subscribe(
      () => {
        // console.log(value);
        // Actualizar direcciones en un futuro
      }, error1 => {
        console.log(error1);
      }
    );
  }
  public irInforme(number: number, perfilTCLA: string, id: string): void {
    switch (number) {
      case 1:
        window.open(this.crmservice.urlCMI + '/accesos/' + perfilTCLA, '_blank');
        break;
      case 2:
        window.open(this.crmservice.urlCMI + '/informePromociones/' + perfilTCLA, '_blank');
        break;
      case 3:
        window.open(this.crmservice.urlCMI + '/accionesDireccion/' + id, '_blank');
        break;
      case 4:
        window.open(this.crmservice.urlCMI + '/informes/evolucion/' + id, '_blank');
        break;
      case 5:
        window.open(this.crmservice.urlCMI + '/informes/comparativa/' + id, '_blank');
        break;
      case 6:
        window.open(this.crmservice.urlCMI + '/informes/cartera/' + id, '_blank');
        break;
      case 7:
        window.open(this.crmservice.urlCMI + '/accionesCliente/' + id, '_blank');
        break;
    }
  }
}
