<div class="cabecera-modal">
  <mat-icon class="icon-modal">account_circle</mat-icon>
  <strong>Crear usuario</strong>
  <mat-icon class="cerrar-modal" [matDialogClose]="">close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargando" class="bg-white ps-md-5 pe-md-5 pt-md-3 pb-md-5 p-3 rounded m-0-auto" style="max-height: calc(100% - 55px); height: calc(100% - 55px); overflow-y: auto">
    <div class="row h-100">
      <div class="col-12 mt-4 mb-2 d-flex flex-row pb-2 pt-2 rounded bg-mat user-select-none">
        <mat-icon class="text-secondary me-2">settings</mat-icon>
        <strong class="text-secondary">Tipo de Usuario</strong>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Usuario</mat-label>
          <input [(ngModel)]="usuarioEditar.nombreUsuario" matInput placeholder="Usuario" required autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Rol</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.rol" placeholder="Rol" required>
            <mat-option *ngFor="let rol of crmservice.roles" [value]="rol.id">{{rol.id}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Equipo</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.team" placeholder="Equipo" required="{{usuarioEditar.rol === 'comercial' || usuarioEditar.rol === 'influencer'}}">
            <mat-option *ngFor="let equipo of crmservice.equipos" [value]="equipo.id">{{equipo.id}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="usuarioEditar.rol === 'asesor'" class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>¿En aprendizaje?</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.aprendizaje" placeholder="¿Es aprendiz?" required="{{usuarioEditar.rol === 'asesor'}}">
            <mat-option [value]="true">Si</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="usuarioEditar.rol === 'asesor'" class="col-12">
        <span class="text-secondary">*Un asesor en aprendizaje no podrá revisar pedidos. Además, los pedidos que traduzca serán revisados por otro asesor.</span>
      </div>

      <div class="col-12 mt-4 mb-2 d-flex flex-row pb-2 pt-2 rounded bg-mat user-select-none">
        <mat-icon class="text-secondary me-2">account_circle</mat-icon>
        <strong class="text-secondary">Datos Personales</strong>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Nombre</mat-label>
          <input [(ngModel)]="usuarioEditar.nombre" matInput placeholder="Nombre" required autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-md-8 col-12">
        <mat-form-field class="w-100">
          <mat-label>Apellidos</mat-label>
          <input [(ngModel)]="usuarioEditar.apellidos" matInput placeholder="Apellidos" required autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Población</mat-label>
          <input [(ngModel)]="usuarioEditar.poblacion" matInput placeholder="Población" required autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Provincia</mat-label>
          <mat-select [(ngModel)]="usuarioEditar.provincia" placeholder="Provincia" required>
            <mat-option *ngFor="let provincia of crmservice.provincias" [value]="provincia.nombre">{{provincia.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-12">
        <mat-form-field class="w-100">
          <mat-label>Teléfono</mat-label>
          <input [(ngModel)]="usuarioEditar.telefono" matInput placeholder="Teléfono" autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input type="email" [(ngModel)]="usuarioEditar.email" matInput placeholder="Email" required autocomplete="off">
        </mat-form-field>
      </div>

      <div class="col-12 mt-4 mb-2 d-flex flex-row pb-2 pt-2 rounded bg-mat user-select-none">
        <mat-icon class="text-secondary me-2">security</mat-icon>
        <strong class="text-secondary">Contraseña</strong>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Contraseña</mat-label>
          <input type="password" [(ngModel)]="pass1" matInput placeholder="Contraseña" required autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Repite Contraseña</mat-label>
          <input type="password" [(ngModel)]="pass2" matInput placeholder="Repite Contraseña" required autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="!cargando" class="d-flex p-2 mt-auto border-top bg-light">
    <div class="btn btn-primary-crm ms-auto" [class.w-100]="mobileQuery.matches" (click)="guardarUsuario()">CREAR USUARIO</div>
  </div>

  <div *ngIf="cargando" class="position-absolute start-50 top-50 translate-middle">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
