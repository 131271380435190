<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong
    >CAMPUS DE {{ persona.nombre }}
    {{
      persona.apellidos != null && persona.apellidos.trim().length
        ? persona.apellidos
        : ""
    }}</strong
  >
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div
    [ngClass]="
      !mobileQuery.matches
        ? 'contenedor-formulario'
        : 'contenedor-formulario-movil'
    "
  >
    <div>
      <h5>DIPLOMA</h5>
    </div>

    <div class="col-12 btn-group">
      <!-- Deshabilitado hasta que se modifiquen las funciones pero que se pueda ir usando el componente mientras tanto -->
      <!-- <button
        class="btn btn-primary"
        (click)="generarDiploma()"
        [disabled]="!mostrarGenerarDiploma()"
      >
        <span
          class="spinner-border spinner-border-sm"
          [hidden]="!cargandoDatos"
        ></span>
        Generar Diploma
      </button>
      <button
        class="btn btn-info"
        (click)="descargarDiploma()"
        [disabled]="!mostararDescargarDiploma()"
      >
        Descargar Diploma
      </button> -->
    </div>

    <div *ngIf="mostrarWarningApellidos" class="col-12 mt-3 text-justify">
      <div class="alert alert-danger">
        <div class="row">
          <div class="col-11">
            <span
              >Tienes que indicar los apellidos de
              <strong>{{ persona.nombre }}</strong> para poder generar el
              diploma.</span
            >
          </div>

          <div class="col-1 text-end">
            <mat-icon
              role="button"
              class="fs-1em"
              (click)="mostrarWarningApellidos = false"
            >
              close
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mostrarWarningHitos" class="col-12 mt-3 text-justify">
      <div class="alert alert-danger">
        <div class="row">
          <div class="col-11">
            <span
              >La dirección necesita <strong>TCLA</strong> y
              <strong>3D</strong> para poder generar el diploma.</span
            >
          </div>
          <div class="col-1 text-end">
            <mat-icon
              role="button"
              class="fs-1em"
              (click)="mostrarWarningHitos = false"
            >
              close
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div>
      <h5>MATRÍCULA</h5>
    </div>

    <mat-accordion>
      <ng-container *ngFor="let curso of cursoBloques">
        <mat-expansion-panel>
          <mat-expansion-panel-header
            [ngStyle]="{ 'background-color': dameColorCurso(curso) }"
          >
            <div style="flex-grow: 1">
              <strong>CURSO {{ curso.curso }}</strong>
            </div>
            <div
              class="text-end mr-2"
              *ngIf="dameEstadoDeCurso(curso) === 'matriculado'"
            >
              Matriculado:
              {{
                curso.bloques[0].acciones[0].fechaInicio ||
                  curso.acciones[0].fechaInicio | date : "dd/MM/yyyy"
              }}
            </div>
            <div
              class="text-end mr-2"
              *ngIf="dameEstadoDeCurso(curso) === 'finalizado'"
            >
              Superado:
              {{
                (curso.acciones.length && curso.acciones.at(-1).fechaFin) ||
                  (curso.bloques.length &&
                    curso.bloques.at(-1).acciones.length &&
                    curso.bloques.at(-1).acciones.at(-1).fechaFin)
                  | date : "dd/MM/yyyy"
              }}
            </div>
            <div style="width: 20px"></div>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div class="mt-3 contenedor__bloque">
              <ng-container
                class="contenedor__bloque"
                *ngFor="let bloque of curso.bloques"
              >
                <!-- <mat-expansion-panel-header> -->
                <div
                  class="bloque_elemento"
                  [ngStyle]="{
                    'background-color': dameColorBloque2(bloque.acciones)
                  }"
                >
                  <div style="flex-grow: 1">
                    <strong>BLOQUE {{ bloque.numero }}</strong>
                  </div>
                  <div *ngIf="dameEstadoDeBloque(bloque) === 'matriculado'">
                    Matriculado:
                    {{ bloque.acciones[0].fechaInicio | date : "dd/MM/yyyy" }}
                  </div>
                  <div *ngIf="dameEstadoDeBloque(bloque) === 'finalizado'">
                    Superado:
                    {{ bloque.acciones.at(-1).fechaFin | date : "dd/MM/yyyy" }}
                  </div>
                  <div style="width: 20px"></div>
                </div>
                <!-- </mat-expansion-panel-header> -->
                <ng-template matExpansionPanelContent> </ng-template>
              </ng-container>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>

    <hr />

    <mat-accordion>
      <mat-expansion-panel id="leyenda">
        <mat-expansion-panel-header>
          <strong>LEYENDA</strong>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <table class="table table-borderless table-hover">
            <thead>
              <tr>
                <td colspan="2" class="p-1"></td>
              </tr>
            </thead>
            <tbody>
              <tr class="alert alert-light">
                <td class="p-1"><strong>BLANCO: </strong></td>
                <td class="p-1">Bloque no comenzado.</td>
              </tr>
              <tr class="alert alert-warning">
                <td class="p-1"><strong>AMARILLO: </strong></td>
                <td class="p-1">Bloque comenzado.</td>
              </tr>
              <tr class="alert alert-danger">
                <td class="p-1"><strong>ROJO: </strong></td>
                <td class="p-1">Bloque no superado.</td>
              </tr>
              <tr class="alert alert-success">
                <td class="p-1"><strong>VERDE: </strong></td>
                <td class="p-1">Bloque superado.</td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="bg-light"
        [expanded]="subirFicherosPanel"
        (opened)="subirFicherosPanel = true"
        (closed)="subirFicherosPanel = false"
      >
        <mat-expansion-panel-header>
          <strong>SUBIR FICHEROS</strong>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="text-center mt-2 mb-2">
            <div
              ngfDrop
              selectable="1"
              multiple="1"
              class="dropFiles"
              [(files)]="files"
            >
              <span>Click o arrastra los ficheros</span>
            </div>
          </div>

          <label *ngFor="let file of files" class="ficheroPreview">
            {{ file.name }}
            <button mat-icon-button (click)="quitarFicheroPreview(file.name)">
              <mat-icon class="mb-2">close</mat-icon>
            </button>
          </label>

          <hr *ngIf="files != null && files.length" />

          <div class="text-center mb-3" *ngIf="files != null && files.length">
            <button class="btn btn-success" (click)="subirFicheros()">
              Subir Ficheros
            </button>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="persona.ficheros != null && persona.ficheros.length"
        class="bg-light"
        [expanded]="verFicherosPanel"
        (opened)="verFicherosPanel = true"
        (closed)="verFicherosPanel = false"
      >
        <mat-expansion-panel-header>
          <strong>VER FICHEROS</strong>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <table class="table table-sm table-borderless table-hover shadow">
            <thead>
              <tr class="bg-light">
                <th>FECHA</th>
                <th>FICHERO</th>
                <th class="text-center">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let fichero of persona.ficheros"
                [ngClass]="estaEnPapelera(fichero) ? 'alert-danger' : ''"
              >
                <td>
                  <span>{{ fichero.fecha | date : "dd-MM-yyyy" }}</span>
                  <br />
                  <span>{{ fichero.fecha | date : "HH:mm" }}</span>
                </td>
                <td>
                  {{ fichero.nombre }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-around">
                    <mat-icon
                      (click)="abrirFichero(fichero.ficheroId)"
                      role="button"
                    >
                      search
                    </mat-icon>
                    <mat-icon
                      *ngIf="mostrarBotonPerfil(fichero.nombre)"
                      role="button"
                      (click)="establecerFotoPerfil(fichero.ficheroId)"
                      [ngStyle]="{
                        color: esPerfil(fichero.ficheroId)
                          ? 'limegreen'
                          : 'darkgray'
                      }"
                    >
                      face
                    </mat-icon>
                    <mat-icon
                      role="button"
                      [ngStyle]="{
                        color: estaEnPapelera(fichero)
                          ? 'cornflowerblue'
                          : 'brown'
                      }"
                      *ngIf="!esPerfil(fichero.ficheroId)"
                      (click)="mandarAPapelera(fichero)"
                    >
                      {{ estaEnPapelera(fichero) ? "undo" : "delete" }}
                    </mat-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="ficherosEliminar.length" class="text-center mt-3">
            <button class="btn btn-sm btn-danger" (click)="borrarFicheros()">
              ACEPTAR
            </button>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
