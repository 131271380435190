<style>
  .row-table {
    height: 21px;
  }
  .cell {
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px #e1e1e1 solid;
    cursor: pointer;
    position: relative;
    background-color: white;
  }
  .black {
    color: black!important;
  }
  .gray {
    color: gray!important;
  }
  .one {
    color: blue!important;
  }
  .two {
    color: green!important;
  }
  .three {
    color: red!important;
  }
  .four {
    color: #73a8ff;
  }
  .five {
    color: #a65959;
  }
  .six {
    color: #00ae51;
  }
  .seven {
    color: #172130;
  }
  .eight {
    color: #ff9000;
  }
  .bg-revealed {
    background-color: #D1D1D1;
  }
</style>

<div>
  <button class="btn btn-sm" (click)="ini()">
    <mat-icon>refresh</mat-icon>
  </button>

  <mat-icon *ngIf="state === 'endedKO'">mood_bad</mat-icon>
  <mat-icon *ngIf="state === 'endedOK'">emoji_emotions</mat-icon>

  <label>
    {{nDmg}} ({{ratio}})%
    <input [(ngModel)]="ratio" type="range" min="5" max="75">
  </label>

  <label>
    X:
    <input [(ngModel)]="x" type="number" min="5" max="50" placeholder="5-50">
  </label>

  <label>
    Y:
    <input [(ngModel)]="y" type="number" min="5" max="50" placeholder="5-50">
  </label>

</div>

<div class="user-select-none" style="opacity: 0.5">
  <div *ngFor="let row of this.table.matrix" class="row-table">
    <div *ngFor="let cell of row" class="cell bg-gradient" (click)="leftClick(cell)" (contextmenu)="rightClick($event, cell)" [ngClass]="cell.bgColor">
      <span *ngIf="cell.state === 'revealed' || cell.state === 'dead'" class="position-absolute start-50 top-50 translate-middle" [class]="cell.color">
        {{cell.content === 0 ? '' : (cell.content === -1 ? '*' : cell.content)}}
      </span>
      <span *ngIf="cell.state === 'flagged'" class="position-absolute start-50 top-50 translate-middle">
        <span>|</span><span class="text-danger">></span>
      </span>
    </div>
  </div>
</div>

