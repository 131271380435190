import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";

import { AppComponent } from "./componentes/app.component";
import { CrmService } from "./servicios/crm.service";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { DatePipe } from "@angular/common";
import { LoginComponent } from "./componentes/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UsuariosComponent } from "./componentes/usuarios/usuarios.component";
import { LabelDataTable } from "./servicios/label-data-table";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { UsuarioFormularioComponent } from "./componentes/usuario-formulario/usuario-formulario.component";
import { DireccionesComponent } from "./componentes/direcciones/direcciones.component";
import { ClienteFormularioComponent } from "./componentes/cliente-formulario/cliente-formulario.component";
import { FichaClienteComponent } from "./componentes/ficha-cliente/ficha-cliente.component";
import { NuevaDireccionFormularioComponent } from "./componentes/ficha-cliente/formularios/nueva-direccion-formulario/nueva-direccion-formulario.component";
import { NuevaPersonaFormularioComponent } from "./componentes/ficha-cliente/formularios/nueva-persona-formulario/nueva-persona-formulario.component";
import { FotografiasDireccionComponent } from "./componentes/ficha-cliente/formularios/fotografias-direccion/fotografias-direccion.component";
import { UbicacionDireccionComponent } from "./componentes/ficha-cliente/formularios/ubicacion-direccion/ubicacion-direccion.component";
import { HistoricoAccionesComponent } from "./componentes/ficha-cliente/formularios/historico-acciones/historico-acciones.component";
import { NuevaAccionFormularioComponent } from "./componentes/ficha-cliente/formularios/nueva-accion-formulario/nueva-accion-formulario.component";
import { RealizarAccionFormularioComponent } from "./componentes/ficha-cliente/formularios/realizar-accion-formulario/realizar-accion-formulario.component";
import { PosponerAccionFormularioComponent } from "./componentes/ficha-cliente/formularios/posponer-accion-formulario/posponer-accion-formulario.component";
import { ContratoWallviewerFormularioComponent } from "./componentes/ficha-cliente/formularios/contrato-wallviewer-formulario/contrato-wallviewer-formulario.component";
import { ContratoIdehabitaFormularioComponent } from "./componentes/ficha-cliente/formularios/contrato-idehabita-formulario/contrato-idehabita-formulario.component";
import { MapaChinchetasComponent } from "./componentes/mapa-chinchetas/mapa-chinchetas.component";
import { MapaserviceService } from "./servicios/mapaservice.service";
import { AgendaComponent } from "./componentes/agenda/agenda.component";
import { AgendaService } from "./servicios/agenda.service";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { AdminMuestrasFormularioComponent } from "./componentes/ficha-cliente/formularios/admin-muestras-formulario/admin-muestras-formulario.component";
import { DetalleMuestraComponent } from "./componentes/ficha-cliente/formularios/admin-muestras-formulario/detalle-muestra/detalle-muestra.component";
import { NotasActualizacionComponent } from "./componentes/notas-actualizacion/notas-actualizacion.component";
import { PosponerEnviarAccionFormularioComponent } from "./componentes/ficha-cliente/formularios/posponer-enviar-accion-formulario/posponer-enviar-accion-formulario.component";
import { InformesComponent } from "./componentes/gestion/informes/informes.component";
import { NgxEchartsModule } from "ngx-echarts";
import { GestionComponent } from "./componentes/gestion/gestion.component";
import { ngfModule } from "angular-file";
import { Licencias3DFormularioComponent } from "./componentes/ficha-cliente/formularios/licencias3-d-formulario/licencias3-d-formulario.component";
import { EditarLicenciaComponent } from "./componentes/ficha-cliente/formularios/licencias3-d-formulario/editar-licencia/editar-licencia.component";
import { RenovarLicenciaComponent } from "./componentes/ficha-cliente/formularios/licencias3-d-formulario/renovar-licencia/renovar-licencia.component";
import { HistorialLicenciaComponent } from "./componentes/ficha-cliente/formularios/licencias3-d-formulario/historial-licencia/historial-licencia.component";
import { ConfigRankingsComponent } from "./componentes/gestion/config-rankings/config-rankings.component";
import { RankingDetalleComponent } from "./componentes/gestion/config-rankings/ranking-detalle/ranking-detalle.component";
import { BalderUsuariosComponent } from "./componentes/gestion/balder-usuarios/balder-usuarios.component";
import { EditarUsuarioBalderComponent } from "./componentes/gestion/balder-usuarios/editar-usuario-balder/editar-usuario-balder.component";
import { FormacionPersonaComponent } from "./componentes/ficha-cliente/formularios/formacion-persona/formacion-persona.component";
import { ContratoAmueblandoComponent } from "./componentes/ficha-cliente/formularios/contrato-amueblando/contrato-amueblando.component";
import { ContratoPresentacionDVComponent } from "./componentes/ficha-cliente/formularios/contrato-presentacion-dv/contrato-presentacion-dv.component";
import { ContratoBannerCreamostumuebleComponent } from "./componentes/ficha-cliente/formularios/contrato-banner-creamostumueble/contrato-banner-creamostumueble.component";
import { ConfigLicenciasComponent } from "./componentes/gestion/config-licencias/config-licencias.component";
import { EditarUsuarioComponent } from "./componentes/editar-usuario/editar-usuario.component";
import { DetallesPerfilTCLAComponent } from "./componentes/ficha-cliente/formularios/detalles-perfil-tcla/detalles-perfil-tcla.component";
import { ConfigLicenciasCliComponent } from "./componentes/ficha-cliente/formularios/config-licencias-cli/config-licencias-cli.component";
import { HistorialConfigLicenciasCliComponent } from "./componentes/ficha-cliente/formularios/historial-config-licencias-cli/historial-config-licencias-cli.component";
import { NuevaActualizacionComponent } from "./componentes/nueva-actualizacion/nueva-actualizacion.component";
import { ControlAsistenciaComponent } from "./componentes/gestion/control-asistencia/control-asistencia.component";
import { ConfigMuestrasComponent } from "./componentes/gestion/config-muestras/config-muestras.component";
import { NuevaMuestraComponent } from "./componentes/gestion/config-muestras/nueva-muestra/nueva-muestra.component";
import { AuthInterceptor } from "./servicios/AuthInterceptor";
import { SesionService } from "./servicios/SesionService";
import { PreguntasClientesComponent } from "./componentes/preguntas-clientes/preguntas-clientes.component";
import { ResponderPreguntaComponent } from "./componentes/preguntas-clientes/responder-pregunta/responder-pregunta.component";
import { AlertasClienteDetalleComponent } from "./componentes/ficha-cliente/formularios/alertas-cliente-detalle/alertas-cliente-detalle.component";
import { AlertasClientesComponent } from "./componentes/alertas-clientes/alertas-clientes.component";
import { ExcepcionesAlertasComponent } from "./componentes/alertas-clientes/excepciones-alertas/excepciones-alertas.component";
import { GestorDescargasComponent } from "./componentes/gestor-descargas/gestor-descargas.component";
import { AsignarPerfilTeclaComponent } from "./componentes/ficha-cliente/formularios/detalles-perfil-tcla/asignar-perfil-tecla/asignar-perfil-tecla.component";
import { BusquedaIpdormaComponent } from "./componentes/ficha-cliente/formularios/busqueda-ipdorma/busqueda-ipdorma.component";
import { DialogConfirmacionComponent } from "./componentes/dialog-confirmacion/dialog-confirmacion.component";
import { IpdormasComponent } from "./componentes/ipdormas/ipdormas.component";
import { ClientesAsignadosIpdormaComponent } from "./componentes/ipdormas/clientes-asignados-ipdorma/clientes-asignados-ipdorma.component";
import { BusquedaClienteComponent } from "./componentes/ipdormas/clientes-asignados-ipdorma/busqueda-cliente/busqueda-cliente.component";
import { Thing2Component } from "./thing2/thing2.component";
import { UsuariosEquipoComponent } from "./componentes/equipo/usuarios-equipo/usuarios-equipo.component";
import { EquipoComponent } from "./componentes/equipo/equipo.component";
import { EncargosComponent } from "./componentes/encargos/encargos.component";
import { SupervisionInfluencersComponent } from "./componentes/supervision-influencers/supervision-influencers.component";
import { CampusPersonaComponent } from "./componentes/ficha-cliente/formularios/campus-persona/campus-persona.component";

const appRoutes: Routes = [
  { path: "", redirectTo: "/direcciones", pathMatch: "full" },
  { path: "usuarios", component: UsuariosComponent },
  { path: "gestion", component: GestionComponent },
  { path: "informes", component: InformesComponent },
  { path: "control-asistencia", component: ControlAsistenciaComponent },
  { path: "config-rankings", component: ConfigRankingsComponent },
  { path: "config-licencias", component: ConfigLicenciasComponent },
  { path: "config-muestras", component: ConfigMuestrasComponent },
  { path: "balder-usuarios", component: BalderUsuariosComponent },
  { path: "direcciones", component: DireccionesComponent },
  { path: "agenda", component: AgendaComponent },
  { path: "mapa", component: MapaChinchetasComponent },
  { path: "direccion/:id", component: FichaClienteComponent },
  { path: "direccion/:id/:persona", component: FichaClienteComponent },
  { path: "accion/:id", component: FichaClienteComponent },
  { path: "login", component: LoginComponent },
  { path: "preguntas-clientes", component: PreguntasClientesComponent },
  { path: "alertas-clientes", component: AlertasClientesComponent },
  { path: "ipdormas", component: IpdormasComponent },
  { path: "encargos", component: EncargosComponent },
  {
    path: "supervision-influencers",
    component: SupervisionInfluencersComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsuariosComponent,
    UsuarioFormularioComponent,
    DireccionesComponent,
    ClienteFormularioComponent,
    FichaClienteComponent,
    NuevaDireccionFormularioComponent,
    NuevaPersonaFormularioComponent,
    FotografiasDireccionComponent,
    UbicacionDireccionComponent,
    HistoricoAccionesComponent,
    NuevaAccionFormularioComponent,
    RealizarAccionFormularioComponent,
    PosponerAccionFormularioComponent,
    ContratoWallviewerFormularioComponent,
    ContratoIdehabitaFormularioComponent,
    MapaChinchetasComponent,
    AgendaComponent,
    AdminMuestrasFormularioComponent,
    DetalleMuestraComponent,
    NotasActualizacionComponent,
    PosponerEnviarAccionFormularioComponent,
    InformesComponent,
    GestionComponent,
    Licencias3DFormularioComponent,
    EditarLicenciaComponent,
    RenovarLicenciaComponent,
    HistorialLicenciaComponent,
    ConfigRankingsComponent,
    RankingDetalleComponent,
    BalderUsuariosComponent,
    EditarUsuarioBalderComponent,
    FormacionPersonaComponent,
    ContratoAmueblandoComponent,
    ContratoPresentacionDVComponent,
    ContratoBannerCreamostumuebleComponent,
    ConfigLicenciasComponent,
    EditarUsuarioComponent,
    DetallesPerfilTCLAComponent,
    ConfigLicenciasCliComponent,
    HistorialConfigLicenciasCliComponent,
    NuevaActualizacionComponent,
    ControlAsistenciaComponent,
    ConfigMuestrasComponent,
    NuevaMuestraComponent,
    PreguntasClientesComponent,
    ResponderPreguntaComponent,
    AlertasClienteDetalleComponent,
    AlertasClientesComponent,
    ExcepcionesAlertasComponent,
    GestorDescargasComponent,
    AsignarPerfilTeclaComponent,
    BusquedaIpdormaComponent,
    DialogConfirmacionComponent,
    IpdormasComponent,
    ClientesAsignadosIpdormaComponent,
    BusquedaClienteComponent,
    Thing2Component,
    UsuariosEquipoComponent,
    EquipoComponent,
    EncargosComponent,
    SupervisionInfluencersComponent,
    CampusPersonaComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
    ngfModule,
  ],
  providers: [
    CrmService,
    MapaserviceService,
    AgendaService,
    SesionService,
    DatePipe,
    { provide: MatPaginatorIntl, useClass: LabelDataTable },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
