import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CrmService} from '../../servicios/crm.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ClientesAsignadosIpdormaComponent} from './clientes-asignados-ipdorma/clientes-asignados-ipdorma.component';
import {IpdormaItemList} from '../../modelos/Interfaces';
import {BusquedaIpdormaComponent} from '../ficha-cliente/formularios/busqueda-ipdorma/busqueda-ipdorma.component';

@Component({
  selector: 'app-ipdormas',
  templateUrl: './ipdormas.component.html',
  styleUrls: ['./ipdormas.component.css']
})
export class IpdormasComponent implements OnInit, AfterViewInit {

  public readonly displayedColumns: string[] = ['codigo', 'cif', 'razonSocial', 'nombreComercial', 'provincia', 'poblacion', 'ultimaActividad', 'nClientes'];
  public dataSource: MatTableDataSource<IpdormaItemList> = new MatTableDataSource();

  public cargando: boolean;
  public ipdormas: IpdormaItemList[];
  public textoBusqueda: string;

  public mostrarIgnorados: true | false | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.cargarDatosDirecciones();
  }

  constructor(public crmservice: CrmService,
              private dialog: MatDialog) {
    this.cargando = true;
    this.textoBusqueda = '';
    this.mostrarIgnorados = false;

    this.getAllCliente_Ipdorma()
      .then(() => {
        if (this.crmservice.clientesCIFRazonSocial == null || !this.crmservice.clientesCIFRazonSocial.length) {
          this.crmservice.dameClientesCIFRazonSocial().subscribe(value => {
            this.crmservice.clientesCIFRazonSocial = value;
            this.cargando = false;
          }, error => {
            console.error(error);
            this.cargando = false;
          });
        } else {
          this.cargando = false;
        }
      })
      .catch(err => {
        this.cargando = false;
        console.error(err);
      });
  }

  ngOnInit(): void {
  }

  public applyFilter(): void {
    this.dataSource.filter = this.textoBusqueda;
  }
  public abrirAsignados(ipdorma: IpdormaItemList): void {
    const dialogRef = this.dialog.open(ClientesAsignadosIpdormaComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: { ipdorma: ipdorma }
    });

    dialogRef.afterClosed().subscribe(() => {
      switch (this.mostrarIgnorados) {
        case null:
          break;
        case true:
          if (!ipdorma.ignorar) {
            const pos = this.ipdormas.findIndex(i => i._id === ipdorma._id);
            if (pos !== -1) {
              this.ipdormas.splice(pos, 1);
              this.dataSource.data = this.ipdormas;
            }
          }
          break;
        case false:
          if (ipdorma.ignorar) {
            const pos = this.ipdormas.findIndex(i => i._id === ipdorma._id);
            if (pos !== -1) {
              this.ipdormas.splice(pos, 1);
              this.dataSource.data = this.ipdormas;
            }
          }
          break;
      }
    });
  }
  public mostrarIgnoradosCambiado(): void {
    this.cargando = true;
    this.getAllCliente_Ipdorma()
      .then(() => this.cargando = false)
      .catch(err => {
        this.cargando = false;
        console.error(err);
      });
  }

  public buscarCodigo(): void {
    const popUpRef = this.dialog.open(BusquedaIpdormaComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'custom-dialog-container',
      autoFocus: false
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.getCliente_Ipdorma(value).subscribe(ipdorma => {
          if (ipdorma != null) {
            this.abrirAsignados(ipdorma);
          }
        });
      }
    });
  }

  private getAllCliente_Ipdorma(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crmservice.getAllCliente_Ipdorma(this.mostrarIgnorados).subscribe(ipdormas_clientes => {
        this.ipdormas = ipdormas_clientes;
        this.dataSource.data = ipdormas_clientes;
        resolve();
      }, error => reject(error));
    });
  }

}
