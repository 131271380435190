import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../servicios/crm.service';
import {Router} from '@angular/router';
import {NotasActualizacionComponent} from './notas-actualizacion/notas-actualizacion.component';
import {EditarUsuarioComponent} from './editar-usuario/editar-usuario.component';
import {NuevaActualizacionComponent} from './nueva-actualizacion/nueva-actualizacion.component';
import {SesionService} from '../servicios/SesionService';
import {DownloadService} from '../servicios/DownloadService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  reason = '';
  mobileQuery: MediaQueryList;

  private readonly _mobileQueryListener: () => void;

  @ViewChild('sidenav') sidenav: MatSidenav;

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close().then();
  }

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public router: Router,
              public crmservice: CrmService,
              public sesionService: SesionService,
              public ds: DownloadService,
              private adapter: DateAdapter<any>,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {

    console.log('AppComponent');

    this.adapter.setLocale('es');

    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.crmservice.getInicioApp().subscribe(value => {
      // Token caducado o incorrecto
      this.crmservice.userLogin = value.userLogin;
      this.crmservice.usuarios = value.users;
      this.crmservice.direcciones = value.direcciones;
      this.crmservice.modoPruebas = value.pruebas;
      this.crmservice.alertasClientes = value.alertas;

      this.crmservice.ordenarUsuarios();

      this.sesionService.setUserLogeado(true);
      this.crmservice.datosCargados = true;

      this.crmservice.setUltimaConexion(this.crmservice.userLogin._id).subscribe();

      // Comprobar si la versión local es la más actual y si el usuario ha revisado el log de cambios.
      setTimeout(() => {
        if (this.sesionService.getUserLogeado()) {
          this.crmservice.getUserCheckedActualizacion(this.crmservice.version, this.crmservice.userLogin._id).subscribe(
            checked => {
              this.crmservice.userCheckActualizacion = checked;
              if (!this.crmservice.userCheckActualizacion) {
                this.notasActualizacion();
              }
            });
        }
      }, 5000);

      this.crmservice.getNumPreguntasPendientes().subscribe(count => this.crmservice.numPreguntas = count ? (count.num || 0) : 0);
      setInterval(() => {
        if (this.crmservice.userLogin != null) {
          this.crmservice.getNumPreguntasPendientes().subscribe(count =>
            this.crmservice.numPreguntas = count ? (count.num || 0) : 0);
        }
      }, 60 * 5 * 1000);

      this.crmservice.countCliente_Ipdorma().subscribe(count => this.crmservice.numIpdormas = count || 0);
      setInterval(() => {
        if (this.crmservice.userLogin != null) {
          this.crmservice.countCliente_Ipdorma().subscribe(count =>
            this.crmservice.numIpdormas = count || 0);
        }
      }, 60 * 5 * 1000);

    }, error1 => {
      console.error(error1);
      this.sesionService.removeToken();
      this.router.navigate(['/login']).then();
    });

  }

  public logout(): void {
    this.crmservice.popUpConfirmacion({textoPrincipal: '¿Salir de DormaCRM?'}).afterClosed().subscribe(value => {
      if (value) {
        this.sesionService.removeToken();
        location.href = this.crmservice.urlCRM2 + '/login';
      }
    });
  }

  public cerrarNavList(): void {
    if (this.mobileQuery.matches) {
      this.sidenav.close().then();
    }
  }

  public editarMiPerfil(): void {
    const dialogRef = this.dialog.open(EditarUsuarioComponent, {
      height: !this.mobileQuery.matches ? '700px' : '100%',
      width: !this.mobileQuery.matches ? '800px' : '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      data: {
        userID: this.crmservice.userLogin._id
      }
    });

    dialogRef.afterClosed().subscribe(usuarioEditar => {
      if (usuarioEditar != null) {
        const pos = this.crmservice.usuarios.findIndex(e => e._id === usuarioEditar._id);

        if (pos !== -1) {
          // Actualizar información más relevante (la que se vé en la tabla de usuarios).
          this.crmservice.usuarios[pos].nombre = usuarioEditar.nombre;
          this.crmservice.usuarios[pos].apellidos = usuarioEditar.apellidos;
          this.crmservice.usuarios[pos].poblacion = usuarioEditar.poblacion;
          this.crmservice.usuarios[pos].provincia = usuarioEditar.provincia;
          this.crmservice.usuarios[pos].telefono = usuarioEditar.telefono;
          this.crmservice.usuarios[pos].email = usuarioEditar.email;
          this.crmservice.usuarios[pos].team = usuarioEditar.team;
          this.crmservice.usuarios[pos].rol = usuarioEditar.rol;

          if (this.crmservice.usuarios[pos]._id === this.crmservice.userLogin._id) {
            // Si he editado mi propio perfil, actualizar también el userLogin del crmservice.
            this.crmservice.userLogin.nombre = usuarioEditar.nombre;
            this.crmservice.userLogin.apellidos = usuarioEditar.apellidos;
            this.crmservice.userLogin.poblacion = usuarioEditar.poblacion;
            this.crmservice.userLogin.provincia = usuarioEditar.provincia;
            this.crmservice.userLogin.telefono = usuarioEditar.telefono;
            this.crmservice.userLogin.email = usuarioEditar.email;
            this.crmservice.userLogin.team = usuarioEditar.team;
            this.crmservice.userLogin.rol = usuarioEditar.rol;
          }
        }
      }
    }, () => {
      this.snackBar.open('Ex0001', 'OK', {duration: 2000});
    });
  }

  public nuevaVersion(): void {
    let width: string;
    let height: string;

    if (!this.mobileQuery.matches) {
      width = '1000px';
      height = '800px';
    } else {
      width = '95%';
      height = '95%';
    }

    this.dialog.open(NuevaActualizacionComponent, {
      height: height,
      width: width,
      panelClass: 'custom-dialog-container',
      autoFocus: false
    });
  }
  public notasActualizacion(): void {
    let width: string;
    let height: string;

    if (!this.mobileQuery.matches) {
      width = '40%';
      height = '80%';

    } else {
      width = '95%';
      height = '95%';

    }

    this.dialog.open(NotasActualizacionComponent, {
      height: height,
      width: width,
      panelClass: 'custom-dialog-container',
      autoFocus: false
      });
  }

  abrirCMI(): void {
    window.open(this.crmservice.urlCMI);
  }

}
