import {Component, Inject, OnInit} from '@angular/core';
import {Cliente} from '../../../../../modelos/Cliente';
import {Direccion} from '../../../../../modelos/Direccion';
import {CrmService} from '../../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Licencia} from '../../../../../modelos/Licencia';

@Component({
  selector: 'app-renovar-licencia',
  templateUrl: './renovar-licencia.component.html',
  styleUrls: ['./renovar-licencia.component.css']
})
export class RenovarLicenciaComponent implements OnInit {

  public clienteSel: Cliente;
  public direccionSel: Direccion;
  public licenciaSel: Licencia;
  public equipo: {
    aliasEquipo: string,
    referencia: string,
    adaptRed: string,
    llaveRed: string,
    activaciones: {
      usuario: string,
      fActivacion: Date,
      fVencimiento: Date,
      caducada: boolean
    }[]
  };

  public fActivacion: string;
  public fVencimiento: string;

  public mensajeError: string;

  constructor(public crmservice: CrmService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<RenovarLicenciaComponent>) {

    this.mensajeError = '';

    this.clienteSel = data.clienteSel;
    this.direccionSel = data.direccionSel;
    this.licenciaSel = data.licencias;
    this.equipo = data.equipo;

    const hoy = new Date();
    this.fActivacion = hoy.getFullYear() + '-' + this.formatDate(hoy.getMonth() + 1) + '-' + this.formatDate(hoy.getDate());
    hoy.setDate(hoy.getDate() + 90);
    this.fVencimiento = hoy.getFullYear() + '-' + this.formatDate(hoy.getMonth() + 1) + '-' + this.formatDate(hoy.getDate());

  }

  ngOnInit() {
  }

  public renovarLicencia(): void {
    if (this.comprobarFecha()) {
      // this.equipo.fVencimiento = this.fVencimiento;
      this.equipo.activaciones.push({
        usuario: this.crmservice.userLogin._id,
        fActivacion: new Date(this.fActivacion),
        fVencimiento: new Date(this.fVencimiento),
        caducada: this.getCaducada(new Date(this.fVencimiento))
      });

      const pos = this.licenciaSel.equipos.findIndex(e => e.aliasEquipo === this.equipo.aliasEquipo);

      this.licenciaSel.equipos[pos] = this.equipo;

      this.crmservice.actualizarLicencia(this.licenciaSel).subscribe(
        valueLicencia => {
          this.dialogRef.close(valueLicencia);
        }, error1 => {
          console.error(error1);
        });
    }
  }
  public comprobarFecha(): boolean {

    if (this.fActivacion == null || this.fActivacion === '') {
      this.mensajeError = 'Introduce la fecha de activación.';
    }

    if (this.fVencimiento == null || this.fVencimiento === '') {
      this.mensajeError = 'Introduce la fecha de vencimiento.';
      return false;
    }

    const dateActivacion = new Date(this.fActivacion);
    const dateVencimiento = new Date(this.fVencimiento);

    if (dateActivacion.getTime() >= dateVencimiento.getTime()) {
      this.mensajeError = 'La fecha de vencimiento no puede ser antes que la de activación.';
      return false;
    }

    this.mensajeError = '';
    return true;
  }

  public formatDate(n: number): string {
    if (n > 9) {
      return n.toString(10);
    } else {
      return '0' + n;
    }
  }

  public getCaducada(vencimiento: Date): boolean {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    vencimiento.setHours(0, 0, 0, 0);
    return vencimiento.getTime() <= hoy.getTime();
  }

}
