import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {PreguntaCliente} from '../../modelos/PreguntaCliente';
import {CrmService} from '../../servicios/crm.service';
import { MatDialog } from '@angular/material/dialog';
import {MediaMatcher} from '@angular/cdk/layout';
import {ResponderPreguntaComponent} from './responder-pregunta/responder-pregunta.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-preguntas-clientes',
  templateUrl: './preguntas-clientes.component.html',
  styleUrls: ['./preguntas-clientes.component.css']
})
export class PreguntasClientesComponent implements OnInit, AfterViewInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargando: boolean;

  public displayedColumns = ['fechacreacion', 'emailCliente', 'cpCliente', 'provinciaCliente', 'respondida'];
  public preguntas: MatTableDataSource<PreguntaCliente> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.preguntas.paginator = this.paginator;
    this.preguntas.sort = this.sort;
  }

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public crmservice: CrmService,
              private dialog: MatDialog) {

    console.log('preguntas-clientes');

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.recargar();

  }

  ngOnInit() {
  }

  public responderPregunta(pregunta: PreguntaCliente): void {
    this.dialog.open(ResponderPreguntaComponent, {
      height: !this.mobileQuery.matches ? '800px' : '100%',
      width: !this.mobileQuery.matches ? '900px' : '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      panelClass: 'tcla2-dialog-container',
      autoFocus: false,
      disableClose: true,
      data: {
        pregunta: pregunta,
      }
    });
  }
  public recargar(): void {
    this.cargando = true;
    this.crmservice.getPreguntasClientes().subscribe(preguntas => {
      this.preguntas.data = preguntas;

      this.preguntas.data.forEach(p => {
        const usuario = this.crmservice.usuarios.find(u => u._id === p.idCliente);
        p.respondida = usuario ? (usuario.nombre + ' ' + usuario.apellidos.split(' ')[0]) : 'Sin Responder';
      });

      this.cargando = false;
    }, error => {
      console.error(error);
      this.cargando = false;
    });
  }
}
