import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Accion} from '../../../../modelos/Accion';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Direccion} from '../../../../modelos/Direccion';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-posponer-enviar-accion-formulario',
  templateUrl: './posponer-enviar-accion-formulario.component.html',
  styleUrls: ['./posponer-enviar-accion-formulario.component.css']
})
export class PosponerEnviarAccionFormularioComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public guardandoDatos = false;
  public accion: Accion;

  public gastoTiempo = false;
  public fechaPrevistaA: Date; // Anterior
  public fechaPrevistaN: Date; // Nueva
  public horaPrevistaN: string;

  public fechaInicioGasto: Date;
  public horaInicioGasto: string;
  public fechaFinGasto: Date;
  public horaFinGasto: string;

  public motivo: string;
  public observaciones: string;

  public direcciones: Direccion[];
  public asesores: string[];
  public pedidos: string[];
  public comerciales: string[];
  public supervisores: string[];
  public para;

  public posponerFormGroup: FormGroup;

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 750px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.accion = this.data.accion;
    this.direcciones = this.data.direcciones;
    this.fechaPrevistaA = new Date(this.accion.fechaPrevista); // Guardar la fecha prevista antigua

    if (this.data.agenda) {
      this.fechaPrevistaN = this.data.fechaPrevista;
      this.horaPrevistaN = this.crmservice.FormatNumberLength(this.fechaPrevistaN.getHours(), 2) + ':' + this.crmservice.FormatNumberLength(this.fechaPrevistaN.getMinutes(), 2);
    }

    this.posponerFormGroup = new FormGroup({
      fechaPrevistaN: new FormControl('', [Validators.required]),
      horaPrevistaN: new FormControl('', []),
      motivo: new FormControl('', [Validators.required]),
      fechaInicioGasto: new FormControl('', []),
      horaInicioGasto: new FormControl('', []),
      horaFinGasto: new FormControl('', []),
      observaciones: new FormControl('', [Validators.required]),
      para: new FormControl ('', []),
      comerciales: new FormControl ('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  public dameMotivo(motivo: string): string {
    const pos = this.crmservice.motivosPosponer.findIndex(e => e.id === motivo);
    if (pos !== -1) {
      return this.crmservice.motivosPosponer[pos].nombre;
    } else {
      return 'Sin motivo';
    }
  }

  public cambiarRequeridos(): void {
    this.posponerFormGroup.controls['fechaInicioGasto'].clearValidators();
    this.posponerFormGroup.controls['horaInicioGasto'].clearValidators();
    this.posponerFormGroup.controls['horaFinGasto'].clearValidators();

    if (this.gastoTiempo) {
      this.posponerFormGroup.controls['fechaInicioGasto'].setValidators([Validators.required]);
      this.posponerFormGroup.controls['horaInicioGasto'].setValidators([Validators.required]);
      this.posponerFormGroup.controls['horaFinGasto'].setValidators([Validators.required]);
    }

    this.posponerFormGroup.controls['fechaInicioGasto'].updateValueAndValidity();
    this.posponerFormGroup.controls['horaInicioGasto'].updateValueAndValidity();
    this.posponerFormGroup.controls['horaFinGasto'].updateValueAndValidity();
  }

  public posponerAccion(): void {
    if (this.posponerFormGroup.valid) {
      this.guardandoDatos = true;
      this.fechaPrevistaN = new Date(this.fechaPrevistaN);
      if (this.horaPrevistaN != null) {
        this.fechaPrevistaN.setHours(parseInt(this.horaPrevistaN.split(':')[0], 10));
        this.fechaPrevistaN.setMinutes(parseInt(this.horaPrevistaN.split(':')[1], 10));
      } else {
        this.fechaPrevistaN.setHours(0);
        this.fechaPrevistaN.setMinutes(0);
      }

      if (this.gastoTiempo) {
        this.fechaInicioGasto = new Date(this.fechaInicioGasto);
        this.fechaInicioGasto.setHours(parseInt(this.horaInicioGasto.split(':')[0], 10));
        this.fechaInicioGasto.setMinutes(parseInt(this.horaInicioGasto.split(':')[1], 10));

        this.fechaFinGasto = new Date(this.fechaInicioGasto);
        this.fechaFinGasto.setHours(parseInt(this.horaFinGasto.split(':')[0], 10));
        this.fechaFinGasto.setMinutes(parseInt(this.horaFinGasto.split(':')[1], 10));

        this.accion.pospuestas.push({
          fechaPrevistaA: this.fechaPrevistaA,
          fechaPrevistaN: this.fechaPrevistaN,
          motivo: this.motivo,
          observaciones: this.observaciones,
          cuandoCambio: new Date(),
          gastoTiempo: true,
          fechaInicio: this.fechaInicioGasto,
          fechaFin: this.fechaFinGasto
        });
      } else {
        this.accion.pospuestas.push({
          fechaPrevistaA: this.fechaPrevistaA,
          fechaPrevistaN: this.fechaPrevistaN,
          motivo: this.motivo,
          observaciones: this.observaciones,
          cuandoCambio: new Date(),
          gastoTiempo: false,
          fechaInicio: null,
          fechaFin: null
        });
      }

      const ahora = new Date();

      this.accion.fechaPrevista = this.fechaPrevistaN;
      this.accion.observaciones += '\n\n' + ahora.getDate() + '/' + (ahora.getMonth() + 1) + '/' + ahora.getFullYear() + ' ' + ahora.getHours() + ':' + ahora.getMinutes() + ' Pospuesta: ' + this.observaciones;

      this.usuariosPara();
      this.accion.estado = '2';
      this.accion.de = [this.crmservice.userLogin._id];
      this.accion.para = this.para;

      if (this.accion.para.length > 1) {
        if (this.asesores !== undefined && this.asesores.length === 1) {
          this.accion.de = this.asesores;
          this.accion.estado = '2';
        } else {
          this.accion.de = []; // Es posible que al marcar como undefined
          this.accion.estado = '1';
        }
      } else {
        this.accion.de = this.accion.para;
        this.accion.estado = '2';
      }

      this.crmservice.updateAccion(this.accion).subscribe(
        () => {
          this.fechaPrevistaN = null;
          this.motivo = null;
          this.observaciones = null;
          this.gastoTiempo = false;
          this.fechaInicioGasto = null;
          this.fechaFinGasto = null;
        }, error1 => {
          this.guardandoDatos = false;
          this.snackBar.open('Error al posponer la acción', 'OK', {duration: 2000});
          console.log(error1);
        }, () => {
          this.guardandoDatos = false;
          this.snackBar.open('Acción pospuesta correctamente', 'OK', {duration: 2000});
          if (this.data.agenda) {
            this.dialogRef.close(this.accion);
          } else {
            this.dialogRef.close();
          }
          // this.crmservice.datosCargados = false;
        }
      );
    } else {
      this.mostrarErrores();
    }
  }

  public calcularHoras(): boolean {
    let resultado = true;
    if (this.horaInicioGasto != null && this.horaFinGasto != null) {
      const horas = [parseInt(this.horaInicioGasto.split(':')[0], 10), parseInt(this.horaFinGasto.split(':')[0], 10)];
      const minutos = [parseInt(this.horaInicioGasto.split(':')[1], 10), parseInt(this.horaFinGasto.split(':')[1], 10)];

      if (horas[0] > horas[1]) {
        resultado = false;
      }
      if (horas[0] === horas[1]) {
        if (minutos[0] >= minutos[1]) {
          resultado = false;
        }
      }
    }

    return resultado;
  }

  public mostrarErrores(): void {
    this.posponerFormGroup.get('fechaPrevistaN').markAsTouched();
    this.posponerFormGroup.get('motivo').markAsTouched();
    this.posponerFormGroup.get('fechaInicioGasto').markAsTouched();
    this.posponerFormGroup.get('horaInicioGasto').markAsTouched();
    this.posponerFormGroup.get('horaFinGasto').markAsTouched();
    this.posponerFormGroup.get('observaciones').markAsTouched();
    this.posponerFormGroup.get('comerciales').markAsTouched();
  }

  public usuariosPara(): void {
    this.para = [];
    try {
      for (const item of this.asesores) {
        this.para.push(item);
      }
    } catch (e) {
      console.log('La lista de asesores está vacía');
    }

    try {
      for (const item of this.pedidos) {
        this.para.push(item);
      }
    } catch (e) {
      console.log('La lista de pedidos está vacía');
    }

    try {
      for (const item of this.comerciales) {
        this.para.push(item);
      }
    } catch (e) {
      console.log('La lista de comerciales está vacía');
    }

    try {
      for (const item of this.supervisores) {
        this.para.push(item);
      }
    } catch (e) {
      console.log('La lista de supervisores está vacía');
    }
  }

  public dameEquipo(): string {
    if (this.accion.direccion != null) {
      const pos = this.direcciones.map(function (e) {
        return e._id;
      }).indexOf(this.accion.direccion);

      return this.direcciones[pos].equipo;
    } else {
      return null;
    }
  }
}
