import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {CrmService} from '../../servicios/crm.service';
import {AlertaClienteConfig} from '../../modelos/AlertaClienteConfig';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {ExcepcionesAlertasComponent} from './excepciones-alertas/excepciones-alertas.component';
import {AlertaClienteConsumo} from '../../modelos/AlertaClienteConsumo';
import {AlertaCliente, AlertaClienteRow} from '../../modelos/AlertaCliente';
import {MatSnackBar} from '@angular/material/snack-bar';

interface SortByAlertas {
  field: 'fecha' | 'razonSocial';
  sort: number;
}
interface SortByInteres {
  field: 'diffYear' | 'diffMonth' | 'diffYearMonth';
  sort: number;
}

@Component({
  selector: 'app-alertas-clientes',
  templateUrl: './alertas-clientes.component.html',
  styleUrls: ['./alertas-clientes.component.css']
})
export class AlertasClientesComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  public cargandoDatos: boolean;

  public alertasConsumo: AlertaClienteConsumo[];
  public dataSourceInteres: MatTableDataSource<AlertaClienteConsumo> = new MatTableDataSource();
  public equipoSel: string;
  public filtroInteres: string;
  public sortByInteres: SortByInteres;

  public alertaClienteRows: AlertaClienteRow[];
  public dataSourceAlertas: MatTableDataSource<AlertaClienteRow> = new MatTableDataSource();
  public acciones: string[];
  public sortByAlertas: SortByAlertas;

  public alertaClienteConfigs: AlertaClienteConfig[];
  public dataSourceConfig: MatTableDataSource<AlertaClienteConfig> = new MatTableDataSource();
  public filtroConfig: string;

  public filtroAlertas: string;
  public configSel: AlertaClienteConfig;
  public alertasMostrarEstado: string;
  public alertasMostrarClasificacion: string;
  public alertasMostrarEquipo: string;
  public alertasMostrarRazonSocial: string;
  public equiposMostrar: string[];
  public razonesSocialesMostrar: string[];

  constructor(changeDetectorRef: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              media: MediaMatcher,
              public crmservice: CrmService, public dialog: MatDialog) {

    console.log('AlertasClientes');

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.cargandoDatos = true;
    this.equipoSel = (this.crmservice.rol('comercial') || !crmservice.rol('influencer') ) ? this.crmservice.userLogin.team : 'todos';
    this.configSel = null;
    this.alertasMostrarEstado = 'todos';
    this.alertasMostrarClasificacion = 'todos';
    this.alertasMostrarEquipo = 'todos';
    this.alertasMostrarRazonSocial = 'todos';
    this.cargandoDatos = true;
    this.equiposMostrar = [];
    this.cargaAlertas()
      .then(() => {
        this.cargandoDatos = false;
      })
      .catch(err => {
        console.error(err);
        this.cargandoDatos = false;
      });
  }

  ngOnInit() {
  }

  private cargaAlertas(): Promise<unknown> {
    const promesaAlertas = new Promise<void>((resolve, reject) => {
      this.crmservice.getAlertasClientes().subscribe(alertas => {
        this.crmservice.alertasClientes = alertas.filter(a => this.crmservice.direcciones.some(d => d._id === a.direccion)).map(a => new AlertaCliente(a));
        this.crmservice.alertasClientes.sort((a, b) => a.fecha > b.fecha ? -1 : a.fecha < b.fecha ? 1 : 0);
        this.getEquipos();
        this.getRazonesSociales();
        this.iniciarDataSourceAlertas();
        this.crmservice.getAlertaClienteConfig().subscribe(config => {
          this.alertaClienteConfigs = config;
          this.dataSourceConfig.data = this.alertaClienteConfigs;
          resolve();
        }, errorConfig => reject(errorConfig));
      }, error => reject(error));
    });
    const promesaAlertasConsumo = new Promise<void>((resolve, reject) => {
      this.crmservice.getAlertasClientesConsumo().subscribe(alertas => {
        this.alertasConsumo = alertas.filter(a => this.alertaConsumoFilter(a)).map(a => new AlertaClienteConsumo(a, this.crmservice));
        this.alertasConsumo = this.alertasConsumo.filter(a => a.cliente != null);
        this.dataSourceInteres.data = this.alertasConsumo;
        this.sortInteres();
        resolve();
      }, error => reject(error));
    });
    const promesaAcciones = new Promise<void>((resolve, reject) => {
      this.acciones = [];
      this.crmservice.accionesAlertaClientes().subscribe(acciones => {
        this.acciones = acciones.map(a => a.direccion);
        resolve();
      }, error => reject(error));
    });

    return Promise.all([promesaAlertas, promesaAlertasConsumo, promesaAcciones]);
  }
  private iniciarDataSourceAlertas(): void {
    this.alertaClienteRows = [];
    for (const alerta of this.crmservice.alertasClientes) {
      const direccion = this.crmservice.direcciones.find(e => e._id === alerta.direccion);
      this.alertaClienteRows.push(new AlertaClienteRow(alerta, direccion));
    }
    this.aplicarSelects();
  }
  private alertaConsumoFilter(a: any): boolean {
    return (a.factCurrentYear > 0) &&
      (a.balanceCurrentYear === false || a.balanceCurrentMonth === false || a.balanceLastYearMonth === false);
  }
  private getEquipos(): void {
    this.equiposMostrar = [];
    this.crmservice.alertasClientes.forEach(a => {
      const direccion = this.crmservice.direcciones.find(d => d._id === a.direccion);
      if (direccion && !this.equiposMostrar.includes(direccion.equipo)) {
        this.equiposMostrar.push(direccion.equipo);
      }
    });
    this.equiposMostrar.sort();
  }
  private getRazonesSociales(): void {
    this.razonesSocialesMostrar = [];
    this.crmservice.alertasClientes.forEach(a => {
      const direccion = this.crmservice.direcciones.find(d => d._id === a.direccion);
      if (direccion && !this.razonesSocialesMostrar.includes(direccion.razonSocial)) {
        this.razonesSocialesMostrar.push(direccion.razonSocial);
      }
    });
    this.razonesSocialesMostrar.sort();
  }

  public applyFilterInteres(): void {
    this.dataSourceInteres.filter = this.filtroInteres;
  }
  public aplicarSelectsInteres(): void {
    this.dataSourceInteres.data = this.alertasConsumo;
    if (this.equipoSel !== 'todos') {
      this.dataSourceInteres.data = this.dataSourceInteres.data.filter(i => i.cliente.equipo === this.equipoSel);
    }
    this.sortInteres(this.sortByInteres);
  }

  public applyFilterAlertas(): void {
    this.dataSourceAlertas.filter = this.filtroAlertas;
  }
  public applyFilterConfig(): void {
    this.dataSourceConfig.filter = this.filtroConfig;
  }
  public aplicarSelects(): void {
    this.dataSourceAlertas.data = this.alertaClienteRows;

    if (this.configSel != null) {
      this.dataSourceAlertas.data = this.dataSourceAlertas.data.filter(a => a.tipo === this.configSel.tipo);
    }
    if (this.alertasMostrarEstado !== 'todos') {
      this.dataSourceAlertas.data = this.dataSourceAlertas.data.filter(a => a.estado === this.alertasMostrarEstado);
    }
    if (this.alertasMostrarClasificacion !== 'todos') {
      this.dataSourceAlertas.data = this.dataSourceAlertas.data.filter(a => a.clasificacion === this.alertasMostrarClasificacion);
    }
    if (this.alertasMostrarEquipo !== 'todos') {
      this.dataSourceAlertas.data = this.dataSourceAlertas.data.filter(a => a.equipo === this.alertasMostrarEquipo);
    }
    if (this.alertasMostrarRazonSocial !== 'todos') {
      this.dataSourceAlertas.data = this.dataSourceAlertas.data.filter(a => a.razonSocial === this.alertasMostrarRazonSocial);
    }
    this.sortAlertas(this.sortByAlertas);
  }

  public sortAlertas(orden: SortByAlertas = {field: 'fecha', sort: -1}): void {
    this.sortByAlertas = orden;
    this.dataSourceAlertas.data.sort((a, b) => a[orden.field] > b[orden.field] ? orden.sort : -orden.sort);
  }
  public sortInteres(orden: SortByInteres = {field: 'diffYear', sort: 1}): void {
    this.sortByInteres = orden;
    this.dataSourceInteres.data.sort((a, b) => a[orden.field] > b[orden.field] ? orden.sort : -orden.sort);
  }

  public mostrarAccionPlanificada(alerta: AlertaClienteRow): boolean {
    return alerta.tipo === 'tcla_pocas_conexiones' && this.acciones.includes(alerta.idDireccion);
  }
  public getObjetivosAlerta(tipo: string): string[] {
    const posTipo = this.alertaClienteConfigs.findIndex(e => e.tipo === tipo);
    if (posTipo !== -1) {
      return this.alertaClienteConfigs[posTipo].objetivo;
    } else {
      return [];
    }
  }
  public getDescripcionAlerta(tipo: string): string {
    const posTipo = this.alertaClienteConfigs.findIndex(e => e.tipo === tipo);
    if (posTipo !== -1) {
      return this.alertaClienteConfigs[posTipo].descripcion;
    } else {
      return 'Desconocido';
    }
  }
  public getNombreEstado(estado: string): string {
    const posEstado = this.crmservice.estados.findIndex(e => e.id === estado);
    if (posEstado !== -1) {
      return this.crmservice.estados[posEstado].nombre;
    } else {
      return 'Desconocido';
    }
  }

  public abrirCliente(alertaRow: AlertaClienteRow): void {

    for (const alertaCliente of this.dataSourceAlertas.data) {
      if (alertaCliente.idCliente === alertaRow.idCliente) {
        const pos = alertaCliente.visto.findIndex(e => e === this.crmservice.userLogin._id);
        if (pos === -1) {
          alertaCliente.visto.push(this.crmservice.userLogin._id);
        }
      }
    }

    window.open(this.crmservice.urlCRM2 + '/direccion/' + alertaRow.idDireccion, '_blank');
  }
  public ignorarAlerta(alertaRow: AlertaClienteRow): void {
    this.crmservice.addExcepcionAlerta(alertaRow._id).subscribe(() => {
      let posAlerta = this.crmservice.alertasClientes.findIndex(e => e._id === alertaRow._id);
      if (posAlerta !== -1) {
        this.crmservice.alertasClientes.splice(posAlerta, 1);
      }

      posAlerta = this.dataSourceAlertas.data.findIndex(e => e._id === alertaRow._id);
      if (posAlerta !== -1) {
        this.dataSourceAlertas.data.splice(posAlerta, 1);
      }
    }, error => {
      console.error(error);
    });
  }
  public adquirirAlerta(alertaRow: AlertaClienteRow): void {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Gestionar esta alerta?',
      btnConfirm: 'ADQUIRIR',
      btnCancel: 'CANCELAR'
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.addGestionado(alertaRow._id).subscribe(idUser => {
          alertaRow.gestionado = idUser;
          this.snackBar.open(
            (idUser === this.crmservice.userLogin._id) ? 'Has adquirido la alerta' : 'Esta alerta ya ha sido adquirida por ' + this.getUsuario(idUser),
            'Ok',
            {duration: 3000}
          );
        }, error => {
          console.error(error);
        });
      }
    });
  }
  public rechazarAlerta(alertaRow: AlertaClienteRow): void {
    const popUpRef = this.crmservice.popUpConfirmacion({
      textoPrincipal: '¿Dejar de gestionar esta alerta?',
      btnConfirm: 'DEJAR LIBRE',
      btnCancel: 'CANCELAR'
    });
    popUpRef.afterClosed().subscribe(value => {
      if (value) {
        this.crmservice.removeGestionado(alertaRow._id).subscribe(() => {
          alertaRow.gestionado = null;
          this.snackBar.open('Has dejado de gestionar la alerta', 'Ok', {duration: 3000});
        }, error => {
          console.error(error);
        });
      }
    });
  }

  public numAlertasConfig(config?: AlertaClienteConfig): number {
    if (config == null) {
      return this.crmservice.alertasClientes.length;
    } else {
      return this.crmservice.alertasClientes.filter(e => e.tipo === config.tipo).length;
    }
  }
  public guardarGenerar(): void {
    this.cargandoDatos = true;
    this.crmservice.updateAlertaClienteConfig(this.alertaClienteConfigs).subscribe(
      () => {
        this.crmservice.revisarAlertasClientes().subscribe(
          () => {
            this.crmservice.crearAlertasClientes().subscribe(
              () => {
                this.crmservice.getAlertasClientes().subscribe(
                  alertas => {
                    this.crmservice.alertasClientes = alertas;
                    this.iniciarDataSourceAlertas();
                    this.cargandoDatos = false;
                  });
              });
          });
      });
  }
  public verExcepcionesAlerta(config: AlertaClienteConfig): void {
    this.dialog.open(ExcepcionesAlertasComponent, {
      width: '1000px',
      height: '800px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      data: {
        config: config
      }
    });
  }

  public getUsuario(idUsuario: string): string {
    const usuario = this.crmservice.usuarios.find(u => u._id === idUsuario);
    return (usuario != null ? (usuario.nombre + ' ' + usuario.apellidos) : 'Desconocido');
  }
  public abrirDireccionAlertaConsumo(idDireccion): void {
    window.open(this.crmservice.urlCRM2 + '/direccion/' + idDireccion, '_blank');
  }
  public abrirCMI(idCliente): void {
    window.open(this.crmservice.urlCMI + '/informes/comparativa/' + idCliente, '_blank');
  }
}
