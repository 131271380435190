import {Component, Inject, OnInit} from '@angular/core';
import {CrmService} from '../../../../servicios/crm.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsuariosComponent} from '../../../usuarios/usuarios.component';
import {Direccion} from '../../../../modelos/Direccion';

@Component({
  selector: 'app-fotografias-direccion',
  templateUrl: './fotografias-direccion.component.html',
  styleUrls: ['./fotografias-direccion.component.css']
})
export class FotografiasDireccionComponent implements OnInit {

  public direccion: Direccion;
  public direcciones: Direccion[];
  public guardandoDatos: boolean;

  public foto: File;
  public imagenes: {
    nombre: string,
    direccion: string
  }[];

  constructor(public crmservice: CrmService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UsuariosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.guardandoDatos = false;

    this.direccion = data.direccion;
    this.direcciones = data.direcciones;

    this.mostrarFotos();
  }

  public mostrarFotos(): void {
    this.imagenes = [];
    for (const imagen of this.direccion.imagenes) {
      this.imagenes.push({nombre: imagen, direccion: this.direccion._id});
    }
  }

  ngOnInit() {
  }

  public fullscreen(cadena: string): void {
    const elem = document.getElementById(cadena);
    if (elem.requestFullscreen) {
      elem.requestFullscreen().then();
    } else {
      // @ts-ignore
      if (elem.webkitRequestFullscreen) {
        // @ts-ignore
        elem.webkitRequestFullscreen();
      }
    }
  }

  public selImagen(): void {
    document.getElementById('inputImagen').click();
  }

  public mostrarImagen(e: any): void {
    this.guardandoDatos = true;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      // @ts-ignore
      img.src = reader.result;
      img.onload = () => {
        const elem = <HTMLCanvasElement>document.getElementById('canvasImagenDir');
        const ctx = elem.getContext('2d');

        elem.height = 500;
        elem.width = 1000;

        // elem.width = img.naturalWidth;
        // elem.height = img.naturalHeight;
        const imageAspectRatio = img.naturalWidth / img.naturalHeight;
        const canvasAspectRatio = elem.width / elem.height;
        let renderableHeight: number;
        let renderableWidth: number;
        let xStart: number;
        let yStart: number;

        if (imageAspectRatio < canvasAspectRatio) {
          renderableHeight = elem.height;
          renderableWidth = img.naturalWidth * (renderableHeight / img.naturalHeight);
          xStart = (elem.width - renderableWidth) / 2;
          yStart = 0;
        } else if (imageAspectRatio > canvasAspectRatio) {
          renderableWidth = elem.width;
          renderableHeight = img.naturalHeight * (renderableWidth / img.naturalWidth);
          xStart = 0;
          yStart = (elem.height - renderableHeight) / 2;
        } else {
          renderableHeight = elem.height;
          renderableWidth = elem.width;
          xStart = 0;
          yStart = 0;
        }

        // img.width and img.height will give the original dimensions
        ctx.drawImage(img, xStart, yStart, renderableWidth, renderableHeight);
        ctx.canvas.toBlob((blob) => {
          this.foto = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          this.guardarNuevaImagen();
        }, 'image/jpeg', 1);
      };
      reader.onerror = error => console.log(error);
    };
  }

  public guardarNuevaImagen(): void {
    this.guardandoDatos = true;
    const nombre = Math.random().toString(36).substr(2) + '.' + this.foto.name.split('.').pop();
    this.crmservice.subirImagenDireccion(this.foto, nombre).subscribe(
      () => {
        this.direccion.imagenes.push(nombre);
      },
      error1 => {
        console.log(error1);
      },
      () => {
        this.guardarDireccion();
      }
    );
  }

  public guardarDireccion(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {
        this.mostrarFotos();
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open('Error al actualizar la dirección', 'OK', {duration: 2000});
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open('Dirección guardada correctamente', 'OK', {duration: 2000});
      }
    );
  }

  public eliminarFoto(foto: any): void {
    const pos = this.direccion.imagenes.indexOf(foto.nombre);

    this.direccion.imagenes.splice(pos, 1);

    this.guardarDireccion();
  }

  public cambiarDireccion(foto: any): void {
    let pos = this.direccion.imagenes.indexOf(foto.nombre);

    this.direccion.imagenes.splice(pos, 1);

    pos = this.direcciones.findIndex(e => e._id === foto.direccion);

    this.direcciones[pos].imagenes.push(foto.nombre);

    this.guardarCambioDireccion(this.direcciones[pos]);
  }

  public guardarCambioDireccion(direccionN: Direccion): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {
      },
      () => {
        this.snackBar.open('Error al actualizar la dirección', 'OK', {duration: 2000});
      },
      () => {
        this.crmservice.updateDireccion(direccionN).subscribe(
          () => {
            this.guardandoDatos = false;
            this.mostrarFotos();
          },
          () => {
            this.guardandoDatos = false;
            this.snackBar.open('Error al actualizar la dirección', 'OK', {duration: 2000});
          },
          () => {
            this.guardandoDatos = false;
            this.snackBar.open('Dirección guardada correctamente', 'OK', {duration: 2000});
          }
        );
      }
    );
  }
}
