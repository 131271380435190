<div class="cabecera-modal">
  <mat-icon class="icon-modal">swap_vert</mat-icon>
  <strong>CLASIFICACIÓN CLIENTE</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!cargandoDatos" class="mat-elevation-z8" [ngStyle]="{'margin' : !mobileQuery.matches ? '15px' : '0px'}">
    <mat-card>
      <mat-card-content>
        <h4>{{clienteSel.razonSocial}}</h4>
        <mat-tab-group dynamicHeight class="mat-tab-group">
          <mat-tab label="Historial">
            <ng-template mat-tab-label>
              <mat-icon class="iconHeader">schedule</mat-icon>
              Historial
            </ng-template>

            <div class="tab-content mt-5">

              <ng-container *ngFor="let cambio of clienteSel.historial">
                <div class="col-12 caja-radio-control">

                  <div *ngIf="!mobileQuery.matches" class="d-flex justify-content-between">
                    <span>{{cambio.fCambio | date: 'dd/MM/yyyy HH:mm:SS'}}</span>
                    <span>Realizado por <strong>{{dameNombreUsuario(cambio.usuario)}}</strong></span>
                  </div>

                  <div *ngIf="mobileQuery.matches">
                    <p class="mb-0"><strong>{{dameNombreUsuario(cambio.usuario)}}</strong></p>
                    <p class="mb-0">{{cambio.fCambio | date: 'dd/MM/yyyy HH:mm'}}</p>
                  </div>

                  <hr class="mt-0">

                  <p *ngIf="!soyPrimero(cambio)">
                    <span class="letra-clasificacion" [ngStyle]="{'color': dameColorClasificacionAnt(cambio)}">
                      {{dameClasificacionAnt(cambio)}}
                    </span>
                    <span class="fs-xx-large"><strong>&rarr;</strong></span>
                    <span [ngStyle]="{'color': dameColorClasificacion(cambio.clasificacion)}" class="letra-clasificacion">
                      {{dameClasificacion(cambio.clasificacion)}}<span *ngIf="!cambio.automatico">*</span>
                    </span>.
                  </p>

                  <p *ngIf="soyPrimero(cambio)">
                    <span [ngStyle]="{'color': dameColorClasificacion(cambio.clasificacion)}" class="letra-clasificacion">
                      {{dameClasificacion(cambio.clasificacion)}}<span *ngIf="!cambio.automatico">*</span>
                    </span>.
                  </p>

                  <p>
                    <strong>Observaciones</strong> - {{cambio.comentarios}}
                  </p>

                </div>
              </ng-container>

            </div>
          </mat-tab>

          <mat-tab label="Configuración">
            <ng-template mat-tab-label>
              <mat-icon class="iconHeader">settings_applications</mat-icon>
              Configuración
            </ng-template>

            <div class="tab-content mt-5">

              <div class="alert alert-info" *ngIf="automatico" style="box-shadow: 2px 2px 1px 0 paleturquoise">
                <strong>Modo automático</strong>: la clasificación se actualizará a diario dependiendo de la facturación e hitos.
              </div>

              <div class="alert alert-warning" *ngIf="!automatico" style="box-shadow: 2px 2px 1px 0 goldenrod">
                <strong>Modo manual</strong>: la clasificacion permanece en el estado indicado y no se actualiza. En la clasificación
                se muestra con un (*).
              </div>

              <div class="col-12 caja-radio-control">
                <div class="mb-2">
                  <span><strong>Modo de clasificación: </strong></span>
                </div>

                <div class="form-check">
                  <input class="form-check-input auto-control" type="radio" name="autoRadio" id="autoRadio1" [value]="true" [(ngModel)]="automatico">
                  <label class="form-check-label auto-control" for="autoRadio1" [ngStyle]="{'font-weight' : automatico ? 'bold' : 'normal'}">Automático</label>
                </div>

                <div class="form-check mb-4">
                  <input class="form-check-input auto-control" type="radio" name="autoRadio" id="autoRadio2" [value]="false" [(ngModel)]="automatico">
                  <label class="form-check-label auto-control" for="autoRadio2" [ngStyle]="{'font-weight' : !automatico ? 'bold' : 'normal'}">Manual</label>
                </div>
              </div>

              <div *ngIf="!automatico">
                <hr>
              </div>

              <div *ngIf="!automatico" class="col-12 caja-radio-control">
                <div>
                  <label>
                    <strong>Clasificacion manual:</strong>
                    <select [(ngModel)]="clasificacion" class="form-select">
                      <option [value]="'A'">A</option>
                      <option [value]="'B1'">B</option>
                      <option [value]="'C'">C</option>
                    </select>
                  </label>
                </div>

                <div>
                  <label class="w-100">
                    <strong>Observaciones</strong>
                    <textarea class="form-control" [(ngModel)]="observaciones"></textarea>
                  </label>
                </div>
              </div>

              <div>
                <hr>
              </div>

              <div class="text-end">
                <button class="btn btn-success" (click)="aplicar()">Realizar Cambios</button>
              </div>

            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="cargandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
